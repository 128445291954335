import React, { useEffect, useState } from 'react';
import { usdValueFormatterWithoutSign } from '../../utils';
import ExchangeActionModal from './ExchangeActionModal';
import ExchangeFeeUpdateScreen from './ExchangeFeeUpdateScreen';

export default function ExchangePairList({
  pairsObject,
  baseCoin,
  search,
  refetch,
  selectedBrand,
  setHideNav,
  isExchangeBaseFooter,
  setIsExchangeBaseFooter,
}) {
  const [pair, setPair] = useState('');
  const [isExchangeFeeUpdateScreen, setisExchangeFeeUpdateScreen] =
    useState(false);
  const [isExchangeActionModal, setisExchangeActionModal] = useState(false);
  const Data = Object.keys(pairsObject).filter((itm) =>
    `${itm}/${baseCoin}`.toLowerCase().includes(search.toLowerCase()),
  );

  useEffect(() => {
    if (isExchangeFeeUpdateScreen) {
      setHideNav(true);
    } else {
      setHideNav(false);
      setPair('');
    }
  }, [isExchangeFeeUpdateScreen]);
  useEffect(() => {
    if (isExchangeActionModal || isExchangeFeeUpdateScreen) {
      setIsExchangeBaseFooter(false);
    } else {
      setIsExchangeBaseFooter(true);
    }
  }, [isExchangeFeeUpdateScreen, isExchangeActionModal]);

  return (
    <div
      style={{
        width: '100%',
        height: isExchangeBaseFooter ? '70vh' : '79vh',
        overflow: 'scroll',
      }}
    >
      {isExchangeFeeUpdateScreen ? (
        <ExchangeFeeUpdateScreen
          selectedBrand={selectedBrand}
          pair={pair}
          refetchPairs={refetch}
          setisExchangeFeeUpdateScreen={setisExchangeFeeUpdateScreen}
          setisExchangeActionModal={setisExchangeActionModal}
        />
      ) : (
        <>
          {Data.map((item, key) => {
            return (
              <>
                <div
                  onClick={() => {
                    setPair(`${item}/${baseCoin}`);
                    setisExchangeActionModal(true);
                  }}
                  key={key}
                  className="exchangePairList_item_container"
                >
                  <div className="pair_item_text">
                    {item}/{baseCoin}
                  </div>
                  <div className="pair_item_text">
                    {usdValueFormatterWithoutSign.format(pairsObject[item])}%
                  </div>
                </div>
              </>
            );
          })}

          <ExchangeActionModal
            selectedBrand={selectedBrand}
            setisExchangeFeeUpdateScreen={setisExchangeFeeUpdateScreen}
            pair={pair}
            setPair={setPair}
            base={pair.split('/')[1]}
            refetchPairs={refetch}
            setisExchangeActionModal={setisExchangeActionModal}
            isExchangeActionModal={isExchangeActionModal}
          />
        </>
      )}
    </div>
  );
}
