
import React, { useContext } from 'react';
import { CRMContext } from '../../contexts/CRMContext';

const TablePagination = () => {
  const { currentPage, currentDataSet, setCurrentPage } = useContext(
    CRMContext
  );

  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';

  const postsPerPage = 10;
  const pageNeighbors = 1;
  const totalPosts = currentDataSet ? currentDataSet.length : 0;

  const getRange = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

      let pages = getRange(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = getRange(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = getRange(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return getRange(1, totalPages);
  };

  const pages = fetchPageNumbers();

  return (
    <nav className="gx-pagination-wrapper">
      <ul className="pagination gx-pagination">
        {!totalPosts || totalPages === 1 ? (
          <li className="page-item">
            <a className="page-link active">{totalPages}</a>
          </li>
        ) : (
          pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    aria-label="Next"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              );
            }

            return (
              <li key={index} className="page-item">
                <a
                  onClick={() => setCurrentPage(page)}
                  className={`page-link ${
                    currentPage === page ? 'active' : ''
                  }`}
                >
                  {page}
                </a>
              </li>
            );
          })
        )}
      </ul>
    </nav>
  );
};

export default TablePagination;
