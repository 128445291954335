import React, { useEffect, useState } from 'react';
import PopupModalLayout from '../layouts/PopupModalLayout';

const UserSearchSettingsModal = ({
  isOpen,
  onClose,
  name,
  methods,
  apps,
  setActiveBillingMethods,
  setActiveProductApp,
  activeProductApp,
  activeBillingMethods,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [isBilling, setIsBilling] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setSearchInput('');
    }
  }, [isOpen]);

  useEffect(() => {
    const list = isBilling ? methods : apps;
    const searchQuery = searchInput.trim().toLowerCase();

    const filtter = list.filter(
      (x) =>
        x.app_name?.toLowerCase().includes(searchQuery) ||
        x.billingMethodName?.toLowerCase().includes(searchQuery),
    );

    setFilteredList(filtter);
  }, [isBilling, methods, apps, searchInput]);

  const onItemClick = (item) => {
    if (isBilling) {
      setActiveBillingMethods(item);
    } else {
      setActiveProductApp(item);
    }
    onClose();
  };

  return (
    <PopupModalLayout
      isOpen={isOpen}
      onClose={onClose}
      noHeader
      width={700}
      style={{ padding: 0 }}
    >
      <div className="user-stats-popup-container">
        <div className="header-container">
          <img
            src={require('../assets/images/endorsement-logo.svg').default}
            alt=""
            className="header-logo"
          />
        </div>
        <div className="modal-container">
          <div className="search-container">
            <input
              className="search-input"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={`Search ${name}’s ${
                isBilling ? 'Billing Type' : 'Apps'
              }...`}
            />
          </div>
          <div className="list-container">
            {filteredList.length > 0 ? (
              filteredList.map((item) => (
                <div
                  key={item.app_name || item.app_code || item.billingMethodName}
                  className="list-item"
                  onClick={() => onItemClick(item)}
                >
                  <img
                    src={item.app_icon || item.icon}
                    alt=""
                    className="item-icon"
                  />
                  <div className="item-name">
                    {item.app_name || item.app_code || item.billingMethodName}
                  </div>
                  {(isBilling
                    ? item.billingMethodName ===
                      activeBillingMethods?.billingMethodName
                    : item.app_code === activeProductApp?.app_code) && (
                    <img
                      src={
                        require('../assets/images/active-check-icon.svg')
                          .default
                      }
                      alt=""
                      className="active-icon"
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="list-empty-text">
                {`No ${isBilling ? 'Billing Type' : 'Apps'} Found...`}
              </div>
            )}
          </div>
          <div className="control-button-container">
            <div
              className={`control-item ${isBilling ? '' : 'active'}`}
              onClick={() => setIsBilling(false)}
            >
              Apps
            </div>
            <div
              className={`control-item ${isBilling ? 'active' : ''}`}
              onClick={() => setIsBilling(true)}
            >
              Billing Type
            </div>
          </div>
        </div>
      </div>
    </PopupModalLayout>
  );
};

export default UserSearchSettingsModal;
