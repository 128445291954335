import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TELLERS_API_URL } from '../../configs';
import LoadingAnimation from '../LoadingAnimation';

const ProfileView = ({ selectedBrand, color }) => {
  const [brandDetails, setBrandDetails] = useState('');

  useEffect(() => {
    setBrandDetails('');
    Axios.get(`${TELLERS_API_URL}/admin/allBankers`, {
      params: { bankerTag: selectedBrand?.bankerTag || '' },
    })
      .then(({ data }) => {
        // console.log('data', data);

        setBrandDetails(data.data[0] || '');
      })
      .catch(error => {});
  }, [selectedBrand]);

  if (!brandDetails) {
    return <LoadingAnimation dark />;
  }

  return (
    <div className="profile-fragment">
      <div className="item-group">
        <div className="header" style={{ color }}>
          What is {selectedBrand?.bankerTag || ''}
        </div>
        <div className="description">{brandDetails.description}</div>
      </div>
      <div className="item-group">
        <div className="header" style={{ color }}>
          Head Quarters
        </div>
        <div className="item-row">
          <div className="item-label">{brandDetails.address}</div>
          <div className="item-value font-weight-bold">
            {brandDetails.country}
          </div>
        </div>
        <div className="item-row">
          <div className="item-label font-weight-bold">Email</div>
          <div className="item-value">{brandDetails.email}</div>
        </div>
        <div className="item-row">
          <div className="item-label font-weight-bold">Phone</div>
          <div className="item-value">{brandDetails.phone}</div>
        </div>
      </div>
      <div className="item-group">
        <div className="header" style={{ color }}>
          Introductory Playlists
        </div>
        <div className="acton-container">
          <div className="action-item" style={{ color, marginRight: 15 }}>
            <div className="">I Am Completely New</div>
          </div>
          <div className="action-item" style={{ color }}>
            <div className="">How To Get Paid</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
