import React from 'react';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import MyProfileCover from '../../components/MyProfileCover';
import GXTokensContent from '../../components/GXTokensContent';

const GXTokensPage = () => {
  return (
    <LayoutWithSideBar>
      <div className="broker-app-main-wrapper">
        <MyProfileCover>
          <div className="main-nav-wrapper" />
        </MyProfileCover>
        <div className="broker-fragment-wrapper">
          <GXTokensContent />
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default GXTokensPage;
