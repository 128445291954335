import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  APP_CODE,
  GX_API_ENDPOINT,
  GX_AUTH_URL,
  MAILSURP_KEY,
} from '../configs';
import RegistrationLayout from '../layouts/RegistrationLayout';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import { toast } from 'react-toastify';
import LoadingAnimation from '../components/LoadingAnimation';
import BrokerSyncCode from '../components/SignupComponents/BrokerSyncCode';
import { BrokerAppContext } from '../contexts/BrokerAppContext';
import StepsSideBar from '../components/SignupComponents/StepsSideBar';
import NameForm from '../components/SignupComponents/NameForm';
import { useHistory } from 'react-router-dom';
import BlockCheckForm from '../components/SignupComponents/BlockCheckForm';
import EmailForm from '../components/SignupComponents/EmailForm';
import PasswordForm from '../components/SignupComponents/PasswordForm';
import ConfirmPasswordForm from '../components/SignupComponents/ConfirmPasswordForm';
import OTPVerifyForm from '../components/SignupComponents/OTPVerifyForm';
import SuccessForm from '../components/SignupComponents/SuccessForm';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import { MailSlurp } from 'mailslurp-client';

const RegistrationPage = ({ noReferral, appData }) => {
  const { isLoading, setIsLoading, setRefreshData } = useContext(
    BrokerAppContext,
  );

  const history = useHistory();

  const mailSlurpClient = useRef(
    new MailSlurp({
      apiKey: MAILSURP_KEY,
    }),
  );

  const [activeStep, setActiveStep] = useState();
  const [usersList, setUsersList] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [affId, setAffId] = useState('1');
  const [emailId, setEmailId] = useState('');
  const [userName, setUserName] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [isCustomMail, setIsCustomMail] = useState(false);
  const [inboxId, setInboxId] = useState('');

  useEffect(() => {
    Axios.get(`${GX_API_ENDPOINT}/listUsernames`).then((res) => {
      const { data } = res;
      if (data.status) {
        const bytes = CryptoJS.Rabbit.decrypt(data.payload, 'gmBuuQ6Er8XqYBd');
        const jsonString = bytes.toString(CryptoJS.enc.Utf8);
        const resultObj = JSON.parse(jsonString);
        setUsersList(resultObj);
      }
    });
  }, []);

  useEffect(() => {
    if (noReferral) {
      setActiveStep('UserForm');
    } else {
      setActiveStep('BrokerSyncCode');
    }
  }, [noReferral]);

  const createBlockcheckId = async () => {
    const id = userName.trim().toLowerCase();

    try {
      if (!id) {
        return toast('Please Create A Blockcheck Id');
      }
      setIsLoading(true);

      const inbox = await mailSlurpClient.current.inboxController.createInbox(
        '',
        `${id}@blockcheck.io`,
        new Date(),
        false,
        `${firstName} ${lastName}`,
        [APP_CODE],
      );

      setInboxId(inbox);

      setActiveStep('PasswordForm');

      setIsLoading(false);

      setEmailId(inbox.emailAddress);

      listenToMail(inbox.id);
    } catch (error) {
      console.log('Error on blockcheck', error);
      setIsLoading(false);
      setIsCustomMail(true);
      setEmailId('');
      setActiveStep('EmailForm');
      toast('Error on creating Blockcheck Address');
    }
  };

  const listenToMail = async (id) => {
    try {
      const email = await mailSlurpClient.current.waitController.waitForLatestEmail(
        id || inboxId.id,
        120000,
        true,
      );

      const pattern = 'use this code <strong>([0-9]{6})&nbsp;</strong>';
      const result = await mailSlurpClient.current.emailController.getEmailContentMatch(
        { pattern },
        email.id,
      );

      // console.log('result');

      setOtpInput(result?.matches[1] || '');
    } catch (error) {
      console.log('Error on blockcheck', error);
      setIsLoading(false);
      setIsCustomMail(true);
      setEmailId('');
      setActiveStep('EmailForm');
      toast('Error on creating Blockcheck Address');
    }
  };

  const sendVerificationCode = () => {
    const postData = {
      username: userName.toLowerCase().trim(),
      email: emailId.toLowerCase().trim(),
      password: passwordInput.trim(),
      ref_affiliate: affId.trim() || '1',
      account_type: 'Personal',
      signedup_app: appData?.appCode || APP_CODE,
    };
    // setActiveRegStep('VERIFY');

    // console.log('PostData', postData);

    setIsLoading(true);

    Axios.post(`${GX_AUTH_URL}/gx/user/signup`, postData)
      .then((resp) => {
        const { data } = resp;

        // console.log('Response', data);

        if (data.status) {
          setActiveStep('VerifyEmail');
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) =>
        console.log('Error on sending Verification Code', error),
      )
      .finally(() => setIsLoading(false));
  };

  const resendVerificationCode = () => {
    setIsLoading(true);

    setOtpInput('');

    const postData = {
      email: emailId.toLowerCase().trim(),
    };

    Axios.post(`${GX_AUTH_URL}/gx/user/confirm/resend`, postData)
      .then((resp) => {
        const { data } = resp;
        listenToMail(null);
        // console.log('Resend Code', data);
        toast.warn(data.message);
      })
      .catch((error) =>
        console.log('Error on resending verification code', error),
      )
      .finally(() => setIsLoading(false));
  };

  const verifyOTP = () => {
    const otp = otpInput.toString().trim();

    if (otp.length < 6) {
      return toast.error('Please Enter The Six Digit Verification Code');
    }

    setIsLoading(true);

    const email = emailId.toLowerCase().trim();

    const postData = {
      email,
      code: otp.toString().trim(),
    };

    console.log('PostData', postData);

    Axios.post(`${GX_AUTH_URL}/gx/user/confirm`, postData)
      .then((resp) => {
        const { data } = resp;

        // console.log('VerifyResp', data);

        if (data.status) {
          // loginUser();
          setActiveStep('SuccessForm');
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error on verifying OTP', error);
        setIsLoading(false);
      });
  };

  const loginUser = () => {
    const email = emailId.toLowerCase().trim();

    setIsLoading(true);

    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email,
      password: passwordInput,
    })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          toast.success('✅ Logged in...');
          Axios.post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
            email,
            app_code: appData?.appCode || APP_CODE,
          }).then((profileResp) => {
            // console.log('profileResp', profileResp.data);

            if (profileResp.data.profile_id) {
              LocalStorageHelper.setProfileId(profileResp.data.profile_id);
              setRefreshData();
            }
          });
          Axios.post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
            email,
          })
            .then((res) => {
              LocalStorageHelper.setUserDetails(
                res.data.name,
                `0x${res.data.ETH_Address}`,
                res.data.affiliate_id,
              );
            })
            .catch((error) => {
              console.log('getUserDetails Error', error);
            })
            .finally(() => {
              LocalStorageHelper.setAppLoginData(
                data.idToken,
                data.accessToken,
                email,
              );
              setIsLoading(false);
              history.push('/');
            });
        } else {
          toast.error(`❌ ${data.message}`);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log('Login Error', error);
        setIsLoading(false);
      })
      .finally(() => {});
  };

  const totalSteps = [
    { title: 'Step 1: Identify Yourself', stepName: 'UserForm', id: 0 },
    {
      title: isCustomMail
        ? 'Step 2: Email And Username'
        : 'Step 2: Create BlockCheck ID',
      stepName: isCustomMail ? 'EmailForm' : 'BlockcheckForm',
      id: 1,
    },
    { title: 'Step 3: Create Password', stepName: 'PasswordForm', id: 2 },
    {
      title: 'Step 4: Confirm Password',
      stepName: 'ConfirmPasswordForm',
      id: 3,
    },
    { title: 'Step 5: Verify BlockCheck ID', stepName: 'VerifyEmail', id: 4 },
  ];

  let fragment;

  switch (activeStep) {
    case 'BrokerSyncCode':
      fragment = (
        <BrokerSyncCode
          setAffId={setAffId}
          onNext={() => setActiveStep('UserForm')}
        />
      );
      break;
    case 'UserForm':
      fragment = (
        <NameForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          onNext={() => setActiveStep('BlockcheckForm')}
          onBack={() =>
            noReferral ? history.goBack() : setActiveStep('BrokerSyncCode')
          }
        />
      );
      break;
    case 'BlockcheckForm':
      fragment = (
        <BlockCheckForm
          setUserName={setUserName}
          userName={userName}
          usersList={usersList}
          onNext={createBlockcheckId}
          onBack={() => {
            setActiveStep('UserForm');
            setUserName('');
          }}
          onCustomMail={() => {
            setActiveStep('EmailForm');
            setUserName('');
            setIsCustomMail(true);
          }}
        />
      );
      break;
    case 'EmailForm':
      fragment = (
        <EmailForm
          usersList={usersList}
          emailId={emailId}
          setEmailId={setEmailId}
          userName={userName}
          setUserName={setUserName}
          onNext={() => setActiveStep('PasswordForm')}
          onBack={() => setActiveStep('UserForm')}
        />
      );
      break;
    case 'PasswordForm':
      fragment = (
        <PasswordForm
          password={passwordInput}
          setPassword={setPasswordInput}
          onNext={() => setActiveStep('ConfirmPasswordForm')}
        />
      );
      break;
    case 'ConfirmPasswordForm':
      fragment = (
        <ConfirmPasswordForm
          password={passwordInput}
          onBack={() => setActiveStep('PasswordForm')}
          onNext={sendVerificationCode}
        />
      );
      break;
    case 'VerifyEmail':
      fragment = (
        <OTPVerifyForm
          otpInput={otpInput}
          setOtpInput={setOtpInput}
          email={emailId}
          isCustomMail={isCustomMail}
          onReSend={resendVerificationCode}
          onNext={verifyOTP}
        />
      );
      break;
    case 'SuccessForm':
      fragment = (
        <SuccessForm onLogin={loginUser} onClose={() => history.goBack()} />
      );
      break;
    default:
  }

  return (
    <RegistrationLayout
      headerLogo={appData?.headerLogo}
      sideBarComponent={
        activeStep !== 'BrokerSyncCode' ? (
          <StepsSideBar stepsList={totalSteps} activeStep={activeStep} />
        ) : null
      }
    >
      {isLoading && (
        <div className="loading-container">
          <LoadingAnimation dark />
        </div>
      )}
      {fragment}
    </RegistrationLayout>
  );
};

export default RegistrationPage;
