
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GX_API_ENDPOINT } from '../configs';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import { formatterWithTwoDigits } from '../utils';
import AddEndorsementPopup from './AddEndorsementPopup';
import EditEndorsementPopup from './EditEndorsementPopup';

const ProductDetailsView = ({ selectedProduct, onBack }) => {
  const [isEndoresOpen, setIsEndoresOpen] = useState(false);
  const [appEndoresments, setAppEndoresments] = useState([]);
  const [listWidth, setListWidth] = useState(0);

  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [selectedEndorsement, setSelectedEndorsement] = useState();

  const scrollRef = useRef();

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const width = scrollRef.current.clientWidth;
      setListWidth(width);
    }
  }, [
    scrollRef.current,
    scrollRef.current ? scrollRef.current.clientWidth : 0,
  ]);

  useEffect(() => {
    updatedEndores();
  }, [selectedProduct]);

  const updatedEndores = () => {
    axios
      .get(`${GX_API_ENDPOINT}/gxb/product/user/fee/get/per/app`, {
        params: {
          email: LocalStorageHelper.getAppEmail(),
          product_id: selectedProduct.product_id,
        },
      })
      .then(({ data }) => {
        // console.log('Data', data);
        const user = data.userFees ? data.userFees[0] : '';
        setAppEndoresments(user.appProducts);
      })
      .catch((error) => {});
  };

  return (
    <div className="product-details-wrapper">
      <div className="product-header-container">
        <img
          src={selectedProduct?.product_icon}
          alt=""
          className="product-icon"
        />
        <div className="header-details">
          <div className="product-name">{selectedProduct?.product_name}</div>
          <div className="product-sub-title">{selectedProduct?.sub_text}</div>
        </div>
        <div
          className={`action-btn ${isEndoresOpen ? 'alt' : ''}`}
          onClick={() => setIsEndoresOpen(!isEndoresOpen)}
        >
          {isEndoresOpen ? 'Finish' : 'Endorse'}
        </div>
      </div>
      <div className="product-desc">{selectedProduct?.full_description}</div>
      <div ref={scrollRef} className="licence-details">
        {isEndoresOpen ? (
          <>
            {appEndoresments?.map((item) => (
              <div
                key={item._id}
                className="item-container"
                style={{ flexBasis: listWidth / 4 }}
                onClick={() => setSelectedEndorsement(item)}
              >
                <div className="item-title">{item.app_code}</div>
                <div className="item-value">
                  {formatterWithTwoDigits.format(item?.fee_percentage)}%
                </div>
                <img
                  src={require('../assets/images/edit-icon-light.svg').default}
                  alt=""
                  className="edit-button"
                />
              </div>
            ))}
            <div
              className="item-container add-action"
              style={{ flexBasis: listWidth / 4 }}
              onClick={() => setIsAddPopupOpen(true)}
            >
              <img
                src={require('../assets/images/plus-icon.svg').default}
                alt=""
                className=""
              />
              <div className="item-title">Add New Endorsement</div>
            </div>
          </>
        ) : (
          <>
            <div
              className="item-container"
              style={{ flexBasis: listWidth / 4 }}
            >
              <div className="item-title">Product Code</div>
              <div className="item-value">{selectedProduct?.product_code}</div>
            </div>
            <div
              className="item-container"
              style={{ flexBasis: listWidth / 4 }}
            >
              <div className="item-title">Lifetime License</div>
              <div className="item-value text-capitalize">
                {JSON.stringify(selectedProduct?.lifetime || false)}
              </div>
            </div>
            <div
              className="item-container"
              style={{ flexBasis: listWidth / 4 }}
            >
              <div className="item-title">Annual License</div>
              <div className="item-value text-capitalize">
                {JSON.stringify(selectedProduct?.annual || false)}
              </div>
            </div>
            <div
              className="item-container"
              style={{ flexBasis: listWidth / 4 }}
            >
              <div className="item-title">Monthly License</div>
              <div className="item-value text-capitalize">
                {JSON.stringify(selectedProduct?.monthly || false)}
              </div>
            </div>
          </>
        )}
      </div>
      <AddEndorsementPopup
        isOpen={isAddPopupOpen}
        onClose={() => setIsAddPopupOpen(false)}
        selectedProduct={selectedProduct}
        updatedEndores={updatedEndores}
      />
      <EditEndorsementPopup
        onClose={() => setSelectedEndorsement()}
        selectedEndorsement={selectedEndorsement}
        updatedEndores={updatedEndores}
        selectedProduct={selectedProduct}
        backToEndorsement={() => {
          setSelectedEndorsement();
          onBack();
        }}
      />
    </div>
  );
};

export default ProductDetailsView;
