const FilterButton = ({
  title,
  onOpen,
  isOpen,
  filters,
  activeFilter,
  onFilterSelect,
}) => (
  <div className={`filter-add-btn ${isOpen && 'opened'}`}>
    <div className="filter-btn" onClick={onOpen}>
      {activeFilter ? (
        <img src={activeFilter.icon} alt="" className="filter-icon" />
      ) : (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="filter-icon"
        >
          <path
            d="M10.9744 5.48718C10.9744 5.63271 10.9165 5.77228 10.8136 5.87518C10.7107 5.97809 10.5712 6.0359 10.4256 6.0359H6.0359V10.4256C6.0359 10.5712 5.97809 10.7107 5.87518 10.8136C5.77228 10.9165 5.63271 10.9744 5.48718 10.9744C5.34165 10.9744 5.20208 10.9165 5.09918 10.8136C4.99627 10.7107 4.93846 10.5712 4.93846 10.4256V6.0359H0.548718C0.403189 6.0359 0.26362 5.97809 0.160716 5.87518C0.0578112 5.77228 0 5.63271 0 5.48718C0 5.34165 0.0578112 5.20208 0.160716 5.09918C0.26362 4.99627 0.403189 4.93846 0.548718 4.93846H4.93846V0.548718C4.93846 0.403189 4.99627 0.26362 5.09918 0.160716C5.20208 0.0578112 5.34165 0 5.48718 0C5.63271 0 5.77228 0.0578112 5.87518 0.160716C5.97809 0.26362 6.0359 0.403189 6.0359 0.548718V4.93846H10.4256C10.5712 4.93846 10.7107 4.99627 10.8136 5.09918C10.9165 5.20208 10.9744 5.34165 10.9744 5.48718Z"
            fill="#464B4E"
          />
        </svg>
      )}

      {activeFilter?.title || title}
    </div>
    {isOpen && (
      <div className="filters-menu">
        {filters?.map((item) => (
          <div
            key={item.title}
            className="menu-item"
            onClick={() => onFilterSelect(item)}
          >
            <img src={item.icon} alt="" className="menu-icon" />
            <div className="menu-title">{item.title}</div>
          </div>
        ))}
        <div
          className={`remove-btn ${activeFilter || 'disabled'}`}
          onClick={() => (activeFilter ? onFilterSelect('') : null)}
        >
          <img
            src={require('../../assets/images/filterIcons/close.svg').default}
            alt=""
            className="menu-icon"
          />
          <div className="menu-title">Remove Filter</div>
        </div>
      </div>
    )}
  </div>
);

export default FilterButton;
