import React, { useContext } from 'react';
import expandIcon from '../../assets/images/chat-expand-icon.svg';
import chatsLogo from '../../assets/images/chatsLogo.svg';
import ChatContext from '../../contexts/ChatContext';

const ChatHeader = ({ toggleChatWidth, isChatExpanded }) => {
  const { isChatClosed, toggleChatClose } = useContext(ChatContext);

  return (
    <div className="chat-header-wrapper">
      <div className="user-details">
        <div className="d-flex w-100 logo-container align-items-center px-4">
          <img
            src={require('../../assets/images/influence-chat-icon.svg').default}
            alt=""
            className="header-logo"
          />
          <div className="expand-toggle" onClick={toggleChatClose}>
            <img
              src={require('../../assets/images/chevron-left.svg').default}
              alt=""
              className="arrow-icon"
              style={{
                transform: `rotate(${isChatClosed ? '0deg' : '180deg'})`,
              }}
            />
          </div>
          <img
            onClick={toggleChatWidth}
            className="expand-icon"
            src={expandIcon}
            alt=""
          />
        </div>
        <div className="poweredBy">
          Support Powered By
          <img src={chatsLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
