import React, { useContext, useEffect } from 'react';
import { BrokerAppContext } from '../../../../contexts/BrokerAppContext';
import { formatterHelper } from '../../../../utils';

const CompleteView = ({ purchaseData, onClose, selectedAsset }) => {
  const { walletCoinData, getWalletCoinData } = useContext(BrokerAppContext);

  console.log('walletCoinData', walletCoinData);

  useEffect(() => {
    getWalletCoinData();
  }, []);

  let updatedSendBalance = 0;

  if (walletCoinData) {
    const wallet = walletCoinData.find(
      x => x.coinSymbol === selectedAsset.coinSymbol,
    );

    if (wallet) {
      updatedSendBalance = wallet.coinValue;
    }
  }

  return (
    <div className="body-container">
      <div className="title">Success</div>
      <div className="body">
        <div className="sub-header">Here Are Your License Details</div>
        <div className="complete-item">
          <div className="item-title">Brand</div>
          <div className="item-value">GlobalXChange</div>
        </div>
        <div className="complete-item">
          <div className="item-title">License</div>
          <div className="item-value">{purchaseData?.product_code}</div>
        </div>
        <div className="sub-header">New {selectedAsset?.coinName} Balance</div>
        <div className="complete-item">
          <div className="item-title">
            {formatterHelper(updatedSendBalance, selectedAsset?.coinSymbol)}
          </div>
          <div className="item-value">{selectedAsset?.coinSymbol}</div>
        </div>
      </div>
      <div onClick={onClose || null} className="action-button">
        Close
      </div>
    </div>
  );
};

export default CompleteView;
