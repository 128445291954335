import React from 'react';

const BrokerSearchView = ({ brokerDetails, onBack, backToLogin, onNext }) => {
  return (
    <div className="view-container">
      <div className="sub-text">Is This Your Broker?</div>
      {brokerDetails ? (
        <>
          <div className="broker-details actions-container">
            <img
              src={brokerDetails?.profile_img}
              alt=""
              className="broker-image"
            />
            <div className="broker-name">{brokerDetails?.name}</div>
            <div className="broker-email">{brokerDetails?.email}</div>
            <hr />
            <div className="bio-header">Bio</div>
            <div className="broker-bio">{brokerDetails?.bio}</div>
          </div>
          <div className="actions-container mt-0">
            <div className="form-buttons">
              <div className="action-outlined" onClick={onBack}>
                Go Back
              </div>
              <div className="action-filled" onClick={onNext}>
                Proceed
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="actions-container">
          <img
            src={require('../../assets/images/user-avatar.svg').default}
            alt=""
            className="broker-image"
          />
          <div className="no-broker-text">
            The Information You Entered Doesn’t Match Any Of Our Brokers
          </div>
          <div className="text-center mt-2">Please Try Again</div>
          <div className="form-buttons mt-5">
            <div className="action-outlined" onClick={backToLogin}>
              Back To Login
            </div>
            <div className="action-filled" onClick={onBack}>
              Try Again
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrokerSearchView;
