import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import ViewHeader from '../BotLineage/ViewHeader';
import LicensesList from './LicensesList';

const LicensesBot = ({ userEmail, onBack }) => {
  const [brandsList, setBrandsList] = useState();
  const [activeBrand, setActiveBrand] = useState('');
  const [queryEmail, setQueryEmail] = useState('');

  useEffect(() => {
    (async () => {
      setQueryEmail(userEmail || LocalStorageHelper.getAppEmail());
    })();
  }, [userEmail]);

  useEffect(() => {
    Axios.get('https://teller2.apimachine.io/admin/allBankers')
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const brands = data.data || [];

          setBrandsList([
            {
              bankerTag: 'All Licenses',
              profilePicURL: require('../../../assets/images/buy-icon.png')
                .default,
            },
            ...brands,
          ]);
          // console.log('data', data);
        } else {
          setBrandsList([]);
        }
      })
      .catch((error) => {
        setBrandsList([]);
      });
  }, []);

  useEffect(() => {
    if (brandsList) {
      setActiveBrand(brandsList[0]);
    }
  }, [brandsList]);

  const color = activeBrand?.colorCode
    ? activeBrand?.colorCode[0] === '#'
      ? activeBrand?.colorCode
      : `#${activeBrand?.colorCode}`
    : '#08152D';

  return (
    <div className="action-add-wrapper licenses-bots-wrapper faq-form-container">
      <ViewHeader
        headerText="Select A Brand"
        disableExpand
        disableBack={!onBack}
        className="m-0"
        onBack={onBack}
      />
      <div className="view-wrapper">
        <div className="choice-group">
          <div className="choice-list">
            {brandsList &&
              brandsList.map((item) => (
                <div
                  onClick={item.disabled ? null : () => setActiveBrand(item)}
                  key={item._id}
                  className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                    activeBrand?.bankerTag === item.bankerTag ? 'active' : ''
                  }`}
                >
                  <img
                    src={item.profilePicURL}
                    alt=""
                    className="choice-icon"
                  />
                  <div className="choice-name">{item.bankerTag}</div>
                </div>
              ))}
          </div>
        </div>
        {activeBrand && (
          <LicensesList
            userEmail={queryEmail}
            activeBrand={activeBrand}
            color={color}
          />
        )}
      </div>
    </div>
  );
};

export default LicensesBot;
