import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { APP_CODE } from '../configs';
import BrandsPage from '../pages/AffiliateApp/BrandsPage';
import ChainViewPage from '../pages/AffiliateApp/ChainViewPage';
import ControllerPage from '../pages/AffiliateApp/ControllerPage';
import CRMPage from '../pages/AffiliateApp/CRMPage';
import CompensationHomePage from '../pages/AffiliateApp/CompensationHomePage';
import EarnPage from '../pages/AffiliateApp/EarnPage';
import EndoresmentHomePage from '../pages/AffiliateApp/EndoresmentHomePage';
import GXTokensPage from '../pages/AffiliateApp/GXTokensPage';
import LoginPage from '../pages/AffiliateApp/LoginPage';
import MyEndoresmentPage from '../pages/AffiliateApp/MyEndoresmentPage';
import MyProfilePage from '../pages/AffiliateApp/MyProfilePage';
import NetworkHomePage from '../pages/AffiliateApp/NetworkHomePage';
import PreRegisterPage from '../pages/AffiliateApp/PreRegisterPage';
import VaultsPage from '../pages/AffiliateApp/VaultsPage';
import RegistrationRouter from './RegistrationRouter';
import AffiliateBankHomePage from '../pages/AffiliateApp/AffiliateBankHomePage';
import ClassroomHomePage from '../pages/AffiliateApp/ClassroomHomePage';
import BankPage from '../pages/AffiliateApp/BankPage';

const BrokerRouter = () => {
  const APP_DATA = {
    headerLogo: require('../assets/images/affliate-app-dark-full-logo.svg')
      .default,
    appCode: APP_CODE,
  };

  return (
    <BrowserRouter basename="apps/affiliate">
      <Switch>
        <Route exact path="/" component={EndoresmentHomePage} />
        <Route path="/compensation" component={CompensationHomePage} />
        <Route path="/industries" component={EarnPage} />
        <Route path="/network" component={NetworkHomePage} />
        <Route path="/affiliatebank" component={AffiliateBankHomePage} />
        <Route path="/bank" component={BankPage} />
        <Route path="/classrooms" component={ClassroomHomePage} />
        <Route path="/profile" component={MyProfilePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/crm" component={CRMPage} />
        <Route path="/gx-tokens" component={GXTokensPage} />
        <Route path="/controller" component={ControllerPage} />
        <Route path="/pre-registration" component={PreRegisterPage} />
        <Route path="/vaults" component={VaultsPage} />
        <Route path="/chainview" component={ChainViewPage} />
        <Route path="/endorsements" component={MyEndoresmentPage} />
        <Route path="/brands" component={BrandsPage} />
        <RegistrationRouter basename="apps/affiliate" appData={APP_DATA} />
      </Switch>
    </BrowserRouter>
  );
};

export default BrokerRouter;
