
import React from 'react';
import mtzon from 'moment-timezone';

const VideoPlayer = ({ activeVideo, playerRef, nextVideos, onClick }) => {
  const dateFormatter = dateString => {
    const date = new Date(dateString);
    const m = mtzon.utc(date);

    const formattedDate = m
      .clone()
      .tz('America/New_York')
      .format('MMM Do YYYY');
    return formattedDate;
  };

  return (
    <div className="row">
      {activeVideo ? (
        <>
          <div className="col-md-8">
            <div className="main-video-wrapper">
              <div className="main-video-container">
                <iframe
                  title="currentVideo"
                  className="player"
                  width="100%"
                  height="100%"
                  src={
                    activeVideo.kind === 'cdn'
                      ? activeVideo.snippet.resourceId.videoId
                      : `https://www.youtube-nocookie.com/embed/${activeVideo.snippet.resourceId.videoId}?rel=0`
                  }
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="main-video-details-wrapper">
              <div className="main-video-details">
                <h6 className="video-date">
                  {dateFormatter(activeVideo.snippet.publishedAt)}
                </h6>
                <h4 className="video-title">{activeVideo.snippet.title}</h4>
                <p className="video-desc">{activeVideo.snippet.description}</p>
              </div>
              <div className="main-video-related">
                <h6 className="related-header">Up Next</h6>
                <div className="row">
                  {nextVideos.map(item => (
                    <div key={item.id} className="col-6">
                      <div className="videoItem">
                        <a className="thumbnail" onClick={() => onClick(item)}>
                          <img
                            src={item.snippet.thumbnails.medium.url}
                            alt=""
                          />
                          <div className="play" />
                        </a>
                        <p
                          className="title"
                          style={{ fontSize: '0.6rem', marginBottom: '0' }}
                        >
                          <a>{item.snippet.title}</a>
                        </p>
                        <p className="author" style={{ fontSize: '10px' }}>
                          <a>{item.snippet.channelTitle}</a>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
