import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button, Icon, InputNumber } from 'antd';
import {
  australiaFlag,
  canadaFlag,
  usaFlag,
  eurFlag,
  britishFlag,
  japanFlag,
  uaeFlag,
  chinaFlag,
  indiaFlag
} from '../../../assets/images/CountryIcons';

const Converter = ({ wholeSaleRate, myFee, retailPrice }) => {
  const wholesaleCurrencyList = [
    { symbol: '$', currency: 'USD', flag: usaFlag },
    { symbol: '$', currency: 'CAD', flag: canadaFlag },
    { symbol: '$', currency: 'AUD', flag: australiaFlag },
    { symbol: '€', currency: 'EUR', flag: eurFlag },
    { symbol: '£', currency: 'GPB', flag: britishFlag },
    { symbol: '¥', currency: 'JPY', flag: japanFlag },
    { symbol: 'د.إ', currency: 'AED', flag: uaeFlag },
    { symbol: '¥', currency: 'CNY', flag: chinaFlag },
    { symbol: '₹', currency: 'INR', flag: indiaFlag }
  ];
  const myFeesCurrencyList = [
    { symbol: '$', currency: 'USDT', flag: 'US' },
    { symbol: '₿', currency: 'BTC', flag: 'US' }
  ];

  const [wholeSaleRateCurrency, setWholeSaleRateCurrency] = useState(
    wholesaleCurrencyList[0]
  );
  const [myFeesCurrency, setMyFeesCurrency] = useState(myFeesCurrencyList[0]);

  const wholeSaleMenu = (
    <Menu
      onClick={e =>
        setWholeSaleRateCurrency(
          wholesaleCurrencyList.find(item => item.currency === e.key)
        )
      }
    >
      {wholesaleCurrencyList.map(item => (
        <Menu.Item key={item.currency}>
          <img src={item.flag} alt="" height="12" width="32" />
          {`${item.symbol} ${item.currency}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  const myFeesMenu = (
    <Menu
      onClick={e =>
        setMyFeesCurrency(
          myFeesCurrencyList.find(item => item.currency === e.key)
        )
      }
    >
      {myFeesCurrencyList.map(item => (
        <Menu.Item key={item.currency}>
          {`${item.symbol} ${item.currency}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="currency-convert-wrapper row mx-0">
      {/* Wholesale Rate */}
      <div className="col-md-4 px-0">
        <div className="converter-card">
          <div className="controls">
            <Dropdown overlay={wholeSaleMenu}>
              <Button>
                {`${wholeSaleRateCurrency.symbol} ${wholeSaleRateCurrency.currency}`}{' '}
                <Icon type="down" />
              </Button>
            </Dropdown>
            <h6 className="tittle">Wholesale Rate</h6>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              {/* <h5 className="input-currency">$</h5>{" "} */}
              <InputNumber
                placeholder={'00.00'}
                value={wholeSaleRate}
                disabled
              />
            </div>
            <h6 className="input-valuation">
              1 {wholeSaleRateCurrency.currency} = 0.876647{' '}
              {myFeesCurrency.currency}
            </h6>
          </div>
        </div>
      </div>
      <div className="swap-icon-wrapper left-side">
        <div className="swap-icon-container">
          <Icon type="swap" style={{ color: 'white' }} />
        </div>
      </div>
      {/* My Fees */}
      <div className="col-md-4 px-0">
        <div className="converter-card converted">
          <div className="controls">
            <Dropdown overlay={myFeesMenu}>
              <Button>
                {`${myFeesCurrency.symbol} ${myFeesCurrency.currency}`}{' '}
                <Icon type="down" />
              </Button>
            </Dropdown>
            <h6 className="tittle">My Fees</h6>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              {/* <h5 className="input-currency">$</h5>{" "} */}
              <InputNumber placeholder={'00.00'} value={myFee} disabled />
            </div>
            <h6 className="input-valuation">
              1 {myFeesCurrency.currency} = 0.876647{' '}
              {wholeSaleRateCurrency.currency}
            </h6>
          </div>
        </div>
      </div>
      <div className="swap-icon-wrapper right-side">
        <div className="swap-icon-container">
          <Icon type="swap" style={{ color: 'white' }} />
        </div>
      </div>
      {/* Retail Price */}
      <div className="col-md-4 px-0">
        <div className="converter-card">
          <div className="controls">
            <Dropdown disabled>
              <Button style={{ opacity: '0' }}>
                {''} <Icon type="down" />
              </Button>
            </Dropdown>
            <h6 className="tittle">Retail Price</h6>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              {/* <h5 className="input-currency">$</h5>{" "} */}
              <InputNumber placeholder={'00.00'} value={retailPrice} disabled />
            </div>
            <h6 className="input-valuation">1 USD = 0.876647 EUR</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Converter;
