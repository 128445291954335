import React from 'react';
import GXTWallet from './GXTWallet';
import MarketLimit from './MarketLimit';
import TradingView from './TradingView';
import BuySellTable from './BuySellTable';

const GXTokensContent = () => {
  return (
    <div className="gx-token-wrapper fragment-wrapper">
      <div className="row mx-0 mb-5">
        <GXTWallet />
        <MarketLimit />
      </div>
      <div className="row mx-0 flex-grow-1">
        <TradingView />
        <BuySellTable />
      </div>
    </div>
  );
};

export default GXTokensContent;
