import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import { GX_API_ENDPOINT } from '../configs';
import BrokerAppSideNav from '../components/BrokerAppNav';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import BrokerChat from '../components/BrokerChat';
import { BrokerAppContext } from '../contexts/BrokerAppContext';
import ChatContext from '../contexts/ChatContext';

const LayoutWithSideBar = ({ children, rightSideBar }) => {
  const { isAdminLoggedIn, userFullName, profileImg } = useContext(
    BrokerAppContext,
  );
  const { setBotActiveCategory, setIsBot } = useContext(ChatContext);

  const [loggedIn, setLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isChatExpanded, setIsChatExpanded] = useState(false);

  useEffect(() => {
    // Checking if token is valid
    const token = LocalStorageHelper.getAppToken();
    if (token) {
      Axios.post(`${GX_API_ENDPOINT}/brokerage/verify_token`, {
        token,
        userpoolid: 'us-east-2_ALARK1RAa',
      })
        .then((resp) => {
          let user;
          if (resp.data) user = resp.data;
          else user = null;

          if (user !== null && Date.now() < user.exp * 1000) {
            // console.log('Session valid!!');
            setLoggedIn(true);
          } else {
            // console.log('Invalid Session', resp);
            LocalStorageHelper.setAppLoginData('', '', '');
            setLoggedIn(false);
          }
        })
        .catch((error) => {
          // console.log('Token verify Error', error);
          setLoggedIn(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      setLoggedIn(false);
    }

    return () => {};
  }, []);

  const onRemoveAdminView = () => {
    LocalStorageHelper.removeAdminView();
    window.location.reload();
  };

  const onChangeUserClick = () => {
    setBotActiveCategory({ title: 'Admin' });
    setIsBot(true);
  };

  return (
    <>
      {!loggedIn && <Redirect to="/login" />}
      <div className="broker-app-wrapper">
        <div className="broker-app-row">
          <BrokerAppSideNav />
          <div className="broker-main-row">
            <div className="d-flex flex-column flex-fill">
              <div className="d-flex flex-fill">{children}</div>
              {isAdminLoggedIn ? (
                <div className="admin-view-wrapper">
                  <div className="header">Currently Viewing As</div>
                  <img src={profileImg} alt="" className="user-img" />
                  <div className="user-name">{userFullName}</div>
                  <div className="filled-btn" onClick={onChangeUserClick}>
                    Change User
                  </div>
                  <div className="outlined-btn" onClick={onRemoveAdminView}>
                    Close User View
                  </div>
                </div>
              ) : null}
            </div>
            {rightSideBar || (
              <BrokerChat
                toggleChatWidth={() => setIsChatExpanded(!isChatExpanded)}
                isChatExpanded={isChatExpanded}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutWithSideBar;
