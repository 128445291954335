import React from "react";

const MarketLimit = () => {
  return (
    <div className="col-md-4 d-flex">
      <div className="market-limit-wrapper">
        <h6 className="market-limit-header">
          Market <span>| Limit</span>
        </h6>
        <div className="market-from-wrapper">
          <form className="market-form">
            <input className="market-input" type="number" placeholder="Price" />
            <button className="submit-btn" type="submit">
              GXT
            </button>
          </form>
          <form className="market-form">
            <input
              className="market-input"
              type="number"
              placeholder="Amount"
            />
            <button className="submit-btn" type="submit">
              USD
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MarketLimit;
