import React, { useContext, useEffect } from 'react';
import { OneToOneChatContext } from '../../../contexts/OneToOneChatContext';
import moment from 'moment-timezone';
import guest from '../../../assets/images/guest.jpg';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';
import FilePreviewOnChat from '../FilePreviewOnChat';
import LoadingAnimation from '../../LoadingAnimation';
import ChatInputArea from '../ChatInputArea';
import { toast } from 'react-toastify';

const MessageList = () => {
  const {
    messageArray,
    isUserTyping,
    userObject,
    selectedUser,
    sendMessage,
    notifyTyping,
    setSelectedUser,
  } = useContext(OneToOneChatContext);

  useEffect(() => {
    if (messageArray?.length > 1) {
      const objDiv = document.getElementById('chat-area');
      // console.log('top: ', objDiv.scrollTop, 'height: ', objDiv.scrollHeight);
      try {
        objDiv.scrollTop = objDiv.scrollHeight;
      } catch (error) {}

      // console.log(objDiv);
    }
  }, [messageArray, isUserTyping]);

  const handleSubmitMessage = (inputText, setInputText) => {
    const message = inputText.trim();

    if (!message) {
      return toast.error('Please Input A Message');
    }

    sendMessage(message);
    setInputText('');
  };

  return (
    <div className="chat-area-wrapper d-flex flex-column">
      {messageArray ? (
        <>
          {messageArray?.length > 0 ? (
            <div
              id="chat-area"
              className="chats flex-grow-1"
              style={{ height: 0, overflowY: 'auto' }}
            >
              {messageArray?.map((item) => {
                return item.type !== 'group' ? (
                  <Message
                    key={item.timestamp}
                    messageObj={item}
                    isSent={userObject?.username === item.sender?.username}
                  />
                ) : (
                  <InChatInfo key={item.timestamp} message={item.message} />
                );
              })}
            </div>
          ) : (
            <div className="d-flex flex-fill flex-column justify-content-center">
              <div className="text-center">
                Start Your Conversation with {selectedUser?.first_name}
              </div>
            </div>
          )}
          <br />
          <div
            className="typing-indicator"
            style={{ opacity: isUserTyping ? 1 : 0 }}
          >
            {/* &nbsp;&nbsp;&nbsp; */}
            <div className="ticontainer">
              <div className="tiblock">
                <div className="tidot" />
                <div className="tidot" />
                <div className="tidot" />
              </div>
            </div>
          </div>
          <ChatInputArea
            onTypingHandler={notifyTyping}
            onSubmit={handleSubmitMessage}
            isFileLoading={false}
            initiateSendImage={() => null}
            disabledFilePicker
          />
        </>
      ) : (
        <div className="d-flex flex-fill flex-column justify-content-center">
          <LoadingAnimation />
        </div>
      )}
    </div>
  );
};

const InChatInfo = ({ message }) => {
  return (
    <div className="in-chat-info">
      <span>{message}</span>
    </div>
  );
};

const Message = ({ messageObj, isSent }) => {
  const { profileImg } = useContext(BrokerAppContext);

  return (
    <>
      {isSent ? (
        <div className="answer">
          <div className="question-wrap">
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className="question-text">{messageObj.message}</p>
          </div>
          <div className="bot">
            <img
              className="img-logo"
              src={messageObj.sender?.avatar || profileImg || guest}
              alt={messageObj.sender?.name || ''}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
        </div>
      ) : (
        <div className="question">
          <div className="bot">
            <img
              className="img-logo"
              src={
                messageObj.sender?.avatar ||
                require('../../../assets/images/affliate-app-white-logo.svg')
                  .default
              }
              alt={messageObj.sender?.name || ''}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
          <div className="question-wrap">
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className="question-text">{messageObj.message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageList;
