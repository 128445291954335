import React from 'react';
import {
  getTimeFromTimeStamp,
  getDateFromTimeStamp,
  usdValueFormatter
} from '../../../utils';
import coin from '../../../assets/images/coin.svg';
import CADIcon from '../../../assets/images/cadIcon.png';

const DirectTokenTransactionalItem = ({ data }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '26%', paddingLeft: '15px' }}
      >
        <label className="token-txn-name">{data.txn.name}</label>
      </div>

      <div className="flexed-item" style={{ width: '9%' }}>
        <img className="token-txn-country-icon" src={CADIcon} alt="" />
        {/* <label className="token-txn-country">
              {parseCountryFromAddress(data.txn.address)}
            </label> */}
      </div>
      <div className="flexed-item" style={{ width: '19%' }}>
        <label className="token-txn-date">
          {getDateFromTimeStamp(data.txn.timestamp)}
        </label>
        <label className="token-txn-time">
          {getTimeFromTimeStamp(data.txn.timestamp)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '24%' }}>
        <label className="token-txn-volume">
          {usdValueFormatter.format(data.txn.usd_amt)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '13%' }}>
        <label className="token-txn-commission">
          {data.commissions.ps > 0
            ? usdValueFormatter.format(data.commissions.it_commission)
            : usdValueFormatter.format(data.commissions.dt_commission)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default DirectTokenTransactionalItem;
