import React from "react";

const BuySellTable = () => {
  return (
    <div className="col-md-4 h-100">
      <div className="buy-sell-table-wrapper h-100">
        <div className="table-header-wrapper">
          <div className="txn-type-wrapper">
            <div className="txn-type">Buy</div>
            <div className="txn-separator">|</div>
            <div className="txn-type sell-txn">Sell</div>
          </div>
          <div className="period-wrapper">
            <div className="period">1D</div>
            <div className="period">7D</div>
            <div className="period">1M</div>
            <div className="period">3M</div>
            <div className="period">1Y</div>
            <div className="period">ALL</div>
          </div>
        </div>
        <div className="sales-table-wrapper">
          <table className="sales-table">
            <thead>
              <tr>
                <th className="time-header">Time</th>
                <th className="price-header">Price</th>
                <th className="amount-header">Amount</th>
              </tr>
            </thead>
            <tbody>
              {Array(15)
                .fill(1)
                .map((item, index) => (
                  <tr key={index}>
                    <td className="time-value">13:03:00</td>
                    <td className="price-value">0.021212</td>
                    <td className="amount-value">0.212123</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BuySellTable;
