import React from 'react';

export default function NumPad({
    currentText,
    updatedCallback,
    setIsWithdrawOpen,
}) {
    const tabs = [
        {
            int: true,
            value: 7,
        },
        {
            int: true,
            value: 8,
        },
        {
            int: true,
            value: 9,
        },
        {
            int: false,
            value: require('../../assets/mobileassets/copy-icon-white.png').default,
            fkey: 'COPY',
        },
        {
            int: true,
            value: 4,
        },
        {
            int: true,
            value: 5,
        },
        {
            int: true,
            value: 6,
        },
        {
            int: false,
            value: require('../../assets/mobileassets/paste-icon.png').default,
            fkey: 'PASTE',
        },
        {
            int: true,
            value: 1,
        },
        {
            int: true,
            value: 2,
        },
        {
            int: true,
            value: 3,
        },
        {
            int: false,
            fkey: 'CLEAR',
            value: require('../../assets/mobileassets/clear-icon.png').default,
        },
        {
            int: true,
            value: '.',
        },
        {
            int: true,
            value: '0',
        },

        {
            int: false,
            fkey: 'BACK',
            value: require('../../assets/mobileassets/numpad-back-icon.png').default,
        },
        {
            int: false,
            value: require('../../assets/mobileassets/hide-keyboard-icon.png')
                .default,
            fkey: 'HIDE',
        },
    ];
    const numberHandle = (i) => {
        const input = i.toString();
        if (input) {
            let oldText = currentText;
            let newText;
            if (input === 'BACK') {
                newText = oldText.toString().slice(0, -1);
            } else if (input === 'CLEAR') {
                newText = '';
            } else if (input === '.') {
                if (!oldText.toString().includes('.')) {
                    console.log('Dot comes here');
                    newText = oldText.toString() + input;
                } else {
                    newText = oldText.toString();
                }
            } else if (input === 'HIDE') {
                setIsWithdrawOpen(false);
            } else if (input === 'PASTE') {
                console.log('paste');
            } else if (input === 'COPY') {
                console.log('copy');
            } else {
                newText = oldText.toString().concat(input);
            }

            updatedCallback(newText);
        }
    };
    return (
        <div className="numpad_container">
            <div className="keyboard_container">
                {tabs.map((item, key) => {
                    return (
                        <div
                            className="keyboard_item_box"
                            onClick={() => {
                                item.int ? numberHandle(item.value) : numberHandle(item.fkey);
                            }}
                            key={key}
                        >
                            {item.int ? (
                                item.value
                            ) : (
                                <img
                                    style={{
                                        height: 30,
                                        width: 30,
                                    }}
                                    alt=""
                                    src={item.value}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
