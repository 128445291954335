import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../configs';
import PopupModalLayout from '../../layouts/PopupModalLayout';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import ProductDetails from './ProductDetails';
import ProductImageUploader from './ProductImageUploader';
import ExtraRatings from './ExtraRatings';

const AddEndorsementPopup = ({
  isOpen,
  onClose,
  selectedProduct,
  updatedEndores,
}) => {
  const [allApps, setAllApps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApp, setSelectedApp] = useState();
  const [percentageInput, setPercentageInput] = useState('');
  const [images, setImages] = useState([]);
  const [overallRating, setOverallRating] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [extraRatings, setExtraRatings] = useState([]);
  const [addintionalLinks, setAddintionalLinks] = useState(['', '']);

  useEffect(() => {
    axios
      .get(`${GX_API_ENDPOINT}/gxb/apps/get`)
      .then(({ data }) => {
        setAllApps(data.apps || []);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setPercentageInput('');
      setSelectedApp();
      setIsLoading(false);
      setImages([]);
      setOverallRating('');
      setShortDesc('');
      setLongDesc('');
      setExtraRatings([]);
      setAddintionalLinks(['', '']);
    }
  }, [isOpen]);

  const onEndorsmentUpdate = () => {
    const percentage = parseFloat(percentageInput.trim());

    if (!selectedApp) {
      return toast.error('Please select an app first');
    }
    if (Number.isNaN(percentage)) {
      return toast.error('Please input a valid percentage');
    }
    if (images.length !== 4) {
      return toast.error('Please upload atleast 4 images');
    }
    if (!overallRating || overallRating > 10) {
      return toast.error('Please set overall product ratings');
    }
    if (!shortDesc) {
      return toast.error('Please input short review');
    }
    if (!longDesc) {
      return toast.error('Please input long review');
    }
    if (extraRatings.length < 3) {
      return toast.error('Please input atleast 3 subratings');
    }

    setIsLoading(true);

    const subRatings = extraRatings.map((item) => ({
      rating: parseFloat(item.rating) || 0,
      value: item.title,
    }));

    const additionalData = {
      overallrating: overallRating,
      reviewstatement: shortDesc,
      longdescription: longDesc,
      images,
      additionalLinks: addintionalLinks,
      subRatings,
    };

    const postData = {
      email: LocalStorageHelper.getAppEmail(),
      token: LocalStorageHelper.getAppToken(),
      product_id: selectedProduct.product_id,
      app_code: selectedApp.app_code,
      fee_percentage: percentage,
      additional_data: additionalData,
    };
    // console.log('PostData: ', postData);

    axios
      .post(`${GX_API_ENDPOINT}/gxb/product/user/fee/set/per/app`, postData)
      .then(({ data }) => {
        if (data.status) {
          updatedEndores();
          onClose();
        } else {
          toast.error(data.message || 'Endorsement Failed');
        }
      })
      .catch((error) => {
        console.log('Error', error);
        toast.error('Network Error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupModalLayout
      isOpen={isOpen}
      onClose={onClose}
      noHeader
      style={{ padding: 0 }}
      width={700}
    >
      <div className="add-endorsement-modal">
        {isLoading ? (
          <div className="d-flex flex-fill flex-column justify-content-center">
            <img
              src={require('../../assets/images/endorsement-logo.svg').default}
              alt=""
              className="center-logo"
            />
            <div className="completed-text">
              We&apos;re Getting Your Endorsements Published. You WIll Be
              Redirected To The Product Page After We Are Done
            </div>
          </div>
        ) : (
          <>
            <div className="header-container">
              <img
                src={
                  require('../../assets/images/endorsement-logo.svg').default
                }
                alt=""
                className="header-logo"
              />
            </div>
            <div className="modal-content-container">
              <div>
                <div className="title">
                  Select The App On Which You Are Endorsing The Product
                </div>
                <div className="app-list-container">
                  {allApps.map((item) => (
                    <div
                      key={item.app_code}
                      className={`app-item ${
                        selectedApp?.app_code === item.app_code ? 'active' : ''
                      }`}
                      onClick={() => setSelectedApp(item)}
                    >
                      <div className="icon-container">
                        <img src={item.app_icon} alt="" className="icon" />
                      </div>
                      <div className="app-name">{item.app_name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="title">
                  Enter Your Fees For Endorsing The Product
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    className="percentage-input"
                    placeholder="0.00"
                    value={percentageInput}
                    onChange={(e) => setPercentageInput(e.target.value)}
                  />
                  <div className="unit">%</div>
                </div>
              </div>
              <ProductImageUploader images={images} setImages={setImages} />
              <ProductDetails
                rating={overallRating}
                setRating={setOverallRating}
                shortDesc={shortDesc}
                setShortDesc={setShortDesc}
                longDesc={longDesc}
                setLongDesc={setLongDesc}
              />
              <ExtraRatings
                extraRatings={extraRatings}
                setExtraRatings={setExtraRatings}
                addintionalLinks={addintionalLinks}
                setAddintionalLinks={setAddintionalLinks}
              />
            </div>
            <div className="complete-btn" onClick={onEndorsmentUpdate}>
              Complete Endorsement
            </div>
          </>
        )}
      </div>
    </PopupModalLayout>
  );
};

export default AddEndorsementPopup;
