import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APP_CODE, GX_API_ENDPOINT } from '../../../configs';
import { formatterHelper, timestampParserMini } from '../../../utils';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';

const TxnList = ({ coin, status }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [txnsList, setTxnsList] = useState();
  const [coinTxns, setCoinTxns] = useState();

  useEffect(() => {
    console.log('status', status);
    (async () => {
      if (status === 'In Process') {
        setIsLoading(true);
        const email = LocalStorageHelper.getAppEmail();
        // const email = 'shorupan@gmail.com';

        Axios.get(`${GX_API_ENDPOINT}/coin/vault/service/processing/txns/get`, {
          params: { email, app_code: APP_CODE },
        })
          .then(resp => {
            const { data } = resp;

            // console.log('Transactions', data);

            setTxnsList(data?.deposits || []);
          })
          .finally(() => setIsLoading(false));
      }
    })();

    return () => {};
  }, [status]);

  useEffect(() => {
    if (txnsList) {
      const txns = txnsList.find(x => x._id === coin.coinSymbol)?.txns || [];
      setCoinTxns(txns);
    }

    return () => {};
  }, [txnsList, coin]);

  return (
    <div className="action-fiat-funding-list">
      <div className="header-container">
        <img src={coin.coinImage} alt="" className="coin-logo" />
        <div className="coin-name">{coin.coinName}</div>
        {coinTxns ? (
          <div className="no-txns">{coinTxns.length} Transactions</div>
        ) : null}
      </div>
      {isLoading ? (
        <div className="mt-3">
          <LoadingAnimation dark />
        </div>
      ) : (
        <div className="list-container">
          {coinTxns?.length > 0 ? (
            coinTxns?.map(item => (
              <div key={item._id} className="list-item">
                <div className="item-details">
                  <div className="item-values">
                    <div className="item-amount">
                      {formatterHelper(item.buy_amount, item.buy_to_deposit)}{' '}
                      {item.buy_to_deposit}
                    </div>
                    <div className="item-date">
                      Initiated On {timestampParserMini(item.timestamp)}
                    </div>
                  </div>
                  <div className="item-status">{item.status}</div>
                </div>
                <div className="item-banker-container">
                  <div className="banker-title">Banker</div>
                  <div className="baker-name">{item.banker}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="font-weight-bold text-center mt-3">
              No Funding Transaction Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TxnList;
