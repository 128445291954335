import React from 'react';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import MyProfileCover from '../../components/MyProfileCover';
import CRMTabFragment from '../../components/CRMTabFragment';
import CRMNavTabBar from '../../components/CRMNavTabBar';

const CRMPage = () => {
  return (
    <LayoutWithSideBar>
      <div className="broker-app-main-wrapper">
        <MyProfileCover>
          <CRMNavTabBar />
        </MyProfileCover>
        <div className="broker-fragment-wrapper">
          <CRMTabFragment />
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default CRMPage;
