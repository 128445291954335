import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

const TradingView = () => {
  return (
    <div className="col-md-8 h-100">
      <div className="trading-view-wrapper">
        <TradingViewWidget
          symbol="ETHUSD"
          theme={Themes.LIGHT}
          locale="en"
          autosize
        />
      </div>
    </div>
  );
};

export default TradingView;
