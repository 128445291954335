/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { message } from 'antd';
import ioClient from 'socket.io-client';
import 'emoji-mart/css/emoji-mart.css';
import ChatHeader from './ChatHeader';
import ChatArea from './ChatArea';
import ChatInputArea from './ChatInputArea';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import LoadingAnimation from '../LoadingAnimation';
import S3FileUpload from 'react-s3';
import ChatContext from '../../contexts/ChatContext';
import BotFragment from './BotFragment';
import { S3_CONFIG } from '../../configs';

const SupportChat = ({
  currentUser,
  currentUserObj,
  toggleChatWidth,
  isChatExpanded,
}) => {
  const { isBot, setIsBot } = useContext(ChatContext);

  const [messageArray, setMessageArray] = useState([]);

  const [threadId, setThreadId] = useState('');
  // const [fileName, setFileName] = useState('');

  const [chatSection, setChatSection] = useState(false);
  const [groupReadUnreadList, setGroupReadUnreadList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [typingFlag, setTypingFlag] = useState(false);
  const [typingMessage, setTypingMessage] = useState('');
  const [isFileLoading, setIsFileLoading] = useState(false);

  const socketRef = useRef();
  const userSocketRef = useRef();

  useEffect(() => {
    if (messageArray.length > 1) {
      const objDiv = document.getElementById('chat-area');
      // console.log('top: ', objDiv.scrollTop, 'height: ', objDiv.scrollHeight);
      try {
        objDiv.scrollTop = objDiv.scrollHeight;
      } catch (error) {}

      // console.log(objDiv);
    }
  }, [messageArray, typingFlag]);

  const getAdminsAndCheck = async () => {
    socketRef.current.emit(
      'get_user_support_group_interaction_list',
      currentUserObj,
      response => {
        // console.log('get_user_support_group_interaction_list', response);
        setSelectedGroup(response[0].group_name);

        setGroupReadUnreadList([...response]);
        socketRef.current.emit(
          'get_support_group_chat_history',
          response[0].group_name,
          response[0].thread_id,
          socketResp => {
            // console.log('All messages', socketResp);

            setThreadId(response[0].thread_id);
            setChatSection(true);
            setMessageArray([...socketResp.reverse()]);
          },
        );
      },
    );
    // }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      window.scrollTo(0, 0);
    }

    socketRef.current = ioClient('https://chatsapi.globalxchange.io', {
      query: {
        email: LocalStorageHelper.getAppEmail(),
        token: LocalStorageHelper.getAppToken(),
        tokfortest: 'nvestisgx',
      },
    });
    userSocketRef.current = ioClient('https://chatsapi.globalxchange.io/user', {
      query: {
        email: LocalStorageHelper.getAppEmail(),
        token: LocalStorageHelper.getAppToken(),
        tokfortest: 'nvestisgx',
      },
    });

    getAdminsAndCheck();

    return () => {
      userSocketRef.current.off('get_online_user_list');
    };
  }, [currentUserObj]);

  useEffect(() => {
    userSocketRef.current.on('new_message_notify', data => {
      // write code to put dot on user if receiver is cuerrentuser

      if (groupReadUnreadList.length > 1) {
        // console.log(data);
        if (data.group) {
          let tempArr = [...groupReadUnreadList];
          const objIndex = tempArr.findIndex(
            obj => obj.group_name === data.receiver,
          );
          tempArr[objIndex].sender = data.sender;

          let newArr = [...tempArr.filter(o => o.group_name === data.receiver)];
          tempArr = tempArr.filter(obj => obj.group_name !== data.receiver);
          newArr = [...newArr, ...tempArr];
          // console.log(newArr);
          setGroupReadUnreadList([...newArr]);

          // console.log(tempArr);
        }
      }
    });
    return () => {
      userSocketRef.current.off('new_message_notify');
    };
  }, [groupReadUnreadList]);

  // useEffect to  update the message list when the other party types a new message.
  useEffect(() => {
    socketRef.current.on('msg_notify', data => {
      // console.log(data);
      if (data.thread_id === threadId) {
        // console.log(messageArray);
        if (messageArray[messageArray.length - 1] !== data) {
          setMessageArray([...messageArray, data]);
        }
      }
    });
    return () => {
      socketRef.current.off('msg_notify');
    };
  }, [messageArray, threadId]);

  // useEffect to notify that other party which the current user is interaction is typing
  useEffect(() => {
    socketRef.current.on('someone_typing', (typingUser, socketThreadId) => {
      if (socketThreadId === threadId) {
        // console.log(typingUser, ` typing`);
        setTypingMessage(`${typingUser} is typing`);
        setTypingFlag(true);
        setTimeout(() => {
          setTypingFlag(false);
        }, 1800);
      } else {
        setTypingFlag(false);
      }
    });
    return () => {
      socketRef.current.off('someone_typing');
    };
  }, [threadId]);

  const handleSubmitMessage = (inputText, setInputText) => {
    if (inputText.length > 0) {
      const tempArr = [...groupReadUnreadList];
      const objIndex = tempArr.findIndex(
        obj => obj.group_name === selectedGroup,
      );
      tempArr[objIndex].last_messaged_user = currentUser;
      // console.log(tempArr);
      setGroupReadUnreadList(tempArr);

      const notificationData = {
        sender: currentUser,
        receiver: selectedGroup,
        group: true,
      };

      userSocketRef.current.emit('new_group_message', notificationData);

      socketRef.current.emit(
        'new_support_group_message',
        JSON.stringify({
          message: inputText,
          thread_id: threadId,
          sender: currentUser,
          timestamp: Date.now(),
        }),
        response => {
          if (response === 'success') {
            // console.log(response);
            setMessageArray([
              ...messageArray,
              {
                message: inputText,
                threadId,
                sender: currentUser,
                timestamp: Date.now(),
              },
            ]);
            setInputText('');

            let tempArr1 = [...groupReadUnreadList];

            const objI = tempArr1.findIndex(
              obj => obj.group_name === selectedGroup,
            );
            tempArr1[objI].sender = currentUser;

            let newArr = [
              ...tempArr1.filter(o => o.group_name === selectedGroup),
            ];
            tempArr1 = tempArr1.filter(obj => obj.group_name !== selectedGroup);
            newArr = [...newArr, ...tempArr1];
            // console.log(newArr);
            setGroupReadUnreadList([...newArr]);
          } else {
            // console.log(response);
          }
        },
      );
    } else {
      message.destroy();
      message.info('Please type something!', 2);
    }
  };

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const initiateSendImage = async (
    fileToUpload,
    chatInput = '',
    uploadSuccessCallback,
  ) => {
    setIsFileLoading(true);

    let file = fileToUpload;
    if (file) {
      try {
        setIsFileLoading(true);
        const fileName = file.name.replace(' ', '_');
        const fullFileName = `${Date.now().toString()}${fileName}`;
        file = renameFile(file, fullFileName);
        const options = {
          dirName: 'uploads/',
          bucketName: 'chatsgx',
          region: 'us-east-2',
          ...S3_CONFIG,
        };

        S3FileUpload.uploadFile(file, options)
          .then(resp => {
            const { location } = resp;
            const inputText = chatInput.trim();
            if (location) {
              const messageData = {
                message: inputText,
                thread_id: threadId,
                sender: currentUser,
                timestamp: Date.now(),
                filename: fullFileName,
                type: file.type.split('/')[0],
                location,
              };

              socketRef.current.emit(
                'new_support_group_message',
                JSON.stringify(messageData),
                response => {
                  if (response === 'success') {
                    setMessageArray([...messageArray, { ...messageData }]);
                    if (uploadSuccessCallback) {
                      uploadSuccessCallback();
                    }
                  } else {
                    console.log('Error in sending message');
                  }
                  setIsFileLoading(false);
                },
              );
            }
          })
          .finally(() => setIsFileLoading(false));
      } catch (error) {
        console.log('Error on FileUpload', error);
      }
    }
  };

  return (
    <>
      {chatSection ? (
        <>
          <ChatArea
            currentUser={currentUser}
            messageArray={messageArray}
            typingFlag={typingFlag}
            typingMessage={typingMessage}
          />
          <ChatInputArea
            onTypingHandler={() =>
              socketRef.current.emit('typing', currentUser, threadId)
            }
            onSubmit={handleSubmitMessage}
            isFileLoading={isFileLoading}
            initiateSendImage={initiateSendImage}
          />
        </>
      ) : (
        <div className="chat-loading-wrapper">
          <LoadingAnimation dark size={90} />
        </div>
      )}
    </>
  );
};

export default SupportChat;
