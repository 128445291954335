import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APP_CODE, GX_API_ENDPOINT } from '../../../configs';
import { formatterHelper } from '../../../utils';
import LoadingAnimation from '../../LoadingAnimation';

const ProductItem = ({
  itemData,
  onPurchase,
  noAction,
  purchaseBtnTitle,
  alwaysOpen,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [productDetails, setProductDetails] = useState();

  useEffect(() => {
    if (itemData) {
      const postData = {
        app_code: APP_CODE,
        product_id: itemData.product_id,
      };

      Axios.post(`${GX_API_ENDPOINT}/gxb/product/price/with/fees/get`, postData)
        .then(resp => {
          const { data } = resp;

          // console.log('data', data);
          setProductDetails(data.product || '');
        })
        .catch(error => {});
    }
  }, [itemData]);

  return (
    <div className="product-item">
      <div
        className="product-header"
        onClick={() => setIsDetailsOpen(!isDetailsOpen)}
      >
        <img src={itemData.product_icon} alt="" className="product-icon" />
        <div className="product-name">
          {itemData?.product_name || itemData?.name}
        </div>
        {isDetailsOpen ? (
          <i className="fa fa-caret-up" aria-hidden="true" />
        ) : (
          <i className="fa fa-caret-down" aria-hidden="true" />
        )}
      </div>
      <div className="desc">{itemData?.sub_text}</div>
      {(isDetailsOpen || alwaysOpen) && (
        <>
          {productDetails ? (
            <div className="details-container">
              <div className="d-flex justify-content-between">
                <div className="more-header">More About The License</div>
                <div className="product-currency">
                  {productDetails?.pricesWithFees
                    ? productDetails?.pricesWithFees[0]?.coin
                    : 'USD'}
                </div>
              </div>
              <div className="full-desc">{itemData.full_description}</div>
              <div className="values-container">
                <div className="value-title">Insurance Fee</div>
                <div className="value">
                  {formatterHelper(
                    productDetails?.pricesWithFees
                      ? productDetails?.pricesWithFees[0]?.price
                      : productDetails?.first_purchase?.price || 0,
                    productDetails?.pricesWithFees
                      ? productDetails?.pricesWithFees[0]?.coin
                      : productDetails?.first_purchase?.coin || 'USD',
                  )}{' '}
                  {productDetails?.pricesWithFees
                    ? productDetails?.pricesWithFees[0]?.coin
                    : productDetails?.first_purchase?.coin || 'USD'}
                </div>
              </div>
              <div className="values-container">
                <div className="value-title">Renewal Fee</div>
                <div className="value">
                  {formatterHelper(
                    productDetails?.pricesWithFees
                      ? productDetails?.pricesWithFees[1]?.price
                      : productDetails.billing_cycle?.monthly?.price || 0,
                    productDetails?.pricesWithFees
                      ? productDetails?.pricesWithFees[1]?.coin
                      : productDetails?.billing_cycle?.monthly?.coin || 'USD',
                  )}{' '}
                  {productDetails?.pricesWithFees
                    ? productDetails?.pricesWithFees[1]?.coin
                    : productDetails?.billing_cycle?.monthly?.coin || 'USD'}
                </div>
              </div>
              <div className="values-container">
                <div className="value-title">Prerequisites</div>
                <div className="value">None</div>
              </div>
              {noAction ? null : (
                <div className="actions-container">
                  <div className="action-filled">Earning Power</div>
                  <div className="action-outlined" onClick={onPurchase}>
                    {purchaseBtnTitle || 'Purchase'}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <LoadingAnimation dark size={50} style={{ marginTop: 20 }} />
          )}
        </>
      )}
    </div>
  );
};

export default ProductItem;
