import React, { useContext } from 'react';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';

const CoinSelector = ({ setSelectedCoin }) => {
  const { walletCoinData } = useContext(BrokerAppContext);

  return (
    <>
      <div className="header-container">
        <div className="header-text">Select Coin</div>
        <div onClick={() => setSelectedCoin('All')} className="header-action">
          See All
        </div>
      </div>
      <div className="coin-selector-container">
        {walletCoinData?.map(item => (
          <div
            className="coin-item"
            key={item.coinSymbol}
            onClick={() => setSelectedCoin(item)}
          >
            <img src={item.coinImage} alt="" className="coin-icon" />
            <div className="coin-name">{item.coinName}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CoinSelector;
