import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PreRegisterPage from '../pages/PreRegisterPage';
import RegistrationPage from '../pages/RegistrationPage';
import SignupLandingPage from '../pages/SignupLandingPage';

const RegistrationRouter = ({ appData }) => {
  return (
    <>
      <Route
        exact
        path="/registration"
        component={() => <SignupLandingPage appData={appData} />}
      />
      <Route
        path="/registration/pre"
        component={() => <PreRegisterPage appData={appData} />}
      />
      <Route
        path="/registration/new"
        component={() => <RegistrationPage noReferral appData={appData} />}
      />
      <Route
        path="/registration/referred"
        component={() => <RegistrationPage appData={appData} />}
      />
    </>
  );
};

export default RegistrationRouter;
