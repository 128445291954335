import SelectedProductDetails from './SelectedProductDetails';

const DeleteConfirm = ({
  deletedEndorsement,
  onBack,
  selectedEndorsement,
  selectedProduct,
}) => (
  <>
    <SelectedProductDetails
      selectedEndorsement={selectedEndorsement}
      selectedProduct={selectedProduct}
    />
    <div className="confirm-message">
      Are You Sure You Want To Stop Endorsing This Product?
    </div>
    <div className="actions-container">
      <div className="action" onClick={onBack}>
        Take Me Back
      </div>
      <div className="action alt" onClick={deletedEndorsement}>
        Yes Delete Endorsement
      </div>
    </div>
  </>
);

export default DeleteConfirm;
