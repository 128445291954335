import React from 'react';

export default function ExchangeBaseFooter({
  baseCoins,
  coin,
  setCoin,
}) {
  return (
    <div
      className="exchangebaseFooter_container"
     
    >
      <div className="exf_title">Base</div>
      <img
        style={{
          marginLeft: 5,
          height: 23,
          width: 23,
        }}
        src={
          require('../../assets/mobileassets/forward-icon-colored.png').default
        }
        alt=""
      />
      {baseCoins.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              borderColor: item === coin ? '#08152D' : '#EBEBEB',
            }}
            onClick={() => {
              setCoin(item);
            }}
            className="exf_item"
          >
            {item}
          </div>
        );
      })}
    </div>
  );
}
