import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginFrom from '../../components/LoginFrom';
import { AFFILIATE_BANK_APP_CODE } from '../../configs';
import LandingLayout from '../../layouts/LandingLayout';

const LoginPage = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  return (
    <LandingLayout className="white">
      <LoginFrom appCode={AFFILIATE_BANK_APP_CODE} />
    </LandingLayout>
  );
};

export default LoginPage;
