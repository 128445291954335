import React, { useEffect, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { GX_API_ENDPOINT } from '../../configs';
import { useExchangePairs } from '../../utils/CustomHooks';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import ExchangeBaseFooter from './ExchangeBaseFooter';
import ExchangePairList from './ExchangePairList';
import SearchHeader from './SearchHeader';

export default function ExchangeFeePairScreen({
  complanFeePairScreen,
  setComplanFeePairScreen,
}) {
  const [coin, setCoin] = useState('');
  const [search, setSearch] = useState('');
  const [hidenav, setHideNav] = useState(false);
  const [isExchangeBaseFooter, setIsExchangeBaseFooter] = useState(true);
  const goBack = () => {
    setComplanFeePairScreen([]);
  };
  const userEmail = LocalStorageHelper.getAppEmail();
  const type = complanFeePairScreen[1]?.type;

  const {
    data: exchPairs = {},
    isLoading,
    refetch,
  } = useExchangePairs(userEmail, type);

  useEffect(() => {
    const tmpCoin = Object.keys(exchPairs)[0];
    setCoin(tmpCoin);
  }, [exchPairs]);
  const selectedBrand = complanFeePairScreen[1]?.selectedBrand;
  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        overflow: 'hidden',
      }}
    >
      {hidenav ? (
        ''
      ) : (
        <SearchHeader search={search} setSearch={setSearch} onBack={goBack} />
      )}
      {isLoading ? (
        <LoadingAnimation dark />
      ) : (
        <>
          <ExchangePairList
            pairsObject={exchPairs?.[coin] || {}}
            baseCoin={coin}
            search={search}
            refetch={refetch}
            selectedBrand={selectedBrand}
            setHideNav={setHideNav}
            setIsExchangeBaseFooter={setIsExchangeBaseFooter}
            isExchangeBaseFooter={isExchangeBaseFooter}
          />
          {isExchangeBaseFooter && (
            <ExchangeBaseFooter
              baseCoins={Object.keys(exchPairs)}
              coin={coin}
              setCoin={setCoin}
            />
          )}
        </>
      )}
    </div>
  );
}
