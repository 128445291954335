import React from 'react';
import coin from '../../../assets/images/coin.svg';
import CADIcon from '../../../assets/images/cadIcon.png';
import {
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  usdValueFormatter
} from '../../../utils';

const IndirectOTCRevenueItem = ({ data }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '22%', paddingLeft: '15px' }}
      >
        <label className="name-indirect-otc">{data.txn.name}</label>
      </div>

      <div className="flexed-item" style={{ width: '8%' }}>
        <img className="token-txn-country-icon" src={CADIcon} alt="" />
        {/* <label className="token-txn-country">
              {parseCountryFromAddress(data.txn.address)}
            </label> */}
      </div>
      <div className="flexed-item" style={{ width: '14%' }}>
        <label className="token-txn-date date-indirect-otc">
          {getDateFromTimeStamp(data.txn.timestamp)}
        </label>
        <label className="token-txn-time">
          {getTimeFromTimeStamp(data.txn.timestamp)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '8%' }}>
        <label className="coin-indirect-otc">BTC</label>
      </div>
      <div className="flexed-item" style={{ width: '18%' }}>
        <label className="broker-indirect-otc">{data.txn.upline_name}</label>
        <label className="token-txn-dd">DD : {data.commissions.ps}</label>
      </div>
      <div className="flexed-item" style={{ width: '11%' }}>
        <label className="broker-indirect-otc">
          {data.commissions.com
            ? usdValueFormatter.format(data.commissions.com)
            : usdValueFormatter.format(0)}
        </label>
        <label className="token-txn-time">{`${data.txn.broker_percentage} %`}</label>
      </div>
      <div className="flexed-item" style={{ width: '11%' }}>
        <label className="commission-indirect-otc">
          {usdValueFormatter.format(data.commissions.it_commission)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default IndirectOTCRevenueItem;
