import SelectedProductDetails from './SelectedProductDetails';

const MarkupEditor = ({
  selectedEndorsement,
  newPercentageText,
  setNewPercentageText,
  selectedProduct,
}) => (
  <>
    <SelectedProductDetails
      selectedEndorsement={selectedEndorsement}
      selectedProduct={selectedProduct}
    />
    <div className="input-row">
      <div className="d-flex flex-column flex-fill mr-4 ml-5">
        <div className="input-title">Old Markup</div>
        <div className="input-container">
          <input
            disabled
            type="number"
            className="percentage-input"
            placeholder="0.00"
            value={selectedEndorsement?.fee_percentage?.toString()}
          />
          <div className="unit">%</div>
        </div>
      </div>
      <div className="d-flex flex-column flex-fill mr-5">
        <div className="input-title">New Markup</div>
        <div className="input-container">
          <input
            type="number"
            className="percentage-input"
            placeholder="0.00"
            value={newPercentageText}
            onChange={(e) => setNewPercentageText(e.target.value)}
          />
          <div className="unit">%</div>
        </div>
      </div>
    </div>
  </>
);

export default MarkupEditor;
