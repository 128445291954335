import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APP_CODE, GX_API_ENDPOINT } from '../../../configs';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import LicensePurchase from './LicensePurchase';
import LicensesTypes from './LicensesTypes';
import ProductList from '../../LandingProductList';

const Licenses = ({ selectedBrand, color }) => {
  const [allLicenceDetails, setAllLicenceDetails] = useState();
  const [allLicenses, setAllLicenses] = useState();
  const [brokerLicenceDetails, setBrokerLicenceDetails] = useState('');
  const [userAllLicense, setUserAllLicense] = useState();
  const [activeLicenseType, setActiveLicenseType] = useState('');
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [purchaseData, setPurchaseData] = useState('');

  useEffect(() => {
    (async () => {
      Axios.get(`${GX_API_ENDPOINT}/gxb/product/get`, {
        params: { product_created_by: selectedBrand?.email },
      })
        .then((resp) => {
          const { data } = resp;

          // console.log('getLicenceDetails', data);

          const products = data.products || [];
          setAllLicenses(products);

          const monthlyProducts = products.filter(
            (item) => item.monthly === true,
          );
          const lifeTimeProducts = products.filter(
            (item) => item.lifetime === true,
          );
          const stakingProducts = products.filter(
            (item) => item.staking_allowed === true,
          );

          const productsObj = {
            Monthly: monthlyProducts,
            'One Time': lifeTimeProducts,
            Staking: stakingProducts,
          };

          // console.log('productsObj', productsObj);

          setAllLicenceDetails(productsObj);
        })
        .catch((error) => {
          console.log('Error on getting licence details', error);
        });
    })();
  }, [selectedBrand]);

  useEffect(() => {
    if (allLicenses) {
      const email = LocalStorageHelper.getAppEmail();

      Axios.get(`${GX_API_ENDPOINT}/coin/vault/user/license/get`, {
        params: { email },
      })
        .then((resp) => {
          const { data } = resp;

          // console.log('All Licenses', data);

          if (data.status) {
            const licenceList = data.licenses || [];

            let brokerDetails = '';
            const parsedList = [];

            licenceList.forEach((item) => {
              const license = allLicenses.find(
                (x) => x.product_id === item.product_id,
              );

              if (license) {
                const parsedItem = {
                  ...item,
                  name: license?.product_name,
                  icon: license?.product_icon,
                };

                parsedList.push(parsedItem);

                if (
                  !brokerDetails &&
                  item.app_code === APP_CODE &&
                  (item.license_status === 'active' ||
                    item.license_status === 'grandfathered')
                ) {
                  brokerDetails = parsedItem;
                }
              }
            });

            setUserAllLicense(parsedList);
            // console.log('brokerDetails', brokerDetails);
            setBrokerLicenceDetails(brokerDetails);
          }
        })
        .catch((error) => {
          console.log('Error on getting broker data', error);
        });
    }
  }, [allLicenses]);

  if (!allLicenses && !brokerLicenceDetails) {
    return (
      <div className="mt-3">
        <LoadingAnimation dark />
      </div>
    );
  }

  if (isPurchaseOpen && purchaseData) {
    return (
      <LicensePurchase
        purchaseData={purchaseData}
        onClose={() => {
          setPurchaseData();
          setIsPurchaseOpen(false);
        }}
        userAllLicense={userAllLicense}
      />
    );
  }

  return (
    <div className="license-details">
      <div className="active-license-container">
        <div className="header-container">
          <div className="active-license">Active License</div>
          {/* <div className="all-button">See All Licenses</div> */}
        </div>
        <div className="active-item">
          <img
            src={
              brokerLicenceDetails.license_id === 'GXBTL'
                ? require('../../../assets/images/gx-logo.png').default
                : brokerLicenceDetails?.icon
            }
            alt=""
            className="license-icon"
          />
          <div className="license-name">
            {brokerLicenceDetails.license_id === 'GXBTL'
              ? 'Legacy GXBroker License'
              : brokerLicenceDetails?.name || 'InstaCrypto Brokerage'}
          </div>
          {brokerLicenceDetails ? (
            <img
              src={require('../../../assets/images/green-check.png').default}
              alt=""
              className="status-icon"
            />
          ) : (
            <div className="red-dot" />
          )}
        </div>
      </div>
      <LicensesTypes
        activeType={activeLicenseType}
        setActiveType={setActiveLicenseType}
      />
      <ProductList
        allLicenceDetails={allLicenceDetails}
        activeType={activeLicenseType}
        isPurchaseOpen={isPurchaseOpen}
        setIsPurchaseOpen={setIsPurchaseOpen}
        purchaseData={purchaseData}
        setPurchaseData={setPurchaseData}
      />
    </div>
  );
};

export default Licenses;
