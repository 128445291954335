import React, { useState } from 'react';

const LicenseItem = ({ item, color, style, onUpgrade }) => {
  const [showPurchaseId, setShowPurchaseId] = useState(false);
  const [showLastPaymentId, setShowLastPaymentId] = useState(false);

  const onLicenseCertificateClick = () => {
    const win = window.open(
      `https://liceneses.brokerapp.io/${item.license_code}`,
      '_blank',
    );
    if (win != null) {
      win.focus();
    }
  };

  const styles = style || {};

  return (
    <div
      className="license-item"
      style={{ color: color || '#08152D', ...styles }}
    >
      <div className="license-header">
        <div className="license-name">{item.name}</div>
        {(item.license_status === 'active' ||
          item.license_status === 'grandfathered') && (
          <img
            src={require('../../../assets/images/green-check.png').default}
            alt=""
            className="status-icon"
          />
        )}
      </div>
      <div className="mt-auto">
        <div className="details-item">
          <div className="detail-label">Brand:</div>
          <div className="detail-value">{item?.client_app}</div>
        </div>
        <div className="details-item">
          <div className="detail-label">Product:</div>
          <div className="detail-value">{item?.product_code}</div>
        </div>
        <div className="details-item">
          <div className="detail-label">License:</div>
          <div className="detail-value">{item?.license_id}</div>
        </div>
        <div className="details-item">
          {showPurchaseId ? null : (
            <div className="detail-label">Last Purchase ID:</div>
          )}
          <div
            className="detail-value"
            style={
              showPurchaseId ? { flex: 1 } : { textDecoration: 'underline' }
            }
            onClick={() => setShowPurchaseId(!showPurchaseId)}
          >
            {showPurchaseId ? item?.last_purchase_id : 'Show'}
          </div>
        </div>
        <div className="details-item">
          {showLastPaymentId ? null : (
            <div className="detail-label">Last Payment ID:</div>
          )}
          <div
            className="detail-value"
            style={
              showLastPaymentId ? { flex: 1 } : { textDecoration: 'underline' }
            }
            onClick={() => setShowLastPaymentId(!showLastPaymentId)}
          >
            {showLastPaymentId ? item?.last_payment_id : 'Show'}
          </div>
        </div>
        <div className="actions-container">
          <div onClick={onLicenseCertificateClick} className="outline-button">
            License Certificate
          </div>
          <div
            className="filled-button"
            style={{ backgroundColor: color || '#08152D' }}
            onClick={onUpgrade || null}
          >
            Upgrade
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseItem;
