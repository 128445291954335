import React, { useEffect } from 'react';

const LicensesTypes = ({ activeType, setActiveType }) => {
  useEffect(() => {
    setActiveType(TYPES[0]);
  }, []);

  return (
    <div className="licenses-types-container">
      {TYPES.map(item => (
        <div
          key={item}
          className={`type-item ${activeType === item ? 'active' : ''}`}
          onClick={() => setActiveType(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default LicensesTypes;

const TYPES = ['Monthly', 'One Time', 'Staking'];
