import React, { useEffect, useState } from 'react';
import FullLoadingComponent from '../components/FullLoadingComponent';
import SiteHeader from '../components/SiteHeader';
import SiteNavBar from '../components/SiteNavBar';

const SiteLayout = ({ noHeader, children, isLoading }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsReady(false);
    }, [2000]);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="site-layout">
      {(isReady || isLoading) && <FullLoadingComponent />}
      {noHeader ? null : <SiteHeader openMenu={() => setIsMenuOpen(true)} />}
      <SiteNavBar isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <div className="d-flex flex-column flex-fill">{children}</div>
    </div>
  );
};

export default SiteLayout;
