import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { usernameRegex } from '../../utils';

const BlockCheckForm = ({
  setUserName,
  userName,
  usersList,
  onNext,
  onBack,
  onCustomMail,
}) => {
  const [isUsernameValid, setIsUsernameValid] = useState(false);

  useEffect(() => {
    if (usersList) {
      const username = userName.toLowerCase().trim();
      if (username.length < 6 || !usernameRegex.test(username)) {
        return setIsUsernameValid(false);
      }
      setIsUsernameValid(!usersList.usernames.includes(username));
    }
  }, [userName, usersList]);

  const onProceed = () => {
    const username = userName.toLowerCase().trim();

    if (!isUsernameValid) {
      if (!usernameRegex.test(username)) {
        return toast.error('PLEASE INPUT A VALID USERNAME');
      }
      return toast.error('USERNAME ALREADY IN USE');
    }

    onNext();
  };

  return (
    <div className="view-container">
      <div className="sub-text">Choose A BlockCheck ID</div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          <div
            className={`input-validator ${isUsernameValid ? 'valid' : ''}`}
          />
          <input
            type="text"
            className="input-field"
            placeholder="Ex. shorupan"
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
          <div className="input-postfix">@blockcheck.io</div>
        </div>
        <div className="form-buttons">
          <div
            className="action-outlined"
            style={{ fontSize: 11 }}
            onClick={onCustomMail}
          >
            Use Custom Email
          </div>
          <div className="action-filled" onClick={onProceed}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCheckForm;
