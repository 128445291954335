import React, { useContext } from 'react';
import { OneToOneChatContext } from '../../../contexts/OneToOneChatContext';
import { timeAgoFormatter } from '../../../utils';
import LoadingAnimation from '../../LoadingAnimation';
import ChatView from './ChatView';

const OneOneChat = () => {
  const {
    isSocketConnected,
    allUsers,
    setSelectedUser,
    selectedUser,
    recentList,
    setCurrentTreadId,
  } = useContext(OneToOneChatContext);

  if (selectedUser) {
    return <ChatView />;
  }

  return (
    <div className="one-one-chat-wrapper">
      {isSocketConnected ? (
        <>
          <div className="search-container">
            <img
              src={
                require('../../../assets/images/search-light-icon.svg').default
              }
              alt=""
              className="search-icon"
            />
            <input
              type="text"
              className="search-input"
              placeholder="Search Contacts"
            />
          </div>
          <div className="header">Recents</div>
          {recentList ? (
            <div className="recent-chats-container">
              {recentList.map((item, index) => (
                <div
                  key={index}
                  className="recent-chat-item"
                  onClick={() => {
                    setCurrentTreadId(item.thread_id);
                    setSelectedUser(item);
                  }}
                >
                  <img src={item.avatar} alt="" className="avatar-image" />
                  <div className="name">{item.first_name?.trim()}</div>
                  <div className="time">
                    {timeAgoFormatter(item.msg_timestamp)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <LoadingAnimation />
          )}
          <div className="header">Friends</div>
          {allUsers ? (
            <div className="friends-list-container">
              {allUsers.map((item, index) => (
                <div
                  key={index}
                  className="friend-item"
                  onClick={() => setSelectedUser(item)}
                >
                  <img src={item.avatar} alt="" className="avatar-image" />
                  <div className="flex-fill d-flex flex-column">
                    <div className="name">{`${item.first_name?.trim()} ${
                      (item.last_name || '')[0]
                    }`}</div>
                    <div className="time">{item.bio}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <LoadingAnimation />
          )}
        </>
      ) : (
        <div className="d-flex flex-fill flex-column justify-content-center">
          <LoadingAnimation />
        </div>
      )}
    </div>
  );
};

export default OneOneChat;
