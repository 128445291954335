import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ProductDetailsView from './ProductDetailsView';

const ProductList = ({
  allProducts,
  activeCategory,
  activeBrand,
  searchInput,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [searchList, setSearchList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    setSelectedProduct();
  }, [allProducts]);

  useEffect(() => {
    if (allProducts) {
      // console.log('allProducts', allProducts);
      // console.log('activeBrand', activeBrand);

      const filter = allProducts.filter(
        (x) =>
          activeBrand?.name === 'All Brands' ||
          x.product_created_by === activeBrand?.email,
      );

      setFilterList(filter);
    }
  }, [allProducts, activeBrand]);

  useEffect(() => {
    const searchQuery = searchInput.trim().toLowerCase();

    const searchFilter = filterList.filter((x) =>
      x.product_name.toLowerCase().includes(searchQuery),
    );

    setSearchList(searchFilter);
  }, [filterList, searchInput]);

  const getProductPrice = (product) => {
    try {
      const priceArray = product.pricesWithFees;
      const priceObj = priceArray[0];

      const { price, coin, billing_method } = priceObj;

      const isFirstPurchase = billing_method === 'first_purchase';

      return `${price} ${coin} ${isFirstPurchase ? 'To Get Started ' : ''}`;
    } catch (e) {
      return product.sub_text;
    }
  };

  if (selectedProduct) {
    return (
      <ProductDetailsView
        selectedProduct={selectedProduct}
        onBack={() => setSelectedProduct()}
      />
    );
  }

  return (
    <div className="products-list-container">
      {allProducts ? (
        searchList.length > 0 ? (
          searchList.map((item) => (
            <div
              key={item._id}
              className="product-item"
              onClick={() => setSelectedProduct(item)}
            >
              <div className="app-icon-container">
                <img src={item.product_icon} alt="" className="app-icon" />
              </div>
              <div className="app-details">
                <div className="app-name">{item?.product_name}</div>
                <div className="app-desc">{getProductPrice(item)}</div>
                <div className="action-container">
                  <div className="action">Edit</div>
                  <div className="action primary">Delete</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-text">
            No Products Found For {activeBrand?.title}{' '}
            {!activeCategory?.name || activeCategory?.name === 'All'
              ? ''
              : `With ${activeCategory?.name} Category`}
          </div>
        )
      ) : (
        Array(20)
          .fill(1)
          .map((_, index) => (
            <div key={index} className="product-item">
              <Skeleton
                className="app-icon-container"
                white={100}
                height={100}
              />
              <div className="app-details">
                <Skeleton width="50%" height={22} />
                <Skeleton width="70%" height={30} className="app-desc" />
                <div className="action-container">
                  <Skeleton width={90} height={30} className="action" />
                  <Skeleton width={90} height={30} className="action" />
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default ProductList;
