import React, { useState } from 'react';
import CardItem from './CardItem';
import marketplaceList from './marketplaceList';
import MarketPlaceDetails from './MarketPlaceDetails';

const Marketplace = ({ searchQuery, navBarHeight }) => {
  const [activeDetails, setActiveDetails] = useState(marketplaceList[0]);

  const renderCards = () => {
    const cardsArray = [];
    const currentCount = 1;
    for (let i = 0; i < currentCount; i += 1) {
      if (
        marketplaceList[0].title
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
      ) {
        cardsArray.push(<CardItem data={marketplaceList[0]} />);
      }
    }
    return cardsArray.length > 0 ? (
      cardsArray
    ) : (
      <h1 className="text-center w-100">No Items Found...</h1>
    );
  };

  return (
    <div className="marketplace-wrapper h-100">
      <div className="row mx-0 h-100">
        {/* <Filter
          categories={categories}
          active={activeFilter}
          setActive={setActiveFilter}
          title="Categories"
        /> */}
        <div className="col-md-12 h-100">
          <div className="row mx-0 h-100">
            <div className="col-md-4">
              <div className="row mx-0">{renderCards()}</div>
            </div>
            {activeDetails && (
              <MarketPlaceDetails
                navBarHeight={navBarHeight}
                data={activeDetails}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
