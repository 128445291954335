import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import SearchLayout from '../../layouts/SearchLayout';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import LoadingAnimation from '../LoadingAnimation';
import ProfileAvatar from '../ProfileAvatar';
import ChainViewItem from './ChainViewItem';
import EarningsModal from './EarningsModal';

const ChainView = ({ viewingUser, setViewingUser }) => {
  const { profileImg, userFullName } = useContext(BrokerAppContext);

  const deptListRef = useRef();
  const axiosTokenRef = useRef();

  const [userData, setUserData] = useState();
  const [depthData, setDepthData] = useState();
  const [allUsers, setAllUsers] = useState();
  const [loadingIndex, setLoadingIndex] = useState();
  const [lastSelectedUser, setLastSelectedUser] = useState();

  const [isMoneyViewOpen, setIsMoneyViewOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const [searchList, setSearchList] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchedLevel, setSearchedLevel] = useState(0);
  const [isOpenNewUser, setIsOpenNewUser] = useState(true);
  const [searchedUser, setSearchedUser] = useState(null);

  useEffect(() => {
    if (!isSearchOpen) {
      setSearchInput('');
    }
  }, [isSearchOpen]);

  useEffect(() => {
    (async () => {
      let email;
      if (viewingUser) {
        email = viewingUser.email;
      } else {
        email = LocalStorageHelper.getAppEmail();
      }

      setUserData();
      setDepthData();
      setAllUsers();
      setLoadingIndex();
      setLastSelectedUser();

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/com/earnings/get`, {
          params: {
            email,
            orderByLevel: true,
          },
        })
        .then((resp) => {
          const { data } = resp;

          // console.log('Resp', data);

          if (data.status) {
            setUserData(data.user);
            const levels = data.levelsData || [];

            const depths = [];
            const all = [];

            levels.forEach((item, index) => {
              if (index === 0) {
                depths.push({ ddLevel: item.level, list: item.users });
              } else {
                depths.push({ ddLevel: item.level, list: undefined });
              }
              all.push(...item.users);
            });

            // console.log('all', all);
            setAllUsers(all);
            setDepthData(depths);
          } else {
            setAllUsers([]);
            setUserData(data?.userData || '');
            setDepthData([{ ddLevel: 0, list: [] }]);
          }
        })
        .catch((error) => {
          console.log('Error on getting Network data', error);
        });
    })();
  }, [viewingUser]);

  const getUserDD = (email, index) => {
    if (axiosTokenRef.current) {
      axiosTokenRef.current.cancel('Cancelled');
      // setIsLoading(false);
    }

    axiosTokenRef.current = axios.CancelToken.source();

    setLoadingIndex(index + 1);

    console.log('Loading', index);

    axios
      .get(`${GX_API_ENDPOINT}/brokerage/com/earnings/get`, {
        params: {
          email,
          orderByLevel: true,
        },
        cancelToken: axiosTokenRef.current.token,
      })
      .then(({ data }) => {
        // console.log('Resp User', data);
        const levels = data?.levelsData || [];

        const firstDepth = levels[0];

        const ddLevelCopy = [...depthData];

        const ddItem = ddLevelCopy[index + 1];

        if (ddItem) {
          ddLevelCopy[index + 1] = { ...ddItem, list: firstDepth?.users || [] };

          ddLevelCopy.forEach((item, i) => {
            if (i > index + 1) {
              ddLevelCopy[i] = { ...item, list: undefined };
            }
          });

          setDepthData(ddLevelCopy);

          if (deptListRef.current) {
            deptListRef.current.scrollToIndex({ index });
          }
        }
      })
      .catch((error) => {
        console.log('Error on getting Network data of the user', error);
      })
      .finally(() => setLoadingIndex());
  };

  const clearToIndex = (index) => {
    const ddLevelCopy = [...depthData];

    ddLevelCopy.forEach((item, i) => {
      if (i > index) {
        ddLevelCopy[i] = { ...item, list: undefined };
      }
    });
    setDepthData(ddLevelCopy);
  };

  const onPopupOpen = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const openNewUser = (email, userObj) => {
    setViewingUser(userObj);
    setIsSearchOpen(false);
  };

  const selectUserInLevel = (email, userObj) => {
    setLastSelectedUser(userObj);
    setSearchedUser(userObj);
    getUserDD(email, searchedLevel);
    setIsSearchOpen(false);
  };

  return (
    <div className="chain-view-container">
      <div className="header-container">
        <div className="profile-container">
          <ProfileAvatar
            avatar={viewingUser?.profile_img || profileImg}
            size={50}
            name={viewingUser?.name || userFullName}
          />
          <div className="name-container">
            <div className="profile-name">
              {viewingUser?.name || userFullName}
            </div>
            <div className="profile-email">
              {LocalStorageHelper.getAppEmail()}
            </div>
          </div>
        </div>
        <div className="actions-container">
          <div
            className={`action-item ${isMoneyViewOpen ? 'active' : ''} `}
            onClick={() => setIsMoneyViewOpen(!isMoneyViewOpen)}
          >
            <img
              src={require('../../assets/images/cash-stack-icon.svg').default}
              alt=""
              className="action-icon"
            />
          </div>
          <div
            className="action-item"
            style={!allUsers ? { cursor: 'not-allowed' } : {}}
            onClick={() => {
              if (allUsers) {
                setSearchList(allUsers);
                setIsOpenNewUser(true);
                setIsSearchOpen(true);
              }
            }}
          >
            <img
              src={require('../../assets/images/search-colorful.svg').default}
              alt=""
              className="action-icon"
            />
          </div>
        </div>
      </div>
      <div className="chain-view-list">
        {depthData ? (
          depthData?.map((item, index) => (
            <ChainViewItem
              key={item?.ddLevel}
              isMoneyViewOpen={isMoneyViewOpen}
              level={item?.ddLevel}
              userList={item?.list}
              onUserSelected={getUserDD}
              isLoading={loadingIndex === item?.ddLevel}
              headerDesc={
                index === 0
                  ? `Select One Of ${
                      viewingUser
                        ? `${userData?.name?.split(' ')[0]}'s`
                        : 'Your'
                    } Directs`
                  : `Select One Of ${
                      lastSelectedUser?.name?.split(' ')[0]
                    }’s Directs`
              }
              setLastSelectedUser={setLastSelectedUser}
              clearToIndex={clearToIndex}
              emptyMessage={
                index === 0
                  ? `Sorry, But ${
                      viewingUser
                        ? `${userData?.name?.split(' ')[0]} Has`
                        : 'You Have'
                    } No Directs`
                  : `Sorry, But ${
                      lastSelectedUser?.name?.split(' ')[0]
                    } Has No Directs`
              }
              onTreeClick={() => {}}
              onPopupOpen={onPopupOpen}
              onSearchOpen={(list, level) => {
                setSearchList(list);
                setIsOpenNewUser(false);
                setSearchedLevel(level);
                setIsSearchOpen(true);
              }}
              searchedUser={searchedLevel === index ? searchedUser : null}
            />
          ))
        ) : (
          <div className="d-flex flex-column justify-content-center flex-fill">
            <LoadingAnimation />
          </div>
        )}
      </div>
      {isSearchOpen && (
        <div className="search-wrapper">
          <SearchLayout
            value={searchInput}
            setValue={setSearchInput}
            onBack={() => setIsSearchOpen(false)}
            onSubmit={isOpenNewUser ? openNewUser : selectUserInLevel}
            placeholder="Search User"
            list={searchList || []}
          />
        </div>
      )}
      <EarningsModal
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        selectedCustomer={selectedUser}
        userEmail={viewingUser?.email}
      />
    </div>
  );
};

export default ChainView;
