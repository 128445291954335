import React from 'react';
import coin from '../../../assets/images/coin.svg';
import {
  formatFromExpValues,
  formatToExpValue,
  getDateFromTimeStamp,
  getTimeFromTimeStamp
} from '../../../utils';

const DirectDigitalRevenueItem = ({ data, brokerRevenue }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '18%', paddingLeft: '15px' }}
      >
        <label className="token-txn-name">{data.txn.name}</label>
      </div>

      <div className="flexed-item" style={{ width: '14%' }}>
        <label className="token-txn-date">
          {getDateFromTimeStamp(data.txn.timestamp)}
        </label>
        <label className="token-txn-time">
          {getTimeFromTimeStamp(data.txn.timestamp)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '6%' }}>
        <label className="token-txn-date">{data.txn.coin}</label>
      </div>
      <div className="flexed-item" style={{ width: '14%' }}>
        <label className="token-txn-broker">
          {data.txn.interest_calculated_on
            ? `${formatToExpValue(data.txn.interest_calculated_on)} ETH`
            : '0 ETH'}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-commission">
          {data.txn.earned_usd_value
            ? `$ ${formatToExpValue(data.txn.earned_usd_value)}`
            : '$ 0'}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-commission">
          {data.txn.commissions_value
            ? `$ ${formatFromExpValues(data.txn.commissions_value)}`
            : '$ 0'}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-commission">
          {brokerRevenue
            ? data.commissions.bdtot
              ? `$ ${formatToExpValue(data.commissions.bdtot)}`
              : '$ 0'
            : `$ ${formatToExpValue(
                data.commissions.dt_commission + data.commissions.it_commission
              )}`}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default DirectDigitalRevenueItem;
