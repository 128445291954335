/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
import React, { memo, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import SupportChat from './SupportChat';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import FooterMenu from './FooterMenu';
import ChatContext from '../../contexts/ChatContext';
import ChatHeader from './ChatHeader';
import BotFragment from './BotFragment';
import ChatsIoMenu from './ChatsIoMenu';
import OneOneChat from './OneOneChat';
import { OneToOneChatProvider } from '../../contexts/OneToOneChatContext';

const chatUrl = 'https://chatsapi.globalxchange.io/gxchat/';

const BrokerChat = memo(({ isChatExpanded, toggleChatWidth }) => {
  const { activeFooterMenu, isChatClosed } = useContext(ChatContext);

  const [currentUserObj, setCurrentUserObj] = useState(null);
  const [currentUser, setCurrentUser] = useState('');
  const [activeFragment, setActiveFragment] = useState();

  useEffect(() => {
    const token = LocalStorageHelper.getAppToken();
    const email = LocalStorageHelper.getAppEmail();

    Axios.post(`${chatUrl}get_user`, {
      email,
      token,
    }).then(res => {
      // console.log('Resp', res.data);
      if (res.data.status) {
        setCurrentUserObj({
          username: res.data.payload.username,
          timestamp: res.data.payload.timestamp,
          email: res.data.payload.email,
        });

        setCurrentUser(res.data.payload.username);
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    switch (activeFooterMenu?.title) {
      case 'Chats':
        setActiveFragment(
          <OneToOneChatProvider>
            <OneOneChat />
          </OneToOneChatProvider>,
        );
        break;
      case 'Bots':
        setActiveFragment(<BotFragment />);
        break;
      case 'Calls':
        break;
      case 'Mail':
        break;
      case 'Menu':
        setActiveFragment(<ChatsIoMenu />);
        break;
      case 'SupportChat':
        setActiveFragment(
          <SupportChat
            currentUserObj={currentUserObj}
            currentUser={currentUser}
            toggleChatWidth={toggleChatWidth}
            isChatExpanded={isChatExpanded}
          />,
        );
        break;
      default:
        break;
    }
  }, [activeFooterMenu]);

  return (
    <div
      className="broker-app-chat-wrapper"
      style={{
        width: isChatClosed ? 0 : isChatExpanded ? '100%' : '30%',
        minWidth: isChatClosed ? 0 : '20%',
      }}
    >
      <div className="broker-chat">
        <div
          className="chat-container"
          style={{
            flexDirection: window.innerWidth > 600 ? 'row' : 'column',
          }}
        >
          <div
            className="chat-wrapper h-100"
            style={{
              display:
                window.innerWidth > 600
                  ? 'flex'
                  : window.innerWidth < 600
                  ? 'flex'
                  : 'none',
            }}
          >
            <ChatHeader
              toggleChatWidth={toggleChatWidth}
              isChatExpanded={isChatExpanded}
            />
            <div className="flex-fill d-flex flex-column">{activeFragment}</div>
            <FooterMenu />
          </div>
        </div>
      </div>
    </div>
  );
});

export default BrokerChat;
