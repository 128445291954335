import React from 'react';
import documentIcon from '../../assets/images/documents.svg';

function FilePreviewOnChat({ link, type }) {
  const getPreview = () => {
    switch (type) {
      case 'image':
        return <img className="previewItem" src={link} alt="" />;
      case 'video':
        return (
          <video
            autoPlay={false}
            controls
            className="previewItem"
            src={link}
            alt=""
          />
        );
      default:
        return <img className="previewItem file" src={documentIcon} alt="" />;
    }
  };
  return (
    <a
      href={link}
      download
      className="preview"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg viewBox="0 0 16 9" />
      {getPreview()}
    </a>
  );
}

export default FilePreviewOnChat;
