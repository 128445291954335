import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const NameForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  onNext,
  onBack,
}) => {
  const history = useHistory();

  const onProceed = () => {
    if (!firstName.trim()) {
      return toast.error('Please Enter Your First Name');
    }

    onNext();
  };

  return (
    <div className="view-container">
      <div className="sub-text">Enter Your First And Last Name</div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          <input
            type="text"
            className="input-field"
            placeholder="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="input-container">
          <input
            type="text"
            className="input-field"
            placeholder="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="form-buttons">
          <div
            className="action-outlined"
            onClick={onBack || (() => history.goBack())}
          >
            Go Back
          </div>
          <div className="action-filled" onClick={onProceed}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameForm;
