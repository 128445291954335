import React, { useEffect, useState } from 'react';
import CoinSelector from './CoinSelector';
import TxnsList from './TxnsList';

const BotTransactions = ({ type }) => {
  const [selectedCoin, setSelectedCoin] = useState('');

  useEffect(() => {
    setSelectedCoin('');
  }, [type]);

  return (
    <div className="bot-transactions-wrapper">
      {selectedCoin ? (
        <TxnsList
          selectedCoin={selectedCoin}
          type={type}
          setSelectedCoin={setSelectedCoin}
        />
      ) : (
        <CoinSelector setSelectedCoin={setSelectedCoin} />
      )}
    </div>
  );
};

export default BotTransactions;
