import React, { useContext } from 'react';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';

const TxnItem = ({ coin, value, date, onClick }) => {
  const { walletCoinData } = useContext(BrokerAppContext);

  let icon = '';

  if (walletCoinData) {
    const txnsCoin = walletCoinData.find(x => x.coinSymbol === coin);

    icon = txnsCoin?.coinImage || '';
  }

  return (
    <div onClick={onClick} className="transaction-item">
      <img src={icon} alt="" className="txn-coin" />
      <div className="txn-details">
        <div className="txn-value">{value}</div>
        <div className="txn-date">{date}</div>
      </div>
    </div>
  );
};

export default TxnItem;
