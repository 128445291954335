import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import brokerAppLogo from '../../assets/images/app-icon.png';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import { nameFormatter, usdValueFormatter } from '../../utils';
import NavItems from './NavItems';

const BrokerAppSideNav = () => {
  const {
    profileImg,
    userFullName,
    walletCoinData,
    isNavCollapsed,
    setIsNavCollapsed,
  } = useContext(BrokerAppContext);

  let totalBalanceInUsd = 0;

  if (walletCoinData) {
    walletCoinData.forEach((item) => {
      totalBalanceInUsd += item?.coinValueUSD || 0;
    });
  }

  return (
    <div
      className={`broker-app-nav-wrapper ${isNavCollapsed ? 'closed' : 'open'}`}
    >
      <div
        // onClick={() => setIsNavCollapsed(!isNavCollapsed)}
        className={`brand-wrapper `}
      >
        <Link to="/chainview">
          <img src={profileImg || brokerAppLogo} alt="" className="brand-img" />
        </Link>
        <div
          className="expand-toggle"
          onClick={() => setIsNavCollapsed(!isNavCollapsed)}
        >
          <img
            src={require('../../assets/images/chevron-left.svg').default}
            alt=""
            className="arrow-icon"
            style={{
              transform: `rotate(${isNavCollapsed ? '180deg' : '0deg'})`,
            }}
          />
        </div>
      </div>
      <div className="broker-app-nav">
        <div className="broker-details">
          <div className="broker-name">{nameFormatter(userFullName)}</div>
          <div className="broker-valuation">
            {usdValueFormatter.format(totalBalanceInUsd)}
          </div>
        </div>
        <NavItems />
      </div>
    </div>
  );
};

export default BrokerAppSideNav;
