import React, { useEffect, useContext } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import DirectUserItem from '../../TableItems/DirectUserItem';
import DirectUsersHeader from '../../TableHeaders/DirectUsersHeader';

const DirectUsers = () => {
  const {
    directUsersList,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(directUsersList);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <DirectUsersHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <DirectUserItem key={index} data={item} />
      ))}
    </>
  );
};

export default DirectUsers;
