import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingAnimation from '../components/LoadingAnimation';
import ConfirmPasswordForm from '../components/SignupComponents/ConfirmPasswordForm';
import EmailForm from '../components/SignupComponents/EmailForm';
import NameForm from '../components/SignupComponents/NameForm';
import PasswordForm from '../components/SignupComponents/PasswordForm';
import StepsSideBar from '../components/SignupComponents/StepsSideBar';
import SuccessForm from '../components/SignupComponents/SuccessForm';
import { APP_CODE, GX_API_ENDPOINT, GX_AUTH_URL } from '../configs';
import { BrokerAppContext } from '../contexts/BrokerAppContext';
import RegistrationLayout from '../layouts/RegistrationLayout';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const PreRegisterPage = ({ appData }) => {
  const { isLoading, setIsLoading, setRefreshData } = useContext(
    BrokerAppContext,
  );

  const history = useHistory();

  const [activeStep, setActiveStep] = useState();
  const [emailId, setEmailId] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const tempLoginClickHandler = () => {
    if (!isLoading) {
      setIsLoading(true);
      const email = emailId.trim().toLowerCase();

      const postData = { email, password: tempPassword };

      axios
        .post(`${GX_AUTH_URL}/gx/user/login`, postData)
        .then((resp) => {
          const { data } = resp;

          // console.log('Temp Login Data', data);

          if (data.resetPassword) {
            setActiveStep('PasswordForm');
          } else {
            toast.error(data.message || 'API Error');
          }
        })
        .catch((error) => {
          console.log('Error on Login', error);
          toast.error('Network Error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const loginHandler = () => {
    setIsLoading(true);

    const email = emailId.trim().toLowerCase();

    const postData = {
      email,
      password: tempPassword,
      newPassword: passwordInput,
    };

    // console.log('postData', postData);

    axios
      .post(`${GX_AUTH_URL}/gx/user/login`, postData)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          setActiveStep('SuccessForm');
          setIsLoading(false);
          axios
            .post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
              email,
              app_code: appData?.appCode || APP_CODE,
            })
            .then((profileResp) => {
              // console.log('profileResp', profileResp.data);

              if (profileResp.data.profile_id) {
                LocalStorageHelper.setProfileId(profileResp.data.profile_id);
                setRefreshData();
              }
            });
          axios
            .post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
              email,
            })
            .then((res) => {
              LocalStorageHelper.setUserDetails(
                res.data.name,
                `0x${res.data.ETH_Address}`,
                res.data.affiliate_id,
              );
            })
            .catch((error) => {
              console.log('getUserDetails Error', error);
            })
            .finally(() => {
              LocalStorageHelper.setAppLoginData(
                data.idToken,
                data.accessToken,
                email,
              );

              // history.push('/');
            });
        } else {
          toast.error(`❌ ${data.message}`);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error on Login', error);
        toast.error(`Network Error`);
      });
  };

  const totalSteps = [
    { title: 'Step 1: Identify Yourself', stepName: 'UserForm', id: 0 },
    { title: 'Step 2: Create Password', stepName: 'PasswordForm', id: 1 },
    {
      title: 'Step 3: Confirm Password',
      stepName: 'ConfirmPasswordFrom',
      id: 2,
    },
  ];

  let fragment;

  switch (activeStep) {
    case 'PasswordForm':
      fragment = (
        <PasswordForm
          password={passwordInput}
          setPassword={setPasswordInput}
          onBack={() => setActiveStep()}
          onNext={() => setActiveStep('ConfirmPasswordFrom')}
        />
      );
      break;
    case 'ConfirmPasswordFrom':
      fragment = (
        <ConfirmPasswordForm
          password={passwordInput}
          onBack={() => setActiveStep()}
          onNext={loginHandler}
        />
      );
      break;
    case 'SuccessForm':
      fragment = (
        <SuccessForm
          onLogin={() => {
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              history.replace('/');
            }, 1000);
          }}
          onClose={() => history.goBack()}
        />
      );
      break;
    default:
      fragment = (
        <EmailForm
          customTitle="Enter The Temporary Credentials You Got In Your Email"
          emailId={emailId}
          setEmailId={setEmailId}
          isPassword
          password={tempPassword}
          setPassword={setTempPassword}
          noValidity
          onBack={() => history.goBack()}
          onNext={tempLoginClickHandler}
        />
      );
  }

  return (
    <RegistrationLayout
      headerLogo={appData?.headerLogo}
      sideBarComponent={
        activeStep !== 'BrokerSyncCode' ? (
          <StepsSideBar
            header="Pre-Registration"
            stepsList={totalSteps}
            activeStep={activeStep}
          />
        ) : null
      }
    >
      {isLoading && (
        <div className="loading-container">
          <LoadingAnimation dark />
        </div>
      )}
      {fragment}
    </RegistrationLayout>
  );
};

export default PreRegisterPage;
