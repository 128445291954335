import Axios from 'axios';
import React from 'react';
import { APP_CODE, GX_API_ENDPOINT } from '../../configs';
import { formatterHelper, timestampParserMini } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import LoadingAnimation from '../LoadingAnimation';
import { useQuery } from 'react-query';
import MoreInfo from './MoreInfo';
import ListFilter from './ListFilter';

const getTransactions = async ({ queryKey }) => {
  const [_key, coin] = queryKey;
  const profileId = LocalStorageHelper.getProfileId();
  const postData = {
    app_code: APP_CODE,
    profile_id: profileId,
    coin,
  };

  const { data } = await Axios.post(
    `${GX_API_ENDPOINT}/coin/vault/service/txns/get`,
    postData,
  );
  return data.txns || [];
};

const WalletTransactionList = ({ activeAsset }) => {
  const { data, status } = useQuery(
    [`${activeAsset.coinSymbol}Transaction`, activeAsset.coinSymbol],
    getTransactions,
  );

  return (
    <div className="d-flex flex-fill">
      <div className="wallet-transaction-list">
        <div className="header-container">
          <div className="txn-date item-header">Date</div>
          <div className="txn-type item-header">Type</div>
          <div className="txn-value item-header">Amount</div>
          <div className="txn-balance item-header">Balance</div>
        </div>
        <ListFilter />
        <div className="list-container">
          {status === 'loading' ? (
            <div className="mt-5">
              <LoadingAnimation dark />
            </div>
          ) : data?.length > 0 ? (
            data.map((item) => (
              <div className="txn-item" key={item._id}>
                <div className="txn-date item-value">
                  {timestampParserMini(item.timestamp)}
                </div>
                <div className="txn-type item-value">
                  {item.deposit ? 'Credit' : 'Debit'}
                </div>
                <div className="txn-value item-value">
                  {formatterHelper(item.amount, activeAsset.coinSymbol)}
                  <div className="coin-container">
                    <img
                      src={activeAsset.coinImage}
                      alt=""
                      className="coin-icon"
                    />
                    <div className="coin-name">{activeAsset.coinSymbol}</div>
                  </div>
                </div>
                <div className="txn-balance item-value">
                  {formatterHelper(item.amount, activeAsset.coinSymbol)}
                  <div className="coin-container">
                    <img
                      src={activeAsset.coinImage}
                      alt=""
                      className="coin-icon"
                    />
                    <div className="coin-name">{activeAsset.coinSymbol}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-5 text-center font-weight-bold">
              No Transaction Found In {activeAsset?.coinName} Wallet
            </div>
          )}
        </div>
      </div>
      <MoreInfo activeAsset={activeAsset} />
    </div>
  );
};

export default WalletTransactionList;
