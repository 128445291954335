import Axios from 'axios';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../../configs';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import UserList from './UserList';

const AdminMenu = () => {
  const [isPinAuthenticated, setIsPinAuthenticated] = useState(false);
  const [pinInput, setPinInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onProceed = () => {
    if (!pinInput || pinInput.length < 4) {
      return toast.error('Please Enter The PIN');
    }

    setIsLoading(true);

    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();

    const postData = {
      email,
      token,
      group_id: '66me7fdkhxsbtur',
      pin: pinInput,
    };

    Axios.post(`${GX_API_ENDPOINT}/gxb/apps/user/validate/group/pin`, postData)
      .then(({ data }) => {
        // console.log('onProceedClick', data);
        if (data.status) {
          setIsPinAuthenticated(true);
        } else {
          toast.error(data.message);
        }
        // setIsPinAuthenticated(true);
      })
      .catch((error) => {
        toast.error('Please Check Your Network Connection');
        console.log('Error on Checking PIN', error);
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="flex-fill d-flex flex-column justify-content-center align-content-center">
        <LoadingAnimation dark />
      </div>
    );
  }

  if (isPinAuthenticated) {
    return <UserList />;
  }

  return (
    <div className="admin-pin-form">
      <img
        src={
          require('../../../assets/images/affliate-app-dark-logo.svg').default
        }
        alt=""
        className="broker-logo"
      />
      <div className="title">Enter Your Pin</div>
      <div className="input-container">
        <OtpInput
          value={pinInput}
          onChange={(otp) => setPinInput(otp)}
          numInputs={4}
          separator={<div className="separator" />}
          containerStyle="otp-container"
          inputStyle="input-field otp-input"
          shouldAutoFocus
          isInputNum
        />
      </div>
      <div onClick={onProceed} className="proceed-btn">
        Proceed
      </div>
    </div>
  );
};

export default AdminMenu;
