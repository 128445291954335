import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { numberFormatter } from '../../utils';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const GXTWallet = () => {
  const [gxtBalance, setGxtBalance] = useState();
  const [stakeModalData, setStakeModalData] = useState({
    visible: false,
    confirmLoading: false,
  });

  const showStakeModal = () => {
    setStakeModalData({ ...stakeModalData, visible: true });
  };

  const cancelStakeModal = () => {
    setStakeModalData({ ...stakeModalData, visible: false });
  };

  const stakeGXT = () => {
    setStakeModalData({ ...stakeModalData, confirmLoading: true });

    Axios.post(`${GX_API_ENDPOINT}/coin/requestStake`, {
      address: LocalStorageHelper.getETHAddress(),
      email: LocalStorageHelper.getAppEmail(),
      token: LocalStorageHelper.getAppToken(),
      value: 1000,
      type: 'stake',
    })
      .then(res => {
        console.log('Staking (Legacy) Resp => ', res.data);
        if (res.data.status) {
          toast.success('Staked Successfully...');
        } else {
          toast.error(
            res.data.message ? res.data.message : 'Some thing went wrong...!',
          );
        }
      })
      .catch(error => {
        console.log('Staking (Legacy) error => ', error);
        toast.error('Some thing went wrong...!');
      })
      .finally(() => {
        setStakeModalData({ visible: false, confirmLoading: false });
      });
  };

  useEffect(() => {
    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();

    Axios.post(`${GX_API_ENDPOINT}/coin/get_all_balances`, {
      email,
      token,
    })
      .then(brokerageResp => {
        const { data } = brokerageResp;
        console.log('brokerageResp', brokerageResp);
        setGxtBalance(data.gxt.gxt_balance);
      })
      .catch(error => {
        console.log('Error on getting gxt balance', error);
      });

    return () => {};
  }, []);

  return (
    <>
      <div className="col-md-8">
        <div className="gxt-wallet-wrapper h-100">
          <div className="wallet-wrapper">
            <h1 className="gxt-balance">
              {gxtBalance !== undefined
                ? numberFormatter.format(parseFloat(gxtBalance))
                : '...'}{' '}
              <span>GXT</span>
            </h1>
            <div className="mt-auto">
              <h6 className="market-cap-value">$43,232,999.00</h6>
              <h6 className="market-cap-title">Market Cap</h6>{' '}
            </div>
          </div>
          <div className="wallet-action-wrapper">
            <div className="action-buttons">
              <div className="gxt-btn primary">BUY</div>
              <div className="gxt-btn">SELL</div>
              <div className="gxt-btn" onClick={showStakeModal}>
                STAKE
              </div>
            </div>
            <div className="gtx-market-wrapper">
              <div className="gx-market">
                <h6 className="market-cap-value">$43,134.08</h6>
                <h6 className="market-cap-title">Volume (24H)</h6>
              </div>
              <div className="gx-market">
                <h6 className="market-cap-value">$0.35</h6>
                <h6 className="market-cap-title">Price</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Stake GXT"
        visible={stakeModalData.visible}
        onOk={stakeGXT}
        confirmLoading={stakeModalData.confirmLoading}
        onCancel={cancelStakeModal}
      >
        <p className="mb-0">Do you want to stake 1000 GXT ?</p>
      </Modal>
    </>
  );
};

export default GXTWallet;
