import React from 'react';

export default function CouncilOverridesScreen({
  setisCouncilOverride,
  setParams,
  setBrokerEarning,
}) {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        padding: 30,
        paddingTop: 20,
      }}
    >
      <div
        onClick={() => {
          setisCouncilOverride(false);
        }}
        className="back_button"
      >
        Back
      </div>
      <div className="councilOverride_header">
        Here Are The Brands You Receive Overrides
      </div>
      <div
        className="brand_item"
        onClick={() => {
          setisCouncilOverride(false);
          setParams('Override');
          setBrokerEarning(true);
        }}
      >
        <img
          style={{
            width: 25,
            height: 25,
          }}
          src={
            require('../../assets/mobileassets/trading-revenue-icon.png')
              .default
          }
        />
        <div className="brand_title">Trading Revenue</div>
      </div>
    </div>
  );
}
