import React, { useContext } from 'react';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import MyProfileCover from '../../components/MyProfileCover';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import Overview from '../../components/MyProfileFragments/Overview';
import ComingSoon from '../../components/MyProfileFragments/ComingSoon';
import MainNavMenu from '../../components/MyProfileCover/MainNavMenu';

const MyProfilePage = () => {
  const { activeMainNav } = useContext(BrokerAppContext);

  let activeFragRef = null;

  switch (activeMainNav) {
    case 'Overview':
      activeFragRef = <Overview />;
      break;
    default:
      activeFragRef = <ComingSoon />;
  }

  return (
    <LayoutWithSideBar>
      <div className="broker-app-main-wrapper">
        <MyProfileCover>
          <MainNavMenu menus={mainMenus} />
        </MyProfileCover>
        <div className="broker-fragment-wrapper">{activeFragRef}</div>
      </div>
    </LayoutWithSideBar>
  );
};

export default MyProfilePage;

const mainMenus = [
  'Overview',
  'Services',
  'Testimonials',
  'Partners',
  'Consulting',
];
