import React, { useState, useContext } from 'react';
import Converter from './Converter';
import CommissionForm from './CommissionForm';
import axios from 'axios';

const VirtualOTCDesk = () => {
  const [brokerFeeLoading, setBrokerFeeLoading] = useState(false);
  const [brokerSellingFeeLoading, setBrokerSellingFeeLoading] = useState(false);

  const changeBrokerFee = value => {
    setBrokerFeeLoading(true);

    let options = { timeZone: 'America/New_York' }; // you have to know that New York in EST
    let estTime = new Date();
    let date = estTime.toLocaleString('en-US', options);
    let timestamp = Date.now();

    const getAffId = getAffId();

    axios
      .post('https://comms.globalxchange.io/btcbrokerage/save_percentage', {
        broker_id: getAffId,
        date,
        timestamp,
        percentage: value
      })
      .then(r => {
        setBrokerFeeLoading(false);
        window.location.reload();
      })
      .catch(error => {
        console.debug('Error on upding broker fee', error);
        setBrokerFeeLoading(false);
      });
  };
  const changeBrokerSellingFee = value => {
    const getAffId = getAffId();

    setBrokerSellingFeeLoading(true);
    axios
      .post('https://comms.globalxchange.io/otc/update_sell_percentage', {
        affiliate_id: getAffId,
        sell_percentage: value
      })
      .then(res => {
        setBrokerSellingFeeLoading(false);
        // context.update_sellPercentage(value);
      })
      .catch(error => {
        setBrokerSellingFeeLoading(false);
        console.log('Error on updating selling broker fee ', error);
      });
  };

  return (
    <div className="frag-wrapper w-100">
      <Converter
        // wholeSaleRate={context.nvest_rate}
        // myFee={context.broker_fee}
        // retailPrice={context.listed_price}

        wholeSaleRate={0}
        myFee={0}
        retailPrice={0}
      />
      <CommissionForm
        changeBrokerFee={changeBrokerFee}
        changeBrokerSellingFee={changeBrokerSellingFee}
        brokerFeeLoading={brokerFeeLoading}
        brokerSellingFeeLoading={brokerSellingFeeLoading}
      />
    </div>
  );
};

export default VirtualOTCDesk;
