import React from 'react';

export default function ExchangeActionModal({
  pair,
  setPair,
  base,
  refetchPairs,
  setisExchangeActionModal,
  isExchangeActionModal,
  selectedBrand,
  setisExchangeFeeUpdateScreen,
}) {
  return (
    <div
      className="exchangeActionModal_container"
      style={{
        height: isExchangeActionModal ? '100vh' : '0vh',
        display: isExchangeActionModal ? '' : 'none',
      }}
    >
      <div
        onClick={() => {
          setisExchangeActionModal(false);
          setPair('');
        }}
        style={{
          width: '100%',
          height: '40%',
        }}
      />

      <div
        style={{
          width: '100%',
          height: isExchangeActionModal ? '75%' : '0',
          backgroundColor: 'white',
          borderTopRightRadius: 50,
          borderTopLeftRadius: 50,
          padding: 40,
          alignItems: 'center',
        }}
      >
        <img
          style={{
            height: 40,
            width: '100%',
          }}
          src={selectedBrand?.icon}
          alt=""
        />
        <div className="eam_question">What Would You Like To Do To </div>
        <div className="eam_pair">{pair}?</div>
        <div className="eam_btnAction">Analyze Exchange Rate</div>
        <div
          onClick={() => {
            setisExchangeActionModal(false);
            setisExchangeFeeUpdateScreen(true);
          }}
          className="eam_btnAction"
        >
          Change Fee For This Pair
        </div>
        <div className="eam_btnAction">Change Fee For All {base} Pairs</div>
      </div>
    </div>
  );
}
