import React from 'react';
import { Link } from 'react-router-dom';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';

const CompensationHomePage = () => (
  <LayoutWithSideBar>
    <div className="endorsement-home-wrapper">
      <div
        className="cover-img"
        style={{
          backgroundImage: `url(${
            require('../../assets/images/compensation-cover.jpg').default
          })`,
        }}
      />
      <div className="header-container">
        <Link
          to="/industries"
          className="header-action"
          style={{
            backgroundColor: '#26A69A',
            color: 'white',
            borderColor: '#26A69A',
          }}
        >
          Industries
        </Link>
        <div className="header-logo-container">
          <img
            src={
              require('../../assets/images/copensation-full-logo.svg').default
            }
            alt=""
            className="header-logo"
          />
        </div>
        <Link
          to="#"
          className="header-action"
          style={{
            backgroundColor: '#26A69A',
            color: 'white',
            borderColor: '#26A69A',
          }}
        >
          TBD
        </Link>
      </div>
      <div className="content-container">
        <div className="content-title">
          Select One Of The Following Options To Get Started
        </div>
        <div className="cards-container">
          <div className="card-item-container">
            <div className="card-item-header">Watch Webinar</div>
            <div className="card-item-body">
              <div className="card-text">
                Click Here To Watch The Quick Start Training
              </div>
              <div className="card-action">Start Training</div>
            </div>
          </div>
          <div className="card-item-container">
            <div
              className="card-item-header alt"
              style={{ backgroundColor: '#26A69A', color: 'white' }}
            >
              Create Store
            </div>
            <div className="card-item-body">
              <div className="card-text">
                Click Here To Watch The Quick Start Training
              </div>
              <div className="card-action">Create Profile</div>
            </div>
          </div>
          <div className="card-item-container">
            <div className="card-item-header">Share Link</div>
            <div className="card-item-body">
              <div className="card-text">
                Click Here To Watch The Quick Start Training
              </div>
              <div className="card-action">Sharing Options</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutWithSideBar>
);

export default CompensationHomePage;
