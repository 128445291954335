import Axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { APP_CODE, GX_API_ENDPOINT } from '../../../../configs';
import LocalStorageHelper from '../../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../../LoadingAnimation';
import BuyCoinSelector from './BuyCoinSelector';
import ChoicePicker from './ChoicePicker';
import CompleteView from './CompleteView';
import LegacyUpgrade from './LegacyUpgarde';
import LegacyUpgradeReview from './LegacyUpgradeReview';
import LicenseReview from './LicenseReview';
import LicenseUpgrade from './LicenseUpgrade';

const LicensePurchase = ({ purchaseData, onClose, userAllLicense }) => {
  const [activeView, setActiveView] = useState();
  const [coinSelected, setCoinSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState();

  const onCheckOut = async (stats, coin) => {
    if (!coin && !coinSelected) {
      return toast.warn('Please Select An Asset First');
    }

    setIsLoading(true);

    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();
    const profileId = LocalStorageHelper.getProfileId();

    let billingMethod = '';

    if (purchaseData?.monthly) {
      billingMethod = 'monthly';
    } else if (purchaseData?.annual) {
      billingMethod = 'annual';
    } else if (purchaseData?.lifetime) {
      billingMethod = 'lifetime';
    } else if (purchaseData?.staking_allowed) {
      billingMethod = 'staking';
    } else {
      billingMethod = 'monthly';
    }

    const postData = {
      email,
      token,
      app_code: APP_CODE,
      profile_id: profileId,
      product_id: purchaseData.product_id,
      billing_method: billingMethod,
      pay_with_coin: coin || coinSelected.coinSymbol,
      client_app: APP_CODE,
      stats: stats || false,
    };

    // console.log('purchaseData', purchaseData);

    // console.log('Post Data', postData);

    Axios.post(`${GX_API_ENDPOINT}/gxb/product/buy`, postData)
      .then((resp) => {
        const { data } = resp;

        // console.log('Purchase Data', data);

        if (data.status) {
          if (stats) {
            setStatsData(data.quoteData);
          } else {
            setActiveView('Completed');
            toast.success(`${'Purchase Successful\n'}${data.license_code}`);
          }
        } else {
          toast.error(data.message || '');
        }
      })
      .catch((error) => {
        console.log('Error on purchasing', error);
      })
      .finally(() => setIsLoading(false));
  };

  let fragment = null;

  switch (activeView) {
    case 'LegacyReview':
      fragment = (
        <LegacyUpgradeReview onNext={() => setActiveView('LegacyUpgrade')} />
      );
      break;

    case 'LegacyUpgrade':
      fragment = (
        <LegacyUpgrade onClose={onClose} setIsLoading={setIsLoading} />
      );
      break;
    case 'SelectCoin':
      fragment = (
        <BuyCoinSelector
          onNext={(item) => {
            setCoinSelected(item);
            setActiveView('Review');
            onCheckOut(true, item?.coinSymbol);
          }}
        />
      );
      break;
    case 'PurchaseUpgrade':
      fragment = (
        <LicenseUpgrade
          purchaseData={purchaseData}
          onClose={onClose}
          userAllLicense={userAllLicense}
          setIsLoading={setIsLoading}
        />
      );
      break;
    case 'Review':
      fragment = (
        <LicenseReview
          purchaseData={purchaseData}
          selectedAsset={coinSelected}
          statsData={statsData}
          onNext={() => onCheckOut(false, coinSelected?.coinSymbol)}
        />
      );
      break;
    case 'Completed':
      fragment = (
        <CompleteView
          onClose={onClose}
          purchaseData={purchaseData}
          selectedAsset={coinSelected}
        />
      );
      break;
    default:
      fragment = (
        <ChoicePicker onClose={onClose} setActiveView={setActiveView} />
      );
  }

  return (
    <div className="checkout-container">
      <div className="header-container">
        <img
          src={require('../../../../assets/images/checkout-header.svg').default}
          alt=""
          className="header-logo"
        />
        {onClose ? (
          <img
            src={require('../../../../assets/images/close.svg').default}
            alt=""
            className="close-button"
            onClick={onClose}
          />
        ) : null}
      </div>
      {isLoading ? (
        <div className="m-auto">
          <LoadingAnimation dark />
        </div>
      ) : (
        fragment
      )}
    </div>
  );
};

export default LicensePurchase;
