import React, { useContext } from 'react';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';
import { timeParserExpanded } from '../../../utils';

const TxnView = ({ txnData }) => {
  const { walletCoinData } = useContext(BrokerAppContext);

  let icon = '';

  if (walletCoinData) {
    const txnsCoin = walletCoinData.find(
      x => x.coinSymbol === txnData?.coin || '',
    );

    icon = txnsCoin?.coinImage || '';
  }

  return (
    <div className="transaction-details-viewer">
      <div className="header-container">
        <img src={icon} alt="" className="txn-icon" />
        <div className="txn-header">
          <div className="txn-id">{txnData?._id}</div>
          <div className="txn-date">
            {timeParserExpanded(txnData?.timestamp)}
          </div>
        </div>
      </div>
      <div className="txn-details">
        {Object.keys(txnData).map(key => (
          <div key={key} className="listItem">
            <div className="key">{key}</div>
            <p className="value" onClick={() => {}}>
              {JSON.stringify(txnData[key])}
            </p>
          </div>
        ))}
      </div>
      <div className="actions-container">
        <div className="action-button">Dispute</div>
        <div className="action-button disabled">Cancel</div>
      </div>
    </div>
  );
};

export default TxnView;
