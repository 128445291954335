import React, { useContext } from 'react';
import ChatContext from '../../../contexts/ChatContext';

const ViewHeader = ({
  headerText = "Lineage FAQ's",
  onBack,
  disableBack,
  disableExpand,
  className = '',
}) => {
  const { setIsBotFullScreen, isBotFullScreen } = useContext(ChatContext);

  const onClose = () => {
    if (onBack) {
      onBack();
    }
  };

  return (
    <div className={`view-header-container ${className}`}>
      <div className="header">{headerText}</div>
      {disableExpand ? null : (
        <img
          src={
            isBotFullScreen
              ? require('../../../assets/images/collapse-icon.png').default
              : require('../../../assets/images/expand-icon.png').default
          }
          className="header-action"
          alt=""
          onClick={() => setIsBotFullScreen(!isBotFullScreen)}
        />
      )}
      {disableBack ? null : (
        <img
          src={
            require('../../../assets/images/cancel-icon-colored.png').default
          }
          onClick={onClose}
          className="header-action"
          alt=""
        />
      )}
    </div>
  );
};

export default ViewHeader;
