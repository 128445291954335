import React from 'react';
import { formatterHelper } from '../../../../utils';
import LoadingAnimation from '../../../LoadingAnimation';
import ProductItem from '../ProductItem';

const LicenseReview = ({ purchaseData, selectedAsset, statsData, onNext }) => {
  if (!statsData) {
    return <LoadingAnimation />;
  }

  return (
    <div className="body-container">
      <div className="title">Final Overview</div>
      <div className="body">
        <div className="sub-header">You Will Be Purchasing A License For</div>
        <ProductItem itemData={purchaseData} noAction />
        <div className="sub-header">
          You Will Be Paying For The{' '}
          {formatterHelper(
            purchaseData?.pricesWithFees
              ? purchaseData?.pricesWithFees[0]?.price
              : purchaseData?.first_purchase?.price || 0,
            purchaseData?.pricesWithFees
              ? purchaseData?.pricesWithFees[0]?.coin
              : purchaseData?.first_purchase?.coin || 'USD',
          )}{' '}
          {purchaseData?.pricesWithFees
            ? purchaseData?.pricesWithFees[0]?.coin
            : purchaseData?.first_purchase?.coin || 'USD'}{' '}
          Issuance Fee WIth Your {selectedAsset?.coinName} Vault
        </div>
        <div className="drop-down-item balance-view">
          <img src={selectedAsset?.coinImage} alt="" className="item-image" />
          <div className="item-name">{selectedAsset?.coinName}</div>
          <div className="item-span">
            {selectedAsset?.balance} {selectedAsset?.coinSymbol}
          </div>
        </div>
        <div className="sub-header">
          Cost Of License {selectedAsset?.coinName}
        </div>
        <div className="drop-down-item balance-view">
          <img src={purchaseData?.product_icon} alt="" className="item-image" />
          <div className="item-name">{purchaseData?.product_name}</div>
          <div className="item-span">
            {formatterHelper(
              statsData?.finalFromAmount || 1,
              selectedAsset.coinSymbol,
            )}{' '}
            {selectedAsset?.coinSymbol}
          </div>
        </div>
      </div>
      <div onClick={onNext || null} className="action-button">
        Proceed
      </div>
    </div>
  );
};

export default LicenseReview;
