import React, { useEffect, useState } from 'react';
import PopupModalLayout from '../layouts/PopupModalLayout';
import ProfileAvatar from './ProfileAvatar';

const UserBrandsContactsModal = ({
  isOpen,
  onClose,
  isContacts,
  setIsContacts,
  contacts,
  brands,
  name = '',
  setActiveBrand,
  activeBrand,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (!isOpen) {
      setSearchInput('');
      setSelectedUser();
    }
  }, [isOpen]);

  useEffect(() => {
    const list = isContacts ? contacts : brands;
    const searchQuery = searchInput.trim().toLowerCase();

    const filtter = list.filter(
      (x) =>
        x.name?.toLowerCase().includes(searchQuery) ||
        x.username?.toLowerCase().includes(searchQuery) ||
        x.email?.toLowerCase().includes(searchQuery),
    );

    setFilteredList(filtter);
  }, [isContacts, contacts, brands, searchInput]);

  const onItemClick = (item) => {
    if (isContacts) {
      setSelectedUser(item);
    } else {
      setActiveBrand(item);
      onClose();
    }
  };

  return (
    <PopupModalLayout
      isOpen={isOpen}
      onClose={onClose}
      noHeader
      width={700}
      style={{ padding: 0 }}
    >
      <div className="user-stats-popup-container">
        {selectedUser ? (
          <div className="user-selected-details">
            <ProfileAvatar
              size={180}
              name={selectedUser.name || selectedUser.username}
              avatar={selectedUser.profile_img}
            />
            <div className="selected-user-name">
              {selectedUser.name || selectedUser.username}
            </div>
            <div className="selected-user-mail">{selectedUser.email}</div>
            <div className="user-redirects-container">
              <a
                href={`https://affiliate.app/brokerid/${selectedUser.username}`}
                target="_blank"
                rel="noreferrer"
                className="redirect-item"
              >
                <img
                  src={
                    require('../assets/images/affliate-full-logo.svg').default
                  }
                  alt=""
                  className="redirect-img"
                />
              </a>
              <a
                href={`https://endorsement.app/affiliate/${selectedUser.username}`}
                className="redirect-item"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require('../assets/images/endorsement-logo.svg').default}
                  alt=""
                  className="redirect-img"
                />
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="header-container">
              <img
                src={require('../assets/images/endorsement-logo.svg').default}
                alt=""
                className="header-logo"
              />
            </div>
            <div className="modal-container">
              <div className="search-container">
                <input
                  className="search-input"
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder={`Search ${name}’s ${
                    isContacts ? 'Contacts' : 'Brands'
                  }...`}
                />
              </div>
              <div className="list-container">
                {filteredList.length > 0 ? (
                  filteredList.map((item) => (
                    <div
                      key={item._id || item.email}
                      className="list-item"
                      onClick={() => onItemClick(item)}
                    >
                      <ProfileAvatar
                        size={40}
                        name={item.name || item.username}
                        avatar={item.profile_img}
                        className="profile-avatar"
                      />
                      <div className="item-name">
                        {item.name || item.username}
                      </div>
                      {!isContacts && item.name === activeBrand?.name && (
                        <img
                          src={
                            require('../assets/images/active-check-icon.svg')
                              .default
                          }
                          alt=""
                          className="active-icon"
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="list-empty-text">
                    {`No ${isContacts ? 'Contacts' : 'Brands'} Found...`}
                  </div>
                )}
              </div>
              <div className="control-button-container">
                <div
                  className={`control-item ${isContacts ? 'active' : ''}`}
                  onClick={() => setIsContacts(true)}
                >
                  Contacts
                </div>
                <div
                  className={`control-item ${isContacts ? '' : 'active'}`}
                  onClick={() => setIsContacts(false)}
                >
                  Brands
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </PopupModalLayout>
  );
};

export default UserBrandsContactsModal;
