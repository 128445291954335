import React from 'react';

const SuccessForm = ({ onLogin, onClose }) => {
  return (
    <div className="view-container">
      <div className="sub-text">
        You Have Successfully Completed Your Registration
      </div>
      <div className="d-flex flex-column actions-container">
        <div className="form-buttons mt-5">
          <div className="action-outlined" onClick={onLogin}>
            Login To My Account
          </div>
          <div className="action-filled" onClick={onClose}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessForm;
