import React from 'react';
import Moment from 'moment-timezone';

const PurchasedLicenses = ({ selectedLicense }) => {
  const getMethod = cycle => {
    let method = '';

    switch (cycle) {
      case 'lifetime':
        method = 'One Time';
        break;
      case 'monthly':
        method = 'Monthly Subscription';
        break;
      case 'annual':
        method = 'Annual Subscription';
        break;
      case 'staking':
        method = 'Staking';
        break;
      default:
        method = '';
    }

    return method;
  };

  return (
    <div className="list">
      {selectedLicense?.licenses?.map(item => (
        <div key={item._id} className="license-purchase-item">
          <div className="row-group">
            <div className="item-name">{item.license_id}</div>
            <div className="item-date">
              {Moment(item.timestamp).format('MMM Do YYYY')}
            </div>
          </div>
          <div className="row-group">
            <div className="billing-cycle">
              Billing Method: {getMethod(item.billing_method)}
            </div>
            <div className="date-label">Issuance Date</div>
          </div>
          <div
            className="status-ribbon"
            style={{
              backgroundColor:
                item.license_status === 'active' ? '#30BC96' : '#FF2D55',
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default PurchasedLicenses;
