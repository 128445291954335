import React, { useEffect, useContext } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import TotalDigitalRevenueHeader from '../../TableHeaders/TotalDigitalRevenueHeader';
import TotalDigitalRevenueItem from '../../TableItems/TotalDigitalRevenueItem';

const TotalDigitalTransactionalRevenue = () => {
  const {
    totalDigitalRevenue,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(totalDigitalRevenue);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <TotalDigitalRevenueHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <TotalDigitalRevenueItem key={index} data={item} />
      ))}
    </>
  );
};

export default TotalDigitalTransactionalRevenue;
