import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';
import SettingsSelector from './SettingsSelector';

export default function BrandSettings({
  selectedBrand,
  onClose,
  setComplanFeePairScreen,
}) {
  const [selectedCategory, setSelectedCategory] = useState();
  const [cryptoTableData, setCryptoTableData] = useState(null);
  const getCryptoData = () => {
    setCryptoTableData(null);

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/get/all/coins`)
      .then((resp) => {
        const { data } = resp;

        if (data.status) {
          const coinData = data.coins || [];
          const finalList = [];
          const coinSymbolArray = [];

          // Parsing Array as UI was designed
          coinData.forEach((item) => {
            if (!coinSymbolArray.includes(item.coinSymbol)) {
              coinSymbolArray.push(item.coinSymbol);
              finalList.push({
                ...item,
                name: item.coinName,
                image: item.coinImage,
                price: {
                  USD: item.usd_price,
                },
                asset_type: item.type === 'fiat' ? 'Fiat' : 'Crypto',
              });
            }
          });

          // Sorting Array as crypto comes first
          finalList.sort((a, b) =>
            a.asset_type > b.asset_type
              ? 1
              : b.asset_type > a.asset_type
              ? -1
              : 0,
          );

          setCryptoTableData(finalList);
        } else {
          setCryptoTableData([]);
        }
      })
      .catch((error) => console.log('Error getting Crypto table data', error));
  };

  useEffect(() => {
    getCryptoData();
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: 'max-content',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <div
          onClick={() => {
            onClose();
          }}
          className="prevBreadCrumb"
        >
          Monetize
        </div>
        <div className="breadCrumbArrow">{' -> '}</div>
        <div
          className={selectedCategory ? 'prevBreadCrumb' : 'currentBreadCrumb'}
          onClick={() => {
            setSelectedCategory();
          }}
        >
          {selectedBrand?.mtToolName}
        </div>
        {selectedCategory ? (
          <>
            <div style={{ fontSize: 12 }}>{'->'}</div>
            <div className="currentBreadCrumb">{`${selectedCategory}`}</div>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="fragment_container">
        <SettingsSelector
          selectedBrand={selectedBrand}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          cryptoTableData={cryptoTableData}
          setComplanFeePairScreen={setComplanFeePairScreen}
        />
      </div>
    </div>
  );
}
