import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ChatContext from '../../contexts/ChatContext';
import { APP_CODE, AGENCY_API_URL } from '../../configs';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';

const BotsActions = ({ setIsBot }) => {
  const {
    setBotActiveCategory,
    botActiveCategory,
    setBotActiveSubCategory,
    botActiveSubCategory,
    learnCategories,
    setLeanCatagories,
  } = useContext(ChatContext);

  const { activeSideMenuGroup } = useContext(BrokerAppContext);

  useEffect(() => {
    setBotActiveCategory(CATEGORIES[0]);

    (async () => {
      Axios.get(`${AGENCY_API_URL}/publication/appcode/`, {
        params: { appcode: APP_CODE },
      })
        .then((resp) => {
          const { data } = resp;
          // console.log('Resp On ', data);

          const list = data.data || [];

          const subMenu = list.map((x) => ({
            title: x.name,
            icon: x.profile_pic,
            id: x._id,
            ...x,
          }));

          setLeanCatagories(subMenu, list);
        })
        .catch((error) => {
          console.log('Error On', error);
        });
    })();
  }, []);

  useEffect(() => {
    setBotActiveSubCategory('');
  }, [botActiveCategory]);

  const CATEGORIES = [
    {
      title: 'My Network',
      header: 'Select A Topic',
      subMenu: [
        {
          title: 'Lineage',
          icon: require('../../assets/images/crowdfunding.png').default,
        },
        {
          title: 'Earnings',
          icon: require('../../assets/images/support-category-icons/send.png')
            .default,
        },
        {
          title: 'Reports',
          icon: require('../../assets/images/support-category-icons/trade.png')
            .default,
        },
        {
          title: 'Audit',
          icon: require('../../assets/images/support-category-icons/invest.png')
            .default,
        },
      ],
    },
    { title: 'My Licenses' },
    {
      title: 'Transactions',
      header: 'What Is The Type Of Transaction',
      subMenu: [
        {
          title: 'Add',
          icon: require('../../assets/images/support-category-icons/add.png')
            .default,
        },
        {
          title: 'Send',
          icon: require('../../assets/images/support-category-icons/send.png')
            .default,
        },
        {
          title: 'Trade',
          icon: require('../../assets/images/support-category-icons/trade.png')
            .default,
        },
        {
          title: 'Invest',
          icon: require('../../assets/images/support-category-icons/invest.png')
            .default,
        },
      ],
    },
    {
      title: 'Actions',
      subMenu: [
        {
          title: 'Add',
          icon: require('../../assets/images/support-category-icons/add.png')
            .default,
        },
        {
          title: 'Send',
          icon: require('../../assets/images/support-category-icons/send.png')
            .default,
        },
        {
          title: 'Fiat Funding',
          icon: require('../../assets/images/support-category-icons/trade.png')
            .default,
        },
        {
          title: 'Invest',
          icon: require('../../assets/images/support-category-icons/invest.png')
            .default,
        },
      ],
    },
    {
      title: 'Learn',
      header: 'What Do You Want To Learn About?',
      subMenu: learnCategories || [],
    },
  ];

  return (
    <div className="bot-options">
      <div className="main-category">
        {CATEGORIES.map((item) => (
          <div
            onClick={() => {
              setBotActiveCategory(item);
              if (item.title === 'My Licenses') {
                setIsBot(true);
              }
            }}
            key={item.title}
            className={`category-name ${
              botActiveCategory?.title === item.title ? 'active' : ''
            }`}
          >
            {item.title}
          </div>
        ))}
        <div
          onClick={() => {
            setBotActiveCategory({ title: 'Admin' });
            setIsBot(true);
          }}
          className={`category-name ${
            botActiveCategory?.title === 'Admin' ? 'active' : ''
          }`}
        >
          Admin
        </div>
      </div>
      {botActiveCategory?.subMenu && (
        <div className="sub-category">
          {botActiveCategory?.subMenu?.map((item) => (
            <div
              key={item.title}
              className={`category-item ${
                botActiveSubCategory.title === item.title ? 'active' : ''
              }`}
              onClick={() => {
                setBotActiveSubCategory(item);
                setIsBot(true);
              }}
            >
              <img src={item.icon} alt="" className="category-icon" />
              <div className="category-name">{item.title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BotsActions;
