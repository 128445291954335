import React, { useContext, useEffect, useState } from 'react';
import { BrokerAppContext } from '../contexts/BrokerAppContext';

const VaultCoinSwitcher = () => {
  const { walletCoinData, walletActiveCoin, setWalletActiveCoin } =
    useContext(BrokerAppContext);

  const [searchInput, setSearchInput] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (walletCoinData) {
      const searchQuery = searchInput.trim().toLowerCase();

      const list = walletCoinData.filter(
        (x) =>
          x.coinName.toLowerCase().includes(searchQuery) ||
          x.coinSymbol.toLowerCase().includes(searchQuery),
      );
      setFilteredList(list || []);
    }
  }, [searchInput, walletCoinData]);

  return (
    <div className="vault-switcher-container">
      <div className="wallet-coin-switcher">
        {filteredList?.length > 0 ? (
          filteredList.map((item) => (
            <div
              key={item.coinSymbol}
              className={`coin-item ${
                walletActiveCoin?.coinSymbol === item.coinSymbol ? 'active' : ''
              }`}
              onClick={() => setWalletActiveCoin(item)}
            >
              <img src={item.coinImage} alt="" className="coin-icon" />
              <div className="coin-name">
                {item.coinName?.length > 8 ? item.coinSymbol : item.coinName}
              </div>
            </div>
          ))
        ) : (
          <div className="empty-message">No Coins Found</div>
        )}
      </div>
      <div className="search-container">
        <input
          type="text"
          className="search"
          placeholder="Search Coin..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
    </div>
  );
};

export default VaultCoinSwitcher;
