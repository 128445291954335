import React, { useState, useContext } from 'react';
import Draggable from 'react-draggable';
import dollarIcon from '../../assets/images/dollar-currency-icon.svg';
import closeIcon from '../../assets/images/close-close.svg';
import { CRMContext } from '../../contexts/CRMContext';

const TabItem = ({
  tabData,
  active,
  setActive,
  closeTab,
  setResetPosition
}) => {
  const {
    changeCrmTabPos,
    totalUsersList,
    directUsersList,
    indirectUsersList
  } = useContext(CRMContext);

  const [dragStarted, setDragStarted] = useState(false);

  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

  const onDragStart = () => {
    setDragStarted(true);
    setActive();
  };

  const onDragging = (event, ui) => {
    const { x, y } = dragPosition;
    setDragPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY
    });
  };

  const onDragStop = () => {
    console.log('Drag Pos', dragPosition);
    setDragStarted(false);
    if (dragPosition.x <= -150 || dragPosition.x >= 150) {
      changeCrmTabPos(
        tabData,
        dragPosition.x < 0,
        Math.floor(Math.abs(dragPosition.x) / 300 + 1)
      );
    } else {
      setResetPosition();
    }
  };

  let count;

  switch (tabData.title) {
    case 'Total Users':
      count = totalUsersList !== null ? totalUsersList.length : '';
      break;
    case 'Direct Users':
      count = directUsersList !== null ? directUsersList.length : '';
      break;
    case 'Indirect Users':
      count = indirectUsersList !== null ? indirectUsersList.length : '';
      break;
    default:
  }

  return (
    <Draggable
      bounds="parent"
      onStart={onDragStart}
      onDrag={onDragging}
      onStop={onDragStop}
      handle=".tab-details"
      axis="x"
    >
      <div
        className={`tab-item-wrapper ${active ? 'active' : ''} ${
          dragStarted ? 'dragging' : ''
        }`}
      >
        <img src={closeIcon} alt="" className="close-btn" onClick={closeTab} />
        <div className="tab-details" onClick={setActive}>
          <img className="tab-icon" src={dollarIcon} alt="" />
          <h6 className="suggestion-title">{tabData.title}</h6>
          <h6 className="suggestion-title-count">{count}</h6>
        </div>
      </div>
    </Draggable>
  );
};

export default TabItem;
