import React from 'react';
import { Link } from 'react-router-dom';

const SiteHeader = () => (
  <div className="site-header">
    <a
      href="http://viral.group/"
      target="_blank"
      rel="noopener noreferrer"
      className="header-action-button right-border"
    >
      <img
        src={require('../assets/images/viral-group-icon.svg').default}
        alt=""
        className="influence-icon"
      />
      <div className="tool-tip-container">
        <img
          src={require('../assets/images/viral-group-full.svg').default}
          alt=""
          className=""
        />
      </div>
    </a>
    <div className="app-logo-container">
      <Link to="/">
        <img
          src={
            require('../assets/images/affliate-app-dark-full-logo.svg').default
          }
          alt=""
          className="broker-logo"
        />
      </Link>
    </div>
    <a
      href="https://influencecoin.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="header-action-button"
      data-tip="InfluenceApps"
    >
      <img
        src={
          require('../assets/images/crypto-influncers-small-icon.svg').default
        }
        alt=""
        className="influence-icon"
      />
      <div className="tool-tip-container">
        <img
          src={require('../assets/images/influence-coin-icon.svg').default}
          alt=""
          className=""
        />
      </div>
    </a>
  </div>
);

export default SiteHeader;
