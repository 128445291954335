import React from 'react';

const RegistrationLayout = ({ children, sideBarComponent, headerLogo }) => {
  return (
    <div className="app-registration">
      <div className="container-fluid flex-fill d-flex flex-column">
        <div className="row flex-fill">
          <div className="col-md-4 px-0 d-flex flex-column">
            {sideBarComponent || (
              <img
                src={
                  require('../assets/images/registration-side-image.jpg')
                    .default
                }
                alt=""
                className="side-image"
              />
            )}
          </div>
          <div className="col-md-8  d-flex flex-column position-relative">
            <div className="registration-container">
              <img
                src={
                  headerLogo ||
                  require('../assets/images/affliate-app-dark-full-logo.svg')
                    .default
                }
                alt=""
                className="app-logo"
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationLayout;
