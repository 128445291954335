import React, { useEffect, useContext } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import IndirectTokenTransactionalHeader from '../../TableHeaders/IndirectTokenTransactionalHeader';
import IndirectTokenTransactionalItem from '../../TableItems/IndirectTokenTransactionalItem';

const TotalTokenTransactionVolume = () => {
  const {
    totalTokenTxnVolume,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(totalTokenTxnVolume);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <IndirectTokenTransactionalHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <IndirectTokenTransactionalItem key={index} data={item} />
      ))}
    </>
  );
};

export default TotalTokenTransactionVolume;
