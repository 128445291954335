import { faTheaterMasks } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { lineRadial } from 'd3';
import { min } from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { APP_CODE, GX_API_ENDPOINT } from '../../configs';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import { usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

export default function NetworkLevelDetailsScreen({
  item,
  setIsNetworkLevelDetailsScreen,
}) {
  const [vaultBalance, setVaultBalance] = useState(0);
  const [netWorkValuation, setNetWorkValuation] = useState('');
  const [levelData, setLevelData] = useState();
  const [walletBalance, setWalletBalance] = useState(0);
  const [cryptoTableData, setCryptoTableData] = useState(null);
  const [affiliateBalance, setAffiliateBalance] = useState(0);
  const { profileImg } = useContext(BrokerAppContext);

  const getwalletBalance = async () => {
    const profile_id = await LocalStorageHelper.getProfileId();

    axios
      .post(`${GX_API_ENDPOINT}/coin/vault/service/balances/get`, {
        app_code: APP_CODE,
        profile_id: profile_id,
      })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          setWalletBalance(data.vault);
        }
      })
      .catch((error) => console.log('Error on getting Wallet Balance', error));
  };
  const getCryptoData = () => {
    setCryptoTableData(null);

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/get/all/coins`)
      .then((resp) => {
        const { data } = resp;

        if (data.status) {
          const coinData = data.coins || [];
          const finalList = [];
          const coinSymbolArray = [];

          // Parsing Array as UI was designed
          coinData.forEach((item) => {
            if (!coinSymbolArray.includes(item.coinSymbol)) {
              coinSymbolArray.push(item.coinSymbol);
              finalList.push({
                ...item,
                name: item.coinName,
                image: item.coinImage,
                price: {
                  USD: item.usd_price,
                },
                asset_type: item.type === 'fiat' ? 'Fiat' : 'Crypto',
              });
            }
          });

          // Sorting Array as crypto comes first
          finalList.sort((a, b) =>
            a.asset_type > b.asset_type
              ? 1
              : b.asset_type > a.asset_type
              ? -1
              : 0,
          );

          setCryptoTableData(finalList);
        } else {
          setCryptoTableData([]);
        }
      })
      .catch((error) => console.log('Error getting Crypto table data', error));
  };
  const getAffiliateBalance = async () => {
    const email = await LocalStorageHelper.getAppEmail();
    if (email) {
      axios
        .get(`${GX_API_ENDPOINT}/coin/vault/gxb/balance`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setAffiliateBalance(data.gxbroker_account_balance || 0);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  useEffect(() => {
    getwalletBalance();
    getCryptoData();
    getAffiliateBalance();
  }, []);

  useEffect(() => {
    if (walletBalance && cryptoTableData) {
      let totalBalance = 0;

      cryptoTableData.forEach((x) => {
        totalBalance +=
          walletBalance[`${x.coinSymbol.toLowerCase()}_balance`] * x.price.USD;
      });
      setVaultBalance(totalBalance);
    }
  }, [walletBalance, cryptoTableData]);
  useEffect(() => {
    if (vaultBalance && affiliateBalance !== '') {
      setNetWorkValuation(vaultBalance + affiliateBalance);
    }
  }, [vaultBalance, affiliateBalance]);

  const isLogin = async () => {
    const token = await LocalStorageHelper.getAppToken();
    if (token) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    (async () => {
      if (item && isLogin()) {
        const email = await LocalStorageHelper.getAppEmail();

        axios
          .get(`${GX_API_ENDPOINT}/brokerage/get/broker/users/by/levels`, {
            params: {
              email,
              dd_level: item.ddlevel,
              getAll: true,
            },
          })
          .then((resp) => {
            const { data } = resp;

            if (data.status) {
              setLevelData(data.levelData);
            } else {
              setLevelData({});
            }
          })
          .catch((error) => {
            console.log('Error on getting Network data', error);
          });
      }
    })();
  }, [item]);
  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
      }}
    >
      <div className="networklevelDetail_contianer">
        <div className="networklevelDetail_coverContainer">
          <div className="nld_avatar">
            I
            <img
              style={{
                width: 70,
                height: 70,
                borderRadius: 5,
              }}
              src={profileImg}
              alt=""
            />
          </div>
          <div className="nld_title_container">
            <div className="nld_title">Your Network Valuation</div>
            <div className="nld_valuation">
              {netWorkValuation === ''
                ? 'Calculating'
                : usdValueFormatter.format(netWorkValuation)}
            </div>
          </div>
        </div>

        <div className="nld_levelHeader">
          <div
            className="nld_backButton"
            onClick={() => {
              setIsNetworkLevelDetailsScreen(false);
            }}
          >
            <img
              style={{
                width: 15,
                height: 15,
              }}
              src={
                require('../../assets/mobileassets/back-solid-icon.png').default
              }
              alt=""
            />
          </div>
          DD Level {item?.ddlevel}
        </div>
        <div className="nld_controllerContainer">
          <div className="nld_controllerPrimary">Stats</div>
          <div className="nld_controller">People</div>
        </div>

        {levelData ? (
          <>
            <div className="nld_feature_container">
              <div className="nld_feature">
                <div className="nld_feature_value">{levelData?.count || 0}</div>
                <div className="nld_feature_label">Users</div>
              </div>
              <div className="nld_feature">
                <div className="nld_feature_value">
                  {levelData?.brands || 0}
                </div>
                <div className="nld_feature_label">Brands</div>
              </div>
              <div className="nld_feature">
                <div className="nld_feature_value">
                  {' '}
                  {levelData?.one_timers || 0}
                </div>
                <div className="nld_feature_label">One Timers</div>
              </div>
            </div>
            <div className="nld_list_container">
              <div className="nld_listItem">
                <div className="nld_listLabel">Gross Volume</div>
                <div className="nld_listValue">
                  {usdValueFormatter.format(
                    levelData?.revenue_data?.volume || 0,
                  )}
                </div>
              </div>

              <div className="nld_listItem">
                <div className="nld_listLabel">Revenue Generated</div>
                <div className="nld_listValue">
                  {usdValueFormatter.format(
                    levelData?.revenue_data?.revenue_generated || 0,
                  )}
                </div>
              </div>
              <div className="nld_listItem">
                <div className="nld_listLabel">You Have Earned</div>
                <div className="nld_listValue">
                  {usdValueFormatter.format(
                    levelData?.revenue_data?.revenue_earned || 0,
                  )}
                </div>
              </div>

              <div className="nld_listItem">
                <div className="nld_listLabel">DD Level 0 Valuation</div>
                <div className="nld_listValue">
                  {usdValueFormatter.format(
                    levelData?.revenue_data?.revenue_generated || 0,
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    </div>
  );
}

const LoadingSkeleton = () => {
  return (
    <>
      <div className="nld_feature_container">
        <div className="nld_feature">
          <div className="nld_feature_value">
            <Skeleton width={50} height={50} />
          </div>
          <div className="nld_feature_label">
            <Skeleton width={50} height={10} />
          </div>
        </div>
        <div className="nld_feature">
          <div className="nld_feature_value">
            <Skeleton width={50} height={50} />
          </div>
          <div className="nld_feature_label">
            <Skeleton width={50} height={10} />
          </div>
        </div>
        <div className="nld_feature">
          <div className="nld_feature_value">
            <Skeleton width={50} height={50} />
          </div>
          <div className="nld_feature_label">
            <Skeleton width={50} height={10} />
          </div>
        </div>
      </div>
      <div className="nld_list_container">
        <div className="nld_listItem">
          <div className="nld_listLabel">
            <Skeleton width={100} height={10} />
          </div>
          <div className="nld_listValue">
            <Skeleton width={100} height={10} />
          </div>
        </div>

        <div className="nld_listItem">
          <div className="nld_listLabel">
            {' '}
            <Skeleton width={100} height={10} />
          </div>
          <div className="nld_listValue">
            <Skeleton width={100} height={10} />
          </div>
        </div>
        <div className="nld_listItem">
          <div className="nld_listLabel">
            {' '}
            <Skeleton width={100} height={10} />
          </div>
          <div className="nld_listValue">
            <Skeleton width={100} height={10} />
          </div>
        </div>

        <div className="nld_listItem">
          <div className="nld_listLabel">
            {' '}
            <Skeleton width={100} height={10} />
          </div>
          <div className="nld_listValue">
            <Skeleton width={100} height={10} />
          </div>
        </div>
      </div>
    </>
  );
};
