import React from 'react';

export default function FilterMenu({
  selectedFilter,
  setSelectedFilter,
  onClose,
  isTxnType,
  setTxnType,
}) {
  let menus = isTxnType ? [...TYPE_MENU_ITEMS] : [...LIST_MENU_ITEMS];

  const selectedIndex = menus.findIndex((x) => x.key === selectedFilter);

  const onItemPress = (item) => {
    if (isTxnType) {
      setTxnType(item);
    } else {
      setSelectedFilter(item);
    }
    onClose();
  };
  return (
    <div className="filtermenu_container">
      {menus.map((item, key) => {
        return (
          <div
            onClick={() => onItemPress(item)}
            key={key}
            className="filter_card"
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
}

const LIST_MENU_ITEMS = [
  { title: 'All', key: 'All' },
  { title: 'Direct Broker', key: 'DB' },
  { title: 'Broker Chain', key: 'BC' },
  { title: 'Direct Broker-Dealer', key: 'DBD' },
  { title: 'Broker-Dealer Chain', key: 'BDC' },
];

const TYPE_MENU_ITEMS = [
  { title: 'Trading Revenue', key: 'TR' },
  { title: 'Token Sales', key: 'TS' },
  { title: 'License Subscriptions', key: 'LS' },
  { title: 'Interest', key: 'IN' },
  { title: 'Legacy Money Markets', key: 'MM' },
];
