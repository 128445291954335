import React from 'react';

const CRMEmptyComponent = () => {
  return (
    <div className="crm-empty-wrapper m-auto">
      <div className="empty-action">
        <h3 className="action-title">
          Open Up A New
          <br /> Dashboard
        </h3>
      </div>
      <div className="empty-action">
        <h3 className="action-title">
          Open Up A Saved <br />
          Dashboard View
        </h3>
      </div>
    </div>
  );
};

export default CRMEmptyComponent;
