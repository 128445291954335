import React from 'react';

export default function SearchHeader({ search, setSearch, onBack }) {
  return (
    <div className="feescreen_searchbar_container">
      <div
        style={{
          display: 'flex',
          width: 60,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          onBack();
        }}
      >
        <img
          style={{
            width: 20,
            height: 20,
          }}
          src={require('../../assets/mobileassets/back-short.png').default}
          alt=""
        />
      </div>
      <input
        className="feescreen_searchinput"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={'Search here'}
      />
      <div className="btn_wrap">
        <img
          style={{
            width: 20,
            height: 20,
          }}
          src={require('../../assets/mobileassets/paste-icon-blue.png').default}
          alt=""
        />
      </div>
      <div className="btn_wrap">
        <img
          style={{
            width: 20,
            height: 20,
          }}
          src={require('../../assets/mobileassets/search-modern.png').default}
          alt=""
        />
      </div>
    </div>
  );
}
