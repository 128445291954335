import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ConfirmPasswordForm = ({ password, onBack, onNext }) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    setIsPasswordValid(confirmPassword === password);
  }, [confirmPassword, password]);

  const onProceed = () => {
    if (!isPasswordValid) {
      return toast.error('PASSWORDS DOES NOT MATCH');
    }

    onNext();
  };

  return (
    <div className="view-container">
      <div className="sub-text">Confirm Your Password</div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          <div
            className={`input-validator ${isPasswordValid ? 'valid' : ''}`}
          />
          <input
            type="password"
            className="input-field"
            placeholder="Password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="form-buttons">
          <div className="action-outlined" onClick={onBack}>
            Change Password
          </div>
          <div className="action-filled" onClick={onProceed}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordForm;
