import { select } from 'd3';
import React from 'react';
import { getUriImage } from '../../utils';

export default function HorizontalItem({
  index,
  item,
  onItemSelected,
  selectedItem,
}) {
  const width = window.innerWidth;

  return (
    <div
      className="horizontalItem"
      style={{
        opacity: selectedItem
          ? selectedItem.email === item.email
            ? 0.4
            : 1
          : '',
        minWidth: 100,
      }}
      key={index}
      onClick={() => {
        onItemSelected();
      }}
    >
      <img
        style={{
          height: 30,
          width: 30,
          borderRadius: 15,
        }}
        src={
          item.image ||
          getUriImage(item.profile_image) ||
          'https://i.pravatar.cc/110'
        }
      />
      <div
        style={{
          textAlign: 'center',
          color: '#788995',
          marginTop: 5,
          fontSize: 9,
          fontFamily: 'Montserrat',
        }}
      >
        {item.name || item.email}
      </div>
    </div>
  );
}
