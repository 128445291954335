import axios from 'axios';
import moment from 'moment-timezone';
import React from 'react';

import { usdValueFormatter } from '../../utils';

export default function TransactionItem({
  selectedFilter,
  txnType,
  item,
  cryptoTableData,
  index,
}) {
  let coinData;

  if (cryptoTableData) {
    coinData = cryptoTableData.find(
      (x) =>
        x.coinSymbol ===
        (item?.txn?.amount_coin ||
          item?.txn?.purchased ||
          item?.txn?.debit_data?.coin ||
          item?.txn?.coin_to_debit ||
          item?.txn?.coin ||
          item?.txn?.paymentOption ||
          'USD'),
    );
  }

  const date = `${moment
    .unix(item?.txn?.timestamp / 1000)
    .tz('America/New_York')
    .format('MMM Do YYYY h:mm A')} EST`;

  let value = 0;

  switch (selectedFilter) {
    case 'All':
      value =
        (item?.commissions?.it_commission || 0) +
        (item?.commissions?.dt_commission || 0);
      break;
    case 'DB':
      value = item?.commissions?.dt_commission || 0;
      break;
    case 'BC':
      value = item?.commissions?.it_commission || 0;
      break;
    case 'DBD':
      value = item?.commissions?.bd_commission || 0;
      break;
    case 'BDC':
      value = item?.commissions?.bd_v || 0;
      break;
    default:
      value =
        (item?.commissions?.it_commission || 0) +
        (item?.commissions?.dt_commission || 0);
  }

  if (txnType === 'LS') {
    value = item?.commissions?.com_credited || 0;
  }

  return (
    <div key={item._id || index.toString()} className="itemContainer">
      <div className="itemImageContainer">
        <img
          style={{
            width: 35,
            height: 35,
          }}
          src={coinData?.coinImage}
          alt=""
        />
      </div>
      <div className="itemDetailsContainer">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className="txtname">
            {item?.txn?.name?.split(' ')[0] || item?.txn?.txn_name || ''}
          </div>
          <div className="txtname">{`${usdValueFormatter.format(value)}`}</div>
        </div>
        <div className="appname">{date}</div>
      </div>
    </div>
  );
}
