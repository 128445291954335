import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import CompleteWithdraw from './CompleteWithdraw';
import InputForm from './InputForm';
import LoadingWithdrawSection from './LoadingWithdrawSection';
import WithdrawSelection from './WithdrawSelection';

export default function WithdrawSection({
    isWithdrawOpen,
    setIsWithdrawOpen,
    withdrawableBalance,
    setRefreshAffiliateBalance,
}) {
    const [wheresend, setWhereSend] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [selecteduserapp, setSelectedUserApp] = useState([]);
    const [withdrawAmount, setWithdrawAmount] = useState('');

    useEffect(() => {
        if (selecteduserapp?.profile_id) {
            onWithdraw();
        }
    }, [selecteduserapp]);

    useEffect(() => {
        if (isWithdrawOpen === false) {
            setIsLoading(false);
            setIsCompleted(false);
            setSelectedUserApp([]);
            setWhereSend(false);
            setWithdrawAmount('');
        }
    }, [isWithdrawOpen]);
    const onWithdraw = async () => {
        setIsLoading(true);

        const email = await LocalStorageHelper.getAppEmail();
        const token = await LocalStorageHelper.getAppToken();

        const postData = {
            email,
            token,
            app_code: selecteduserapp?.app_code,
            profile_id: selecteduserapp?.profile_id,
            amount: parseFloat(withdrawAmount),
        };

        axios
            .post(`${GX_API_ENDPOINT}/coin/vault/gxb/balance/withdraw`, postData)
            .then((resp) => {
                const { data } = resp;

                if (data.status) {
                    setIsCompleted(true);
                } else {
                    setIsLoading(false);
                    setIsWithdrawOpen(false);
                    toast.error(data.message || 'Error on Withdrawing');
                }
            })
            .catch((error) => {
                setIsLoading(true);
                setIsWithdrawOpen(false);
                toast.error('Error on Withdrawing');

                console.log('Error on withdraw', error);
            });
    };

    return (
        <div
            className="withdraw_section"
            style={{
                display: isWithdrawOpen ? 'flex' : 'none',
                top: isWithdrawOpen ? 0 : '100%',
            }}
        >
            <div
                className="withdraw_section_top"
                onClick={() => {
                    setIsWithdrawOpen(false);
                }}
            />

            {isCompleted ? (
                <CompleteWithdraw
                    isWithdrawOpen={isWithdrawOpen}
                    setRefreshAffiliateBalance={setRefreshAffiliateBalance}
                    withdrawAmount={withdrawAmount}
                    selecteduserapp={selecteduserapp}
                    setIsWithdrawOpen={setIsWithdrawOpen}
                    setIsLoading={setIsLoading}
                />
            ) : wheresend ? (
                <WithdrawSelection setSelectedUserApp={setSelectedUserApp} />
            ) : (
                <InputForm
                    withdrawableBalance={withdrawableBalance}
                    setWhereSend={setWhereSend}
                    withdrawAmount={withdrawAmount}
                    setWithdrawAmount={setWithdrawAmount}
                    setIsWithdrawOpen={setIsWithdrawOpen}
                />
            )}
            {isLoading && <LoadingWithdrawSection />}
        </div>
    );
}
