import React, { useEffect, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import InterestEarningItem from './InterestEarningItem';
import TransactionItem from './TransactionItem';

export default function SearchList({
  transactionList,
  setIsSearchFocused,
  selectedFilter,
  isFullScreen,
  setIsFullScreen,
  txnType,
  cryptoTableData,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [filteredList, setFilteredList] = useState();

  useEffect(() => {
    if (transactionList) {
      const searchQuery = searchInput.trim().toLowerCase();

      const filtered = transactionList.filter(
        (x) =>
          x.txn?.name?.toLowerCase()?.includes(searchQuery) ||
          x.txn?.txn_name?.toLowerCase()?.includes(searchQuery) ||
          x.txn?.email?.toLowerCase()?.includes(searchQuery),
      );
      setFilteredList(filtered);
    }
  }, [searchInput, transactionList]);
  return (
    <div className="searchList_container">
      {isFullScreen ? (
        <div className="fullScreenHeader">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="fullScreenTitle">
              {txnType?.title || 'Trading Revenue'}
            </div>
            <img
              onClick={() => {
                setIsSearchFocused(true);
                setIsFullScreen(false);
              }}
              style={{
                height: 15,
              }}
              src={require('../../assets/mobileassets/search-icon.png').default}
              alt=""
            />
          </div>
          <div className="back_button">
            <div
              onClick={() => {
                setIsFullScreen(false);
                setIsSearchFocused(false);
              }}
            >
              Back
            </div>
          </div>
        </div>
      ) : (
        <div className="searchBoxShadowContainer">
          <img
            onClick={() => setIsSearchFocused(false)}
            style={{
              height: 15,
              width: 29,
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: 15,
            }}
            src={require('../../assets/mobileassets/back-arrow.png').default}
            alt=""
          />
          <input
            className="searchinput"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            value={searchInput}
            placeholder={'Search Transaction'}
            style={{
              flexGrow: 1,
              width: 0,
              marginTop: 'auto',
              marginBottom: 'auto',

              fontWeight: 'normal',
              color: 'black',
            }}
          />
          <img
            style={{
              height: 15,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            src={require('../../assets/mobileassets/search-icon.png').default}
            alt=""
          />
        </div>
      )}
      {transactionList ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 20,
            overflow: 'scroll',
          }}
        >
          {filteredList?.length > 0 ? (
            filteredList.map((item, index) => {
              return (
                <>
                  {txnType.key === 'IN' ? (
                    <InterestEarningItem
                      item={item}
                      index={index}
                      selectedFilter={selectedFilter.key}
                    />
                  ) : (
                    <TransactionItem
                      txnType={txnType}
                      selectedFilter={selectedFilter.key}
                      item={item}
                      index={index}
                      cryptoTableData={cryptoTableData}
                    />
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '40%',
              }}
              className="noT"
            >
              No Transactions Found
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadingAnimation dark />
        </div>
      )}
    </div>
  );
}
