import React, { useEffect, useContext } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import TotalOTCVolumeHeader from '../../TableHeaders/TotalOTCVolumeHeader';
import TotalOTCVolumeItem from '../../TableItems/TotalOTCVolumeItem';

const TotalOTCTransactionalVolume = () => {
  const {
    totalOtcVolume,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(totalOtcVolume);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <TotalOTCVolumeHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <TotalOTCVolumeItem key={index} data={item} />
      ))}
    </>
  );
};

export default TotalOTCTransactionalVolume;
