import React, { useRef, useState } from 'react';
import sendIcon from '../../assets/images/sendChat.svg';
import addFileIcon from '../../assets/images/cloudUpload.svg';
import FilePreviewModal from './FilePreviewModal';

const ChatInputArea = ({
  onTypingHandler,
  onSubmit,
  isFileLoading,
  initiateSendImage,
  disabledFilePicker,
}) => {
  const [chatInput, setChatInput] = useState('');
  const [fileToUpload, setFileToUpload] = useState('');

  const textRef = useRef();

  const onChangeHandler = e => {
    const { target } = e;
    textRef.current.style.height = '60px';
    textRef.current.style.height = `${target.scrollHeight}px`;
  };

  const pasteImageHandler = event => {
    let items = [
      ...(event.clipboardData || event.originalEvent.clipboardData).items,
    ];
    items = items.filter(i => /image/.test(i.type));
    let item = items[0];
    if (item && item.kind && item.kind === 'file') {
      let blob = item.getAsFile();
      let reader = new FileReader();
      setFileToUpload(blob);
    }
  };

  const uploadSuccessCallback = () => {
    setFileToUpload('');
    setChatInput('');
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(chatInput, setChatInput);
        }}
        className="chatInputs"
      >
        {disabledFilePicker || (
          <label className="addFile">
            <img src={addFileIcon} alt="" />
            <input
              type="file"
              className="d-none"
              onChange={e => {
                setFileToUpload(e.target.files[0]);
              }}
            />
          </label>
        )}

        <input
          ref={textRef}
          value={chatInput}
          onPaste={pasteImageHandler}
          onChange={e => {
            onChangeHandler(e);
            setChatInput(e.target.value);
            onTypingHandler();
          }}
          className="textArea"
          placeholder="Type Your Message..."
        />
        <button type="submit" className="sendBtn">
          <img src={sendIcon} alt="" />
        </button>
      </form>
      {fileToUpload && (
        <FilePreviewModal
          isFileLoading={isFileLoading}
          chatInput={chatInput}
          setChatInput={setChatInput}
          fileToUpload={fileToUpload}
          onClose={() => setFileToUpload('')}
          onSubmit={() =>
            initiateSendImage(fileToUpload, chatInput, uploadSuccessCallback)
          }
        />
      )}
    </>
  );
};

export default ChatInputArea;
