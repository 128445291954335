import React, { useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import VaultCoinSwitcher from '../../components/VaultCoinSwitcher';
import WalletTransactionList from '../../components/WalletTransactionList';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import { useUserEarnings } from '../../queryHooks';
import { usdValueFormatter } from '../../utils';

const VaultsPage = () => {
  const { walletCoinData, walletActiveCoin, setWalletActiveCoin } =
    useContext(BrokerAppContext);

  const { data: earningsData, status } = useUserEarnings();

  useEffect(() => {
    if (walletCoinData && !walletActiveCoin) {
      setWalletActiveCoin(walletCoinData[0]);
    }
  }, [walletCoinData]);

  return (
    <LayoutWithSideBar>
      <div className="broker-app-main-wrapper">
        <div className="vault-cover-wrapper">
          <div className="cover-value-container">
            {status === 'success' ? (
              <div className="cover-value">
                {usdValueFormatter.format(
                  (earningsData?.gxbroker_account_balance || 0) +
                    (earningsData?.withdraw_sum || 0),
                )}
              </div>
            ) : (
              <Skeleton className="cover-value" width={150} height={30} />
            )}
            <div className="cover-value-label">Life Time Earnings</div>
            <div className="currency-container">
              <img
                src={require('../../assets/images/usa-today.png').default}
                alt=""
                className="currency-icon"
              />
            </div>
          </div>
          <img
            src={
              require('../../assets/images/affiliate-bank-vector.svg').default
            }
            alt=""
            className="affiliate-bank-logo"
          />
          <div className="cover-value-container">
            {status === 'success' ? (
              <div className="cover-value">
                {usdValueFormatter.format(
                  earningsData?.gxbroker_account_balance || 0,
                )}
              </div>
            ) : (
              <Skeleton className="cover-value" width={150} height={30} />
            )}
            <div className="cover-value-label">Earnings Vault</div>
            <div className="currency-container left">
              <img
                src={require('../../assets/images/usa-today.png').default}
                alt=""
                className="currency-icon"
              />
            </div>
          </div>
        </div>
        <VaultCoinSwitcher />
        <div className="broker-fragment-wrapper flex-column p-0">
          <WalletTransactionList activeAsset={walletActiveCoin} />
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default VaultsPage;
