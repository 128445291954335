import { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';
import ChatContext from '../contexts/ChatContext';

const ProductsBrandSlider = ({ allBrands, activeBrand, setActiveBrand }) => {
  const { isChatClosed } = useContext(ChatContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, [300]);

    return () => {
      clearTimeout(timeout);
    };
  }, [isChatClosed]);

  const onSliderChange = (newIndex) => {
    if (allBrands) {
      setActiveBrand(allBrands[newIndex]);
    }
  };

  return (
    <div className="brands-carousel">
      {!isLoading && allBrands ? (
        <Slider
          className="center"
          centerMode
          infinite
          centerPadding="60px"
          slidesToShow={isChatClosed ? 5 : 3}
          speed={500}
          arrows={false}
          focusOnSelect
          afterChange={onSliderChange}
        >
          {allBrands?.map((item) => (
            <div key={item._id} className="brand-item-wrapper">
              <div className="brand-item-container">
                <div
                  key={item.displayName}
                  className={`brand-item ${
                    activeBrand?.displayName === item.displayName
                      ? 'active'
                      : ''
                  }`}
                  style={{ backgroundColor: `#${item.colorCode}` }}
                >
                  {item.profilePicWhitePNG && (
                    <img
                      src={item.profilePicWhitePNG}
                      alt=""
                      className="brand-logo"
                    />
                  )}
                  <div className="brand-name">{item.displayName}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="loading-skelton">
          {Array(8)
            .fill(1)
            .map((_, index) => (
              <div key={index} className="brand-skeleton">
                <Skeleton count={1} height={40} width={40} />
                <Skeleton
                  count={1}
                  height={20}
                  width={150}
                  className="brand-name-skelton"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ProductsBrandSlider;
