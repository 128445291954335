import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import Axios from 'axios';
import { APP_CODE, GX_API_ENDPOINT } from '../../../../configs';
import LocalStorageHelper from '../../../../utils/LocalStorageHelper';
import { formatterHelper } from '../../../../utils';

const BuyCoinSelector = ({ onNext, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [coinsWithBalance, setCoinsWithBalance] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const profileId = LocalStorageHelper.getProfileId();

      Axios.post(`${GX_API_ENDPOINT}/coin/vault/service/coins/get`, {
        app_code: APP_CODE,
        profile_id: profileId,
      })
        .then(resp => {
          const { data } = resp;

          // console.log('Sending Balance', data);

          if (data.status) {
            const balances = data.coins_data || [];

            const filteredCurrencies = [];

            balances.forEach(item => {
              if (item.coinValue > 0) {
                filteredCurrencies.push({
                  ...item,
                  balance: formatterHelper(item.coinValue, item.coinSymbol),
                  image: item.coinImage,
                  name: item.coinName,
                });
              }
            });

            setCoinsWithBalance(filteredCurrencies);
          }
        })
        .catch(error => {})
        .finally(() => setIsLoading(false));
    })();
  }, []);

  const onCoinSelect = coin => {
    onNext(coin);
  };

  const menu = (
    <Menu>
      {coinsWithBalance.map((item, index) => (
        <Menu.Item key={index} onClick={() => onCoinSelect(item)}>
          <div className="drop-down-item">
            <img src={item.image} alt="" className="item-image" />
            <div className="item-name">{item.name}</div>
            <div className="item-span">
              {item.balance} {item.coinSymbol}
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="body-container">
      <div className="title">
        {title ||
          'Which Of Your Vaults Do You Want To Use To Pay The Issuance Fee?'}
      </div>
      <div className="body">
        <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
          <Button>Select A Vault</Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default BuyCoinSelector;
