import React, { useContext, useEffect } from 'react';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';

const MainNavMenu = ({ menus }) => {
  const { activeMainNav, setActiveMainNav, isMenuOpen } = useContext(
    BrokerAppContext
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setActiveMainNav(menus[0]);
    }
  }, [menus]);

  return (
    <div className="main-nav-wrapper">
      <ul className={`main-nav ${isMenuOpen ? '' : 'menu-closed'}`}>
        {menus &&
          menus.map(item => (
            <li
              key={item}
              className={`main-nav-item ${
                activeMainNav === item ? 'active' : ''
              }`}
              onClick={() => setActiveMainNav(item)}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MainNavMenu;
