import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const PasswordForm = ({ password, setPassword, onBack, onNext }) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const capRegex = new RegExp(/^.*[A-Z].*/);
    const numRegex = new RegExp(/^.*[0-9].*/);
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

    setIsPasswordValid(
      password.length >= 6 &&
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password),
    );
  }, [password]);

  const onProceed = () => {
    if (!isPasswordValid) {
      return toast.error(
        'PASSWORD SHOULD CONTAIN\n1. 6 Letters\n2. Atleast One Uppercase Character\n3. A Numeric Character\n4. A Special Character',
      );
    }
    onNext();
  };

  return (
    <div className="view-container">
      <div className="sub-text">Create Your Password</div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          <div
            className={`input-validator ${isPasswordValid ? 'valid' : ''}`}
          />
          <input
            type="password"
            className="input-field"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div className="form-buttons">
          <div className="action-outlined" onClick={() => setIsModalOpen(true)}>
            Password Requirements
          </div>
          <div className="action-filled" onClick={onProceed}>
            Next
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <div className="password-requirements">
          <div className="header">Password Requirements</div>
          <div className="modal-body">
            <div className="item">Should Be 6 Amount Of Characters</div>
            <div className="item">Contains Atleast One Capital Letter</div>
            <div className="item">Contains Atleast One Number</div>
            <div className="item">Contains Atleast One Special Character</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PasswordForm;

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 0,
  },
};
