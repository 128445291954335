import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { emailValidator, usernameRegex } from '../../utils';

const EmailForm = ({
  emailId,
  setEmailId,
  userName = '',
  setUserName,
  usersList,
  onBack,
  onNext,
  noValidity,
  customTitle,
  isPassword,
  password,
  setPassword,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isUserNameValid, setIsUserNameValid] = useState(false);

  useEffect(() => {
    if (usersList) {
      const email = emailId.toLowerCase().trim();
      const validEmail = emailValidator(email);

      if (!validEmail) {
        return setIsEmailValid(false);
      }
      if (!noValidity) {
        setIsEmailValid(!usersList.emails.includes(email));
      }
    }
  }, [emailId, usersList, noValidity]);

  useEffect(() => {
    if (usersList) {
      const username = userName?.toLowerCase().trim();

      if (username.length < 6 || !usernameRegex.test(username)) {
        return setIsUserNameValid(false);
      }

      if (!noValidity) {
        setIsUserNameValid(!usersList.usernames.includes(username));
      }
    }
  }, [userName, usersList, noValidity]);

  const onProceed = () => {
    const email = emailId.toLowerCase().trim();
    if (!isEmailValid) {
      if (!emailValidator(email)) {
        return toast.error('PLEASE INPUT A VALID EMAIL ID');
      }
      if (!noValidity) {
        return toast.error('EMAIL ID ALREADY IN USE');
      }
    }

    if (!isPassword) {
      const username = userName?.toLowerCase().trim();

      if (!isUserNameValid) {
        if (!usernameRegex.test(username)) {
          return toast.error('PLEASE INPUT A VALID USERNAME');
        }
        if (!noValidity) {
          return toast.error('USERNAME ALREADY IN USE');
        }
      }
    }

    onNext();
  };

  return (
    <div className="view-container">
      <div className="sub-text">
        {customTitle || 'Create Username And Enter Your Email'}
      </div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          {noValidity || (
            <div className={`input-validator ${isEmailValid ? 'valid' : ''}`} />
          )}
          <input
            type="text"
            className="input-field"
            placeholder="Email"
            value={emailId}
            onChange={e => setEmailId(e.target.value)}
          />
        </div>
        {isPassword ? (
          <div className="input-container">
            {noValidity || (
              <div
                className={`input-validator ${isUserNameValid ? 'valid' : ''}`}
              />
            )}
            <input
              type="password"
              className="input-field"
              placeholder="Temp Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        ) : (
          <div className="input-container">
            {noValidity || (
              <div
                className={`input-validator ${isUserNameValid ? 'valid' : ''}`}
              />
            )}
            <input
              type="text"
              className="input-field"
              placeholder="Username"
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </div>
        )}
        <div className="form-buttons">
          <div className="action-outlined" onClick={onBack}>
            Go Back
          </div>
          <div className="action-filled" onClick={onProceed}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
