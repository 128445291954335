import React from 'react';
import { Link } from 'react-router-dom';
import RegistrationLayout from '../layouts/RegistrationLayout';

const SignupLandingPage = ({ appData }) => {
  return (
    <RegistrationLayout headerLogo={appData?.headerLogo}>
      <div className="view-container">
        <div className="sub-text">
          If This Is Your First Time Here. You Can Register For Free.
        </div>
        <div className="sub-text">Select Which Process Applies To You</div>
        <div className="d-flex flex-column actions-container">
          <Link to="/registration/pre" className="action-outlined">
            I Was Pre-Registered
          </Link>
          <Link to="/registration/new" className="action-filled">
            I Got Here By Myself
          </Link>
          <Link to="/registration/referred" className="action-outlined">
            I Was Referred By A Broker
          </Link>
        </div>
      </div>
    </RegistrationLayout>
  );
};

export default SignupLandingPage;
