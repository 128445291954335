/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import guest from '../../assets/images/guest.jpg';
import moment from 'moment-timezone';
import FilePreviewOnChat from './FilePreviewOnChat';

const ChatArea = ({ messageArray, currentUser, typingFlag, typingMessage }) => {
  return (
    <div id="chat-area" className="chat-area-wrapper">
      <div className="chats">
        {messageArray.map((item) => {
          return item.type !== 'group' ? (
            <Message
              key={item.timestamp}
              messageObj={item}
              isSent={currentUser === item.sender}
            />
          ) : (
            <InChatInfo key={item.timestamp} message={item.message} />
          );
        })}
        <br />
        {typingFlag && <TypingIndicator typingMessage={typingMessage} />}
      </div>
    </div>
  );
};

const TypingIndicator = ({ typingMessage }) => {
  return (
    <div className="typing-indicator">
      {typingMessage}&nbsp;&nbsp;&nbsp;
      <div className="ticontainer">
        <div className="tiblock">
          <div className="tidot" />
          <div className="tidot" />
          <div className="tidot" />
        </div>
      </div>
    </div>
  );
};

const InChatInfo = ({ message }) => {
  return (
    <div className="in-chat-info">
      <span>{message}</span>
    </div>
  );
};

const Message = ({ messageObj, isSent }) => {
  const { profileImg } = useContext(BrokerAppContext);
  return (
    <>
      {isSent ? (
        <div className="answer">
          <div className="question-wrap">
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className="question-text">{messageObj.message}</p>
          </div>
          <div className="bot">
            <img
              className="img-logo"
              src={profileImg || guest}
              alt={messageObj.sender}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
        </div>
      ) : (
        <div className="question">
          <div className="bot">
            <div className="app-logo-container">
              <img
                className="app-logo"
                src={
                  require('../../assets/images/affliate-app-white-logo.svg')
                    .default
                }
                alt={messageObj.sender}
              />
            </div>

            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
          <div className="question-wrap">
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className="question-text">{messageObj.message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatArea;
