import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';

const BrandItem = ({ name, icon, count, bankerEmail, onClick }) => {
  const [noOfLicence, setNoOfLicence] = useState('');

  useEffect(() => {
    if (bankerEmail) {
      Axios.get(`${GX_API_ENDPOINT}/gxb/product/get`, {
        params: { email: bankerEmail },
      })
        .then(resp => {
          const { data } = resp;

          // console.log('getLicenceDetails', data);

          const products = data.products || [];

          setNoOfLicence(products.length || 0);
        })
        .catch(error => {
          console.log('Error on getting licence details', error);
        });
    }
  }, [bankerEmail]);

  return (
    <div className="list-item" onClick={onClick}>
      <img src={icon} alt="" className="item-icon" />
      <div className="item-details">
        <div className="item-name-container">
          <div className="item-name">{name}</div>
          <div className="numbers">
            <i className="fa fa-users" aria-hidden="true" />
            {count || 0}
          </div>
        </div>
        <div className="no-of-licenses">{noOfLicence || 0} Licenses</div>
      </div>
    </div>
  );
};

export default BrandItem;
