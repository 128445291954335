import React, { useEffect, useMemo, useState } from 'react';
import MenuItem from './MenuItem';

export default function SettingsSelector({
  setSelectedCategory,
  selectedCategory,
  selectedBrand,
  cryptoTableData,
  setComplanFeePairScreen,
}) {
  const [isAssetIoControllerOpen, setIsAssetIoControllerOpen] = useState(false);
  const [isLiquid, setIsLiquid] = useState(false);
  const [isEditor, setIsEditor] = useState(false);

  const [isOTCBotsOpen, setIsOTCBotsOpen] = useState(false);
  const [otcData, setOtcData] = useState('');

  useEffect(() => {
    if (isAssetIoControllerOpen) {
      setIsOTCBotsOpen(false);
    }
  }, [isAssetIoControllerOpen]);

  useEffect(() => {
    if (isOTCBotsOpen) {
      setIsAssetIoControllerOpen(false);
    }
  }, [isOTCBotsOpen]);

  let fiatAssets = 0;
  let cryptoAssets = 0;

  if (cryptoTableData) {
    fiatAssets = cryptoTableData.filter(
      (item) => item.asset_type === 'Fiat',
    ).length;

    cryptoAssets = cryptoTableData.length - fiatAssets;
  }

  const instaProductsList = useMemo(() => {
    switch (selectedBrand.title) {
      case 'Bonds':
        return [
          {
            icon: require('../../assets/mobileassets/forex.png').default,
            title: 'Forex Currencies',
            subTitle: 'When Your Customers Earn Interest From Forex ',
            onPress: () => {
              setComplanFeePairScreen([
                'MoneyMarketFeeScreen',
                {
                  type: 'fiat',
                  selectedBrand,
                  bonds: true,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/crypto.png').default,
            title: 'Cryptocurrencies',
            subTitle: 'When Your Customers Earn Interest From Crypto ',
            onPress: () => {
              setComplanFeePairScreen([
                'MoneyMarketFeeScreen',
                {
                  type: 'crypto',
                  selectedBrand,
                  bonds: true,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/per-customer-icon.png')
              .default,
            title: 'Per Customer',
            isCustom: true,
            subTitle: 'Set Custom Exchange Fees For Specific Customers',
          },
          {
            icon: require('../../assets/mobileassets/bond.png').default,
            title: 'Per Bond',
            isCustom: true,
            subTitle: 'Set Custom Bond Fees For Specific Bonds',
          },
        ];
      case 'MoneyMarkets':
        return [
          {
            icon: require('../../assets/mobileassets/forex.png').default,
            title: 'Forex Currencies',
            subTitle: 'When Your Customers Earn Interest From Forex ',
            onPress: () => {
              setComplanFeePairScreen([
                'MoneyMarketFeeScreen',
                {
                  type: 'fiat',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/crypto.png').default,
            title: 'Cryptocurrencies',
            subTitle: 'When Your Customers Earn Interest From Crypto ',
            onPress: () => {
              setComplanFeePairScreen([
                'MoneyMarketFeeScreen',
                {
                  type: 'crypto',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/per-customer-icon.png')
              .default,
            title: 'Per Customer',
            isCustom: true,
            subTitle: 'Set Custom Exchange Fees For Specific Customers',
          },
        ];

      default:
        return [
          {
            icon: require('../../assets/mobileassets/fiat-crypto-icon.png')
              .default,
            title: 'Forex To Crypto',
            fromAssets: 'Fiat',
            toAsset: 'Crypto',
            subTitle: 'When Your Customers Trade From Forex To Crypto',
            onPress: () => {
              setComplanFeePairScreen([
                'ExchangeFeePairScreen',
                {
                  type: 'fiat_crypto',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/crypto-fiat-icon.png')
              .default,
            title: 'Crypto To Forex',
            fromAssets: 'Crypto',
            toAsset: 'Fiat',
            subTitle: 'When Your Customers Trade From Crypto To Forex',
            onPress: () => {
              setComplanFeePairScreen([
                'ExchangeFeePairScreen',
                {
                  type: 'crypto_fiat',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/fiat-fiat-icon.png')
              .default,
            title: 'Forex To Forex',
            fromAssets: 'Fiat',
            toAsset: 'Fiat',
            subTitle: 'When Your Customers Trade From Forex To Forex',
            onPress: () => {
              setComplanFeePairScreen([
                'ExchangeFeePairScreen',
                {
                  type: 'fiat_fiat',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/crypto-crypto-icon.png')
              .default,
            title: 'Crypto To Crypto',
            fromAssets: 'Crypto',
            toAsset: 'Crypto',
            subTitle: 'When Your Customers Trade Crypto To Crypto',
            onPress: () => {
              setComplanFeePairScreen([
                'ExchangeFeePairScreen',
                {
                  type: 'crypto_crypto',
                  selectedBrand,
                },
              ]);
            },
          },
          {
            icon: require('../../assets/mobileassets/per-customer-icon.png')
              .default,
            title: 'Per Customer',
            isCustom: true,
            subTitle: 'Set Custom Exchange Fees For Specific Customers',
          },
        ];
    }
  }, []);
  const viewContents = useMemo(() => {
    switch (selectedCategory) {
      case 'Update':
        return (
          <>
            <div
              style={{
                width: '100%',
                height: 'auto',
                overflow: 'scroll',
              }}
            >
              {instaProductsList &&
                instaProductsList.map((item, key) => {
                  return (
                    <>
                      <MenuItem
                        icon={item.icon}
                        title={item.title}
                        subTitle={item.subTitle}
                        onPress={item.onPress}
                        disabled={item.disabled}
                      />
                    </>
                  );
                })}
            </div>
          </>
        );

      default:
        return (
          <div
            style={{
              width: '100%',
              height: 'auto',
              overflow: 'scroll',
            }}
          >
            <>
              <MenuItem
                icon={
                  require('../../assets/mobileassets/change-fee-icon.png')
                    .default
                }
                title={`Change ${selectedBrand.mtToolName}`}
                subTitle={`Change ${selectedBrand.mtToolName}`}
                onPress={() => setSelectedCategory('Update')}
              />
            </>
          </div>
        );
    }
  }, [
    instaProductsList,
    selectedBrand.mtToolName,
    selectedCategory,
    setSelectedCategory,
  ]);
  return <div>{viewContents}</div>;
}
