import React from 'react';

const FullScreenToggler = ({ isExpanded, expandTable }) => {
  return (
    <div
      className="fullscreen-toggler"
      onClick={() => expandTable(!isExpanded)}
    >
      <i
        className={`fas  ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-up'}`}
      />
    </div>
  );
};

export default FullScreenToggler;
