import React from 'react'
import MobileNav from '../components/MobileNav'

export default function Bot() {
    return (
        <>
            <MobileNav />

            <div className='support_container'>

                <div className='support_header'>
                    Hey I’m Eric
                </div>

                <div className='support_subheader'>
                    The Affiliate Bot
                </div>
                <div className='support_desc'>
                    I was created to help you making lightning fast decisions as you grow
                    your network. Please select one of the categories so I can best assist
                    you.
                </div>
                {
                    SECTIONS?.map((item, key) => {
                        return (
                            <>
                                <div key={key} className='section_header'>{item.title}</div>
                                {
                                    item?.data.map((item, key) => {
                                        return (
                                            <div className='support_list_item'>
                                                {
                                                    item.title
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>

                        )
                    })
                }

            </div>
        </>
    )
}

const SECTIONS = [
    {
        title: 'Looking For Info?',
        data: [
            {
                title: 'My Network',

            },
            {
                title: 'My Licenses',

            },
            {
                title: 'Transactions',
                header: 'What Is The Type Of Transaction',

            },
        ],
    },
    {
        title: 'Looking For Guidance?',
        data: [
            {
                title: 'Actions',

            },
            {
                title: 'Learn',


            },
        ],
    },
];
