import React from 'react';
import LoadingAnimation from '../LoadingAnimation';
import BrandCategories from './BrandCategories';
import BrandsList from './BrandsList';
import TopEarners from './TopEarners';

const BrandsView = ({
  activeBrandCategory,
  activeBrandCategoriesList,
  setActiveBrandCategory,
  activeNavMenu,
  setShowDetails,
}) => {
  return (
    <div className="earn-view">
      <BrandCategories
        activeNavMenu={activeNavMenu}
        setActiveBrandCategory={setActiveBrandCategory}
        activeBrandCategoriesList={activeBrandCategoriesList}
        activeBrandCategory={activeBrandCategory}
      />
      {activeBrandCategory ? (
        <div className="brand-details">
          <BrandsList
            activeNavMenu={activeNavMenu}
            activeBrandCategory={activeBrandCategory}
            setShowDetails={setShowDetails}
          />
          <TopEarners
            activeNavMenu={activeNavMenu}
            activeBrandCategory={activeBrandCategory}
          />
        </div>
      ) : (
        <LoadingAnimation dark />
      )}
    </div>
  );
};

export default BrandsView;
