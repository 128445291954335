import React from 'react';
import LicenseItem from '../../../../BrokerChat/LicensesBot/LicenseItem';

const UpgradingLicense = ({ userAllLicense, setUpgradingLicense, onNext }) => {
  return (
    <div className="body-container">
      <div className="title">Select The License You Are Upgrading?</div>
      <div className="body">
        <div className="all-license-list">
          {userAllLicense
            ? userAllLicense.map((item, index) => (
                <LicenseItem
                  key={index}
                  item={item}
                  style={{ marginRight: 0, marginBottom: 15 }}
                  onUpgrade={() => {
                    setUpgradingLicense(item);
                    onNext();
                  }}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default UpgradingLicense;
