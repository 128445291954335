import React from 'react';
import coin from '../../../assets/images/coin.svg';
import CADIcon from '../../../assets/images/cadIcon.png';
import { getDateFromTimeStamp, getTimeFromTimeStamp } from '../../../utils';

const DirectUserItem = ({ data }) => {
  return (
    <div className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '45%', paddingLeft: '15px' }}
      >
        <label className="user-name">{data.name}</label>
      </div>

      <div className="flexed-item" style={{ width: '17%' }}>
        {/* <label>{data.country ? data.country : "-"}</label> */}
        <img className="user-country-icon" src={CADIcon} alt="" />
      </div>
      <div className="flexed-item" style={{ width: '30%' }}>
        <label className="user-signup-date">
          {getDateFromTimeStamp(data.timestamp)}
        </label>
        <label className="user-signup-time">
          {getTimeFromTimeStamp(data.timestamp)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default DirectUserItem;
