import React, { useContext } from 'react';
import { CRMContext } from '../../../contexts/CRMContext';
import FullScreenToggler from '../FullScreenToggler';
import TableSearchBar from '../TableSearchBar';

const IndirectOTCRevenueHeader = () => {
  const { isTableSearchOpen, isTableExpanded, expandTable } = useContext(
    CRMContext
  );

  return (
    <div className="details-table-item details-table-header scroll-shadow">
      {!isTableSearchOpen ? (
        <>
          <div
            className="flexed-item"
            style={{
              width: '3%'
            }}
          />
          <div
            className="flexed-item"
            style={{
              width: '22%',
              paddingLeft: '15px'
            }}
          >
            <label>Name</label>
          </div>

          <div
            className="flexed-item"
            style={{
              width: '8%'
            }}
          >
            <label>Country</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '14%'
            }}
          >
            <label>Date(EST)</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '8%'
            }}
          >
            <label>Coin</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '18%'
            }}
          >
            <label>Upline</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '11%'
            }}
          >
            <label>Total Fees</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '11%'
            }}
          >
            <label>My Revenue</label>
          </div>
        </>
      ) : (
        <div
          className="flexed-item"
          style={{
            width: '65%'
          }}
        />
      )}
      <FullScreenToggler
        isExpanded={isTableExpanded}
        expandTable={expandTable}
      />
      <TableSearchBar />
    </div>
  );
};

export default IndirectOTCRevenueHeader;
