import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BrandDetailsSideBar from '../../components/BrandDetailsSideBar';
import BrandsView from '../../components/BrandsView';
import EarnTopMenu from '../../components/EarnTopMenu';
import MyProfileCover from '../../components/MyProfileCover';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';

const EarnPage = () => {
  const [activeNavMenu, setActiveNavMenu] = useState('');
  const [activeBrandCategoriesList, setActiveBrandCategoriesList] = useState();
  const [activeBrandCategory, setActiveBrandCategory] = useState();
  const [showDetails, setShowDetails] = useState();

  useEffect(() => {
    setActiveBrandCategoriesList();
    Axios.get(
      'https://storeapi.apimachine.io/dynamic/BrokerApp/whatdoyouneedabrokerfor',
      { params: { key: '867e1ca2-8932-4eff-a3b8-e448c936821b' } },
    )
      .then(resp => {
        const { data } = resp;

        // console.log('Data', data);

        if (data.success) {
          setActiveBrandCategoriesList(data.data || []);
          setActiveBrandCategory(data.data[0]);
        } else {
          toast.error('API Error');
        }
      })
      .catch(error => {
        toast.error('Network Error, Please Check Your Internet Connection');
        console.log('Error getting brands list', error);
      });

    return () => {};
  }, [activeNavMenu]);

  return (
    <LayoutWithSideBar
      rightSideBar={
        showDetails ? <BrandDetailsSideBar selectedBrand={showDetails} /> : null
      }
    >
      <div className="broker-app-main-wrapper">
        <MyProfileCover>
          <EarnTopMenu
            activeNavMenu={activeNavMenu}
            setActiveNavMenu={setActiveNavMenu}
          />
        </MyProfileCover>
        <div className="broker-fragment-wrapper flex-column p-5">
          {activeNavMenu.title === 'Financial' ? (
            <BrandsView
              activeBrandCategory={activeBrandCategory}
              activeBrandCategoriesList={
                activeNavMenu.title === 'Financial'
                  ? activeBrandCategoriesList
                  : []
              }
              setActiveBrandCategory={setActiveBrandCategory}
              activeNavMenu={activeNavMenu}
              setShowDetails={setShowDetails}
            />
          ) : (
            <div className="coming-soon-text">Coming Soon...</div>
          )}
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default EarnPage;
