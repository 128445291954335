import React, { useContext } from 'react';
import dollarIcon from '../../assets/images/dollar-currency-icon.svg';
import { CRMContext } from '../../contexts/CRMContext';

const SearchSuggestions = ({ suggestions, setSearchInput }) => {
  const { crmTabs, addToCrmTabs, setActiveCrmTab } = useContext(CRMContext);

  const addToTabs = tab => {
    if (!crmTabs.some(item => item.title === tab.title)) {
      addToCrmTabs(tab);
    }
    setActiveCrmTab(tab.title);
    setSearchInput('');
  };

  return (
    <div
      className="search-suggestion-wrapper"
      style={{
        opacity: suggestions.length > 0 ? '1' : '0',
        visibility: suggestions.length > 0 ? 'visible' : 'hidden'
      }}
    >
      {suggestions.map(item => (
        <div
          key={item.title}
          className="suggestion-item"
          onClick={() => addToTabs(item)}
        >
          <img src={dollarIcon} alt="" />
          <h6 className="suggestion-title">{item.title}</h6>
          <h6 className="suggestion-title-count">{'.'}</h6>
        </div>
      ))}
    </div>
  );
};

export default SearchSuggestions;
