import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../../configs';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import LicenseItem from './LicenseItem';
import PurchasedLicenses from './PurchasedLicenses';

const LicensesList = ({ activeBrand, userEmail }) => {
  const [currentUserDetails, setCurrentUserDetails] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [activeFilter, setActiveFilter] = useState(FILTERS[0]);
  const [totalPurchaseLicenses, setTotalPurchaseLicenses] = useState(0);
  const [selectedLicense, setSelectedLicense] = useState(false);
  const [productLicenses, setProductLicenses] = useState();

  useEffect(() => {
    if (userEmail) {
      Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/get/uplines`, {
        params: { email: userEmail },
      })
        .then(({ data }) => {
          if (data.status) {
            setCurrentUserDetails(data.user || '');
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => console.log('Error on locating user', error));
    }
  }, [userEmail]);

  useEffect(() => {
    setProductLicenses();
    setErrorMessage('');
    setSelectedLicense();

    const merchant = activeBrand?.bankerTag
      ? activeBrand?.bankerTag === 'All Licenses'
        ? undefined
        : activeBrand?.bankerTag
      : undefined;

    let params = {
      email: userEmail,
      merchant,
    };

    if (activeFilter?.status === true) {
      params = { ...params, getActive: true };
    } else if (activeFilter.status === false) {
      params = { ...params, getInactive: true };
    }

    Axios.get(`${GX_API_ENDPOINT}/coin/vault/user/license/get`, {
      params,
    })
      .then((resp) => {
        const { data } = resp;

        // console.log('Data', data);

        if (data.status) {
          setTotalPurchaseLicenses(data.total_licenses_purchased);
          const licenceList = data.productLicenses || [];

          setProductLicenses(licenceList);
        } else {
          setErrorMessage(data.message);
          setProductLicenses([]);
        }
      })
      .catch((error) => {
        setProductLicenses([]);
        console.log('Error on getting broker data', error);
      });
  }, [userEmail, activeBrand, activeFilter]);

  return (
    <div className="license-list-container">
      <div className="filter-container">
        {FILTERS.map((item) => (
          <div
            key={item.title}
            className={`filter-item ${
              activeFilter?.title === item.title ? 'active' : ''
            } `}
            onClick={() => setActiveFilter(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
      {productLicenses ? (
        errorMessage ? (
          <div className="error-message-container">{errorMessage}</div>
        ) : (
          <div className="view-container">
            <div className="user-container border-0">
              <img
                src={currentUserDetails?.profile_img}
                alt=""
                className="user-image"
              />
              <div className="user-details">
                <div className="user-name">{currentUserDetails?.name}</div>
              </div>
              {selectedLicense ? (
                <img
                  onClick={() => setSelectedLicense()}
                  className="back-button"
                  src={
                    require('../../../assets/images/back-bend-arrow.png')
                      .default
                  }
                  alt=""
                />
              ) : null}
            </div>
            <div className="license-details">
              {selectedLicense
                ? `Has Obtained The Following Licenses For ${selectedLicense.product_name}`
                : `Has${
                    activeFilter.decsText ? '' : ' purchased'
                  } ${totalPurchaseLicenses} ${
                    activeFilter.decsText
                  } licenses across 5 products offered by ${
                    activeBrand?.bankerTag || 'All Brands'
                  }.`}
            </div>
            <div className="list-container">
              {selectedLicense ? (
                <PurchasedLicenses selectedLicense={selectedLicense} />
              ) : (
                <div className="list">
                  {productLicenses?.map((item) => (
                    <div
                      key={item.product_id}
                      className="product-group-item"
                      onClick={() => setSelectedLicense(item)}
                    >
                      <img
                        src={item.product_icon}
                        alt=""
                        className="product-icon"
                      />
                      <div className="product-name flex-fill">
                        {item.product_name}
                      </div>
                      <div className="product-name">{item.license_count}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div className="flex-fill d-flex flex-column justify-content-center">
          <LoadingAnimation dark />
        </div>
      )}
    </div>
  );
};

export default LicensesList;

const FILTERS = [
  { status: undefined, title: 'All', decsText: '' },
  { status: true, title: 'Active', decsText: 'active' },
  { status: false, title: 'Inactive', decsText: 'inactive' },
];
