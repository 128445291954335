import React, { useState } from "react";
import StakeComponent from "./StakeComponent";
import { Button } from "antd";
import OldStakeModal from "./OldStakeModal";

const MarketPlaceDetails = ({ data }) => {
  const [openStaking, setOpenStaking] = useState(false);
  const [openOldStaking, setOpenOldStaking] = useState(false);

  return (
    <>
      <div className="col-md-8 h-100 pb-4">
        <div className="container marketplace-container h-100">
          <div className="marketplace-details-header">
            <div className="row">
              <div className="col-md-3 details-img-container">
                <div className="details-img-wrapper">
                  <img src={data.image} alt="" />
                </div>
              </div>
              <div className="col-md-9">
                <div className="details-header-wrapper">
                  <div className="d-flex ant-row-flex-space-between">
                    <h3 className="title">{data.title}</h3>
                    <h3 className="title">{data.requirement}</h3>
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <h5 className="sub-title">{data.application}</h5>
                      <label className="intro">{data.intro}</label>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        onClick={() => {
                          setOpenOldStaking(false);
                          setOpenStaking(true);
                        }}
                      >
                        Redeem
                      </Button>
                      <Button
                        onClick={() => {
                          setOpenStaking(false);
                          setOpenOldStaking(true);
                        }}
                        style={{ marginTop: "10px" }}
                      >
                        Stake
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="marketplace-details-main">
            <div className="row">
              <div className="col-md-3">
                <div className="meta-wrapper">
                  <div className="meta-item">
                    <label className="meta-title">Type</label>
                    <label className="meta-value">{data.data.type}</label>
                  </div>
                  <div className="meta-item">
                    <label className="meta-title">Last Updated</label>
                    <label className="meta-value">
                      {data.data.lastUpdated}
                    </label>
                  </div>
                  <div className="meta-item">
                    <label className="meta-title">Version</label>
                    <label className="meta-value">{data.data.version}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="details-main">
                  {data.data.body.map((item, index) => (
                    <div key={index} className="details-content">
                      <h6>{item.title}</h6>
                      <p>{item.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.actionName && (
        <StakeComponent
          open={openStaking}
          closeModal={() => setOpenStaking(false)}
        />
      )}

      {openOldStaking && (
        <OldStakeModal
          open={openOldStaking}
          closeModal={() => setOpenOldStaking(false)}
        />
      )}
    </>
  );
};

export default MarketPlaceDetails;
