import React, { useState, useContext } from 'react';
import VideoGallery from './VideoGallery';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';

const Training = ({ categories, searchQuery }) => {
  const { isMobile } = useContext(BrokerAppContext);

  const [activeFilter, setActiveFilter] = useState(categories[0].title);

  const onCategoryChange = category => {
    document.getElementById('scroll-wrapper').scrollTop = 60;
    setActiveFilter(category);
  };

  return (
    <div id="scroll-wrapper" className="container-fluid training-container">
      <div className="row mx-0">
        {/* <Filter
          categories={categories}
          active={activeFilter}
          setActive={onCategoryChange}
          title="Free GXBroker Courses"
          isMobile={isMobile}
        /> */}
        <VideoGallery active={activeFilter} searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default Training;
