import React, { useContext } from 'react';
import Logo from "../../assets/images/logo-desk.svg";
import burger from "../../assets/mobileassets/menu-icon.png";
import { BrokerAppContext } from '../../contexts/BrokerAppContext';

export default function MobileNav() {
    const { setisSidebar, isSidebar } = useContext(BrokerAppContext)

    return (
        <div className='nav_container'>

            <div className='toggle'>
                <img
                    style={{
                        marginHorizontal: 20, width: 30, height: 30
                    }}
                    onClick={() => {
                        setisSidebar(true);
                    }}
                    src={burger} />
            </div>


            <div className="logo_container">
                <img src={Logo}


                    style={{
                        height: 36,
                        marginRight: "15%"
                    }} />
            </div>

        </div>
    )
}
