import React from 'react';
import ProductItem from '../../ProductItem';

const ReviewUpgrade = ({ purchaseData, onNext }) => {
  return (
    <div className="body-container">
      <div className="title">Review Upgrade</div>
      <div className="body">
        <div className="sub-header">
          You Will Be Charged The Difference Between The Issuance Fee You Paid
          In Your Previous License And This One Immediately. You Will Be Also
          Charged The Prorated Amount For The Difference In The Monthly
          Subscription Fee For The Remaining Days Of This Month
        </div>
        <ProductItem
          itemData={purchaseData}
          onPurchase={() => onNext()}
          purchaseBtnTitle="Upgrade"
          alwaysOpen
        />
      </div>
    </div>
  );
};

export default ReviewUpgrade;
