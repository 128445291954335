import React, { useEffect } from 'react';

export default function MenuController({
  selectedMenu,
  setSelectedMenu,
  selectedBrand,
  setScreenToShow,
}) {
  const MENU_ITEMS = [
    {
      title: 'Monetize',
      menu: [
        {
          pos: 0,
          title: selectedBrand?.mtToolName,
          subTitle: `Explore The ${selectedBrand?.mtToolName} You Use To Earn`,
          icon: require('../../assets/mobileassets/fees-icon.png').default,
          desc: `${selectedBrand?.mtToolName} refer to the amount of money which is deducted from each interest payment made to users on ${selectedBrand.title}`,
          onPress: () => setScreenToShow('BrandSettings'),
        },
      ],
    },
  ];
  useEffect(() => {
    setSelectedMenu(MENU_ITEMS[0]);
    setScreenToShow('BrandSettings');
  }, []);

  return (
    <div className="menuController_container">
      <div
        style={{
          display: 'flex',
          overflow: 'scroll',
        }}
      >
        {MENU_ITEMS.map((item, key) => {
          return (
            <div
              key={key}
              style={{
                borderBottomColor:
                  selectedMenu?.title === item.title ? '#308152D' : 'black',
                borderBottomWidth: selectedMenu?.title === item.title ? 1 : 1,
              }}
              className="menuController_menuItem"
              onClick={() => {
                setSelectedMenu(item);
                setScreenToShow('');
              }}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </div>
  );
}
