import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_AUTH_URL } from '../../../configs';
import Moment from 'moment-timezone';
import { toast } from 'react-toastify';
import ViewHeader from './ViewHeader';
import LoadingAnimation from '../../LoadingAnimation';

const SignUpStatus = ({ selectedOption, userEmail, onClose, onBack }) => {
  const [userData, setUserData] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [userCreationDate, setUserCreationDate] = useState();

  useEffect(() => {
    (async () => {
      axios
        .get(`${GX_AUTH_URL}/gx/user/status/data/get`, {
          params: { email: userEmail },
        })
        .then(({ data }) => {
          if (data.status) {
            setUserData(data?.gxData);

            if (data?.cognitoData?.status) {
              setUserCreationDate(
                Moment(data?.cognitoData?.user?.UserCreateDate).format(
                  'MMMM Do YYYY',
                ),
              );
            }

            let status = '';

            switch (data.userStatus) {
              case 'UNCONFIRMED':
                status = 'Email Remains Unverified';
                break;
              case 'FORCE_CHANGE_PASSWORD':
                status = 'Administrative Reset';
                break;
              case 'CONFIRMED':
                status = 'Valid User';
                break;
              case 'NotFound':
                status = 'Not Registered';
                break;
              default:
                status = 'Not Registered';
            }

            setStatusMessage(status);
          } else {
            toast.error(data.message);
            onClose && onClose();
          }
        })
        .catch(error => console.log('Error on locating user', error));
    })();
  }, [userEmail]);

  return (
    <div className="faq-form-container">
      <ViewHeader disableExpand onBack={onBack} />
      <div className="option-item">
        <img src={selectedOption?.icon} alt="" className="option-icon" />
        <div className="option-name">{selectedOption?.title}</div>
      </div>
      {userData ? (
        <div className="list-container">
          {userData?.notFound ? null : (
            <>
              <div className="list-title">The Selected User</div>
              <div className="user-container">
                <img
                  src={userData?.profile_img}
                  alt=""
                  className="user-image"
                />
                <div className="user-details">
                  <div className="user-name">{userData?.name}</div>
                  <div className="user-email">{userData?.email}</div>
                </div>
              </div>
            </>
          )}
          <div className="list-title">Status</div>
          <div className="user-container">
            <img alt="" className="user-image" />
            <div className="user-details">
              <div className="user-name">{statusMessage}</div>
              {userCreationDate ? (
                <div className="user-email">Since {userCreationDate}</div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <LoadingAnimation dark />
        </div>
      )}
    </div>
  );
};

export default SignUpStatus;
