import React, { useContext } from 'react';
import { CRMContext } from '../../contexts/CRMContext';
import TotalUser from './CRMTables/Users/TotalUser';
import DirectUsers from './CRMTables/Users/DirectUsers';
import IndirectUsers from './CRMTables/Users/IndirectUsers';
import TotalCustomers from './CRMTables/Customers/TotalCustomers';
import IndirectCustomers from './CRMTables/Customers/IndirectCustomers';
import DirectCustomers from './CRMTables/Customers/DirectCustomers';
import TotalBrokers from './CRMTables/Brokers/TotalBrokers';
import DirectBroker from './CRMTables/Brokers/DirectBroker';
import IndirectBrokers from './CRMTables/Brokers/IndirectBrokers';
import TotalTokenTransactionVolume from './CRMTables/TokenTransaction/TotalTokenTransactionVolume';
import DirectTokenTransactionalRevenue from './CRMTables/TokenTransaction/DirectTokenTransactionalRevenue';
import IndirectTokenTransactionalRevenue from './CRMTables/TokenTransaction/IndirectTokenTransactionalRevenue';
import BrokerDealerTransactionalRevenue from './CRMTables/TokenTransaction/BrokerDealerTransactionalRevenue';
import TotalOTCTransactionalVolume from './CRMTables/OTCTransactions/TotalOTCTransactionalVolume';
import TotalOTCTransactionalRevenue from './CRMTables/OTCTransactions/TotalOTCTransactionalRevenue';
import DirectOTCTransactionalRevenue from './CRMTables/OTCTransactions/DirectOTCTransactionalRevenue';
import IndirectOTCTransactionalRevenue from './CRMTables/OTCTransactions/IndirectOTCTransactionalRevenue';
import BrokerageMoneyMarketEarnings from './CRMTables/DigitalTransactions/BrokerageMoneyMarketEarnings';
import TotalDigitalTransactionalRevenue from './CRMTables/DigitalTransactions/TotalDigitalTransactionalRevenue';
import DirectDigitalTransactionalVolume from './CRMTables/DigitalTransactions/DirectDigitalTransactionalVolume';
import IndirectDigitalTransactionalVolume from './CRMTables/DigitalTransactions/IndirectDigitalTransactionalVolume';
import BrokerDealerRevenue from './CRMTables/DigitalTransactions/BrokerDealerRevenue';
import WithdrawalLedger from './CRMTables/Withdrawals/WithdrawalLedger';
import ComingSoon from './CRMTables/ComingSoon';
import LoadingAnimation from '../LoadingAnimation';

const CRMTable = () => {
  const {
    activeCrmTab,
    totalUsersList,
    directUsersList,
    indirectUsersList,
    totalCustomersList,
    directCustomersList,
    indirectCustomersList,
    totalBrokerList,
    directBrokersList,
    indirectBrokersList,
    getUsersDetails,
    totalTokenTxnVolume,
    directTokenTxnRevenue,
    indirectTokenTxnRevenue,
    brokerDealerTxnRevenue,
    getTokenTransactions,
    totalOtcVolume,
    totalOtcRevenue,
    directOtcRevenue,
    indirectOtcRevenue,
    getOtcTransactions,
    brokerageMarket,
    totalDigitalRevenue,
    directDigitalVolume,
    indirectDigitalVolume,
    brokerDealerRevenue,
    getDigitalTransaction,
    withdrawals,
    getWithdrawalData,
  } = useContext(CRMContext);

  const getTableContents = () => {
    let tableContent;

    switch (activeCrmTab) {
      case 'Total Users':
        if (!totalUsersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalUser />;
        }
        break;
      case 'Direct Users':
        if (!directUsersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <DirectUsers />;
        }
        break;
      case 'Indirect Users':
        if (!indirectUsersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectUsers />;
        }
        break;
      case 'Total Customers':
        if (!totalCustomersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalCustomers />;
        }
        break;
      case 'Direct Customers':
        if (!directCustomersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <DirectCustomers />;
        }
        break;
      case 'Indirect Customers':
        if (!indirectCustomersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectCustomers />;
        }
        break;
      case 'Total Brokers':
        if (!totalBrokerList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalBrokers />;
        }
        break;
      case 'Direct Brokers':
        if (!directBrokersList) {
          getUsersDetails(activeCrmTab);
        } else {
          tableContent = <DirectBroker />;
        }
        break;
      case 'Indirect Brokers':
        if (!indirectBrokersList) {
          getUsersDetails(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectBrokers />;
        }
        break;
      case 'Total Token Transactional Volume':
        if (!totalTokenTxnVolume) {
          getTokenTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalTokenTransactionVolume />;
        }
        break;
      case 'Direct Token Transactional Revenue':
        if (!directTokenTxnRevenue) {
          getTokenTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <DirectTokenTransactionalRevenue />;
        }
        break;
      case 'Indirect Token Transactional Revenue':
        if (!indirectTokenTxnRevenue) {
          getTokenTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectTokenTransactionalRevenue />;
        }
        break;
      case 'Broker Dealer Transactional Revenue':
        if (!brokerDealerTxnRevenue) {
          getTokenTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <BrokerDealerTransactionalRevenue />;
        }
        break;
      case 'Total OTC Transactional Volume':
        if (!totalOtcVolume) {
          getOtcTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalOTCTransactionalVolume />;
        }
        break;
      case 'Total OTC Transactional Revenue':
        if (!totalOtcRevenue) {
          getOtcTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalOTCTransactionalRevenue />;
        }
        break;
      case 'Direct OTC Transactional Revenue':
        if (!directOtcRevenue) {
          getOtcTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <DirectOTCTransactionalRevenue />;
        }
        break;
      case 'Indirect OTC Transactional Revenue':
        if (!indirectOtcRevenue) {
          getOtcTransactions(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectOTCTransactionalRevenue />;
        }
        break;
      case 'Brokerage Money Market Earnings':
        if (!brokerageMarket) {
          getDigitalTransaction(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <BrokerageMoneyMarketEarnings />;
        }
        break;
      case 'Total Digital Transactional Revenue':
        if (!totalDigitalRevenue) {
          getDigitalTransaction(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <TotalDigitalTransactionalRevenue />;
        }
        break;
      case 'Direct Digital Transactional Volume':
        if (!directDigitalVolume) {
          getDigitalTransaction(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <DirectDigitalTransactionalVolume />;
        }
        break;
      case 'Indirect Digital Transactional Volume':
        if (!indirectDigitalVolume) {
          getDigitalTransaction(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <IndirectDigitalTransactionalVolume />;
        }
        break;
      case 'Broker Dealer Revenue':
        if (!brokerDealerRevenue) {
          getDigitalTransaction(activeCrmTab);
          tableContent = <LoadingView />;
        } else {
          tableContent = <BrokerDealerRevenue />;
        }
        break;
      case 'Withdrawal Ledger':
        if (!withdrawals) {
          getWithdrawalData();
          tableContent = <LoadingView />;
        } else {
          tableContent = <WithdrawalLedger />;
        }
        break;
      case 'Total OTC Transactional Volume-InstaCryptoPurchase.com':
        tableContent = <ComingSoon />;
        break;
      case 'Total OTC Transactional Revenue-InstaCryptoPurchase.com':
        tableContent = <ComingSoon />;
        break;
      case '':
        break;
      case 'Direct OTC Transactional Revenue-InstaCryptoPurchase.com':
        tableContent = <ComingSoon />;
        break;
      case 'Indirect OTC Transactional Revenue-InstaCryptoPurchase.com':
        tableContent = <ComingSoon />;
        break;
      default:
        tableContent = '';
    }
    return tableContent;
  };

  return <div className="crm-table-wrapper">{getTableContents()}</div>;
};

const LoadingView = () => (
  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
    <LoadingAnimation dark />
  </div>
);

export default CRMTable;
