import React from 'react';
import { toast } from 'react-toastify';
import { usdValueFormatter, usdValueFormatterWithoutSign } from '../../utils';
import NumPad from './NumPad';

export default function InputForm({
    setWhereSend,
    withdrawAmount,
    setWithdrawAmount,
    setIsWithdrawOpen,
    withdrawableBalance,
}) {
    const withdrawAmountValidHanlde = () => {
        if (isNaN(parseFloat(withdrawAmount)) || !parseFloat(withdrawAmount)) {
            return toast.error('Please Input A Valid Value');
        }
        setWhereSend(true);
    };
    return (
        <div className="withdraw_section_content">
            <div className="withdraw_common">
                <div className="image_container">
                    <img
                        style={{
                            height: 52.45,
                            width: 253.45,
                        }}
                        src={
                            require('../../assets/mobileassets/affliate-bank-icon-full_blue.png')
                                .default
                        }
                    />
                </div>
                <div className="input_container">
                    <div className="input_text">
                        {withdrawAmount
                            ? isNaN(parseFloat(withdrawAmount))
                                ? '$' + withdrawAmount
                                : '$' +
                                usdValueFormatterWithoutSign.format(
                                    parseFloat(withdrawAmount),
                                )
                            : '$' + usdValueFormatterWithoutSign.format('0')}
                    </div>
                    <div className="input_text">USD</div>
                </div>
                <div className="divider" />
                <div className="balance_container">
                    <div className="balance_label">Balance</div>
                    <div className="balance_label">
                        {withdrawableBalance === ''
                            ? 'Calculating'
                            : usdValueFormatter.format(withdrawableBalance)}
                    </div>
                </div>
            </div>
            <NumPad
                setIsWithdrawOpen={setIsWithdrawOpen}
                currentText={withdrawAmount}
                updatedCallback={setWithdrawAmount}
            />
            <div
                className="confirm_withdraw_balance_button"
                onClick={() => {
                    withdrawAmountValidHanlde();
                }}
            >
                Confirm Withdraw
            </div>
        </div>
    );
}
