import { createStyles } from '@material-ui/core';
import axios from 'axios';
import { active } from 'd3';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import FilterMenu from './FilterMenu';
import InterestEarningItem from './InterestEarningItem';
import SearchList from './SearchList';
import TransactionItem from './TransactionItem';

export default function TransactionList({
  isSearchFocused,
  setIsSearchFocused,
  isFullScreen,
  setIsFullScreen,
}) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    title: 'All',
    key: 'All',
  });
  const [isTxnTypeFilterOpen, setIsTxnTypeFilterOpen] = useState(false);
  const [txnType, setTxnType] = useState('');
  const [otcTxns, setOtcTxns] = useState();
  const [tokenTxns, setTokenTxns] = useState();
  const [digitalTxns, setDigitalTxns] = useState();
  const [licenseTxns, setLicenseTxns] = useState();
  const [activeList, setActiveList] = useState();
  const [interestList, setInterestList] = useState();
  const [cryptoTableData, setCryptoTableData] = useState(null);

  useEffect(() => {
    getCryptoData();
  }, []);
  useEffect(() => {
    (async () => {
      const email = await LocalStorageHelper.getAppEmail();

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/otc/txn/stats/get`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          if (data.status) {
            setOtcTxns(data.logs || []);
          } else {
            setOtcTxns([]);
          }
        })
        .catch((error) => {
          console.log('Error getting Transaction', error);
        });

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/stats/token_txns`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setTokenTxns(data.all || []);
        })
        .catch((error) => {
          console.log('Error getting Transaction', error);
        });

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/stats/digital_txns`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setDigitalTxns(data.all || []);
        })
        .catch((error) => {
          console.log('Error getting Transaction', error);
        });

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/interest/comm/txns/get`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setInterestList(data?.logsData?.logs || []);
        })
        .catch((error) => {
          console.log('Error getting Transaction', error);
        });

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/comp4/txn/stats/get`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setLicenseTxns(data.logs || []);
        })
        .catch((error) => {
          console.log('Error getting Transaction', error);
        });
    })();
  }, []);

  useEffect(() => {
    switch (txnType.key) {
      case 'TR':
        setActiveList(otcTxns);
        break;
      case 'TS':
        setActiveList(tokenTxns);
        break;
      case 'LS':
        setActiveList(licenseTxns);
        break;
      case 'MM':
        setActiveList(digitalTxns);
        break;
      case 'IN':
        setActiveList(interestList);
        break;
      default:
        setActiveList(otcTxns);
        break;
    }
  }, [txnType, otcTxns, tokenTxns, digitalTxns, licenseTxns, interestList]);

  const getCryptoData = () => {
    setCryptoTableData(null);

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/get/all/coins`)
      .then((resp) => {
        const { data } = resp;

        if (data.status) {
          const coinData = data.coins || [];
          const finalList = [];
          const coinSymbolArray = [];

          coinData.forEach((item) => {
            if (!coinSymbolArray.includes(item.coinSymbol)) {
              coinSymbolArray.push(item.coinSymbol);
              finalList.push({
                ...item,
                name: item.coinName,
                image: item.coinImage,
                price: {
                  USD: item.usd_price,
                },
                asset_type: item.type === 'fiat' ? 'Fiat' : 'Crypto',
              });
            }
          });

          finalList.sort((a, b) =>
            a.asset_type > b.asset_type
              ? 1
              : b.asset_type > a.asset_type
              ? -1
              : 0,
          );

          setCryptoTableData(finalList);
        } else {
          setCryptoTableData([]);
        }
      })
      .catch((error) => console.log('Error getting Crypto table data', error));
  };

  if (isSearchFocused || isFullScreen) {
    return (
      <SearchList
        transactionList={activeList}
        setIsSearchFocused={setIsSearchFocused}
        selectedFilter={selectedFilter}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        txnType={txnType}
        cryptoTableData={cryptoTableData}
      />
    );
  }

  return (
    <>
      <div className="latest_transaction">
        <div className="latest_transaction_header">
          <div className="latest_transaction_text">Latest Transactions</div>
          <div
            style={{
              opacity: 0.3,
            }}
            onClick={() => setIsFullScreen(true)}
            className="latest_transaction_text"
          >
            FullScreen
          </div>
        </div>
      </div>

      {activeList ? (
        <div className="latest_transaction_list">
          <div className="searchContainer">
            <div
              onClick={() => setIsSearchFocused(true)}
              className="searchInput"
            >
              Search Transactions...
            </div>
            <div
              className="filter_item"
              onClick={() => {
                setIsTxnTypeFilterOpen(true);
                setIsMenuOpened(!isMenuOpened);
              }}
            >
              {txnType?.key || 'TR'}
            </div>
            <div
              onClick={() => {
                setIsTxnTypeFilterOpen(false);
                setIsMenuOpened(!isMenuOpened);
              }}
              className="filter_item"
            >
              {selectedFilter.key || selectedFilter.tittle || 'All'}
            </div>
          </div>
          <div
            className={
              activeList.length > 0
                ? 'latest_transaction_content'
                : 'center_text'
            }
            style={{
              paddingLeft: isMenuOpened ? 0 : 10,
              paddingRight: isMenuOpened ? 0 : 10,
            }}
          >
            {isMenuOpened ? (
              <FilterMenu
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                isTxnType={isTxnTypeFilterOpen}
                onClose={() => setIsMenuOpened(false)}
                setTxnType={setTxnType}
              />
            ) : activeList.length > 0 ? (
              activeList.map((item, index) => {
                return (
                  <>
                    {txnType.key === 'IN' ? (
                      <InterestEarningItem
                        item={item}
                        index={index}
                        selectedFilter={selectedFilter.key}
                      />
                    ) : (
                      <TransactionItem
                        txnType={txnType}
                        selectedFilter={selectedFilter.key}
                        item={item}
                        index={index}
                        cryptoTableData={cryptoTableData}
                      />
                    )}
                  </>
                );
              })
            ) : (
              <div className="noT">No Transactions Found</div>
            )}
          </div>
        </div>
      ) : (
        <SkeltonAnimation />
      )}
    </>
  );
}

const SkeltonAnimation = () => (
  <div className="latest_transaction_list">
    <div className="searchContainer">
      <div className="searchInput">
        <Skeleton height={15} width={150} />
      </div>
      <div className="filter_item">
        <Skeleton height={15} width={30} />
      </div>
      <div className="filter_item">
        <Skeleton height={15} width={30} />
      </div>
    </div>
    <div
      style={{
        width: '100%',
        height: '100%',
        paddingRight: 10,
        paddingLeft: 10,
        overflow: 'scroll',
      }}
    >
      {Array(5)
        .fill('')
        .map((item, key) => {
          return (
            <div className="itemContainer" key={key}>
              <div>
                <Skeleton width={35} height={35} />
              </div>
              <div className="itemDetailsContainer">
                <div
                  style={{
                    marginLeft: 10,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Skeleton height={22} width={60} />
                  <Skeleton height={12} width={70} />
                </div>
              </div>
              <Skeleton height={12} width={90} />
            </div>
          );
        })}
    </div>
  </div>
);
