import React, { useContext } from 'react';
import { OneToOneChatContext } from '../../../contexts/OneToOneChatContext';
import MessageList from './MessageList';

const ChatView = () => {
  const { selectedUser, setSelectedUser } = useContext(OneToOneChatContext);

  return (
    <div className="flex-fill d-flex flex-column chat-layout-container">
      <div className="chat-header">
        <img
          src={
            require('../../../assets/images/back-arrow-icon-white.png').default
          }
          alt=""
          className="chat-back-button"
          onClick={() => setSelectedUser()}
        />
        <img src={selectedUser?.avatar} alt="" className="chat-user-avatar" />
        <div className="chat-user-container">
          <div className="chat-user-name">{selectedUser?.first_name}</div>
          <div className="chat-user-bio">{selectedUser?.bio}</div>
        </div>
      </div>
      <MessageList />
    </div>
  );
};

export default ChatView;
