import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { EMPOWERED_APP_CODE } from '../configs';
import LoginPage from '../pages/EmpoweredApp/LoginPage';
import PreRegisterPage from '../pages/EmpoweredApp/PreRegisterPage';
import RegistrationRouter from './RegistrationRouter';

const EmpoweredRouter = () => {
  const APP_DATA = {
    headerLogo: require('../assets/images/empowered-icon.svg').default,
    appCode: EMPOWERED_APP_CODE,
  };

  return (
    <BrowserRouter basename="apps/empowered">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/pre-registration" component={PreRegisterPage} />
        <RegistrationRouter basename="apps/broker" appData={APP_DATA} />
      </Switch>
    </BrowserRouter>
  );
};

export default EmpoweredRouter;
