import React from 'react';

export default function MenuItem({
  onPress,
  icon,
  title,
  subTitle,
  style,
  disabled,
}) {
  return (
    <div
      className="menuItem_containor"
      onClick={onPress}
      style={{
        opacity: disabled ? 0.5 : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: 32,
            height: 32,
            marginRight: 15,
          }}
          src={icon}
          alt=""
        />
        <div>
          <div className="menuItem_container_itemName">{title}</div>
          <div className="menuItem_container_itemSubText">{subTitle}</div>
        </div>
      </div>
      <img
        style={{
          width: 12,
          height: 20,
          marginLeft: 8,
        }}
        src={require('../../assets/mobileassets/arrow-carrot.png').default}
        alt=""
      />
    </div>
  );
}
