import React from 'react';
import coin from '../../../assets/images/coin.svg';
import {
  usdValueFormatter,
  getDateFromTimeStamp,
  getTimeFromTimeStamp
} from '../../../utils';

const WithdrawalLedgerItem = ({ data }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '22%', paddingLeft: '15px' }}
      >
        <label className="token-txn-name">{data.pid}</label>
      </div>

      <div className="flexed-item" style={{ width: '14%' }}>
        <label className="token-txn-date">
          {getDateFromTimeStamp(data.ts)}
        </label>
        <label className="token-txn-time">
          {getTimeFromTimeStamp(data.ts)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '14%' }}>
        <label className="token-txn-date">
          {usdValueFormatter.format(
            data.usd_amt
              ? data.usd_amt
              : data.withdrawl_amt
              ? data.withdrawl_amt
              : 0
          )}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-broker">{data.quote}</label>
      </div>
      <div className="flexed-item" style={{ width: '16%' }}>
        <label className="token-txn-commission">
          {usdValueFormatter.format(data.bal)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '16%' }}>
        <label className="token-txn-commission">
          {data.rejected && data.rejected === true ? 'Rejected' : data.status}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default WithdrawalLedgerItem;
