import React, { useEffect, useContext, useState } from 'react';
import SearchBar from './SearchBar';
import TabItem from './TabItem';
import SavedDashBoards from './SavedDashBoards';
import SaveDashboard from './SaveDashboard';
import { CRMContext } from '../../contexts/CRMContext';

const CRMNavTabBar = () => {
  const { crmTabs, activeCrmTab, setActiveCrmTab, removeCrmTabs } = useContext(
    CRMContext
  );

  const [resetPosition, setResetPosition] = useState(false);
  const [time, setTime] = useState(Date.now());

  const closeTab = item => {
    if (item.title === activeCrmTab) {
      if (crmTabs[crmTabs.length - 2]) {
        const tabToRemove = crmTabs[crmTabs.length - 2].title;
        setActiveCrmTab(tabToRemove);
      } else {
        setActiveCrmTab(null);
      }
    }
    removeCrmTabs(item);
  };

  useEffect(() => {
    setTime(Date.now());
  }, [resetPosition, crmTabs]);

  return (
    <div className="main-nav-wrapper nav-tab-bar-wrapper">
      <div className="tab-container">
        {crmTabs.length > 0 ? (
          crmTabs.map(item => (
            <TabItem
              key={`${item.title}${time}`}
              tabData={item}
              active={activeCrmTab === item.title}
              setActive={() => setActiveCrmTab(item.title)}
              closeTab={() => closeTab(item)}
              setResetPosition={() => setResetPosition(!resetPosition)}
            />
          ))
        ) : (
          <h6 className="tab-empty-title ml-4">
            Add A Dashboard Tab By Searching The Data That You Are Looking For{' '}
            <span>
              <i className="fas fa-angle-double-right" />
            </span>
          </h6>
        )}
      </div>
      <SaveDashboard />
      <SavedDashBoards />
      <SearchBar />
    </div>
  );
};

export default CRMNavTabBar;
