import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import FullLoadingComponent from '../components/FullLoadingComponent';
import { GX_API_ENDPOINT } from '../configs';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const LandingLayout = ({ children, className, isLoading }) => {
  const [isReady, setIsReady] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const token = LocalStorageHelper.getAppToken();
    if (token) {
      Axios.post(`${GX_API_ENDPOINT}/brokerage/verify_token`, {
        token,
        userpoolid: 'us-east-2_ALARK1RAa',
      })
        .then(resp => {
          let user;
          if (resp.data) user = resp.data;
          else user = null;

          if (user !== null && Date.now() < user.exp * 1000) {
            // console.log('Session valid!!');
            setRedirect(true);
          }
        })
        .catch(error => {
          // console.log('Token verify Error', error);
        });
    }

    const timeout = setTimeout(() => {
      setIsReady(false);
    }, [2000]);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {redirect && <Redirect to="/" />}
      <div className={`login-page ${className || ''}`}>
        {(isReady || isLoading) && <FullLoadingComponent />}
        {children}
      </div>
    </>
  );
};

export default LandingLayout;
