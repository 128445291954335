import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';
import { usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import BrokerEarningCarousel from './BrokerEarningCarousel';
import BrokerTransactionList from './BrokerTransactionList';
import BrokerUsers from './BrokerUsers';

export default function BrokerEarningSection({ params, setBrokerEarning }) {
  const [searchText, setSearchText] = useState('');
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [isBrokers, setIsBrokers] = useState(true);
  const [totalTransaction, setTotalTransaction] = useState();
  const [transactionalVolume, setTransactionalVolume] = useState();
  const [transactionalFees, setTransactionalFees] = useState();
  const [transactionalRevenue, setTransactionalRevenue] = useState();
  const [close, setClose] = useState(false);
  useEffect(() => {
    (async () => {
      const email = await LocalStorageHelper.getAppEmail();

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/otc/txn/stats/get`, {
          params: {
            email,
            type: params ? params.toLowerCase() : 'direct',
          },
        })
        .then((resp) => {
          const { data } = resp;

          // console.log(params, 'Resp', data);

          if (data.status) {
            // setTotalTransaction(data.logs);
            setTransactionalVolume(data.total_txn_volume);
            setTransactionalFees(data.total_txn_fees);
            setTransactionalRevenue(data.total_txn_revenue);
          }
        })
        .catch((error) => {
          console.log('Error on getting transactions');
        });
    })();
  }, [params]);
  const onSelectUser = (item) => {
    setSearchText('');
    setSelectedUser(item);
  };
  const carouselData = [
    {
      title: `${params || 'Direct'} Transactional Volume`,
      value: usdValueFormatter.format(transactionalVolume || 0),
    },
    {
      title: `${params || 'Direct'} Transactional Fees`,
      value: usdValueFormatter.format(transactionalFees || 0),
    },
    {
      title: `${params || 'Direct'} Transactional Revenue`,
      value: usdValueFormatter.format(transactionalRevenue || 0),
    },
  ];
  let isSearchOpen = isKeyboardOpen || searchText || close;
  return (
    <div className="brokerearning_container">
      <div>
        <div className="back_button" style={{ padding: 0, paddingLeft: 10 }}>
          <div
            onClick={() => {
              setBrokerEarning(false);
            }}
          >
            Back
          </div>
        </div>
        <div className="animate" style={{ display: close ? 'none' : '' }}>
          <div className="broker_header">
            {params || 'Direct'} {params === 'Override' ? '' : 'Network '}
            Earnings
          </div>
          <div className="broker_subhead">Powered By AffiliateApp</div>
          <BrokerEarningCarousel carouselData={carouselData} close={close} />
        </div>
      </div>
      <div
        className="searchBoxShadowContainer"
        style={{
          marginLeft: '10%',
          marginRight: '10%',
          justifyContent: 'space-around',
        }}
      >
        {params === 'Indirect' && (
          <div
            onClick={() => setIsBrokers(!isBrokers)}
            className="switchertext"
          >
            {isBrokers ? 'Broker' : 'Customers'}
          </div>
        )}
        <input
          style={{
            width: '78%',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            color: 'black',
            fontSize: 13,
          }}
          onFocus={() => {
            setClose(true);
          }}
          onBlur={() => {
            setClose(false);
          }}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          placeholder={`Search Name Of ${params || ''} ${
            params ? (isBrokers ? 'Brokers' : 'Customers') : 'Customers'
          }`}
        />
        <img
          style={{
            height: 15,
          }}
          src={require('../../assets/mobileassets/search-icon.png').default}
          alt=""
        />
      </div>

      <BrokerUsers
        horizontal={!isSearchOpen}
        searchText={searchText}
        onItemSelected={onSelectUser}
        selectedItem={selectedUser}
        type={params || 'Direct'}
        isBrokers={isBrokers}
      />
      <BrokerTransactionList
        hidden={isSearchOpen}
        selectedUser={selectedUser}
        type={params || 'Direct'}
      />
    </div>
  );
}
