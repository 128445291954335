import React, { useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useMoneyMarketList } from '../../utils/CustomHooks';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import MoneyMarketActionModal from './MoneyMarketActionModal';
import MoneyMarketCoinList from './MoneyMarketCoinList';
import MoneyMarketTypeFooter from './MoneyMarketTypeFooter';
import SearchHeader from './SearchHeader';

export default function MoneyMarketFeeScreen({
  complanFeePairScreen,
  setComplanFeePairScreen,
}) {
  const userEmail = LocalStorageHelper.getAppEmail();
  const goBack = () => {
    setComplanFeePairScreen([]);
  };
  const type = complanFeePairScreen[1]?.type;
  const bonds = complanFeePairScreen[1]?.bonds;

  const [typeSelected, setTypeSelected] = useState(type || 'crypto');
  const {
    data: feeList = [],
    isLoading,
    refetch,
  } = useMoneyMarketList(userEmail, typeSelected);
  const [search, setSearch] = useState('');
  const selectedBrand = complanFeePairScreen[1]?.selectedBrand;
  const [hidenav, setHideNav] = useState(false);
  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {hidenav ? (
        ''
      ) : (
        <SearchHeader search={search} setSearch={setSearch} onBack={goBack} />
      )}

      <div
        style={{
          height: hidenav ? '90vh' : '70.6vh',
          overflow: 'scroll',
        }}
      >
        {isLoading && <LoadingAnimation dark />}
        <MoneyMarketCoinList
          feeList={feeList}
          search={search}
          refetch={refetch}
          setHideNav={setHideNav}
          bonds={bonds}
          selectedBrand={selectedBrand}
        />
      </div>

      {hidenav ? (
        ''
      ) : (
        <div
          style={{
            height: '10vh',
            display: 'flex',
            overflow: 'scroll',

            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MoneyMarketTypeFooter
            type={typeSelected}
            setType={setTypeSelected}
          />
        </div>
      )}
    </div>
  );
}
