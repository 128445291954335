import React, { useContext } from 'react';
import { CRMContext } from '../../../contexts/CRMContext';
import FullScreenToggler from '../FullScreenToggler';
import TableSearchBar from '../TableSearchBar';

const IndirectTokenTransactionalHeader = () => {
  const { isTableSearchOpen, isTableExpanded, expandTable } = useContext(
    CRMContext
  );
  return (
    <div className="details-table-item details-table-header scroll-shadow">
      {!isTableSearchOpen ? (
        <>
          <div className="flexed-item" style={{ width: '3%' }} />
          <div
            className="flexed-item"
            style={{ width: '26%', paddingLeft: '15px' }}
          >
            <label>Name</label>
          </div>

          <div className="flexed-item" style={{ width: '9%' }}>
            <label>Country</label>
          </div>
          <div className="flexed-item" style={{ width: '19%' }}>
            <label>Date (EST)</label>
          </div>
          <div className="flexed-item" style={{ width: '24%' }}>
            <label>Upline / DD</label>
          </div>
          <div className="flexed-item" style={{ width: '13%' }}>
            <label>Commission</label>
          </div>
        </>
      ) : (
        <div className="flexed-item" style={{ width: '65%' }} />
      )}
      <FullScreenToggler
        isExpanded={isTableExpanded}
        expandTable={expandTable}
      />
      <TableSearchBar />
    </div>
  );
};

export default IndirectTokenTransactionalHeader;
