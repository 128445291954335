import React from 'react';
import LoginFrom from '../../components/LoginFrom';
import LandingLayout from '../../layouts/LandingLayout';

const LoginPage = () => {
  return (
    <LandingLayout>
      <LoginFrom />
    </LandingLayout>
  );
};

export default LoginPage;
