
import React, { useContext, useState, useRef, useLayoutEffect } from 'react';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import { usdValueFormatter } from '../../utils';
import BackgroundChart from './BackgroundChart';

const MyProfileCover = ({ children }) => {
  const { walletActiveCoin } = useContext(BrokerAppContext);

  const [navHeight, setNavHeight] = useState(0);
  const [coinPrice, setCoinPrice] = useState(0);

  const navContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (navContainerRef.current) {
      const height = navContainerRef.current.clientHeight;
      setNavHeight(height);
    }
  }, [
    navContainerRef.current,
    navContainerRef.current ? navContainerRef.current.clientHeight : 0,
  ]);

  return (
    <div className="broker-app-cover-wrapper">
      <div className="d-flex flex-column flex-fill position-relative">
        <BackgroundChart navHeight={navHeight} setCoinPrice={setCoinPrice} />
        <div className="cover-header">
          <div className="header-wrapper">
            <div className="brokerage-assets show">
              <h6
                className="assets-title"
                style={{
                  color: 'black',
                }}
              >
                {walletActiveCoin?.coinName || 'Bitcoin'} Price
              </h6>
              <h4
                className="assets-value"
                style={{
                  color: 'black',
                }}
              >
                {usdValueFormatter.format(coinPrice)}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="" ref={navContainerRef}>
        {children}
      </div>
    </div>
  );
};

export default MyProfileCover;
