import React, { useContext } from 'react';
import { CRMContext } from '../../../contexts/CRMContext';
import FullScreenToggler from '../FullScreenToggler';
import TableSearchBar from '../TableSearchBar';

const TotalUsersHeaders = () => {
  const { isTableSearchOpen, isTableExpanded, expandTable } = useContext(
    CRMContext
  );

  return (
    <div className="details-table-item details-table-header scroll-shadow">
      {!isTableSearchOpen ? (
        <>
          <div className="flexed-item" style={{ width: '3%' }} />
          <div
            className="flexed-item"
            style={{ width: '30%', paddingLeft: '15px' }}
          >
            <label>Name</label>
          </div>
          <div className="flexed-item" style={{ width: '10%' }}>
            <label>Country</label>
          </div>
          <div className="flexed-item" style={{ width: '28%' }}>
            <label>Date of Signup (EST)</label>
          </div>
          <div className="flexed-item" style={{ width: '25%' }}>
            <label>Upline</label>
          </div>
        </>
      ) : (
        <div className="flexed-item" style={{ width: '65%' }} />
      )}
      <FullScreenToggler
        isExpanded={isTableExpanded}
        expandTable={expandTable}
      />
      <TableSearchBar />
    </div>
  );
};

export default TotalUsersHeaders;
