import React, { useState, useEffect, useContext } from 'react';
import { formatterWithTwoDigits, usdValueFormatter } from '../../utils';
import Axios from 'axios';
import { CRMContext } from '../../contexts/CRMContext';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const Main = () => {
  const { totalUsersList, getUsersDetails } = useContext(CRMContext);
  const {
    stakedGxt,
    sefEarned,
    withdrawals,
    getWithdrawals,
    brokerBalance,
    getBrokerBalances,
  } = useContext(BrokerAppContext);

  const [brokerageVolume, setBrokerageVolume] = useState();
  const [realizedEarning, setRealizedEarning] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [totalCryptoAssetBrokerage, setTotalCryptoAssetBrokerage] = useState();
  const [brokerageTransactions, setBrokerageTransactions] = useState();
  const [moneyMarketTransactions, setMoneyMarketTransactions] = useState();
  const [totalOTCVolume, setTotalOTCVolume] = useState();

  useEffect(() => {
    if (!totalUsersList) {
      getUsersDetails();
    }
    getBrokerVolume();

    if (!withdrawals) {
      getWithdrawals();
    }

    if (!brokerBalance) {
      getBrokerBalances();
    }
  }, []);

  useEffect(() => {
    if (withdrawals) {
      let earning = 0;
      withdrawals.forEach(item => {
        if (item.status === 'Approved')
          earning += parseFloat(item.withdrawl_amt);
      });
      setRealizedEarning(earning);
    }
  }, [withdrawals]);

  const getBrokerVolume = () => {
    const email = LocalStorageHelper.getAppEmail();
    if (email) {
      Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/token_txns`, {
        params: { email },
      })
        .then(tokenResp => {
          Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/otc_txns`, {
            params: { email },
          }).then(otcResp => {
            Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/digital_txns`, {
              params: { email },
            }).then(digiResp => {
              setBrokerageVolume(
                tokenResp?.data?.revenue?.ttr +
                  otcResp?.data?.revenue?.ttr +
                  digiResp?.data?.revenue?.ttr,
              );
              setTotalCryptoAssetBrokerage(digiResp?.data?.last_total_assets);
              setBrokerageTransactions(
                tokenResp?.data?.length?.all +
                  otcResp?.data?.length?.all +
                  digiResp?.data?.length?.all,
              );
              setMoneyMarketTransactions(digiResp?.data?.length?.latest_txns);
              setTotalOTCVolume(otcResp?.data?.volume?.ttv);
            });
          });
        })
        .catch(e => {
          setBrokerageVolume(0);
          setTotalCryptoAssetBrokerage(0);
          setMoneyMarketTransactions(0);
          setTotalOTCVolume(0);
        });
    }
  };

  const revenues = [
    {
      title: 'Total Users',
      value: totalUsersList ? totalUsersList.count : 0,
      unit: 'Users',
    },
    {
      title: 'Staked GXT',
      value: formatterWithTwoDigits.format(stakedGxt || 0),
      unit: 'GXT',
    },
    {
      title: 'SEF Coins',
      value: formatterWithTwoDigits.format(sefEarned || 0),
      unit: 'SEF',
    },
    {
      title: 'Brokerage Volume Revenue',
      value: brokerageVolume
        ? formatterWithTwoDigits.format(brokerageVolume)
        : 'Retrieving',
      unit: '$',
    },
    {
      title: 'Realized Earnings',
      value: usdValueFormatter.format(realizedEarning || 0),
      unit: '$',
    },
    {
      title: 'GXBroker Account Balance',
      value: usdValueFormatter.format(brokerBalance || 0),
      unit: '$',
    },
    {
      title: 'Withdrawal Balance',
      value: usdValueFormatter.format(parseFloat(totalBalance || 0)),
      unit: '$',
    },
    {
      title: 'Crypto Assets In Brokerage (USD)',
      value: totalCryptoAssetBrokerage
        ? usdValueFormatter.format(parseFloat(totalCryptoAssetBrokerage))
        : 'Retrieving',
      unit: '$',
    },
    {
      title: 'Brokerage Transactions (All Time)',
      value: brokerageTransactions || 0,
      unit: '',
    },
    {
      title: 'Money Market Transactions (24 Hrs)',
      value: moneyMarketTransactions || 0,
      unit: '',
    },
    {
      title: 'Total OTC Volume (All Time)',
      value: totalOTCVolume
        ? usdValueFormatter.format(totalOTCVolume)
        : 'Retrieving',
      unit: '$',
    },
  ];

  return (
    <div className="main-controller-fragment">
      <div className="data-cards-list row">
        {revenues.map((item, index) => (
          <div key={index} className="col-md-6 mb-5">
            <div className="data-card-item">
              <div className="title">{item.title}</div>
              <div className="value">{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
