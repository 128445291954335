import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ViewHeader from './ViewHeader';

const UserForm = ({
  selectedOption,
  setUserQuery,
  onSubmit,
  onBack,
  disableFilter,
  showUserList,
}) => {
  const [activeFilter, setActiveFilter] = useState(INPUT_OPTIONS[0]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (disableFilter) {
      setActiveFilter(INPUT_OPTIONS[0]);
    }
  }, [disableFilter]);

  const onSubmitHandler = (submitValue = null) => {
    const input = submitValue || searchInput;

    if (!input) {
      return toast.error('Please Input A Value');
    }

    const value = {};

    value[`${activeFilter.paramKey}`] = input?.toLowerCase().trim();

    setUserQuery(value);
    onSubmit();
  };

  return (
    <div className="faq-form-container">
      <ViewHeader disableExpand onBack={onBack} />
      <div className="option-item">
        <img src={selectedOption?.icon} alt="" className="option-icon" />
        <div className="option-name">{selectedOption?.title}</div>
      </div>

      <div className="input-filter">
        {INPUT_OPTIONS.map(item => (
          <div
            key={item.title}
            className={`filter-item ${
              activeFilter?.title === item.title ? 'active' : ''
            }`}
            onClick={() => setActiveFilter(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Ex: Shorupan"
        />
      </div>
      <div onClick={() => onSubmitHandler()} className="submit-button">
        Submit
      </div>
    </div>
  );
};

export default UserForm;

const INPUT_OPTIONS = [
  { title: 'Email', paramKey: 'email' },
  { title: 'Name', paramKey: 'name' },
  { title: 'Username', paramKey: 'username' },
];
