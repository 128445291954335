import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

export default function CompleteWithdraw({
  setIsWithdrawOpen,
  selecteduserapp,
  withdrawAmount,
  setIsLoading,
  setRefreshAffiliateBalance,
  isWithdrawOpen,
}) {
  const [coinvalue, setCoinValue] = useState('');
  const [gxbalance, setGxBalance] = useState('');

  useEffect(() => {
    if (!isWithdrawOpen) {
      setCoinValue('');
      setGxBalance('');
    }
  }, [isWithdrawOpen]);

  const handleBrokerBalance = async () => {
    try {
      const email = await LocalStorageHelper.getAppEmail();

      const res = await axios.get(
        `https://comms.globalxchange.io/coin/vault/gxb/balance?email=${email}`,
      );

      setGxBalance(res?.data?.gxbroker_balance);
    } catch (error) {
      setIsLoading(true);
      setIsWithdrawOpen(false);
      toast.error('Error on GettingGXbalance');

      console.log('Error on GettingGXbalance', error);
    }
  };
  const handleCoinValue = async () => {
    try {
      const email = await LocalStorageHelper.getAppEmail();

      const postData = {
        app_code: selecteduserapp?.app_code,
        email,
        include_coins: ['USD'],
      };
      const res = await axios.post(
        'https://comms.globalxchange.io/coin/vault/service/coins/get',
        postData,
      );
      console.log(res?.data?.coins_data[0]?.coinValue);
      setCoinValue(res?.data?.coins_data[0]?.coinValue);
    } catch (error) {
      setIsLoading(true);
      setIsWithdrawOpen(false);
      toast.error('Error on GettingCoinValue');

      console.log('Error on GettingCoinValue', error);
    }
  };

  useEffect(() => {
    handleBrokerBalance();
    setTimeout(() => {
      handleCoinValue();
    }, 1000);
  }, []);
  useEffect(() => {
    if (coinvalue !== '' && gxbalance !== '') {
      setIsLoading(false);
      setRefreshAffiliateBalance(true);
    }
  }, [coinvalue, gxbalance]);

  return (
    <div className="withdraw_section_content">
      <div className="withdraw_common">
        <div className="image_container">
          <img
            style={{
              height: 52.45,
              width: 253.45,
            }}
            src={
              require('../../assets/mobileassets/affiliate_bank_icon.svg')
                .default
            }
          />
        </div>
        <div className="complete_withdraw_title">Congratulations</div>
        <div className="complete_withdraw_subtitle">
          Your Have Successfully Withdrew
          {usdValueFormatter.format(withdrawAmount)} USD From Your AffiliateBank
          Balance To {selecteduserapp?.app_name}.
        </div>

        <div className="complete_withdraw_sub_head">
          Update AffiliateBank Balance
        </div>
        <div className="complete_withdraw_item_box">
          <div className="complete_withdraw_price">{gxbalance}</div>
          <div className="complete_withdraw_price">USD</div>
        </div>
        <div className="complete_withdraw_sub_head">
          Updated {selecteduserapp?.app_name}{' '}
        </div>
        <div className="complete_withdraw_item_box">
          <div className="complete_withdraw_price"> {coinvalue}</div>
          <div className="complete_withdraw_price">USD</div>
        </div>

        <div
          className="complete_withdraw_close_button"
          onClick={() => {
            setIsWithdrawOpen(false);
          }}
        >
          close
        </div>
      </div>
    </div>
  );
}
