import React from 'react';

const LegacyUpgradeReview = ({ onNext }) => {
  return (
    <div className="body-container">
      <div className="title">Lets See If You Qualify</div>
      <div className="body">
        <div className="sub-header font-weight-bold">Qualification 1</div>
        <div className="desc">
          The Email Which You Are Logged In Right Now Has To Be The Same Email
          Which Staked A Minimum Of 500 GXTokens Between April 1st 2019 -
          December 31st 202 or Stated A Minimum Of 1000 GXTokens At Any Point
          Between January 1st 2020 To September 18th 2020.
        </div>
        <div className="sub-header font-weight-bold">Qualification 2</div>
        <div className="desc">
          The GXTokens That You Used In That Original Staking Contract Were
          Never Unstaked And Never Used For Another Grandfathering Procedure
        </div>
      </div>
      <div onClick={onNext || null} className="action-button">
        Check If I Qualify
      </div>
    </div>
  );
};

export default LegacyUpgradeReview;
