import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ProductDetailsView from './ProductDetailsView';

const LandingProductList = ({
  allProducts,
  activeCategory,
  activeBrand,
  list,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    setSelectedProduct();
  }, [list]);

  if (selectedProduct) {
    return (
      <ProductDetailsView
        selectedProduct={selectedProduct}
        onBack={() => setSelectedProduct()}
      />
    );
  }

  return (
    <div className="products-list-container">
      {allProducts ? (
        list.length > 0 ? (
          list.map((item) => (
            <div
              key={item._id}
              className="product-item"
              onClick={() => setSelectedProduct(item)}
            >
              <div className="app-icon-container">
                <img src={item.product_icon} alt="" className="app-icon" />
              </div>
              <div className="app-details">
                <div className="app-name">{item?.product_name}</div>
                <div className="app-desc">{item?.sub_text}</div>
                <div className="action-container">
                  <div className="action">Learn</div>
                  <div className="action primary">Endorse</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-text">
            No Products Found For {activeBrand?.displayName}{' '}
            {!activeCategory?.name || activeCategory?.name === 'All'
              ? ''
              : `With ${activeCategory?.name} Category`}
          </div>
        )
      ) : (
        Array(20)
          .fill(1)
          .map((_, index) => (
            <div key={index} className="product-item">
              <Skeleton
                className="app-icon-container"
                white={100}
                height={100}
              />
              <div className="app-details">
                <Skeleton width="50%" height={22} />
                <Skeleton width="70%" height={30} className="app-desc" />
                <div className="action-container">
                  <Skeleton width={90} height={30} className="action" />
                  <Skeleton width={90} height={30} className="action" />
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default LandingProductList;
