import React, { useState, useRef, useEffect, useContext } from 'react';
import { createChart } from 'lightweight-charts';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Axios from 'axios';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';

const BackgroundChart = ({ navHeight, setCoinPrice }) => {
  const { walletActiveCoin } = useContext(BrokerAppContext);

  const chartContainerRef = useRef();
  const chartCanvasRef = useRef();
  const chartRef = useRef();
  const chartSeriesRef = useRef();

  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [unSubscribe, setUnSubscribe] = useState({
    method: 'UNSUBSCRIBE',
    params: [`btcusdt@kline_1m`],
    id: 1,
  });
  const [wsResp, setWsResp] = useState();
  const [socketUrl, setSocketUrl] = useState(
    'wss://stream.binance.com:9443/ws/btcusdt@kline_1m',
  );
  const [isChartOk, setIsChartOk] = useState(false);

  const { sendMessage, lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    window.addEventListener('resize', dimensionsHandler);
    dimensionsHandler();
    return () => {
      window.removeEventListener('resize', dimensionsHandler);
    };
  }, []);

  useEffect(() => {
    createAreaChart();
    // workaround to fix chart width
    setTimeout(() => chartRef.current.timeScale().fitContent(), 100);
    return () => {};
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.resize(chartDimensions.width, chartDimensions.height);
      chartRef.current.timeScale().fitContent();
    }
  }, [chartDimensions]);

  useEffect(() => {
    if (chartRef.current) {
      if (chartSeriesRef.current) {
        chartRef.current.removeSeries(chartSeriesRef.current);
      }

      chartSeriesRef.current = chartRef.current.addCandlestickSeries();

      chartSeriesRef.current.applyOptions({
        upColor: '#2A2E39',
        downColor: '#186AB4',
        borderUpColor: '#2A2E39',
        borderDownColor: '#186AB4',
        wickUpColor: '#2A2E39',
        wickDownColor: '#186AB4',
        priceLineVisible: false,
      });
    }
  }, []);

  useEffect(() => {
    const response = JSON.parse(lastMessage && lastMessage.data);

    if (response) {
      setWsResp(response);
    }
  }, [lastMessage]);

  useEffect(() => {
    Axios.get(
      `https://api.binance.com/api/v3/klines?symbol=${walletActiveCoin?.coinSymbol ||
        'BTC'}USDT&interval=1m&limit=80`,
    )
      .then(res => {
        const dataSet = res.data || [];

        const formattedDataSet = [];

        if (dataSet) {
          dataSet.forEach(item => {
            const [openTime, open, high, low, close] = item;

            setCoinPrice(open || 0);

            const dataObj = {
              time: openTime,
              open,
              high,
              low,
              close,
            };

            formattedDataSet.push(dataObj);
          });
        }
        // console.log('dataSet', dataSet);
        setIsChartOk(true);

        chartSeriesRef.current.setData(formattedDataSet);

        chartRef.current.timeScale().fitContent();

        updateSocket(walletActiveCoin?.coinSymbol);
      })
      .catch(error => {
        console.log('Error on first Data', error);
        setIsChartOk(false);
        setCoinPrice(0);
      });
  }, [walletActiveCoin]);

  useEffect(() => {
    if (wsResp) {
      if (wsResp.k && wsResp.k.T) {
        setCoinPrice(wsResp.k.o || 0);

        try {
          chartSeriesRef.current.update({
            time: wsResp.k.T,
            open: wsResp.k.o,
            high: wsResp.k.h,
            low: wsResp.k.l,
            close: wsResp.k.c,
          });
        } catch (error) {
          // console.log('Eroor', error);
        }
      }
    }
  }, [wsResp]);

  const dimensionsHandler = () => {
    setChartDimensions({
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight - 30,
    });
  };

  const createAreaChart = () => {
    chartRef.current = createChart(chartCanvasRef.current, {
      width: chartDimensions.width,
      height: chartDimensions.height,
    });

    chartRef.current.applyOptions({
      layout: {
        backgroundColor: '#FFFFFF',
        textColor: '#000000',
      },
      grid: {
        vertLines: {
          visible: false,
          color: '#334158',
        },
        horzLines: {
          visible: false,
          color: '#334158',
        },
      },
      priceScale: {
        borderVisible: false,
        borderColor: '#485c7b',
        position: 'none',
      },
      timeScale: {
        borderVisible: false,
        borderColor: '#485c7b',
        visible: false,
        timeVisible: false,
        secondsVisible: false,
      },
      crosshair: {
        vertLine: {
          visible: false,
          labelVisible: false,
        },
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        mode: 1,
      },
    });
  };

  const updateSocket = (coin = 'BTC') => {
    const subscribe = {
      method: 'SUBSCRIBE',
      params: [`${coin.toLowerCase()}usdt@kline_1m`],
      id: 1,
    };
    sendMessage(JSON.stringify(unSubscribe));
    sendMessage(JSON.stringify(subscribe));
    setUnSubscribe({
      method: 'UNSUBSCRIBE',
      params: [`${coin.toLowerCase()}usdt@kline_1m`],
      id: 1,
    });
  };

  return (
    <div className="cover-chart-wrapper flex-fill" ref={chartContainerRef}>
      {!isChartOk && (
        <div className="not-supported-mask">Chart Not Supported</div>
      )}
      <div className="chart-canvas" ref={chartCanvasRef} />
    </div>
  );
};

export default BackgroundChart;
