import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../configs';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import { onPaste } from '../../utils';
import BrokerSearchView from './BrokerSearchView';

const BrokerSyncCode = ({ setAffId, onNext }) => {
  const { setIsLoading } = useContext(BrokerAppContext);

  const [brokerSyncCode, setBrokerSyncCode] = useState('');
  const [showBrokerDetails, setShowBrokerDetails] = useState(false);
  const [brokerDetails, setBrokerDetails] = useState('');

  const history = useHistory();

  const onProceed = () => {
    if (!brokerSyncCode.trim()) {
      return toast.error('Please Enter BrokerSync™ Code');
    }
    setIsLoading(true);

    axios
      .get(`${GX_API_ENDPOINT}/user/details/get`, {
        params: { username: brokerSyncCode.trim() },
      })
      .then(({ data }) => {
        if (data.status) {
          setShowBrokerDetails(true);
          setBrokerDetails(data.user);
          setAffId(brokerSyncCode.trim());
        } else {
          setShowBrokerDetails(true);
        }
      })
      .catch((error) => {
        console.log('Error on getting broker data', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (showBrokerDetails) {
    return (
      <BrokerSearchView
        brokerDetails={brokerDetails}
        onBack={() => {
          setShowBrokerDetails(false);
          setBrokerDetails('');
        }}
        backToLogin={() => history.goBack()}
        onNext={onNext}
      />
    );
  }

  return (
    <div className="view-container">
      <div className="sub-text">
        Please Enter Your BrokerSync™ Code To Initiate Registration
      </div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container">
          <input
            type="text"
            className="input-field"
            placeholder="Ex. broker123"
            value={brokerSyncCode}
            onChange={(e) => setBrokerSyncCode(e.target.value)}
          />
          <div
            onClick={() => onPaste(setBrokerSyncCode)}
            className="input-action"
          >
            <img
              src={require('../../assets/images/paste-icon.svg').default}
              alt=""
              className="input-action-icon"
            />
          </div>
        </div>
        <div className="form-buttons">
          <div className="action-outlined" onClick={() => history.goBack()}>
            Go Back
          </div>
          <div className="action-filled" onClick={onProceed}>
            Proceed
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerSyncCode;
