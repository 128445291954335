import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../../configs';
import ChatContext from '../../../contexts/ChatContext';
import PopupModalLayout from '../../../layouts/PopupModalLayout';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import ViewHeader from './ViewHeader';

const SwapUpline = ({ onBack, selectedOption }) => {
  const history = useHistory();

  const { isBotFullScreen, setIsBotFullScreen } = useContext(ChatContext);

  const [currentUpline, setCurrentUpline] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [desiredUpline, setDesiredUpline] = useState();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [activeFilter, setActiveFilter] = useState(INPUT_OPTIONS[0]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setIsBotFullScreen(true);

    const email = LocalStorageHelper.getAppEmail();

    axios
      .get(`${GX_API_ENDPOINT}/brokerage/stats/get/uplines`, {
        params: { email },
      })
      .then(({ data }) => {
        if (data.status) {
          // console.log('Data', data);

          const uplines = data?.uplines;

          const directUpline = uplines ? uplines[0] : '';

          setCurrentUpline(directUpline || { name: 'No Upline', email: '' });
        } else {
          toast.error(data.message);
          onBack();
        }
      })
      .catch((error) => console.log('Error on locating user', error));

    return () => {
      setIsBotFullScreen(false);
    };
  }, []);

  const onSubmitHandler = (submitValue = null) => {
    const input = submitValue || searchInput;

    if (!input) {
      return toast.error('Please Input A Value');
    }

    setIsLoading(true);

    const value = {};

    value[`${activeFilter.paramKey}`] = input?.toLowerCase().trim();

    axios
      .get(`${GX_API_ENDPOINT}/user/details/get`, {
        params: value,
      })
      .then(({ data }) => {
        if (data.status) {
          setDesiredUpline(data.user);
        } else {
          toast.error(
            `No User Found Associated With This ${activeFilter.paramKey}`,
          );
        }
      })
      .catch((error) => {
        console.log('Error on getting broker data', error);
        toast.error('Please Check Your Internet Connection');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUplineChange = async () => {
    setIsLoading(true);
    setShowConfirmPopup(false);

    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();

    const postData = {
      email,
      token,
      app_code: 'gx',
      update_data: {
        ref_affiliate: desiredUpline?.affiliate_id || '1',
      },
    };

    // console.log('desiredUpline', desiredUpline);

    // console.log('PostData', postData);

    axios
      .post(`${GX_API_ENDPOINT}/user/profile/fields/update/values`, postData)
      .then(({ data }) => {
        // console.log('Data', data);

        if (data.status) {
          setShowSuccessPopup(true);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log('Error on updating uplines', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="faq-form-container">
      <ViewHeader disableExpand onBack={onBack} headerText="Swap Uplines" />
      <div className="option-item">
        <img src={selectedOption?.icon} alt="" className="option-icon" />
        <div className="option-name">{selectedOption?.title}</div>
      </div>
      {isLoading || !currentUpline ? (
        <div className="flex-fill d-flex flex-column justify-content-center">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="flex-fill d-flex flex-column">
          <div className="list-title">Current Upline</div>
          <div className="user-container">
            <img
              src={currentUpline?.profile_img}
              alt=""
              className="user-image"
            />
            <div className="user-details">
              <div className="user-name">{currentUpline?.name}</div>
              <div className="user-email">{currentUpline?.email}</div>
            </div>
          </div>
          {desiredUpline ? (
            <div>
              <div className="list-title">Desired Upline</div>
              <div className="user-container">
                <img
                  src={desiredUpline?.profile_img}
                  alt=""
                  className="user-image"
                />
                <div className="user-details">
                  <div className="user-name">{desiredUpline?.name}</div>
                  <div className="user-email">{desiredUpline?.email}</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="list-title">Desired Upline</div>
              <div className="input-filter">
                {INPUT_OPTIONS.map((item) => (
                  <div
                    key={item.title}
                    className={`filter-item ${
                      activeFilter?.title === item.title ? 'active' : ''
                    }`}
                    onClick={() => setActiveFilter(item)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
              <div className="input-container">
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Ex: Shorupan"
                />
              </div>
            </>
          )}
          <div
            onClick={() =>
              desiredUpline ? setShowConfirmPopup(true) : onSubmitHandler()
            }
            className="submit-button"
          >
            Submit
          </div>
        </div>
      )}
      <PopupModalLayout
        isOpen={showConfirmPopup}
        onClose={() => setShowConfirmPopup(false)}
        headerText="Upline Swap Confirmation"
      >
        <div className="modal-text">
          You Will Be Responsible For Conveying This Upline Swap To The
          Respective Parties
        </div>
        <div className="action-container">
          <div
            className="action-filled"
            onClick={() => setShowConfirmPopup(false)}
          >
            Back
          </div>
          <div className="action-outlined" onClick={onUplineChange}>
            Proceed
          </div>
        </div>
      </PopupModalLayout>
      <PopupModalLayout
        isOpen={showSuccessPopup}
        onClose={() => setShowConfirmPopup(false)}
        headerText="Upline Swap Confirmation"
      >
        <div className="modal-text">
          Your New Upline Is {desiredUpline?.name}. Please Inform Accordingly.
        </div>
        <div className="action-container">
          <div className="action-filled" onClick={onBack}>
            Back To Bots
          </div>
          <div
            className="action-outlined"
            onClick={() => {
              history.push('/');
              onBack();
            }}
          >
            Home
          </div>
        </div>
      </PopupModalLayout>
    </div>
  );
};

export default SwapUpline;

const INPUT_OPTIONS = [
  { title: 'Email', paramKey: 'email' },
  { title: 'Name', paramKey: 'name' },
  { title: 'Username', paramKey: 'username' },
];
