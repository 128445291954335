import React, { useMemo } from 'react';
import BrandSettings from './BrandSettings';
import MenuItem from './MenuItem';

export default function MenuListItems({
  selectedMenu,
  selectedBrand,
  screenToShow,
  setScreenToShow,
  setComplanFeePairScreen,
}) {
  const menuOrDetail = useMemo(() => {
    switch (screenToShow) {
      case 'BrandSettings':
        console.log('bradning...');
        return (
          <BrandSettings
            selectedBrand={selectedBrand}
            onClose={() => setScreenToShow('')}
            setComplanFeePairScreen={setComplanFeePairScreen}
          />
        );

      default:
        console.log('elkse...');

        return (
          <>
            {selectedMenu &&
              selectedMenu?.menu.map((item, key) => {
                return (
                  <MenuItem
                    icon={item.icon}
                    title={item.title}
                    subTitle={item.subTitle}
                    onPress={item.onPress}
                    disabled={item.disabled}
                  />
                );
              })}
          </>
        );
    }
  }, [screenToShow, selectedBrand, selectedMenu.menu, setScreenToShow]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'scroll',
      }}
    >
      {menuOrDetail}
    </div>
  );
}
