import React, { useEffect, useState, useContext } from 'react';
import menuDots from '../../assets/images/menu-dots.svg';
import eyeIcon from '../../assets/images/eye-icon.svg';
import { CRMContext } from '../../contexts/CRMContext';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const SavedDashBoards = () => {
  const { setCrmDashboardView } = useContext(CRMContext);

  const [dashboards, setDashboards] = useState([]);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const dashs = LocalStorageHelper.getSavedDashboards();
    if (dashs) {
      setDashboards(dashs);
    } else {
      setDashboards([]);
    }
  }, [hovered]);

  return (
    <div className="saved-dashboards-wrapper">
      <img
        src={menuDots}
        alt=""
        className="save-menu-icon"
        onMouseEnter={() => setHovered(!hovered)}
      />
      <div className="saved-dashboard-list">
        <div className="saved-dashboard">
          <h6 className="title">
            {dashboards.length > 0
              ? 'Saved Dashboard Views'
              : 'No Saved Dashboard Views Found'}
          </h6>
        </div>
        {dashboards.map(item => (
          <div
            key={item.name}
            className="saved-dashboard"
            onClick={() => setCrmDashboardView(item.tabs)}
          >
            <img className="eye-icon" src={eyeIcon} alt="" />
            <h6 className="saved-title">{item.name}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedDashBoards;
