import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { GX_API_ENDPOINT } from '../../configs';
import { percentageFormatter, usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

export default function UserStatsSheet({
  isBottomSheetOpen,
  setIsBottomSheetOpen,
  data,
}) {
  const [statsData, setStatsData] = useState({});
  useEffect(() => {
    if (data && isBottomSheetOpen) {
      (async () => {
        const email = await LocalStorageHelper.getAppEmail();

        axios
          .get(`${GX_API_ENDPOINT}/brokerage/otc/txn/stats/get`, {
            params: { email, customer_email: data.email },
          })
          .then((resp) => {
            const stats = resp.data;

            // console.log('Data', stats);

            const totalTransaction = stats.total_transactions;
            const transactionalVolume = stats.total_txn_volume;
            const totalFeePaid = stats.total_txn_fees;
            const averageFeePaid = stats.total_txn_fees_percentage;
            const revenueFromUser = stats.total_txn_revenue;

            if (stats.status) {
              setStatsData({
                totalTransaction,
                transactionalVolume,
                totalFeePaid,
                averageFeePaid,
                revenueFromUser,
              });
            }
          })
          .catch((error) => {
            console.log('Error on getting statsData', error);
          });
      })();
    } else {
      setStatsData();
    }
  }, [data, isBottomSheetOpen]);

  return (
    <div
      className="userstats_container"
      style={{
        height: isBottomSheetOpen ? '100%' : 0,
        display: isBottomSheetOpen ? '' : 'none',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '45%',
        }}
        onClick={() => {
          setIsBottomSheetOpen(false);
        }}
      />
      <div
        style={{
          width: '100%',
          height: data ? (statsData ? 'auto' : '80%') : '80%',
          backgroundColor: 'white',
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          // overflow: 'scroll',
          position: 'relative',
        }}
      >
        {data && (
          <div className="userstatusBottomsheet_container">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  height: 45,
                  width: 45,
                  borderRadius: 25,
                }}
                src={data.profile_img || 'https://i.pravatar.cc/110'}
                alt=""
              />
              <div className="userstats_namecontainer">
                <div className="userstats_name">{data.name || 'Jon Doe'}</div>
                <div className="userstats_email"> {data.email} </div>
              </div>
            </div>
            {statsData ? (
              <div className="userstats_listcontainer">
                <div className="userstats_listitem">
                  <div className="userstats_listtitle">Total Transaction</div>
                  <div className="userstats_listvalue">
                    {statsData?.totalTransaction || 0}
                  </div>
                </div>
                <div className="userstats_listitem">
                  <div className="userstats_listtitle">Transaction Volume</div>
                  <div className="userstats_listvalue">
                    {usdValueFormatter.format(
                      statsData?.transactionalVolume || 0,
                    )}
                  </div>
                </div>
                <div className="userstats_listitem">
                  <div className="userstats_listtitle">Total Fee Paid</div>
                  <div className="userstats_listvalue">
                    {usdValueFormatter.format(statsData?.totalFeePaid || 0)}
                  </div>
                </div>
                <div className="userstats_listitem">
                  <div className="userstats_listtitle">
                    Average Fee Percentage
                  </div>
                  <div className="userstats_listvalue">
                    {percentageFormatter.format(statsData?.averageFeePaid || 0)}
                    %
                  </div>
                </div>
                <div
                  className="userstats_listitem"
                  style={{
                    marginBottom: '12%',
                  }}
                >
                  <div className="userstats_listtitle">
                    You Revenue From{' '}
                    {data?.name ? data?.name?.split(' ')[0] : ''}
                  </div>
                  <div className="userstats_listvalue">
                    {percentageFormatter.format(statsData?.averageFeePaid || 0)}
                    %
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingAnimation dark />
              </div>
            )}
          </div>
        )}
        <div
          onClick={() => {
            setIsBottomSheetOpen(false);
          }}
          className="userStats_close_button"
        >
          Close
        </div>
      </div>
    </div>
  );
}
