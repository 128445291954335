import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import videoHolder from '../../../assets/images/placeHolder/videoHolder.svg';

function LearnVideoCard({ video }) {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    Axios.post(
      'https://vod-backend.globalxchange.io/get_user_profiled_video_stream_link',
      {
        video_id: video && video.video,
      },
    ).then(res => {
      setVideoUrl(res.data);
    });
  }, [video]);

  return (
    <div className="videoCard">
      <div className="videoHolder">
        <img className="videoHolderImg" src={videoHolder} alt={videoUrl} />
        <ReactPlayer
          className="reactPlayer"
          light={video.image}
          width="100%"
          height="100%"
          url={videoUrl}
          playing
          controls
        />
      </div>
      <div className="vidTitle">{video.title}</div>
      <p className="desc">{video.desc}</p>
    </div>
  );
}

export default LearnVideoCard;
