import axios from 'axios';
import React, { useEffect, useState } from 'react';
import UserLevels from '../../components/BrokerChat/BotLineage/UserLevels';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import HorizontalItem from './HorizontalItem';
import { ScrollView } from '@cantonjs/react-scroll-view';
import VerticalItem from './VerticalItem';

export default function BrokerUsers({
  selectedItem,
  onItemSelected,
  horizontal,
  searchText,
  type,
  isBrokers,
}) {
  const [usersList, setUsersList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [live, setLive] = useState([]);
  useEffect(() => {
    (async () => {
      if (type === 'Direct') {
        const email = await LocalStorageHelper.getAppEmail();
        axios
          .get(`${GX_API_ENDPOINT}/brokerage/otc/user/txn/stats/get`, {
            params: { email },
          })
          .then((resp) => {
            const { data } = resp;

            if (data.status) {
              const userData = data.userStats;
              let totalTxns = 0;

              userData.forEach((item) => {
                totalTxns += item.txns;
              });

              const allData = {
                name: 'All Directs',
                image: require('../../assets/mobileassets/all-user-icon.png')
                  .default,
                email: '',
                txns: totalTxns,
              };
              const finalArray = [allData, ...userData];

              setUsersList(finalArray);
              setFilterList(finalArray);
              onItemSelected(allData);
              setLive(finalArray);
            }
          })
          .catch((error) => {
            console.log('Error on getting userlist', error);
          });
      } else {
        if (isBrokers) {
          const email = await LocalStorageHelper.getAppEmail();
          axios
            .get(`${GX_API_ENDPOINT}/brokerage/otc/broker/team/stats`, {
              params: { email },
            })
            .then((resp) => {
              const { data } = resp;

              console.log('data', data);
              const allData = {
                name: 'All Indirect',
                image: require('../../assets/mobileassets/all-user-icon.png')
                  .default,
                email: '',
              };
              if (data.status) {
                setUsersList([allData, ...data.data]);
                setFilterList([allData, ...data.data]);
                setLive([allData, ...data.data]);
                onItemSelected(allData);
              } else {
                setUsersList([allData]);
                setFilterList([allData]);
                setLive([allData]);
                onItemSelected(allData);
              }
            })
            .catch((error) => {
              console.log('Error on getting userlist', error);
            });
        } else {
          const affId = await LocalStorageHelper.getBrokerAffId();
          axios
            .get(`${GX_API_ENDPOINT}/brokerage/stats/getUsers`, {
              params: { affiliate_id: affId },
            })
            .then((resp) => {
              const { data } = resp;

              if (data.status) {
                const allData = {
                  name: 'All Indirect',
                  image: require('../../assets/mobileassets/all-user-icon.png')
                    .default,
                  email: '',
                };

                setUsersList([allData, ...data.indirect.users]);
                setFilterList([allData, ...data.indirect.users]);
                setLive([allData, ...data.indirect.users]);
                onItemSelected(allData);
              }
            })
            .catch((error) => {
              console.log('Error on getting userlist', error);
            });
        }
      }
    })();
  }, [isBrokers, type]);

  useEffect(() => {
    if (horizontal) {
      setLive(usersList);
    } else {
      setLive(filterList);
    }
    console.log(horizontal);
  }, [horizontal]);

  useEffect(() => {
    if (searchText && usersList) {
      const searchQuery = searchText.trim().toLowerCase();

      const list = usersList.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery) ||
          item.email.includes(searchQuery),
      );
      setFilterList(list);
      setLive(list);
    }
  }, [searchText, usersList]);

  return (
    <ScrollView
      contentContainerStyle={{
        display: horizontal ? 'flex' : '',
        width: '100%',

        overflow: 'scroll',
        marginTop: 10,
        height: horizontal ? '10vh' : '100%',
        paddingRight: horizontal ? '0' : '8%',
        paddingLeft: horizontal ? '0' : '8%',
        paddingTop: horizontal ? '0' : '5%',
      }}
    >
      {live?.map((item, index) => {
        return horizontal ? (
          <HorizontalItem
            item={item}
            index={index.toString()}
            onItemSelected={() =>
              onItemSelected ? onItemSelected(item) : null
            }
            selectedItem={selectedItem}
          />
        ) : (
          <VerticalItem
            item={item}
            onItemSelected={() =>
              onItemSelected ? onItemSelected(item) : null
            }
            selectedItem={selectedItem}
          />
        );
      })}
    </ScrollView>
  );
}
