import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { APP_CODE, GX_API_ENDPOINT } from '../../configs';
import FilterOptionSelector from './FilterOptionSelector';
import LandingSearchLayout from './LandingSearchLayout';
import OptionSelector from './OptionSelector';

const LandingSearch = () => {
  const location = useLocation();

  const [allUsersList, setAllUsersList] = useState();
  const [productList, setProductList] = useState();
  const [brandsList, setBrandsList] = useState();
  const [influencersList, setInfluencersList] = useState();

  const SEARCH_OPTION = [
    {
      title: 'Affiliates',
      icon: require('../../assets/images/affliate-app-dark-logo.svg').default,
      placeholder: 'Find An Affiliate...',
      list: allUsersList,
    },
    {
      title: 'Products',
      icon: require('../../assets/images/products-search-icon.svg').default,
      placeholder: 'Search For Endorsed Products...',
      list: productList,
    },
    {
      title: 'Brands',
      icon: require('../../assets/images/brands-search-icon.svg').default,
      placeholder: 'Search For Endorsed Brands...',
      list: brandsList,
    },
    {
      title: 'Influencers',
      icon: require('../../assets/images/influence-search-icon.svg').default,
      placeholder: 'Find An Influencer...',
      list: influencersList,
    },
  ];

  const [activeSearchOption, setActiveSearchOption] = useState(
    SEARCH_OPTION[0],
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState(
    FILTER_OPTION[0],
  );
  const [searchText, setSearchText] = useState('');
  const [isOptionPopupOpen, setIsOptionPopupOpen] = useState(false);
  const [isSearchLayoutOpen, setIsSearchLayoutOpen] = useState(false);
  const [isSearchFilterPopupOpen, setIsSearchFilterPopupOpen] = useState(false);
  const [isSearchAsList, setIsSearchAsList] = useState(true);

  useEffect(() => {
    axios
      .get('https://teller2.apimachine.io/admin/allBankers')
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          // console.log('data', data);

          const brands = (data.data || []).map((item) => ({
            ...item,
            name: item.displayName,
            profile_img: item.profilePicURL,
          }));

          setBrandsList(brands);
        } else {
          setBrandsList([]);
        }
      })
      .catch((error) => {
        setBrandsList([]);
      });

    axios
      .get(`${GX_API_ENDPOINT}/gxb/product/get`)
      .then(({ data }) => {
        const products = data?.products || [];
        // console.log('products', products);

        const parsedList = products.map((item) => ({
          ...item,
          name: item.product_name,
          profile_img: item.product_icon,
          email: item.product_code,
        }));

        setProductList(parsedList);
      })
      .catch((error) => {
        console.log('Error on getting products list', error);
      });
  }, []);

  useEffect(() => {
    if (searchText && isSearchAsList) {
      setIsSearchLayoutOpen(true);
    }
  }, [searchText, isSearchAsList]);

  useEffect(() => {
    if (!isSearchLayoutOpen) {
      setSearchText('');
    }
  }, [isSearchLayoutOpen]);

  useEffect(() => {
    if (location.state?.openEdit) {
      setIsSearchLayoutOpen(true);
    }

    if (location.state?.openOptions) {
      setIsOptionPopupOpen(true);
    }
    if (location.state?.isOpenUser) {
      setIsSearchLayoutOpen(true);
    }
  }, [location]);

  const onSearchClick = () => {
    setIsSearchAsList(false);
    if (searchText) {
      setIsSearchLayoutOpen(true);
    }
  };

  return (
    <>
      <div className="search-bar-container">
        <div
          className="selected-option"
          onClick={() => setIsOptionPopupOpen(true)}
          data-tip={activeSearchOption.title}
          data-for="search-list"
        >
          <img src={activeSearchOption.icon} alt="" className="option-img" />
          <ReactTooltip
            id="search-list"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          />
        </div>
        {activeSearchOption?.title === 'Affiliates' && (
          <div
            className="selected-option"
            onClick={() => setIsSearchFilterPopupOpen(true)}
            data-tip={activeSearchFilter.title}
            data-for="search-filter"
          >
            <img src={activeSearchFilter.icon} alt="" className="option-img" />
            <ReactTooltip
              id="search-filter"
              place="bottom"
              type="dark"
              effect="solid"
              border
              borderColor="#e5e5e5"
              backgroundColor="#fff"
              textColor="#343C5B"
              className="tooltip"
            />
          </div>
        )}
        <input
          type="text"
          className="search-input"
          placeholder={`${activeSearchOption.placeholder}`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div
          className={`selected-option right ${
            isSearchAsList ? 'no-active' : 'active'
          }`}
          onClick={onSearchClick}
          data-tip="Search"
          data-for="Search"
        >
          <img
            src={require('../../assets/images/search-as-item.svg').default}
            alt=""
            className="option-img"
          />
          <ReactTooltip
            id="Search"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          />
        </div>
        <div
          className={`selected-option right ${
            isSearchAsList ? 'active' : 'no-active'
          }`}
          onClick={() => setIsSearchAsList(true)}
          data-tip="Filter"
          data-for="Filter"
        >
          <img
            src={require('../../assets/images/search-as-list.svg').default}
            alt=""
            className="option-img"
          />
          <ReactTooltip
            id="Filter"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          />
        </div>
        <OptionSelector
          isOpen={isOptionPopupOpen}
          onClose={() => setIsOptionPopupOpen(false)}
          options={SEARCH_OPTION}
          activeSearchOption={activeSearchOption}
          setActiveSearchOption={setActiveSearchOption}
        />
        <FilterOptionSelector
          isOpen={isSearchFilterPopupOpen}
          onClose={() => setIsSearchFilterPopupOpen(false)}
          options={FILTER_OPTION}
          activeSearchFilter={activeSearchFilter}
          setActiveSearchOption={setActiveSearchFilter}
        />
      </div>
      <LandingSearchLayout
        isSearchLayoutOpen={isSearchLayoutOpen}
        toggleSearchType={() => setIsSearchAsList(!isSearchAsList)}
        isSearchAsList={isSearchAsList}
        activeSearchOption={activeSearchOption}
        activeSearchFilter={activeSearchFilter}
        onBack={() => setIsSearchLayoutOpen(false)}
        searchText={searchText}
        setSearchText={setSearchText}
        openOptionPopup={() => setIsOptionPopupOpen(true)}
        openFilterPopup={() => setIsSearchFilterPopupOpen(true)}
      />
    </>
  );
};

export default LandingSearch;

const FILTER_OPTION = [
  {
    title: 'Email',
    icon: require('../../assets/images/gmail-icon.svg').default,
    key: 'email',
  },
  {
    title: 'Username',
    icon: require('../../assets/images/filter-by-username-icon.svg').default,
    key: 'username',
  },
  {
    title: 'Name',
    icon: require('../../assets/images/filter-by-name.svg').default,
    key: 'name',
  },
  {
    title: 'Sponsor',
    icon: require('../../assets/images/filter-by-upline.svg').default,
    key: 'upline',
  },
];
