import React, { useContext, useEffect, useState } from 'react';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import MobileNav from '../components/MobileNav';

import Mobilestyle from '../Mobilestyle.css';
import brokerAppLogo from '../../assets/images/app-icon.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import axios from 'axios';
import { APP_CODE, GX_API_ENDPOINT } from '../../configs';
import { numberFormatter, usdValueFormatter } from '../../utils';
import LoadingAnimation from '../../components/LoadingAnimation';
import FullLoadingComponent from '../../components/FullLoadingComponent';
import NetworkLevelDetailsScreen from '../components/NetworkLevelDetailsScreen';

export default function Network() {
  const { isSidebar, profileImg } = useContext(BrokerAppContext);
  // false ? value: size
  const [isValue, setisValue] = useState(false);
  const [networkData, setNetworkData] = useState();
  const [vaultBalance, setVaultBalance] = useState(0);
  const [netWorkValuation, setNetWorkValuation] = useState();
  const [totalDepth, setTotalDepth] = useState(0);
  const [totalUsers, setTotalUsers] = useState();
  const [affiliateBalance, setAffiliateBalance] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [cryptoTableData, setCryptoTableData] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [isNetworkLevelDetailsScreen, setIsNetworkLevelDetailsScreen] =
    useState(false);
  const [
    selectedNetworkLevelDetailsScreen,
    setSelectedNetworkLevelDetailsScreen,
  ] = useState([]);

  const getAffiliateBalance = async () => {
    const email = await LocalStorageHelper.getAppEmail();
    if (email) {
      axios
        .get(`${GX_API_ENDPOINT}/coin/vault/gxb/balance`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setAffiliateBalance(data.gxbroker_account_balance || 0);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const getWalletBalances = async () => {
    const profile_id = await LocalStorageHelper.getProfileId();

    axios
      .post(`${GX_API_ENDPOINT}/coin/vault/service/balances/get`, {
        app_code: APP_CODE,
        profile_id: profile_id,
      })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          setWalletBalance(data.vault);
        }
      })
      .catch((error) => console.log('Error on getting Wallet Balance', error));
  };
  const getCryptoData = () => {
    setCryptoTableData(null);

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/get/all/coins`)
      .then((resp) => {
        const { data } = resp;

        if (data.status) {
          const coinData = data.coins || [];
          const finalList = [];
          const coinSymbolArray = [];

          // Parsing Array as UI was designed
          coinData.forEach((item) => {
            if (!coinSymbolArray.includes(item.coinSymbol)) {
              coinSymbolArray.push(item.coinSymbol);
              finalList.push({
                ...item,
                name: item.coinName,
                image: item.coinImage,
                price: {
                  USD: item.usd_price,
                },
                asset_type: item.type === 'fiat' ? 'Fiat' : 'Crypto',
              });
            }
          });

          // Sorting Array as crypto comes first
          finalList.sort((a, b) =>
            a.asset_type > b.asset_type
              ? 1
              : b.asset_type > a.asset_type
              ? -1
              : 0,
          );

          setCryptoTableData(finalList);
        } else {
          setCryptoTableData([]);
        }
      })
      .catch((error) => console.log('Error getting Crypto table data', error));
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const email = LocalStorageHelper.getAppEmail();

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/get/broker/users/by/levels`, {
          params: {
            email,
          },
        })
        .then((resp) => {
          const { data } = resp;

          if (data.status) {
            const levels = data.levels || [];

            let total = 0;

            levels.forEach((item) => {
              total = total + (item.count || 0);
            });
            setTotalUsers(total);
            setTotalDepth(levels?.length || 0);
            setNetworkData(levels);
          } else {
            setNetworkData([]);
          }
        })
        .catch((error) => {
          console.log('Error on getting Network data', error);
        });
    })();
    getAffiliateBalance();
    getWalletBalances();
    getCryptoData();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (walletBalance && cryptoTableData) {
      let totalBalance = 0;

      cryptoTableData.forEach((item) => {
        totalBalance +=
          walletBalance[`${item.coinSymbol.toLowerCase()}_balance`] *
          item.price.USD;
      });
      setVaultBalance(totalBalance);
    }
  }, [walletBalance, cryptoTableData]);

  useEffect(() => {
    if (vaultBalance && affiliateBalance !== '') {
      setNetWorkValuation(vaultBalance + affiliateBalance);
    }
  }, [vaultBalance, affiliateBalance]);

  useEffect(() => {
    if (!isNetworkLevelDetailsScreen) {
      setSelectedNetworkLevelDetailsScreen([]);
    }
  }, [isNetworkLevelDetailsScreen]);

  return (
    <>
      <MobileNav />
      {isNetworkLevelDetailsScreen ? (
        <NetworkLevelDetailsScreen
          setIsNetworkLevelDetailsScreen={setIsNetworkLevelDetailsScreen}
          item={selectedNetworkLevelDetailsScreen}
        />
      ) : Loading ? (
        <LoadingAnimation dark />
      ) : (
        <div className="network_container">
          <div className="network_first_box">
            <div className="first_box_part1">
              <div
                onClick={() => {
                  setisValue(false);
                }}
                className={isValue ? 'network_button' : 'network_button_active'}
              >
                Size
              </div>
              <img
                src={profileImg || brokerAppLogo}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                }}
              />
              <div
                onClick={() => {
                  setisValue(true);
                }}
                className={isValue ? 'network_button_active' : 'network_button'}
              >
                value
              </div>
            </div>

            <div className="first_box_part2">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',

                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyCenter: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="first_box_part2_title">
                    {isValue ? 'Your Network Valuation' : 'Your Network Size'}
                  </div>

                  <img
                    style={{
                      width: 14,
                      height: 14,
                      marginLeft: 10,
                    }}
                    src={
                      require('../../assets/mobileassets/forward-arrow-icon.png')
                        .default
                    }
                  />
                </div>
                {(isValue ? netWorkValuation : totalUsers) !== undefined ? (
                  <div className="network_valuations">
                    {isValue
                      ? netWorkValuation === ''
                        ? 'Calculating'
                        : usdValueFormatter.format(netWorkValuation || 0)
                      : `${numberFormatter.format(totalUsers || 0)} Contacts`}
                  </div>
                ) : (
                  <>
                    <Skeleton
                      height={30}
                      className="network_valuations"
                      width={160}
                    />
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',

                  justifyContent: 'space-between',
                  backgroundColor: '#F1F4F6',
                  alignItems: 'center',
                  width: '100%',
                }}
                className="grayLinebox"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    toast.info(
                      'DD Level Stands For Depth Differential Level And Signifies How Many Levels Away From You The Selected User Is. A DD Level Of Zero Means The User Is Directly Attached To You.',
                    );
                  }}
                >
                  <div className="text_depth">Total Depth</div>
                  <img
                    src={
                      require('../../assets/mobileassets/i-button-icon.png')
                        .default
                    }
                    style={{
                      width: 13,
                      height: 13,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    toast.info(
                      'On A Given DD Level You Will Have Any Number Of Users. That Is What Is Displayed Here As Contacts Per DD Level.',
                    );
                  }}
                >
                  <img
                    src={
                      require('../../assets/mobileassets/i-button-icon.png')
                        .default
                    }
                    style={{
                      width: 13,
                      height: 13,
                      marginRight: 8,
                    }}
                  />
                  <div className="text_depth">
                    {totalDepth ? (
                      totalDepth + ' Levels'
                    ) : (
                      <Skeleton height={'100%'} width={80} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="network_second_box">
            {networkData ? (
              <>
                {networkData?.length > 0 ? (
                  networkData?.map((item) => {
                    return (
                      <div
                        className="network_list_box"
                        key={item.ddlevel}
                        onClick={() => {
                          setSelectedNetworkLevelDetailsScreen(item);
                          setIsNetworkLevelDetailsScreen(true);
                        }}
                      >
                        <div
                          className="network_list_item"
                          style={{ textAlign: 'left' }}
                        >
                          DD Level {item.ddlevel}
                        </div>
                        <div
                          className="network_list_item"
                          style={{ textAlign: 'right' }}
                        >
                          {item.count} Contacts
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="network_list_box">
                      <div
                        className="network_list_item"
                        style={{ textAlign: 'left' }}
                      >
                        DD Level 0
                      </div>
                      <div
                        className="network_list_item"
                        style={{ textAlign: 'right' }}
                      >
                        0 Contacts
                      </div>
                    </div>

                    <img
                      className="network_uparrow"
                      src={
                        require('../../assets/mobileassets/next-forward-icon.png')
                          .default
                      }
                      alt="forward-arrow"
                    />
                    <div className="network_static_content">
                      Don’t worry we all start somewhere. It is super easy to
                      start building your network.
                    </div>
                    <div className="network_static_content_2">
                      Click <span className="here_button">Here</span> to add
                      your first contact.
                    </div>
                  </>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
}
