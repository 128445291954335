import React, { useEffect } from 'react';

const EarnTopMenu = ({ activeNavMenu, setActiveNavMenu }) => {
  useEffect(() => {
    setActiveNavMenu(MENU_ITEMS[0]);
  }, []);

  return (
    <div className="main-nav-wrapper earn-top-nav">
      {MENU_ITEMS.map(item => (
        <div
          key={item.title}
          className={`menu-item ${
            activeNavMenu?.title === item.title ? 'active' : ''
          }`}
          onClick={() => setActiveNavMenu(item)}
        >
          <div className="menu-title">{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default EarnTopMenu;

const MENU_ITEMS = [
  { title: 'Financial' },
  { title: 'Gaming', disabled: true },
  { title: 'Education', disabled: true },
  { title: 'Entertainment', disabled: true },
  { title: 'E-Commerce', disabled: true },
];
