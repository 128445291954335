import React, { useEffect, useState } from 'react';
import MenuController from './MenuController';
import MenuListItems from './MenuListItems';

export default function BrandController({
  selectedBrand,
  setComplanFeePairScreen,
}) {
  const [selectedMenu, setSelectedMenu] = useState('');
  const [screenToShow, setScreenToShow] = useState('');

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        overflow: 'scroll',
      }}
    >
      <MenuController
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        selectedBrand={selectedBrand}
        setScreenToShow={setScreenToShow}
      />
      <MenuListItems
        selectedMenu={selectedMenu}
        selectedBrand={selectedBrand}
        screenToShow={screenToShow}
        setScreenToShow={setScreenToShow}
        setComplanFeePairScreen={setComplanFeePairScreen}
      />
    </div>
  );
}
