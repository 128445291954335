import axios from 'axios';
import { useQuery } from 'react-query';
import { APP_CODE, GX_API_ENDPOINT } from '../configs';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const getUserEarnings = async ({ queryKey }) => {
  const [_key, email] = queryKey;
  const { data } = await axios.get(
    `${GX_API_ENDPOINT}/coin/vault/gxb/balance`,
    { params: { email } },
  );

  return data;
};

const useUserEarnings = () => {
  const email = LocalStorageHelper.getAppEmail();

  const query = useQuery(['userEarnings', email], getUserEarnings);

  return query;
};

const getUserBalance = async ({ queryKey }) => {
  const [_key, email] = queryKey;

  const { data } = await axios.post(
    `${GX_API_ENDPOINT}/coin/vault/service/coins/get`,
    { email, app_code: APP_CODE },
  );

  return data;
};

const useUserBalance = () => {
  const email = LocalStorageHelper.getAppEmail();

  const query = useQuery(['userBalance', email], getUserBalance);

  return query;
};

export { useUserEarnings, useUserBalance };
