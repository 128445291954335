import React from 'react';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';

const Overview = () => {
  return (
    <div className="my-broker-wrapper fragment-wrapper">
      <div className="row mx-0 mb-5">
        <div className="col-md-8">
          <h1 className="broker-name mb-5">
            {LocalStorageHelper.getBrokerName()}’s Brokerage
          </h1>
          <p className="p-brokerage">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id dolorem
            placeat sint, consectetur aliquid quod quam asperiores dolore quos
            numquam? Corrupti qui excepturi rerum nesciunt nobis vel illum
            numquam reprehenderit! Veniam mollitia quia dolor nam. Quam aut
            illum dolorum. Reprehenderit soluta dolore voluptatum itaque quas
            fugiat officiis unde, ab modi est cumque sit repellendus ut
            voluptatem at libero incidunt similique? At dolorum temporibus ad
            eligendi, quas dolore libero vitae quam, sit amet, rem sed
            doloremque blanditiis et sapiente? Dicta ipsum corporis dolorem,
            eius omnis nobis iste accusamus ipsa veniam dolore.
          </p>
        </div>
        <div className="col-md-4 d-flex">
          <div className="brokerage-details-wrapper">
            <h1 className="brokerage-header">2,123</h1>
            <h6 className="header-type">NETWORK TYPE</h6>
            <div className="brokerage-rating">BROKER RATING : A+</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
