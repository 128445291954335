import React, { useContext } from 'react';
import ChatContext from '../../contexts/ChatContext';

const ChatsIoMenu = () => {
  const { setActiveFooterMenu } = useContext(ChatContext);

  const menus = [
    {
      title: 'Speak To Support',
      icon: require('../../assets/images/qr-black.png').default,
      onClick: () => setActiveFooterMenu({ title: 'SupportChat' }),
    },
    {
      title: 'Manage Contacts',
      icon: require('../../assets/images/wallet-icon-menu-support.png').default,
      disabled: true,
    },
    {
      title: 'Schedule A Meeting',
      icon: require('../../assets/images/currency-icon-support.png').default,
      disabled: true,
    },
    {
      title: 'Chats.io MarketPlace',
      icon: require('../../assets/images/exit-support-icon.png').default,
      disabled: true,
    },
    {
      title: 'Edit Profile',
      icon: require('../../assets/images/exit-support-icon.png').default,
    },
  ];

  return (
    <div className="chats-io-menu-wrapper">
      {menus?.map((item) => (
        <div
          key={item.title}
          className={`menu-item ${item.disabled ? 'disabled' : ''}`}
          onClick={item.onClick}
        >
          <img src={item.icon} alt="" className="menu-icon" />
          <div className="menu-name">{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default ChatsIoMenu;
