import React from 'react';
import LandingLayout from '../../layouts/LandingLayout';
import PreRegisterSteps from '../../components/PreRegisterSteps';
import { AFFILIATE_BANK_APP_CODE } from '../../configs';

const PreRegisterPage = () => {
  return (
    <LandingLayout>
      <div className="login-page white">
        <div className="d-flex flex-column mx-auto">
          <div className="page-wrapper flex-fill d-flex flex-column justify-content-center my-5">
            <img
              className="broker-logo mx-auto"
              src={
                require('../../assets/images/affiliate-bank-icon.svg').default
              }
              alt="AffiliateApp™"
            />
            <div className="registration-form-view">
              <PreRegisterSteps appCode={AFFILIATE_BANK_APP_CODE} />
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PreRegisterPage;
