import React, { useContext } from 'react';
import { CRMContext } from '../../../contexts/CRMContext';
import TableSearchBar from '../TableSearchBar';
import FullScreenToggler from '../FullScreenToggler';

const TotalDigitalVolumeHeader = () => {
  const { isTableSearchOpen, isTableExpanded, expandTable } = useContext(
    CRMContext
  );

  return (
    <div className="details-table-item details-table-header scroll-shadow">
      {!isTableSearchOpen ? (
        <>
          <div
            className="flexed-item"
            style={{
              width: '3%'
            }}
          />
          <div
            className="flexed-item"
            style={{
              width: '24%',
              paddingLeft: '15px'
            }}
          >
            <label>Name</label>
          </div>

          <div
            className="flexed-item"
            style={{
              width: '18%'
            }}
          >
            <label>Date(EST)</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '12%'
            }}
          >
            <label>Coin</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '22%'
            }}
          >
            <label>Vault Asset Value</label>
          </div>
          <div
            className="flexed-item"
            style={{
              width: '16%'
            }}
          >
            <label>Interest Earned</label>
          </div>
        </>
      ) : (
        <div
          className="flexed-item"
          style={{
            width: '65%'
          }}
        />
      )}
      <FullScreenToggler
        isExpanded={isTableExpanded}
        expandTable={expandTable}
      />
      <TableSearchBar />
    </div>
  );
};

export default TotalDigitalVolumeHeader;
