import React, { useContext, useState } from 'react';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import MyProfileCover from '../../components/MyProfileCover';
import MainNavMenu from '../../components/MyProfileCover/MainNavMenu';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import Main from '../../components/ControllerFragments/Main';
import Training from '../../components/ControllerFragments/Training';
import Marketplace from '../../components/ControllerFragments/Marketplace';
import VirtualOTCDesk from '../../components/ControllerFragments/VirtualOTCDesk';

const ControllerPage = () => {
  const { activeMainNav } = useContext(BrokerAppContext);
  const [searchQuery, setSearchQuery] = useState('');

  let activeFragRef = null;

  switch (activeMainNav) {
    case 'Main':
      activeFragRef = <Main />;
      break;

    case 'Training':
      activeFragRef = (
        <Training categories={academyCategories} searchQuery={searchQuery} />
      );
      break;

    case 'Licence':
      activeFragRef = <Marketplace searchQuery={searchQuery} />;
      break;

    case 'OTC':
      activeFragRef = <VirtualOTCDesk />;
      break;

    default:
      activeFragRef = '';
  }

  return (
    <LayoutWithSideBar>
      <div className="broker-app-main-wrapper">
        <MyProfileCover>
          <MainNavMenu menus={mainMenus} />
        </MyProfileCover>
        <div className="broker-fragment-wrapper">{activeFragRef}</div>
      </div>
    </LayoutWithSideBar>
  );
};

export default ControllerPage;

const mainMenus = ['Main', 'Training', 'Licence', 'OTC'];

const academyCategories = [
  { title: 'All', count: 33 },
  {
    title: 'Get Started',
    count: 21,
  },

  { title: 'Crypto 101', count: 4 },
  { title: 'Road To $5000', count: 0 },
  { title: 'Master GX', count: 0 },
  { title: 'Crypto Influence', count: 0 },
];
