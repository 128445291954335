import React from 'react';

const FullLoadingComponent = () => (
  <div className="full-loading-wrapper">
    <img
      src={require('../assets/images/affliate-app-dark-full-logo.svg').default}
      alt=""
      className="full-loading-logo"
    />
  </div>
);

export default FullLoadingComponent;
