import SelectedProductDetails from './SelectedProductDetails';

const DeletedMessageView = ({
  backToEndorsement,
  onClose,
  selectedEndorsement,
  selectedProduct,
}) => (
  <>
    <SelectedProductDetails
      selectedEndorsement={selectedEndorsement}
      selectedProduct={selectedProduct}
    />
    <div className="confirm-message">
      You Have Successfuky Deleted Your Endorsement Of This Product
    </div>
    <div className="actions-container">
      <div className="action" onClick={backToEndorsement}>
        My Endorsements
      </div>
      <div className="action alt" onClick={onClose}>
        Back To Product Page
      </div>
    </div>
  </>
);

export default DeletedMessageView;
