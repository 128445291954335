const ProductDetails = ({
  rating,
  setRating,
  shortDesc,
  setShortDesc,
  longDesc,
  setLongDesc,
  showLinks,
  addintionalLinks,
  setAddintionalLinks,
}) => {
  const updateAddintionalLinks = (link, pos) => {
    try {
      const links = [...addintionalLinks];

      links[pos] = link;

      setAddintionalLinks(links);
    } catch (error) {}
  };

  const onPaste = async (pos) => {
    try {
      const copiedText = await navigator.clipboard.readText();

      updateAddintionalLinks(copiedText.trim(), pos);
    } catch (error) {}
  };

  return (
    <>
      <div className="scroll-item product-details">
        <div className="title">
          What Is The Overall Rating You Give This Product?
        </div>
        <div className="input-container">
          <input
            type="number"
            className="percentage-input"
            placeholder="0.00"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          />
          <div className="unit">10</div>
        </div>
      </div>
      <div className="scroll-item">
        <div className="title">
          Write A One Sentence Review About The Product
        </div>
        <input
          type="text"
          className="short-input"
          placeholder="Ex. This Is The Best Shampoo I Ever Used!!!"
          value={shortDesc}
          onChange={(e) => setShortDesc(e.target.value)}
        />
      </div>
      <div className="scroll-item">
        <div className="title">Write A Long Form Review About The Product</div>
        <textarea
          className="long-input"
          placeholder=""
          value={longDesc}
          onChange={(e) => setLongDesc(e.target.value)}
        />
      </div>
      {showLinks && (
        <div className="scroll-item">
          <div className="title">
            Add Any Additional Links You Want People To Click On
          </div>
          <div className="website-input">
            <input
              type="text"
              className="web-input"
              placeholder="Ex. www.youtube.com/review"
              value={addintionalLinks[0]}
              onChange={(e) => updateAddintionalLinks(e.target.value, 0)}
            />
            <img
              src={require('../../assets/images/paste-icon.svg').default}
              alt=""
              className="pate-icon"
              onClick={() => onPaste(0)}
            />
          </div>
          <div className="website-input">
            <input
              type="text"
              className="web-input"
              placeholder="Ex. www.youtube.com/review"
              value={addintionalLinks[1]}
              onChange={(e) => updateAddintionalLinks(e.target.value, 1)}
            />
            <img
              src={require('../../assets/images/paste-icon.svg').default}
              alt=""
              className="pate-icon"
              onClick={() => onPaste(1)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
