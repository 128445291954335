import axios from 'axios';
import React, { useEffect, useState } from 'react'
import LoadingAnimation from '../../components/LoadingAnimation';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

export default function WithdrawSelection({ setSelectedUserApp, }) {
    const [appsdata, setAppsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const getApps = async () => {
        setLoading(true);
        try {

            const email = await LocalStorageHelper.getAppEmail();

            const res = await axios.get(
                `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`,
            );

            setAppsData(res.data.userApps);
            setLoading(false);
            // setIsLoading(false);
        } catch (error) {
            console.error(error);
        }

    }
    useEffect(() => {
        getApps();
    }, []);

    return (
        <div className='withdraw_section_content'
            style={{
                paddingBottom: 10,
                height: '65%'
            }}>
            <div className='withdraw_common'>
                <div className='image_container'>
                    <img
                        style={{
                            height: 52.45,
                            width: 253.45,
                        }}
                        src={require('../../assets/mobileassets/affliate-bank-icon-full_blue.png').default} />


                </div>
                <div className='withdraw_selection_title'>
                    Where Do You Want To Send The Withdrawal?
                </div>
                {
                    loading ?
                        <LoadingAnimation dark /> :
                        <div className='withdraw_selection_apps_container'>
                            {
                                appsdata.map((item, key) => {
                                    return (
                                        <div key={key} className='withdraw_selection_app_card'
                                            onClick={() => {
                                                setSelectedUserApp(item);

                                            }}
                                        >
                                            <img src={item.app_icon}
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                    marginRight: 8,
                                                }} />
                                            <div className='withdraw_selection_app_card_title'>

                                                {item.app_name}
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )
}
