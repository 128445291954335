import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { APP_CODE, GX_API_ENDPOINT } from '../../../configs';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';

const HashForm = ({ selectedCoin }) => {
  const { walletCoinData } = useContext(BrokerAppContext);

  const [coinAddress, setCoinAddress] = useState('');
  const [hashInput, setHashInput] = useState('');
  const [addressQrOpen, setAddressQrOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (walletCoinData) {
      const ethCoinData = walletCoinData.find(
        (item) => item.coinSymbol === 'ETH',
      );
      const ethAddress = ethCoinData?.coin_address;

      setCoinAddress(ethAddress || '');
    }
  }, [walletCoinData]);

  const submitHandler = () => {
    if (!hashInput) {
      return toast.error('Please Enter The Hash');
    }

    setIsLoading(true);
    const email = LocalStorageHelper.getAppEmail();

    const postData = {
      app_code: APP_CODE,
      email,
      coin: selectedCoin.coinSymbol,
      txn_hash: hashInput,
      // amount: 0.5,
    };

    Axios.post(
      `${GX_API_ENDPOINT}/coin/vault/service/deposit/eth/coins/request`,
      postData,
    )
      .then((resp) => {
        const { data } = resp;
        // console.log('Hash Resp', data);

        if (data.status) {
          toast.success(data.message || '');
        } else {
          toast.error(data.message || '');
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="mt-5">
        <LoadingAnimation dark />
      </div>
    );
  }

  return (
    <div className="hash-form-container">
      <div className="headCoin">
        <img src={selectedCoin && selectedCoin.coinImage} alt="" />{' '}
        {selectedCoin.coinName}
      </div>
      <div className="steps">
        <h5>Step 1</h5>
        <p>
          Copy this {selectedCoin.coinName} address and input it as the
          destination address into which ever wallet you are currently using
        </p>
        <div className="boxView">
          <input
            type="text"
            className="ipVal"
            value={coinAddress || 'Address Not Available'}
            readOnly
          />
          <div
            className="boxBtn"
            onClick={() =>
              navigator.clipboard.writeText(selectedCoin.coin_address)
            }
          >
            <img
              src={require('../../../assets/images/clipIcons/qr.svg').default}
              alt=""
            />
          </div>
        </div>
        <h5>Step 2</h5>
        <p>
          Enter the transaction hash for the transfer for it to reflect into
          your InstaCrypto Vault.
        </p>
        <div className="boxView">
          <input
            type="text"
            className="ipVal"
            placeholder="Enter Hash Here"
            value={hashInput}
            onChange={(e) => setHashInput(e.target.value)}
          />
          <div
            className="boxBtn"
            onClick={() => {
              navigator.clipboard
                .readText()
                .then((clipText) => setHashInput(clipText));
            }}
          >
            <img
              src={
                require('../../../assets/images/clipIcons/paste.svg').default
              }
              alt=""
            />
          </div>
        </div>
        <div className="btnSubmit" onClick={submitHandler}>
          Submit Hash
        </div>
      </div>
    </div>
  );
};

export default HashForm;
