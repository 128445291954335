import React from 'react';
import { usdValueFormatter } from '../../utils';

export default function InterestEarningItem({ index, item, selectedFilter }) {
  let value = 0;

  switch (selectedFilter) {
    case 'All':
      value =
        (item?.commissions?.it_commission || 0) +
        (item?.commissions?.dt_commission || 0) +
        (item?.commissions?.mot_commission || 0);
      break;
    case 'DB':
      value = item?.commissions?.dt_commission || 0;
      break;
    case 'BC':
      value = item?.commissions?.it_commission || 0;
      break;
    case 'DBD':
      value = item?.commissions?.bd_commission || 0;
      break;
    case 'BDC':
      value = item?.commissions?.bd_v || 0;
      break;
    default:
      value =
        (item?.commissions?.it_commission || 0) +
        (item?.commissions?.dt_commission || 0) +
        (item?.commissions?.mot_commission || 0);
  }
  return (
    <div key={item._id || index.toString()} className="itemContainer">
      <div className="itemImageContainer">
        <img
          style={{
            width: 35,
            height: 35,
          }}
          src={
            item?.txn?.liquid_interest
              ? require('../../assets/mobileassets/liquid-earnings-icon.png')
              : require('../../assets/mobileassets/bonds-icon.png')
          }
          alt=""
        />
      </div>
      <div className="itemDetailsContainer">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className="txtname">{item?.txn?.email}</div>
          <div className="txtname">{`${usdValueFormatter.format(value)}`}</div>
        </div>
      </div>
      <div className="appname">
        {item?.txn?.liquid_interest
          ? `Interest On ${item?.txn?.coin} Holding`
          : `${item?.txn?.coin} Bond`}
      </div>
    </div>
  );
}
