import React, { useContext, useState } from 'react'
import AppLogo from '../../assets/images/affliate-app-dark-full-logo.svg';
import ButtonLoadingAnimation from '../../components/ButtonLoadingAnimation';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { APP_CODE, GX_API_ENDPOINT, GX_AUTH_URL } from '../../configs';
import { emailValidator } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import LoginLoadingAnimation from '../../components/LoginLoadingAnimation';
import { useHistory } from 'react-router-dom';

export default function MobileLogin() {
    const history = useHistory();
    const [passwordInput, setPasswordInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setRefreshData } = useContext(BrokerAppContext);


    const onFormSubmit = (event) => {
        if (event?.preventDefault) {
            event.preventDefault();
        }

        if (!isLoading) {
            const email = emailInput.toLowerCase().trim();

            if (!email) {
                toast.error('📧 Please enter your email');
            } else if (!emailValidator(email)) {
                toast.error('📧 Please enter a valid email');
            } else if (!passwordInput) {
                toast.error('🔑 Please enter your password');
            } else {
                setIsLoading(true);

                let postData = {
                    email,
                    password: passwordInput,
                };

                // if (show2FA) {
                //     postData = { ...postData, totp_code: otpInput };
                // }

                Axios.post('https://gxauth.apimachine.com/gx/user/login', postData)
                    .then((response) => {
                        const { data } = response;
                        if (data.mfa) {
                            // setShow2FA(true);
                            setIsLoading(false);
                        } else if (data.status) {
                            toast.success('✅ Logged in...');

                            Axios.post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
                                email,
                                app_code: APP_CODE,
                            }).then((profileResp) => {
                                // console.log('profileResp', profileResp.data);

                                if (profileResp.data.profile_id) {
                                    LocalStorageHelper.setProfileId(profileResp.data.profile_id);
                                    setRefreshData();
                                }
                            });

                            Axios.post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
                                email,
                            })
                                .then((res) => {
                                    LocalStorageHelper.setUserDetails(
                                        res.data.name,
                                        `0x${res.data.ETH_Address}`,
                                        res.data.affiliate_id,
                                    );
                                })
                                .catch((error) => {
                                    console.log('getUserDetails Error', error);
                                })
                                .finally(() => {
                                    LocalStorageHelper.setAppLoginData(
                                        data.idToken,
                                        data.accessToken,
                                        email,
                                    );
                                    history.push('network');
                                });
                        } else {
                            setIsLoading(false);
                            // if (show2FA) {
                            //     setOtpInput('');
                            // } else {
                            //     setPasswordInput('');
                            // }
                            toast.error(`❌ ${data.message}`);
                            console.log(data.message);
                        }
                    })
                    .catch((error) => {
                        console.log('Login Error', error);
                        setIsLoading(false);
                    });
            }
        } else {
            toast.warn('Logging in...');
        }
    };
    return (
        <>
            <div className="login_container">
                <form className='login_form' onSubmit={onFormSubmit}>
                    <img className="form_logo" src={AppLogo} alt="" />
                    <div className='form_input_wrapper'>
                        <input
                            type="text"
                            className="form_input"
                            placeholder="Email"
                            value={emailInput}
                            onChange={(e) => {
                                setEmailInput(e.target.value);
                            }}
                        />
                    </div>
                    <div className='form_input_wrapper'>
                        <input
                            type="password"
                            className="form_input"
                            placeholder="Password"
                            value={passwordInput}
                            onChange={(e) => {
                                setPasswordInput(e.target.value);
                            }}
                        />
                    </div>
                    <button className="login_button" type="submit">
                        {false ? 'Loggin In' : 'Login'}{' '}
                        {false && <ButtonLoadingAnimation />}
                    </button>
                    <div
                        className="reset"

                    >
                        <span style={{
                            fontWeight: "700"
                        }}>Click Here</span> To Reset Password
                    </div>

                </form>

            </div>

            {isLoading && (
                <div className="login_loader_container">
                    <LoginLoadingAnimation />
                    <div className="login_loader_text">Loading Up Your Affiliate App</div>
                </div>
            )}
        </>
    )
}
