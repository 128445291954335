import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../../configs';
import { timeParserExpanded } from '../../../utils';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import TxnItem from './TxnItem';
import TxnView from './TxnView';

const TxnsList = ({ selectedCoin, type, setSelectedCoin }) => {
  const [txnList, setTxnList] = useState('');

  const [selectedTransaction, setSelectedTransaction] = useState();

  useEffect(() => {
    const email = LocalStorageHelper.getAppEmail();

    let params = { email };

    if (selectedCoin !== 'All') {
      params = {
        ...params,
        coin: selectedCoin?.coinSymbol || '',
      };
    }

    Axios.get(
      `${GX_API_ENDPOINT}/coin/vault/service/path/${
        type === 'Add' ? 'deposit' : 'withdraw'
      }/txn/get`,
      {
        params,
      },
    )
      .then(({ data }) => {
        setTxnList(data.txns || []);

        // console.log('data.txns', data.txns);
      })
      .catch(error => {
        console.log('Error on getting transaction list', error);
      });
  }, [selectedCoin, type]);

  if (selectedTransaction) {
    return <TxnView txnData={selectedTransaction} />;
  }

  return (
    <>
      {selectedCoin !== 'All' && (
        <div className="coin-item" onClick={() => setSelectedCoin()}>
          <img src={selectedCoin?.coinImage} alt="" className="coin-icon" />
          <div className="coin-name">{selectedCoin?.coinName}</div>
        </div>
      )}
      <div className="header-container">
        <div className="header-text">Select Transaction</div>
      </div>
      <div className="txns-list">
        {txnList ? (
          txnList.length > 0 ? (
            txnList.map(item => (
              <TxnItem
                key={item._id}
                value={`${item.coin} ${
                  type === 'Add' ? 'Deposit' : 'Withdraw'
                }`}
                coin={item.coin}
                date={timeParserExpanded(item.timestamp)}
                onClick={() => setSelectedTransaction(item)}
              />
            ))
          ) : (
            <div className="empty-list-text">No Transaction Found</div>
          )
        ) : (
          <div className="loading-container">
            <LoadingAnimation dark />
          </div>
        )}
      </div>
    </>
  );
};

export default TxnsList;
