import React, { useState, useEffect } from 'react';
import SearchSuggestions from './SearchSuggestions';
import searchIcon from '../../assets/images/search-icon.svg';
import { searchSuggestionListCRM } from '../../configs';

const SearchBar = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  useEffect(() => {
    setSearchSuggestions([]);
    if (searchInput) {
      const suggestion = [];
      searchSuggestionListCRM.forEach(item => {
        if (item.title.toLowerCase().includes(searchInput.toLowerCase()))
          suggestion.push(item);
      });

      setSearchSuggestions([...suggestion]);
    }
  }, [searchInput]);

  return (
    <div className="tab-search-wrapper">
      <input
        type="text"
        className="search-input"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search Dashboards"
      />
      <img src={searchIcon} alt="" className="tab-search-icon" />
      <SearchSuggestions
        suggestions={searchSuggestions}
        setSearchInput={setSearchInput}
      />
    </div>
  );
};

export default SearchBar;
