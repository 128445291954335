import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../../configs';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';
import ViewHeader from './ViewHeader';

const UserLevels = ({ userQuery, selectedOption, onClose, onBack }) => {
  const [currentUserDetails, setCurrentUserDetails] = useState();
  const [uplineList, setUplineList] = useState();

  useEffect(() => {
    (async () => {
      let params;

      if (userQuery) {
        params = userQuery;
      } else {
        const email = LocalStorageHelper.getAppEmail();
        params = { email };
      }

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/stats/get/uplines`, {
          params,
        })
        .then(({ data }) => {
          // console.log('Daata', data);
          if (data.status) {
            setCurrentUserDetails(data.user || '');
            setUplineList(data.uplines || []);
          } else {
            toast.error(data.message);
            if (onClose) {
              onClose();
            }
          }
        })
        .catch(error => console.log('Error on locating user', error));
    })();
  }, [userQuery]);

  return (
    <div className="faq-form-container">
      <ViewHeader disableExpand onBack={onBack} />
      <div className="option-item">
        <img src={selectedOption?.icon} alt="" className="option-icon" />
        <div className="option-name">{selectedOption?.title}</div>
      </div>
      {currentUserDetails ? (
        <div className="list-container">
          <div className="list-title">The Selected User</div>
          <div className="user-container">
            <img
              src={currentUserDetails?.profile_img}
              alt=""
              className="user-image"
            />
            <div className="user-details">
              <div className="user-name">{currentUserDetails?.name}</div>
              <div className="user-email">{currentUserDetails?.email}</div>
            </div>
          </div>
          <div className="list">
            {uplineList?.map(item => (
              <React.Fragment key={item.email}>
                {item?.dds <= 1 && (
                  <div className="list-title">
                    {item?.dds === 0 ? 'Direct Upline' : 'Indirect Uplines'}
                  </div>
                )}
                <div className="user-container">
                  <img src={item?.profile_img} alt="" className="user-image" />
                  <div className="user-details">
                    <div className="user-name">{item?.name}</div>
                    <div className="user-email">{item?.email}</div>
                  </div>
                  <div className="user-dd">
                    <div className="dd-level">{item?.dds}</div>
                    <div className="dd-level-label">DD Level</div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <LoadingAnimation dark />
        </div>
      )}
    </div>
  );
};

export default UserLevels;
