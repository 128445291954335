import React from 'react';
import coin from '../../../assets/images/coin.svg';
import CADIcon from '../../../assets/images/cadIcon.png';
import {
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  usdValueFormatter
} from '../../../utils';

const TotalOTCVolumeItem = ({ data }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      {data.txn ? (
        <>
          <div className="flexed-item" style={{ width: '3%' }}>
            <img src={coin} alt="" height="30px" />
          </div>
          <div
            className="flexed-item"
            style={{ width: '25%', paddingLeft: '15px' }}
          >
            <label className="token-txn-name">{data.txn.name}</label>
          </div>

          <div className="flexed-item" style={{ width: '8%' }}>
            <img className="token-txn-country-icon" src={CADIcon} alt="" />
            {/* <label className="token-txn-country">
              {parseCountryFromAddress(data.txn.address)}
            </label> */}
          </div>
          <div className="flexed-item" style={{ width: '18%' }}>
            <label className="token-txn-date">
              {getDateFromTimeStamp(data.txn.timestamp)}
            </label>
            <label className="token-txn-time">
              {getTimeFromTimeStamp(data.txn.timestamp)}
            </label>
          </div>
          <div className="flexed-item" style={{ width: '24%' }}>
            <label className="token-txn-broker">{data.txn.upline_name}</label>
            <label className="token-txn-dd">DD : {data.commissions.ps}</label>
          </div>
          <div className="flexed-item" style={{ width: '17%' }}>
            <label className="token-txn-commission">
              {usdValueFormatter.format(data.txn.final_charged_amount)}
            </label>
          </div>
          <div className="flexed-item" style={{ width: '35px' }} />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default TotalOTCVolumeItem;
