const videoList = [
  {
    category: "Get Started",
    items: [
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/cu2T1KMpCZaIBJs4I9qz9DujV5g"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS5EQUE1NTFDRjcwMDg0NEMz",
        snippet: {
          publishedAt: "2020-01-09T10:55:31.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "What Is A Gx Broker   Nvest   Interview With CEO Shorupan Pirakaspathy",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBro...\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/186gG-5tQjk/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 0,
          resourceId: {
            kind: "youtube#video",
            videoId: "186gG-5tQjk"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/GRFezs1fjSHqfd_nGbxGYnE4S1Y"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS41MjE1MkI0OTQ2QzJGNzNG",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Global Xchange - Uber Of CoinBase - Gx Brokers $$$",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/8LR569H-wXU/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 1,
          resourceId: {
            kind: "youtube#video",
            videoId: "8LR569H-wXU"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/UrqlX7MO7osB8c1ROJx9PRkJUJs"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS45NDk1REZENzhEMzU5MDQz",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "How Much Does It Cost To Become A Gx Broker? - 1000 Gx Tokens",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/HGuGs3w3H0Q/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 2,
          resourceId: {
            kind: "youtube#video",
            videoId: "HGuGs3w3H0Q"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/lSZGT1Mp_61CK1R_l1Svdi5tqaE"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS5EMEEwRUY5M0RDRTU3NDJC",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Gx Broker Compensation Plan Explained - POWERFUL!!",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/GVutEs1-9r8/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 3,
          resourceId: {
            kind: "youtube#video",
            videoId: "GVutEs1-9r8"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/csWvERHWncJsRL309xVuRxSpwJM"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS41NkI0NEY2RDEwNTU3Q0M2",
        snippet: {
          publishedAt: "2020-01-06T09:42:46.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Gx Token Economics - Limited Gx Token Circulation = Limited Gx Brokers",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/M9t1SMa4iRg/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 4,
          resourceId: {
            kind: "youtube#video",
            videoId: "M9t1SMa4iRg"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/9lgt8gmWuqV1rLdVI-cNcI8JWPk"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS5DQUNERDQ2NkIzRUQxNTY1",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Gx Tokens - Primary vs Secondary Markets - Value Of Gx Tokens",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/oCfJCqNblkI/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 5,
          resourceId: {
            kind: "youtube#video",
            videoId: "oCfJCqNblkI"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/gvfPC5N-w0zReyQT1MWJ11H5cno"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS45RTgxNDRBMzUwRjQ0MDhC",
        snippet: {
          publishedAt: "2020-01-09T12:18:05.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Who Sets Fees For Gx Brokers   Bitcoin Crypto OTC Buying & Selling",
          description:
            "Exclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/1u0XPDNTEIA/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 6,
          resourceId: {
            kind: "youtube#video",
            videoId: "1u0XPDNTEIA"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/GDW4MLBKABdLBe3PpsGHuduYVaU"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS45ODRDNTg0QjA4NkFBNkQy",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "What Is The BrokerChain? - How Does It Pay Gx Brokers Infinite Levels??",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/Vmj_QqW4370/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 7,
          resourceId: {
            kind: "youtube#video",
            videoId: "Vmj_QqW4370"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/5PNAPgMjoojfAoktpP0i5hTw-pY"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4wMTcyMDhGQUE4NTIzM0Y5",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Gx Broker Dealer Sales - $100,000 Sale - $30,000 Commissions To Gx Broker",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/6UvuizeLK3A/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 8,
          resourceId: {
            kind: "youtube#video",
            videoId: "6UvuizeLK3A"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/ivn-oh6q5pNruePXJVg-BI3Lz98"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS5GNjNDRDREMDQxOThCMDQ2",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Gx Broker vs Gx Broker Dealer - Compensation Plan Math",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/1c-QsL41JDQ/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 9,
          resourceId: {
            kind: "youtube#video",
            videoId: "1c-QsL41JDQ"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/j8uBbB0Amg4PADPvXgVd2ynV-Gc"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4wOTA3OTZBNzVEMTUzOTMy",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Do We Need A License To Be A Gx Broker? - Global Xchange",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/zG-Vakn1OB4/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 10,
          resourceId: {
            kind: "youtube#video",
            videoId: "zG-Vakn1OB4"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/RJ5JxYOzPZfyYgMhl9-FaAfdANM"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4xMkVGQjNCMUM1N0RFNEUx",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Gx Broker - Career Of The New Era - Residual Crypto Income",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/gCaSf2t3vUo/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 11,
          resourceId: {
            kind: "youtube#video",
            videoId: "gCaSf2t3vUo"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/6Fp4Nbf-0TBSuN18wkfPjL6mokI"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS41MzJCQjBCNDIyRkJDN0VD",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Gx Brokers 📣The World Is Sleeping On Bitcoin & Crypto 📣Wake Up Gx Brokers !!",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/SHbbSwkeVZI/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 12,
          resourceId: {
            kind: "youtube#video",
            videoId: "SHbbSwkeVZI"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/798DxpWxplrE_2Pjr8a9q_jgdjo"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4yODlGNEE0NkRGMEEzMEQy",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "ONLY 450 GxT ~ $360 To Become A Gx Broker 🎁 Get Grandfathered In BEFORE December 31st",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/EDMjhQo60Po/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 13,
          resourceId: {
            kind: "youtube#video",
            videoId: "EDMjhQo60Po"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/eUsfpNR1fuSoMqVlB1ZkvgOvCGE"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS40NzZCMERDMjVEN0RFRThB",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "Gx Broker - Kickstart Bonus - 2x Commissions = 30% On Gx Token Sales",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/WQxCoXn5do0/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 14,
          resourceId: {
            kind: "youtube#video",
            videoId: "WQxCoXn5do0"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/0Vx9yC261G4EZGLZOKa4lMgAJ1Q"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4zMDg5MkQ5MEVDMEM1NTg2",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "The Market For Gx Brokers - A Vast Ocean Of Money $$$",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/ZwnhD8LxP6k/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 15,
          resourceId: {
            kind: "youtube#video",
            videoId: "ZwnhD8LxP6k"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/wCT2KZjf6D7nAAXdeO0ayLKYJM8"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS41Mzk2QTAxMTkzNDk4MDhF",
        snippet: {
          publishedAt: "2020-01-06T09:43:15.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "The Progression of The GX Broker Platform",
          description:
            "Join our Facebook family of Gx crypto brokers: \nhttps://www.facebook.com/groups/GXBrokers/\n\nIn a single sentence, just as Uber has allowed for the decentralization of the taxi industry Gx brokerage is allowing any crypto enthusiast to be their own full-service broker with their own book of clients that they will receive a lifetime residual income on!\n\n\nExclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/fPbL9BkRBpg/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 16,
          resourceId: {
            kind: "youtube#video",
            videoId: "fPbL9BkRBpg"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/cVBbaSeFZhHN8hfmTRMcWQiqEHE"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS41QTY1Q0UxMTVCODczNThE",
        snippet: {
          publishedAt: "2020-01-09T12:17:30.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Why Are You Not In Crypto Yet   3 Reasons For Gx Brokers",
          description:
            "Exclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/pXWFyvvhD5k/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 17,
          resourceId: {
            kind: "youtube#video",
            videoId: "pXWFyvvhD5k"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/_sLe9c26qav1k9U7oiJZJ1QXO8Q"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4yMUQyQTQzMjRDNzMyQTMy",
        snippet: {
          publishedAt: "2020-01-09T12:17:48.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Gx Tokens SOLD Out!   Get Ready Gx Brokers",
          description:
            "Exclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/IxYalEVs4HY/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 18,
          resourceId: {
            kind: "youtube#video",
            videoId: "IxYalEVs4HY"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/jTW3sQ1m4xyIQBL-rDR0sVaRVUw"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS5ENDU4Q0M4RDExNzM1Mjcy",
        snippet: {
          publishedAt: "2020-01-09T12:18:21.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title:
            "ONLY 450 GxT   $360 To Become A Gx Broker 🎁 Get Grandfathered In BEFORE December 31st",
          description:
            "Exclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/dZW7fM1AxIw/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 19,
          resourceId: {
            kind: "youtube#video",
            videoId: "dZW7fM1AxIw"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/aqV8omi8fRMds1JHis_DcpifnWI"',
        id:
          "UExLQ2VOWXZkT0VpZUZHczB0ZTJvamNpd2N4UHh4RUdYOS4yMDhBMkNBNjRDMjQxQTg1",
        snippet: {
          publishedAt: "2020-01-25T03:40:21.000Z",
          channelId: "UChqcSDo6sUzeR8rZfVLzM7A",
          title: "Beginner's Guide To Global Xchange - Why Become A Gx Broker?",
          description:
            "Exclusive Webinar Available At http://bit.ly/gxlivewebinar1\n\nThe greatest opportunity of your lifetime is underway. The digitization of financial assets will be the biggest wealth shift in a generation. Will you be partaking in it? \n\n» Subscribe to GXLive: http://bit.ly/328vNno\n\nAbout GXLive: GXLive is the community built on top of the GX operating system. It is a network of traders, data scientists, and analysts building to the new financial operating system.\n\nConnect with GXLive Online\nGet the latest news: http://live.globalxchange.com \nFollow GXLive on Facebook: http://bit.ly/GXLiveFB\nFollow GXLive on Twitter:https://twitter.com/GlobalXLive\nFollow GXLive on Instagram: https://instagram.com/gxlive\n\nDon't Get Left Behind | #SHIFTSHAPPEN\n#SHIFTSHAPPEN #GXISTHENEWFX #cryptocurrency #BecomeTheHouse",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/bqUm14p1Cp8/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "GXLive",
          playlistId: "PLKCeNYvdOEieFGs0te2ojciwcxPxxEGX9",
          position: 20,
          resourceId: {
            kind: "youtube#video",
            videoId: "bqUm14p1Cp8"
          }
        }
      }
    ]
  },
  { category: "Road To $5000", items: [] },
  {
    category: "Crypto 101",
    items: [
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/uzgy0jWOUhdhRib8kryAuZKUqsk"',
        id:
          "UEwtN0ZzbHZFX1lKMXdKcHZDa29CZVJGU2N5TFhNdnNwMS41NkI0NEY2RDEwNTU3Q0M2",
        snippet: {
          publishedAt: "2019-07-06T16:10:59.000Z",
          channelId: "UCET9I4Rs6_Sn4yZmek_8LLw",
          title:
            'Nvest CEO Explains "What Is Bitcoin?" | Part 1 - Introduction',
          description:
            "Bitcoin is one of the most important advancements of our lifetimes. Many people have heard things about Bitcoin. I have prepared this video series to provide an end to end explanation of Bitcoin. \n\nIn video one I give an introduction to Bitcoin as a concept. \nIn video two I give a high level overview of the technology that underpins Bitcoin.\nIn video three I address how Bitcoin derives it's value.\nIn video four I cover the immense societal and political implications which Bitcoin carries.  \n\nAbout This Channel: On this channel, I will release a series of web shows documenting my life, as well as elucidate the path of entrepreneurship which I have taken. Please refrain from subscribing if you don't like profanity or an excessive admiration of capitalism. \n\n» Subscribe to Shorupan's Youtube Channel: http://bit.ly/shorupanyoutube\n\nAbout Shorupan Pirakaspathy: I am a 23-year-old from Toronto Canada currently living between several countries. At 21 I co-founded the world's first retail cryptocurrency bank, and am currently the CEO of a 100+ person multinational holdings company focused on investing in Blockchain technologies. I host a series of web shows about finance, entrepreneurship, and Blockchain.",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/0xRQOC5XHWQ/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "Shorupan Pirakaspathy",
          playlistId: "PL-7FslvE_YJ1wJpvCkoBeRFScyLXMvsp1",
          position: 0,
          resourceId: {
            kind: "youtube#video",
            videoId: "0xRQOC5XHWQ"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/uHSUefKqyFqglzI9ASCIcg2i-6k"',
        id:
          "UEwtN0ZzbHZFX1lKMXdKcHZDa29CZVJGU2N5TFhNdnNwMS4yODlGNEE0NkRGMEEzMEQy",
        snippet: {
          publishedAt: "2019-07-06T16:10:59.000Z",
          channelId: "UCET9I4Rs6_Sn4yZmek_8LLw",
          title:
            'Nvest CEO Explains "What Is Bitcoin?" | Part 2 - Technology Overview',
          description:
            "Bitcoin is one of the most important advancements of our lifetimes. Many people have heard things about Bitcoin. I have prepared this video series to provide an end to end explanation of Bitcoin. \n\nIn video one I give an introduction to Bitcoin as a concept. \nIn video two I give a high level overview of the technology that underpins Bitcoin.\nIn video three I address how Bitcoin derives it's value.\nIn video four I cover the immense societal and political implications which Bitcoin carries.  \n\nAbout This Channel: On this channel, I will release a series of web shows documenting my life, as well as elucidate the path of entrepreneurship which I have taken. Please refrain from subscribing if you don't like profanity or an excessive admiration of capitalism. \n\n» Subscribe to Shorupan's Youtube Channel: http://bit.ly/shorupanyoutube\n\nAbout Shorupan Pirakaspathy: I am a 23-year-old from Toronto Canada currently living between several countries. At 21 I co-founded the world's first retail cryptocurrency bank, and am currently the CEO of a 100+ person multinational holdings company focused on investing in Blockchain technologies. I host a series of web shows about finance, entrepreneurship, and Blockchain.",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/q1tn1yKBRHs/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "Shorupan Pirakaspathy",
          playlistId: "PL-7FslvE_YJ1wJpvCkoBeRFScyLXMvsp1",
          position: 1,
          resourceId: {
            kind: "youtube#video",
            videoId: "q1tn1yKBRHs"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/37FFe0hvQt5lcciCfIHztu7_Vl0"',
        id:
          "UEwtN0ZzbHZFX1lKMXdKcHZDa29CZVJGU2N5TFhNdnNwMS4wMTcyMDhGQUE4NTIzM0Y5",
        snippet: {
          publishedAt: "2019-07-06T16:10:59.000Z",
          channelId: "UCET9I4Rs6_Sn4yZmek_8LLw",
          title: 'Nvest CEO Explains "What Is Bitcoin?" | Part 3 - Economics',
          description:
            "Bitcoin is one of the most important advancements of our lifetimes. Many people have heard things about Bitcoin. I have prepared this video series to provide an end to end explanation of Bitcoin. \n\nIn video one I give an introduction to Bitcoin as a concept. \nIn video two I give a high level overview of the technology that underpins Bitcoin.\nIn video three I address how Bitcoin derives it's value.\nIn video four I cover the immense societal and political implications which Bitcoin carries.  \n\nAbout This Channel: On this channel, I will release a series of web shows documenting my life, as well as elucidate the path of entrepreneurship which I have taken. Please refrain from subscribing if you don't like profanity or an excessive admiration of capitalism. \n\n» Subscribe to Shorupan's Youtube Channel: http://bit.ly/shorupanyoutube\n\nAbout Shorupan Pirakaspathy: I am a 23-year-old from Toronto Canada currently living between several countries. At 21 I co-founded the world's first retail cryptocurrency bank, and am currently the CEO of a 100+ person multinational holdings company focused on investing in Blockchain technologies. I host a series of web shows about finance, entrepreneurship, and Blockchain.",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/D3iUWSaRBL0/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "Shorupan Pirakaspathy",
          playlistId: "PL-7FslvE_YJ1wJpvCkoBeRFScyLXMvsp1",
          position: 2,
          resourceId: {
            kind: "youtube#video",
            videoId: "D3iUWSaRBL0"
          }
        }
      },
      {
        kind: "youtube#playlistItem",
        etag: '"Fznwjl6JEQdo1MGvHOGaz_YanRU/Ku-h79GTsIVVHGJfqEbUurI1bGk"',
        id:
          "UEwtN0ZzbHZFX1lKMXdKcHZDa29CZVJGU2N5TFhNdnNwMS41MjE1MkI0OTQ2QzJGNzNG",
        snippet: {
          publishedAt: "2019-07-06T16:10:59.000Z",
          channelId: "UCET9I4Rs6_Sn4yZmek_8LLw",
          title:
            'Nvest CEO Explains "What Is Bitcoin?" | Part 4 - What Bitcoin Means For Society',
          description:
            "Bitcoin is one of the most important advancements of our lifetimes. Many people have heard things about Bitcoin. I have prepared this video series to provide an end to end explanation of Bitcoin. \n\nIn video one I give an introduction to Bitcoin as a concept. \nIn video two I give a high level overview of the technology that underpins Bitcoin.\nIn video three I address how Bitcoin derives it's value.\nIn video four I cover the immense societal and political implications which Bitcoin carries.  \n\nAbout This Channel: On this channel, I will release a series of web shows documenting my life, as well as elucidate the path of entrepreneurship which I have taken. Please refrain from subscribing if you don't like profanity or an excessive admiration of capitalism. \n\n» Subscribe to Shorupan's Youtube Channel: http://bit.ly/shorupanyoutube\n\nAbout Shorupan Pirakaspathy: I am a 23-year-old from Toronto Canada currently living between several countries. At 21 I co-founded the world's first retail cryptocurrency bank, and am currently the CEO of a 100+ person multinational holdings company focused on investing in Blockchain technologies. I host a series of web shows about finance, entrepreneurship, and Blockchain.",
          thumbnails: {
            medium: {
              url: "https://i.ytimg.com/vi/N2MxwFc1bHI/mqdefault.jpg",
              width: 320,
              height: 180
            }
          },
          channelTitle: "Shorupan Pirakaspathy",
          playlistId: "PL-7FslvE_YJ1wJpvCkoBeRFScyLXMvsp1",
          position: 3,
          resourceId: {
            kind: "youtube#video",
            videoId: "N2MxwFc1bHI"
          }
        }
      }
    ]
  },
  { category: "Master GX", items: [] },
  { category: "Crypto Influence", items: [] }
];
export default videoList;
