import React, { useEffect, useState } from 'react';

const StepsSideBar = ({ stepsList, header = 'Registration', activeStep }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (activeStep && stepsList) {
      let i = 0;

      for (i = 0; i < stepsList.length; i++) {
        const x = stepsList[i];

        if (x.stepName === activeStep) {
          break;
        }
      }
      setActiveIndex(i);
    } else {
      setActiveIndex(0);
    }
  }, [stepsList, activeStep]);

  return (
    <div className="step-sidebar-container">
      <div className="steps-header">{header}</div>
      {stepsList?.map((item, index) => (
        <div
          key={item.id}
          className={`step-item ${activeIndex >= index ? 'active' : ''}`}
        >
          <div className="step-name">{item.title}</div>
          {activeIndex > index && (
            <img
              src={require('../../assets/images/check-colored.svg').default}
              alt=""
              className="checked-icon"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default StepsSideBar;
