import React from 'react';

const BrandCategories = ({
  activeNavMenu,
  activeBrandCategoriesList,
  activeBrandCategory,
  setActiveBrandCategory,
}) => {
  const onBrandSelect = item => {
    setActiveBrandCategory(item);
  };

  return (
    <div className="brands-categories-list">
      {activeNavMenu.title === 'Financial' ? (
        activeBrandCategoriesList &&
        activeBrandCategoriesList.map((item, index) => (
          <div
            key={item?.formData?.Name || index}
            className={`brand-item ${
              activeBrandCategory?.formData?.Name === item?.formData?.Name
                ? 'active'
                : ''
            } `}
            style={{
              marginRight: `${
                index === activeBrandCategoriesList.length - 1 ? '0' : '15'
              }px`,
            }}
            onClick={() => onBrandSelect(item)}
          >
            <img
              src={item?.formData?.icon || ''}
              alt=""
              className="brand-icon"
            />
            <div className="brand-name">{item?.formData?.Name || ''}</div>
          </div>
        ))
      ) : (
        <div> </div>
      )}
    </div>
  );
};

export default BrandCategories;
