import React from 'react';
import coin from '../../../assets/images/coin.svg';
import {
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  formatToExpValue,
  formatFromExpValues
} from '../../../utils';

const TotalDigitalVolumeItem = ({ data }) => {
  return (
    <>
      {data.txn ? (
        <div id="details-table-item" className="details-table-item">
          <div className="flexed-item" style={{ width: '3%' }}>
            <img src={coin} alt="" height="30px" />
          </div>
          <div
            className="flexed-item"
            style={{ width: '24%', paddingLeft: '15px' }}
          >
            <label className="token-txn-name">{data.txn.name}</label>
          </div>

          <div className="flexed-item" style={{ width: '18%' }}>
            <label className="token-txn-date">
              {getDateFromTimeStamp(data.txn.timestamp)}
            </label>
            <label className="token-txn-time">
              {getTimeFromTimeStamp(data.txn.timestamp)}
            </label>
          </div>
          <div className="flexed-item" style={{ width: '12%' }}>
            <label className="token-txn-date">{data.txn.coin}</label>
          </div>
          <div className="flexed-item" style={{ width: '22%' }}>
            <label className="token-txn-broker" style={{ fontSize: '1.4rem' }}>
              {data.txn.interest_calculated_on
                ? `${formatToExpValue(data.txn.interest_calculated_on)} ETH`
                : '0 ETH'}
            </label>
          </div>
          <div className="flexed-item" style={{ width: '16%' }}>
            <label
              className="token-txn-commission"
              style={{ fontSize: '1.4rem' }}
            >
              {data.txn.earned_interest
                ? `${formatFromExpValues(data.txn.earned_interest)} ETH`
                : '0 ETH'}
            </label>
          </div>
          <div className="flexed-item" style={{ width: '35px' }} />
        </div>
      ) : null}
    </>
  );
};

export default TotalDigitalVolumeItem;
