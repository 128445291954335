import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FullLoadingComponent from '../../components/FullLoadingComponent';
import LoadingAnimation from '../../components/LoadingAnimation';
import { GX_API_ENDPOINT } from '../../configs';
import { usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import BrokerEarningSection from '../components/BrokerEarningSection';
import CouncilOverridesScreen from '../components/CouncilOverridesScreen';
import MobileNav from '../components/MobileNav';
import TransactionList from '../components/TransactionList';
import WithdrawSection from '../components/WithdrawSection';

export default function AffilateBank() {
  const [affiliateBalance, setAffiliateBalance] = useState(0);
  const [isShowTotalBalance, setIsShowTotalBalance] = useState(false);
  const [withdrawableAffiliateBalance, setWithDrawableAffiliateBalance] =
    useState(0);
  const [loading, setLoading] = useState(false);
  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
  const [refreshAffiliateBalance, setRefreshAffiliateBalance] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isCouncilOverride, setisCouncilOverride] = useState(false);
  const [isBrokerEarning, setBrokerEarning] = useState(false);
  const [params, setParams] = useState('');
  const getAffiliateBalance = async () => {
    setLoading(true);
    const email = await LocalStorageHelper.getAppEmail();

    if (email) {
      axios
        .get(`${GX_API_ENDPOINT}/coin/vault/gxb/balance`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          setAffiliateBalance(data.gxbroker_account_balance || 0);
          setWithDrawableAffiliateBalance(data.gxbroker_balance || 0);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  useEffect(() => {
    getAffiliateBalance();
  }, []);

  useEffect(() => {
    if (refreshAffiliateBalance) {
      setLoading(true);
      getAffiliateBalance();
      setLoading(false);
      setRefreshAffiliateBalance(false);
    }
  }, [refreshAffiliateBalance]);

  return (
    <>
      <MobileNav />
      {isCouncilOverride ? (
        <CouncilOverridesScreen
          setisCouncilOverride={setisCouncilOverride}
          setParams={setParams}
          setBrokerEarning={setBrokerEarning}
        />
      ) : isBrokerEarning ? (
        <BrokerEarningSection
          params={params}
          setBrokerEarning={setBrokerEarning}
        />
      ) : loading ? (
        <LoadingAnimation dark />
      ) : (
        <>
          <div
            className="affiliate_container"
            style={{
              overflow: isWithdrawOpen ? 'hidden' : '',
            }}
          >
            <div
              style={{
                display: isSearchFocused || isFullScreen ? 'none' : '',
              }}
            >
              <div className="affiliate_head_container">
                <div
                  className="affiliate_head_text"
                  onClick={() => {
                    setIsShowTotalBalance(false);
                  }}
                  style={{
                    opacity: isShowTotalBalance ? 0.5 : 1,
                  }}
                >
                  Affiliate Balance
                </div>
                <div
                  className="affiliate_head_text"
                  onClick={() => {
                    setIsShowTotalBalance(true);
                  }}
                  style={{
                    opacity: !isShowTotalBalance ? 0.5 : 1,
                  }}
                >
                  Total Balance
                </div>
              </div>
              <div className="withdraw_container">
                <div className="withdrawBalance">
                  {isShowTotalBalance
                    ? affiliateBalance === ''
                      ? 'Calculating'
                      : usdValueFormatter.format(affiliateBalance)
                    : withdrawableAffiliateBalance === ''
                    ? 'Calculating'
                    : usdValueFormatter.format(withdrawableAffiliateBalance)}
                </div>
                <div
                  className="withdrawButton"
                  onClick={() => {
                    setIsWithdrawOpen(true);
                  }}
                >
                  Withdraw
                </div>
              </div>
              <div className="analyticsContainer">Advanced Analysis</div>
              <div className="controlWrapContainer">
                <div
                  className="controlContainer"
                  onClick={() => {
                    setParams('Direct');
                    setBrokerEarning(true);
                  }}
                >
                  <img
                    className="controlIcon"
                    src={
                      require('../../assets/mobileassets/direct-earning-icon.png')
                        .default
                    }
                  />
                  <div className="controlName">Direct</div>
                </div>
                <div
                  className="controlContainer"
                  onClick={() => {
                    setParams('Indirect');
                    setBrokerEarning(true);
                  }}
                >
                  <img
                    className="controlIcon"
                    src={
                      require('../../assets/mobileassets/indirect-earning-icon.png')
                        .default
                    }
                  />
                  <div className="controlName">Indirect</div>
                </div>
                <div className="controlContainer">
                  <img
                    className="controlIcon"
                    src={
                      require('../../assets/mobileassets/direct-earning-icon.png')
                        .default
                    }
                  />
                  <div
                    style={{
                      opacity: 0.3,
                    }}
                    className="controlName"
                  >
                    Custom
                  </div>
                </div>
                <div
                  className="controlContainer"
                  onClick={() => {
                    setisCouncilOverride(true);
                  }}
                >
                  <img
                    className="controlIcon"
                    src={
                      require('../../assets/mobileassets/direct-earning-icon.png')
                        .default
                    }
                  />
                  <div className="controlName">Council Overrides</div>
                </div>
              </div>
            </div>

            <TransactionList
              isSearchFocused={isSearchFocused}
              setIsSearchFocused={setIsSearchFocused}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
            />
          </div>
          <WithdrawSection
            setRefreshAffiliateBalance={setRefreshAffiliateBalance}
            setIsWithdrawOpen={setIsWithdrawOpen}
            isWithdrawOpen={isWithdrawOpen}
            withdrawableBalance={withdrawableAffiliateBalance}
          />
        </>
      )}
    </>
  );
}
