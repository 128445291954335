import React from 'react';
import { getUriImage } from '../../utils';

export default function VerticalItem({ item, onItemSelected, selectedItem }) {
  return (
    <div className="vertical_item">
      <div
        style={{
          display: 'flex',
        }}
      >
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: 10,
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          src={
            item.image ||
            getUriImage(item.profile_image) ||
            'https://i.pravatar.cc/110'
          }
          alt=""
        />
        <div className="nameContainer">{item.name || item.email}</div>
      </div>
      <div className="priceContainer">{`${item.txns || 0} Transactions`}</div>
    </div>
  );
}
