import React from 'react'
import MobileNav from '../components/MobileNav'

export default function Onhold() {
    return (
        <>
            <MobileNav />
            <div>Onhold</div></>
    )
}
