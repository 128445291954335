import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { APP_CODE, GX_API_ENDPOINT } from '../../../../configs';
import LocalStorageHelper from '../../../../utils/LocalStorageHelper';
import ProductItem from '../ProductItem';

const LegacyUpgrade = ({ onClose, setIsLoading }) => {
  const [productDetails, setProductDetails] = useState();

  const itemData = {
    product_id: 'ICBGXT937538117da0T1600185913470',
    product_name: 'InstaCrypto Brokerage Premium',
    product_icon: '',
    sub_text: "Get paid to sell crypto's supported by GX on InstaCrypto App",
    full_description:
      "Get paid to sell crypto's supported by GX on InstaCrypto App",
    name: 'InstaCrypto Brokerage Premium',
  };

  useEffect(() => {
    if (itemData) {
      const postData = {
        app_code: APP_CODE,
        product_id: itemData.product_id,
      };

      Axios.post(`${GX_API_ENDPOINT}/gxb/product/price/with/fees/get`, postData)
        .then(resp => {
          const { data } = resp;

          // console.log('data', data);
          setProductDetails(data.product || '');
        })
        .catch(error => {});
    }
  }, [itemData]);

  const makeGrandFathered = () => {
    setIsLoading(true);

    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();

    Axios.post(`${GX_API_ENDPOINT}/gxb/product/upgrade/user/license`, {
      email,
      token,
      product_id: 'ICBGXT937538117da0T1600185913470',
    })
      .then(resp => {
        const { data } = resp;

        // console.log('makeGrandFathered', data);

        if (data.status) {
          toast.success('Your Has Been Upgraded');
          onClose();
        } else {
          toast.error(data.message || 'Error On License Upgrade');
        }
      })
      .catch(error => {
        console.log('Error on legacy upgrade', error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="body-container">
      <div className="title">Final Overview</div>
      <div className="body">
        <div className="sub-header">
          You Will Be Granted This License Without Requirement To Pay The First
          Month Fee Or The Reoccurring Monthly Subscription. Your Grandfathered
          License Will Remain Active As Long As There Is No Interference WIth
          the Tokens From Your Original Staking Contract.
        </div>
        <ProductItem
          itemData={itemData}
          onPurchase={makeGrandFathered}
          purchaseBtnTitle="Redeem"
          alwaysOpen
        />
      </div>
    </div>
  );
};

export default LegacyUpgrade;
