import React from 'react';
import { toast } from 'react-toastify';

export default function MoneyMarketActionModal({
  selectedBrand,
  coin,
  setCoin,
  refetch,
  bonds,
  isMoneyMarketActionModel,
  setIsMoneyMarketActionModel,
  setIsMoneyMarketFeeUpdateScreen,
}) {
  return (
    <div
      className="mma_container"
      style={{
        display: isMoneyMarketActionModel ? 'flex' : 'none',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '40%',
        }}
        onClick={() => {
          setIsMoneyMarketActionModel(false);
        }}
      />
      <div
        className="mma_inner_container"
        style={{
          height: '60%',
        }}
      >
        <img
          src={selectedBrand.icon}
          style={{
            height: 50,
            width: '80%',
          }}
          alt=""
        />

        <div className="eam_question">What Would You Like To Do To</div>
        <div className="eam_pair">
          {coin} {bonds ? 'Bond' : 'MM'} Fees?
        </div>
        <div
          style={{
            opacity: 0.6,
          }}
          className="mma_btnAction"
        >
          Analyze Exchange Rate
        </div>
        <div
          onClick={() => {
            setIsMoneyMarketActionModel(false);
            setIsMoneyMarketFeeUpdateScreen(true);
          }}
          className="mma_btnAction"
        >
          Change Fee For This Asset
        </div>
        <div
          style={{
            opacity: 0.6,
          }}
          className="mma_btnAction"
        >
          Change Fee For All Asset
        </div>
      </div>
    </div>
  );
}
