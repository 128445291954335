import Axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { APP_CODE, GX_API_ENDPOINT } from '../../../../../configs';
import LocalStorageHelper from '../../../../../utils/LocalStorageHelper';
import ReviewUpgrade from './ReviewUpgrade';
import UpgradingLicense from './UpgradingLicense';
import BuyCoinSelector from '../BuyCoinSelector';
import OverView from './OverView';
import LoadingAnimation from '../../../../LoadingAnimation';
import CompleteView from '../CompleteView';

const LicenseUpgrade = ({ onClose, purchaseData, userAllLicense }) => {
  const [activeView, setActiveView] = useState();
  const [upgradingLicense, setUpgradingLicense] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [statsData, setStatsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const makePurchase = async (stats, coin) => {
    // console.log('upgradingLicense', upgradingLicense);
    // console.log('purchaseData', purchaseData);

    setIsLoading(true);

    const email = LocalStorageHelper.getAppEmail();
    const token = LocalStorageHelper.getAppToken();
    const profileId = LocalStorageHelper.getProfileId();

    const postData = {
      email,
      token,
      current_license_code: upgradingLicense.license_code,
      upgrade_to_product_id: purchaseData.product_id,
      user_pay_coin: coin || selectedAsset.coinSymbol,
      stats: stats || false,
      app_code: APP_CODE,
      profile_id: profileId,
    };

    // console.log('PostData', postData);

    Axios.post(`${GX_API_ENDPOINT}/gxb/product/license/upgrade`, postData)
      .then(resp => {
        const { data } = resp;

        // console.log('makePurchase', data);

        if (data.status) {
          if (stats) {
            setStatsData(data);
            setActiveView('Overview');
          } else {
            setActiveView('Completed');
          }
        } else {
          toast.error(data.message || 'Error on license upgrade');
        }
      })
      .catch(error => {
        console.log('Error on upgrading', error);
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <div className="d-flex flex-column flex-fill justify-content-center">
        <LoadingAnimation dark />
      </div>
    );
  }

  switch (activeView) {
    case 'ReviewUpgrade':
      return (
        <ReviewUpgrade
          purchaseData={purchaseData}
          statsData={statsData}
          onNext={() => setActiveView('CoinSelect')}
        />
      );
    case 'CoinSelect':
      return (
        <BuyCoinSelector
          onNext={item => {
            setSelectedAsset(item);
            setIsLoading(true);
            setActiveView('Overview');
            makePurchase(true, item?.coinSymbol);
          }}
          title="Which Of Your Vaults Do You Want To Use To Pay The Difference?"
        />
      );

    case 'Overview':
      return (
        <OverView
          statsData={statsData}
          selectedAsset={selectedAsset}
          purchaseData={purchaseData}
          onNext={() => makePurchase(false)}
        />
      );

    case 'Completed':
      return (
        <CompleteView
          onClose={onClose}
          purchaseData={purchaseData}
          selectedAsset={selectedAsset}
        />
      );

    default:
      return (
        <UpgradingLicense
          userAllLicense={userAllLicense}
          setUpgradingLicense={setUpgradingLicense}
          onNext={() => setActiveView('ReviewUpgrade')}
        />
      );
  }
};

export default LicenseUpgrade;
