import { useRef, useState } from 'react';
import JsonWebToken from 'jsonwebtoken';
import {
  BRAIN_API_ENDPOINT,
  BRAIN_PATH,
  BRAIN_SECRET,
  EMAIL_DEV,
} from '../../configs';
import axios from 'axios';
import CloseBtn from './CloseBtn';

const ProductImageUploader = ({ images, setImages }) => {
  const updateImageLink = (link, pos) => {
    const list = [...images];

    if (pos >= 0) {
      list.splice(pos, 1);
    } else {
      list.push(link);
    }

    setImages(list);
  };

  return (
    <div className="product-images">
      <div className="title">
        Please Upload Four Images Of You Using The Product
      </div>
      <div className="images-container">
        <DragAndDropImage
          pos={0}
          link={images[0]}
          updateImageLink={updateImageLink}
        />
        <DragAndDropImage
          pos={1}
          link={images[1]}
          updateImageLink={updateImageLink}
        />
        <DragAndDropImage
          pos={2}
          link={images[2]}
          updateImageLink={updateImageLink}
        />
        <DragAndDropImage
          pos={3}
          link={images[3]}
          updateImageLink={updateImageLink}
        />
      </div>
    </div>
  );
};

const DragAndDropImage = ({ pos, updateImageLink, link }) => {
  const [isUploading, setIsUploading] = useState(false);

  const dragRef = useRef();

  const renameFile = (originalFile, newName) =>
    new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setIsUploading(true);
      const fileName = `${new Date().getTime()}${selectedFile.name.substr(
        selectedFile.name.lastIndexOf('.'),
      )}`;
      const file = renameFile(selectedFile, fileName);

      const formData = new FormData();
      formData.append('files', file);

      const token = JsonWebToken.sign(
        { name: fileName, email: EMAIL_DEV },
        BRAIN_SECRET,
        {
          algorithm: 'HS512',
          expiresIn: 240,
          issuer: 'gxjwtenchs512',
        },
      );

      axios
        .post(`${BRAIN_API_ENDPOINT}/file/dev-upload-file`, formData, {
          params: { email: EMAIL_DEV, path: BRAIN_PATH, token, name: fileName },
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then(({ data }) => {
          // setImageLink(data.payload.url);
          updateImageLink(data.payload.url);
        })
        .catch((err) => {
          console.log('Error on uploding', err);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const onRemoveImage = () => {
    // setImageLink('');
    updateImageLink('', pos);
  };

  return (
    <div
      ref={dragRef}
      className="image-item"
      style={link || isUploading ? { background: 'white' } : {}}
    >
      {link ? (
        <>
          <img src={link} alt="" className="image-preview" />
          <CloseBtn onClose={onRemoveImage} />
        </>
      ) : (
        <input
          type="file"
          className="drag-input"
          accept="image/png, image/gif, image/jpeg"
          onChange={onFileChange}
          style={{ display: isUploading ? 'none' : 'block' }}
        />
      )}
      {isUploading && <div className="loading-text">Uploading Photo</div>}
    </div>
  );
};

export default ProductImageUploader;
