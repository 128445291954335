import React, { useState } from 'react';
import ChainView from '../../components/ChainView';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';

const ChainViewPage = () => {
  const [viewingUser, setViewingUser] = useState('');

  return (
    <LayoutWithSideBar>
      <ChainView viewingUser={viewingUser} setViewingUser={setViewingUser} />
    </LayoutWithSideBar>
  );
};

export default ChainViewPage;
