import React from 'react';
import OtpInput from 'react-otp-input';

const OTPVerifyForm = ({
  onReSend,
  onNext,
  otpInput,
  setOtpInput,
  isCustomMail,
  email,
}) => {
  return (
    <div className="view-container">
      <div className="sub-text">
        {isCustomMail
          ? `Enter The Code That Was Sent To ${email}`
          : 'Verifying BlockCheck ID'}
      </div>
      <div className="d-flex flex-column actions-container">
        <div className="input-container border-0 p-0 mb-3">
          <OtpInput
            value={otpInput}
            onChange={otp => setOtpInput(otp)}
            numInputs={6}
            containerStyle="otp-container"
            inputStyle="input-field otp-input"
            shouldAutoFocus
          />
        </div>
        <div className="form-buttons mt-5">
          <div className="action-outlined" onClick={onReSend}>
            Resend Code
          </div>
          <div className="action-filled" onClick={onNext}>
            Create
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerifyForm;
