import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import Moment from 'moment-timezone';
import LoadingAnimation from '../../components/LoadingAnimation';
import { usdValueFormatter } from '../../utils';
import UserStatsSheet from './UserStatsSheet';

export default function BrokerTransactionList({ hidden, selectedUser, type }) {
  const [totalTransaction, setTotalTransaction] = useState();
  const [filteredList, setFilteredList] = useState();
  const [parsedList, setParsedList] = useState();
  const [isStatsOpen, setIsStatsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const email = await LocalStorageHelper.getAppEmail();

      axios
        .get(`${GX_API_ENDPOINT}/brokerage/stats/otc_txns`, {
          params: { email },
        })
        .then((resp) => {
          const { data } = resp;

          if (data.status) {
            if (type === 'Direct') {
              setTotalTransaction(data.direct);
              setFilteredList(data.direct);
            } else {
              setTotalTransaction(data.indirect);
              setFilteredList(data.indirect);
            }
          }
        })
        .catch((error) => {
          console.log('Error on getting transactions');
        });
    })();
  }, [type]);
  useEffect(() => {
    if (selectedUser && totalTransaction) {
      if (
        selectedUser.name === 'All Directs' ||
        selectedUser.name === 'All Indirect'
      ) {
        setFilteredList(totalTransaction);
      } else {
        const filtered = totalTransaction.filter(
          (item) => item.txn.email === selectedUser.email,
        );

        setFilteredList(filtered);
      }
    }
  }, [selectedUser, totalTransaction]);
  useEffect(() => {
    const tempList = filteredList || [];

    const addedList = [];

    const parsed = [];

    tempList.forEach((tmpItem) => {
      if (!addedList.includes(tmpItem)) {
        const date = Moment(tmpItem.txn.timestamp);
        const subList = [];
        addedList.push(tmpItem);
        subList.push(tmpItem);

        tempList.forEach((item) => {
          if (!addedList.includes(item)) {
            const itemDate = Moment(item.txn.timestamp);
            if (date.isSame(itemDate, 'day')) {
              addedList.push(item);
              subList.push(item);
            }
          }
        });

        parsed.push({
          title: date.format('dddd MMMM Do YYYY'),
          data: subList,
        });
      }
    });
    setParsedList(parsed);
  }, [filteredList]);
  const onStatsClick = () => {
    setIsStatsOpen(true);
  };

  return (
    <div
      className="bokertransactionList_container"
      style={{
        display: hidden ? 'none' : '',
        overflow: hidden ? 'hidden' : '',
      }}
    >
      {filteredList ? (
        <>
          <div className="btl_header_container">
            {selectedUser && selectedUser.email ? (
              <div
                className="btl_stats"
                onClick={() => {
                  onStatsClick();
                }}
              >
                {type === 'Direct'
                  ? `${
                      selectedUser.name ? selectedUser.name.split(' ')[0] : ''
                    }'s Stats`
                  : 'Brokerage Stats'}
              </div>
            ) : (
              <div className="btl_header">
                {selectedUser && selectedUser.email
                  ? `${
                      selectedUser.name ? selectedUser.name.split(' ')[0] : ''
                    }'s`
                  : `${type} Customer`}{' '}
                Transactions
              </div>
            )}
          </div>
          <div className="btl_sectionList">
            <div
              style={{
                marginBottom: 5,
                marginTop: 0,
                height: '30vh',
                overflow: 'scroll',
              }}
            >
              {parsedList.length > 0 ? (
                parsedList.map((item, index) => {
                  return (
                    <>
                      <div className="transactiongroup">{item.title}</div>
                      {item?.data.map((item, key) => {
                        return (
                          <div key={key} className="transaction_item">
                            <div className="transaction_txnName">
                              {`${
                                item && item.txn && item.txn.name
                                  ? item.txn.name.split(' ')[0]
                                  : ''
                              } Did A ${item?.txn?.purchased}`}
                            </div>
                            <div className="txnCommination">
                              {`You Made ${usdValueFormatter.format(
                                item?.commissions?.dt_commission || 0,
                              )} USD`}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <div
                  className="not"
                  style={{
                    display: 'flex',
                    paddingTop: '10%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No Transaction Found
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadingAnimation dark />
        </div>
      )}
      <UserStatsSheet
        isBottomSheetOpen={isStatsOpen}
        setIsBottomSheetOpen={setIsStatsOpen}
        data={selectedUser}
      />
    </div>
  );
}
