import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GX_API_ENDPOINT } from '../../configs';
import NumberToWord from 'number-to-words';
import { usdValueFormatter } from '../../utils';
import LoadingAnimation from '../LoadingAnimation';

const TopEarners = ({ activeNavMenu, activeBrandCategory }) => {
  const [otcLeads, setOtcLeads] = useState();
  const [tokensLeads, setTokensLeads] = useState();

  useEffect(() => {
    Axios.get(`${GX_API_ENDPOINT}/brokerage/get/top/revenue/brokers`)
      .then((resp) => {
        const { data } = resp;

        // console.log('Resp On Leads', data);

        if (data.status) {
          setOtcLeads(data.otcData);
          setTokensLeads(data.tokenData);
        } else {
          setOtcLeads([]);
          setTokensLeads([]);
        }
      })
      .catch((error) => {
        setOtcLeads([]);
        setTokensLeads([]);
        console.log('Error On', error);
      });
  }, []);

  return (
    <div className="brand-list-container">
      <div className="list-header">
        <div className="header">Top Earners</div>
        <div className="bread-crumbs">
          <div className="item">{activeNavMenu?.title}</div>
          <i className="fa fa-angle-right" aria-hidden="true" />
          <div className="item">{activeBrandCategory?.formData?.Name}</div>
        </div>
      </div>
      {activeBrandCategory?.formData?.Name === 'Cryptocurrency' ? (
        <div className="list">
          {otcLeads ? (
            otcLeads.map((item, index) => (
              <div key={item._id} className="list-item">
                <img
                  src={
                    item?.profile_img ||
                    require('../../assets/images/app-icon.png').default
                  }
                  alt=""
                  className="item-icon"
                />
                <div className="item-details">
                  <div className="item-name-container">
                    <div className="item-name">{`${NumberToWord.toOrdinal(
                      index + 1,
                    )} Place`}</div>
                    <div className="numbers">
                      {usdValueFormatter.format(item.total_commissions || 0)}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <LoadingAnimation dark />
          )}
        </div>
      ) : (
        <div className="empty-container">
          <h6 className="text-center">
            {`Top Earners Cannot Be Calculated For ${
              activeBrandCategory?.formData?.Name || ''
            }`}
          </h6>
        </div>
      )}
    </div>
  );
};

export default TopEarners;
