import React from 'react';
import coin from '../../../assets/images/coin.svg';
import CADIcon from '../../../assets/images/cadIcon.png';
import {
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  usdValueFormatter
} from '../../../utils';

const DirectOTCRevenueItem = ({ data }) => {
  return (
    <div id="details-table-item" className="details-table-item">
      <div className="flexed-item" style={{ width: '3%' }}>
        <img src={coin} alt="" height="30px" />
      </div>
      <div
        className="flexed-item"
        style={{ width: '26%', paddingLeft: '15px' }}
      >
        <label className="token-txn-name">{data.txn.name}</label>
      </div>

      <div className="flexed-item" style={{ width: '8%' }}>
        <img className="token-txn-country-icon" src={CADIcon} alt="" />
        {/* <label className="token-txn-country">
              {parseCountryFromAddress(data.txn.address)}
            </label> */}
      </div>
      <div className="flexed-item" style={{ width: '18%' }}>
        <label className="token-txn-date">
          {getDateFromTimeStamp(data.txn.timestamp)}
        </label>
        <label className="token-txn-time">
          {getTimeFromTimeStamp(data.txn.timestamp)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '10%' }}>
        <label className="token-txn-date">{'BTC'}</label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-commission">
          {data.commissions.com
            ? usdValueFormatter.format(data.commissions.com)
            : usdValueFormatter.format(0)}
        </label>
        <label className="token-txn-dd">{`${data.txn.broker_percentage} %`}</label>
      </div>
      <div className="flexed-item" style={{ width: '15%' }}>
        <label className="token-txn-commission">
          {usdValueFormatter.format(data.commissions.dt_commission)}
        </label>
      </div>
      <div className="flexed-item" style={{ width: '35px' }} />
    </div>
  );
};

export default DirectOTCRevenueItem;
