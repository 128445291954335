
import React from 'react';

const VideoCard = ({ videoData, onClick }) => {
  return (
    <div className="col-md-3">
      <div className="videoItem">
        <a className="thumbnail" onClick={() => onClick(videoData)}>
          <img src={videoData.snippet.thumbnails.medium.url} alt="" />
          <div className="play" />
        </a>
        <p className="title">{videoData.snippet.title}</p>
        <p className="author">{videoData.snippet.channelTitle}</p>
      </div>
    </div>
  );
};

export default VideoCard;
