import React, { useState, useEffect } from 'react';
import VideoCard from './VideoCard';
import VideoPlayer from './VideoPlayer';
import videoList from './videoList';

const VideoGallery = ({ active, searchQuery }) => {
  let activeList = [];

  if (active !== 'All') {
    activeList = [
      ...activeList,
      ...videoList.find(item => item.category === active).items
    ];
  } else {
    videoList.forEach(item => {
      activeList = [...activeList, ...item.items];
    });
  }

  if (searchQuery !== '') {
    activeList = activeList.filter(item =>
      item.snippet.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const [activeVideo, setActiveVideo] = useState(null);
  const [nextVideos, setNextVideos] = useState(null);

  const videoClickHandler = videoData => {
    document.getElementById('scroll-wrapper').scrollTop = 0;
    setActiveVideo(videoData);
    const pos = activeList.indexOf(videoData) + 1;
    if (pos >= activeList.length) {
      setNextVideos(activeList.slice(0, 1));
    } else {
      setNextVideos(activeList.slice(pos, pos + 2));
    }
  };

  useEffect(() => {
    setActiveVideo(activeList[0]);
    setNextVideos(activeList.slice(1, 3));
    return () => {};
  }, [active, searchQuery]);

  console.log('Active VideoList', activeList);

  return (
    <div className="col-md-12">
      {activeList.length > 0 ? (
        <>
          <VideoPlayer
            activeVideo={activeVideo}
            nextVideos={nextVideos}
            onClick={videoClickHandler}
          />
          <div className="row mx-0 mt-5">
            {activeList.map(item => (
              <VideoCard
                key={item.id}
                videoData={item}
                onClick={videoClickHandler}
              />
            ))}
          </div>
        </>
      ) : (
        <h2 className="text-center">No videos found...</h2>
      )}
    </div>
  );
};

export default VideoGallery;
