import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../configs';
import PopupModalLayout from '../../layouts/PopupModalLayout';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import LoadingAnimation from '../LoadingAnimation';
import DeleteConfirm from './DeleteConfirm';
import DeletedMessageView from './DeletedMessageView';
import MarkupEditor from './MarkupEditor';
import ProductImageUploader from '../AddEndorsementPopup/ProductImageUploader';
import ExtraRatings from '../AddEndorsementPopup/ExtraRatings';
import ProductDetails from '../AddEndorsementPopup/ProductDetails';

const EditEndorsementPopup = ({
  selectedEndorsement,
  onClose,
  updatedEndores,
  selectedProduct,
  backToEndorsement,
}) => {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isDeleteCompleted, setIsDeleteCompleted] = useState(false);

  const [newPercentageText, setNewPercentageText] = useState('');
  const [images, setImages] = useState([]);
  const [extraRatings, setExtraRatings] = useState([]);
  const [overallRating, setOverallRating] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [addintionalLinks, setAddintionalLinks] = useState(['', '']);

  useEffect(() => {
    if (!selectedEndorsement) {
      setIsLoading(false);
      setIsShowConfirm(false);
      setIsDeleteCompleted(false);
      setActiveTab(TABS[0]);
      setNewPercentageText('');
      setImages([]);
      setOverallRating('');
      setShortDesc('');
      setLongDesc('');
      setExtraRatings([]);
      setAddintionalLinks(['', '']);
    }
  }, [selectedEndorsement]);

  useEffect(() => {
    if (selectedEndorsement?.additional_data) {
      setNewPercentageText(
        (selectedEndorsement?.fee_percentage || 0).toString(),
      );
      const addintionalData = selectedEndorsement.additional_data;

      setImages(addintionalData.images || []);
      setOverallRating(addintionalData.overallrating || '');
      setShortDesc(addintionalData.reviewstatement || '');
      setLongDesc(addintionalData.longdescription || '');
      setAddintionalLinks(addintionalData.additionalLinks || ['', '']);

      const subRatings = (addintionalData.subRatings || []).map((item) => ({
        rating: String(item.rating || 0),
        title: item.value,
      }));
      setExtraRatings(subRatings);
    }
  }, [selectedEndorsement]);

  const deletedEndorsement = () => {
    setIsLoading(true);

    const postData = {
      email: LocalStorageHelper.getAppEmail(),
      token: LocalStorageHelper.getAppToken(),
      product_id: selectedProduct.product_id,
      app_code: selectedEndorsement.app_code,
    };

    axios
      .post(`${GX_API_ENDPOINT}/gxb/product/user/fee/delete/per/app`, postData)
      .then(({ data }) => {
        if (data.status) {
          updatedEndores();
          setIsDeleteCompleted(true);
        } else {
          toast.error(data.message || 'Endorsement Delete Failed');
        }
      })
      .catch((error) => {
        console.log('Error deleting', error);
      })
      .finally(() => setIsLoading(false));
  };

  const updateEdorsment = () => {
    const percentage = parseFloat(newPercentageText.trim());
    if (Number.isNaN(percentage)) {
      return toast.error('Please input a valid percentage');
    }
    if (images.length !== 4) {
      return toast.error('Please upload atleast 4 images');
    }
    if (!overallRating || overallRating > 10) {
      return toast.error('Please set overall product ratings');
    }
    if (!shortDesc) {
      return toast.error('Please input short review');
    }
    if (!longDesc) {
      return toast.error('Please input long review');
    }
    if (extraRatings.length < 3) {
      return toast.error('Please input atleast 3 subratings');
    }

    setIsLoading(true);

    const subRatings = extraRatings.map((item) => ({
      rating: parseFloat(item.rating) || 0,
      value: item.title,
    }));

    const additionalData = {
      overallrating: overallRating,
      reviewstatement: shortDesc,
      longdescription: longDesc,
      images,
      additionalLinks: addintionalLinks,
      subRatings,
    };

    const postData = {
      email: LocalStorageHelper.getAppEmail(),
      token: LocalStorageHelper.getAppToken(),
      product_id: selectedProduct.product_id,
      app_code: selectedEndorsement.app_code,
      fee_percentage: percentage,
      additional_data: additionalData,
    };

    // console.log('Post Data', postData);

    axios
      .post(`${GX_API_ENDPOINT}/gxb/product/user/fee/set/per/app`, postData)
      .then(({ data }) => {
        if (data.status) {
          updatedEndores();
          onClose();
        } else {
          toast.error(data.message || 'Endorsement Failed');
        }
      })
      .catch((error) => {
        console.log('Error', error);
        toast.error('Network Error');
      })
      .finally(() => setIsLoading(false));
  };

  const renderFragments = () => {
    switch (activeTab.title) {
      case 'Markup':
        return (
          <MarkupEditor
            newPercentageText={newPercentageText}
            selectedEndorsement={selectedEndorsement}
            setNewPercentageText={setNewPercentageText}
            selectedProduct={selectedProduct}
          />
        );
      case 'Content':
        return (
          <div className="edit-components">
            <ProductImageUploader images={images} setImages={setImages} />
          </div>
        );
      case 'Ratings':
        return (
          <div className="edit-components scroll">
            <ExtraRatings
              extraRatings={extraRatings}
              setExtraRatings={setExtraRatings}
              noLinks
            />
          </div>
        );
      case 'Reviews & Links':
        return (
          <div className="edit-components scroll">
            <ProductDetails
              rating={overallRating}
              setRating={setOverallRating}
              shortDesc={shortDesc}
              setShortDesc={setShortDesc}
              longDesc={longDesc}
              setLongDesc={setLongDesc}
              showLinks
              addintionalLinks={addintionalLinks}
              setAddintionalLinks={setAddintionalLinks}
            />
          </div>
        );
      default:
    }
  };

  return (
    <PopupModalLayout
      isOpen={!!selectedEndorsement}
      onClose={onClose}
      noHeader
      style={{ padding: 0 }}
      width={700}
    >
      <div className="edit-endorsement-modal add-endorsement-modal">
        {isLoading ? (
          <div className="flex-fill d-flex flex-column justify-content-center">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <div className="tabs-container">
              {TABS.map((item) => (
                <div
                  key={item.title}
                  className={`tab-item ${
                    item.title === activeTab.title ? 'active' : ''
                  }`}
                  onClick={() => setActiveTab(item)}
                >
                  {item.title}
                </div>
              ))}
            </div>
            {isDeleteCompleted ? (
              <DeletedMessageView
                backToEndorsement={backToEndorsement}
                onClose={onClose}
                selectedEndorsement={selectedEndorsement}
                selectedProduct={selectedProduct}
              />
            ) : isShowConfirm ? (
              <DeleteConfirm
                onBack={() => setIsShowConfirm(false)}
                deletedEndorsement={deletedEndorsement}
                selectedEndorsement={selectedEndorsement}
                selectedProduct={selectedProduct}
              />
            ) : (
              <>
                {renderFragments()}
                <div className="actions-container">
                  <div
                    className="action"
                    onClick={() => setIsShowConfirm(true)}
                  >
                    Delete Endorsement
                  </div>
                  <div className="action alt" onClick={updateEdorsment}>
                    Complete Edit
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </PopupModalLayout>
  );
};

export default EditEndorsementPopup;

const TABS = [
  { title: 'Markup' },
  { title: 'Content' },
  { title: 'Ratings' },
  { title: 'Reviews & Links' },
];
