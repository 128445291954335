import React, { useContext, useEffect } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import DirectUsersHeader from '../../TableHeaders/DirectUsersHeader';
import DirectUserItem from '../../TableItems/DirectUserItem';

const DirectBroker = () => {
  const {
    directBrokersList,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(directBrokersList);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <DirectUsersHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <DirectUserItem key={index} data={item} />
      ))}
    </>
  );
};

export default DirectBroker;
