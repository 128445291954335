import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ENGAGEMENT_APP_CODE } from '../configs';
import LoginPage from '../pages/EngagementApp/LoginPage';
import PreRegisterPage from '../pages/EngagementApp/PreRegisterPage';
import RegistrationRouter from './RegistrationRouter';

const EngagementRouter = () => {
  const APP_DATA = {
    headerLogo: require('../assets/images/engagement-app-logo.svg').default,
    appCode: ENGAGEMENT_APP_CODE,
  };

  return (
    <BrowserRouter basename="apps/engagement">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/pre-registration" component={PreRegisterPage} />
        <RegistrationRouter basename="apps/broker" appData={APP_DATA} />
      </Switch>
    </BrowserRouter>
  );
};

export default EngagementRouter;
