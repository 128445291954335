import React, { useContext, useEffect } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import TotalDigitalVolumeHeader from '../../TableHeaders/TotalDigitalVolumeHeader';
import TotalDigitalVolumeItem from '../../TableItems/TotalDigitalVolumeItem';

const BrokerageMoneyMarketEarnings = () => {
  const {
    brokerageMarket,
    setCurrentSets,
    currentDataSet,
    currentPage,
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(brokerageMarket);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <TotalDigitalVolumeHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <TotalDigitalVolumeItem key={index} data={item} />
      ))}
    </>
  );
};

export default BrokerageMoneyMarketEarnings;
