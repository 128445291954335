import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MobileRoutes from '../MobileRoutes';
import AffiliateBankRouter from './AffiliateBankRouter';
import BrokerRouter from './BrokerRouter';
import EmpoweredRouter from './EmpoweredRouter';
import EngagementRouter from './EngagementRouter';

const AppsRouter = () => {
  return (
    <BrowserRouter basename="apps">
      <Switch>
        {
          window.innerWidth <= 576 ?
            <Route path="/affiliate" component={MobileRoutes} />
            :
            <Route path="/affiliate" component={BrokerRouter} />

        }
        <Route path="/engagement" component={EngagementRouter} />
        <Route path="/affiliatebank" component={AffiliateBankRouter} />
        <Route path="/empowered" component={EmpoweredRouter} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppsRouter;
