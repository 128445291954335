import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import BrandItem from './BrandItem';

const BrandsList = ({ activeNavMenu, activeBrandCategory, setShowDetails }) => {
  const [brandList, setBrandList] = useState();

  useEffect(() => {
    Axios.get('https://teller2.apimachine.io/admin/allBankers')
      .then(resp => {
        const { data } = resp;
        if (data.status) {
          setBrandList(data.data);
          // console.log('data', data);
        } else {
          setBrandList([]);
        }
      })
      .catch(error => {
        setBrandList([]);
      });
  }, []);

  return (
    <div
      className="brand-list-container"
      style={{ borderRight: '1px solid #E7E7E7' }}
    >
      <div className="list-header">
        <div className="header">Brands</div>
        <div className="bread-crumbs">
          <div className="item">{activeNavMenu?.title}</div>
          <i className="fa fa-angle-right" aria-hidden="true" />
          <div className="item">{activeBrandCategory?.formData?.Name}</div>
        </div>
      </div>
      {activeBrandCategory?.formData?.Name === 'Cryptocurrency' ? (
        <div className="list">
          {brandList &&
            brandList.map(item => (
              <BrandItem
                key={item._id}
                count={0}
                icon={item.profilePicURL}
                name={item.bankerTag}
                bankerEmail={item?.email}
                onClick={() => setShowDetails(item)}
              />
            ))}
        </div>
      ) : (
        <div className="empty-container">
          <h6 className="text-center">
            {`There Are Currently Zero ${activeBrandCategory?.formData?.Name ||
              ''} Brands`}
          </h6>
        </div>
      )}
    </div>
  );
};

export default BrandsList;
