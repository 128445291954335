import React from 'react';
import LandingLayout from '../../layouts/LandingLayout';
import brokerLogo from '../../assets/images/affliate-app-dark-full-logo.svg';
import PreRegisterSteps from '../../components/PreRegisterSteps';

const PreRegisterPage = () => {
  return (
    <LandingLayout>
      <div className="login-page white">
        <div className="d-flex flex-column mx-auto">
          <div className="page-wrapper flex-fill d-flex flex-column justify-content-center my-5">
            <img
              className="broker-logo mx-auto"
              src={brokerLogo}
              alt="AffiliateApp™"
            />
            <div className="registration-form-view">
              <PreRegisterSteps />
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PreRegisterPage;
