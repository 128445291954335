import React, { useContext } from 'react';
import { BrokerAppContext } from '../../../../../contexts/BrokerAppContext';
import { formatterHelper } from '../../../../../utils';

const OverView = ({ statsData, selectedAsset, purchaseData, onNext }) => {
  const { walletCoinData } = useContext(BrokerAppContext);

  let licenseCoin = '';

  if (walletCoinData) {
    licenseCoin =
      walletCoinData.find(
        x => x.coinSymbol === purchaseData?.first_purchase?.coin,
      ) || '';
  }

  return (
    <div className="body-container">
      <div className="title">Final Overview</div>
      <div className="body">
        <div className="sub-header">Total DIfference In Issuance Fees</div>
        <div className="drop-down-item balance-view">
          <img src={licenseCoin?.coinImage} alt="" className="item-image" />
          <div className="item-name">{licenseCoin?.coinSymbol}</div>
          <div className="item-span">
            {formatterHelper(
              statsData?.diff_initialAmount || 0,
              licenseCoin?.coinSymbol,
            )}{' '}
            {licenseCoin?.coinSymbol}
          </div>
        </div>
        <div className="sub-header">
          Difference In Subscription For The Rest Of The Month
        </div>
        <div className="drop-down-item balance-view">
          <img src={licenseCoin?.coinImage} alt="" className="item-image" />
          <div className="item-name">{licenseCoin?.coinSymbol}</div>
          <div className="item-span">
            {formatterHelper(
              statsData?.diff_subscriptionAmount || 0,
              licenseCoin?.coinSymbol,
            )}{' '}
            {licenseCoin?.coinSymbol}
          </div>
        </div>
        <div className="sub-header">Cost Of Upgrading In Bitcoin</div>
        <div className="drop-down-item balance-view">
          <img src={selectedAsset?.coinImage} alt="" className="item-image" />
          <div className="item-name">{selectedAsset?.coinSymbol}</div>
          <div className="item-span">
            {formatterHelper(
              statsData?.finalFromAmount || 0,
              selectedAsset?.coinSymbol,
            )}{' '}
            {selectedAsset?.coinSymbol}
          </div>
        </div>
      </div>
      <div onClick={onNext || null} className="action-button">
        Proceed
      </div>
    </div>
  );
};

export default OverView;
