const CloseBtn = ({ onClose, size = 22 }) => (
  <div
    className="close-btn"
    onClick={onClose}
    style={{ width: size, height: size, top: -size / 2, right: -size / 2 }}
  >
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="close-icon"
      style={{ width: size / 2, height: size / 2 }}
    >
      <path
        d="M5.31585 4.5L8.83087 0.984965C9.05632 0.759561 9.05641 0.394586 8.83089 0.169128C8.60545 -0.0563468 8.24051 -0.0563995 8.01503 0.169111L4.5 3.68414L0.984967 0.169128C0.759563 -0.0563292 0.39457 -0.0563995 0.169113 0.169093C-0.0563621 0.39455 -0.0563797 0.759473 0.169113 0.984948L3.68411 4.5L0.169095 8.01501C-0.0558524 8.23996 -0.0558524 8.60596 0.169095 8.83087C0.394535 9.05634 0.759492 9.05638 0.98495 8.83087L4.49996 5.31582L8.01498 8.83083C8.24038 9.05631 8.60538 9.05632 8.83082 8.83085C9.05629 8.60541 9.05634 8.24047 8.83083 8.01496L5.31585 4.5Z"
        fill="#08152D"
      />
      <path
        d="M5.31585 4.5L8.83087 0.984965C9.05633 0.759561 9.05641 0.394586 8.83089 0.169128C8.60545 -0.0563467 8.24051 -0.0563995 8.01503 0.169111L4.5 3.68413V5.31587L8.01501 8.83088C8.24042 9.05636 8.60541 9.05638 8.83085 8.8309C9.05633 8.60546 9.05638 8.24052 8.83087 8.01501L5.31585 4.5Z"
        fill="#08152D"
      />
    </svg>
  </div>
);

export default CloseBtn;
