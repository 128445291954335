import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginFrom from '../../components/LoginFrom';
import { EMPOWERED_APP_CODE } from '../../configs';
import LandingLayout from '../../layouts/LandingLayout';

const LoginPage = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  return (
    <LandingLayout className="white">
      <div className="page-wrapper">
        <img
          className="broker-logo"
          src={require('../../assets/images/empowered-icon.svg').default}
          alt="AffiliateApp™"
        />
        <div className="login-actions">
          {!isLoginOpen && !isSignupOpen && (
            <>
              <div
                className="actions-btn"
                onClick={() => {
                  setIsLoginOpen(true);
                  setIsSignupOpen(false);
                }}
              >
                Login
              </div>
              <div className="separator" />
              <Link
                to="/pre-registration"
                className="actions-btn"
                onClick={() => {
                  setIsLoginOpen(false);
                  setIsSignupOpen(true);
                }}
              >
                I Am Pre-Registered
              </Link>
              <div className="separator" />
              <Link
                to="/registration"
                className="actions-btn"
                onClick={() => {
                  setIsLoginOpen(false);
                  setIsSignupOpen(true);
                }}
              >
                Create New
              </Link>
            </>
          )}
          {isLoginOpen && <LoginFrom appCode={EMPOWERED_APP_CODE} />}
        </div>
      </div>
    </LandingLayout>
  );
};

export default LoginPage;
