import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

function ChatLearnSelectCategory({ categories, setCategory }) {
  return (
    <div className="chatLearn">
      <div className="pubHead">Select One Of The Following Categories</div>
      <Scrollbars className="categoryList">
        {categories.map((cat, index) => (
          <div
            key={`${cat._id || ''}`}
            className="categoryItm"
            onClick={() => setCategory(cat)}
          >
            <img src={cat.thumbnail} alt="" className="thumb" />
            <div className="textContent">
              <div className="pubTitle">{cat.title}</div>
              <div className="pubDesc">{cat.cv}</div>
            </div>
          </div>
        ))}
      </Scrollbars>
    </div>
  );
}

export default ChatLearnSelectCategory;
