import axios from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { GX_API_ENDPOINT } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

async function updateRateApi(params) {
  const token = LocalStorageHelper.getAppToken();

  const { data } = await axios.post(
    `${GX_API_ENDPOINT}/coin/iced/user/set/interest/fees`,
    {
      token,
      ...params,
    },
  );

  if (data?.status) {
    return data;
  } else {
    return toast.error('Plz Logout And Login Again');
  }
}

export default function MoneyMarketFeeUpdateScreen({
  selectedBrand,
  coin,
  refetch,
  bonds,
  setIsMoneyMarketFeeUpdateScreen,
}) {
  const userEmail = LocalStorageHelper.getAppEmail();

  const [value, setValue] = useState('');
  const handleValidation = (value) => {
    if (value === '0' || value === '0.00') {
      return true;
    }
    if (isNaN(parseFloat(value)) || !parseFloat(value) || value.includes('-')) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (handleValidation(value)) {
      updateRate({
        email: userEmail,
        interest_fee_data: [
          {
            coin: coin,
            liquid: bonds ? undefined : value,
            iced: bonds ? value : undefined,
          },
        ],
      });
    } else {
      toast.error('Please Input A Valid Value');
    }
  };
  const { mutate: updateRate, isLoading } = useMutation(updateRateApi, {
    onSuccess: () => {
      toast.success('Rate Updated');
      refetch();
      setIsMoneyMarketFeeUpdateScreen(false);
    },
  });

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            paddingLeft: 15,
          }}
        >
          <div
            onClick={() => {
              setIsMoneyMarketFeeUpdateScreen(false);
            }}
            className="back_button"
          >
            Back
          </div>
        </div>
      )}

      <>
        {isLoading ? (
          <>
            <div
              style={{
                width: '100%',
                height: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div
                className="full-loading-wrapper"
                style={{
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="full-loading-logo"
                >
                  <img
                    style={{
                      width: 50,
                      height: 50,
                    }}
                    src={selectedBrand?.halfIcon}
                    alt=""
                  />
                  <div className="loading_title">{selectedBrand.title}</div>
                </div>

                <div className="updating_text">Updating Fees For {coin}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              src={selectedBrand?.icon}
              style={{
                height: 50,
                width: '65%',
                marginTop: 40,
                marginBottom: 40,
              }}
              alt=""
            />
            <div className="exchangeFee_inputwrap">
              <div className="input_text_wrap">{coin}</div>
              <input
                className="exchangeFee_input"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                placeholder="0.00%"
              />
            </div>

            <div
              style={{
                width: '90%',
                borderRadius: 15,
                textAlign: 'center',
                marginTop: '20%',
              }}
              className="login_button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </div>
          </>
        )}
      </>
    </div>
  );
}
