import React from 'react';
import BrandController from './BrandController';
import ExchangeFeePairScreen from './ExchangeFeePairScreen';
import MoneyMarketFeeScreen from './MoneyMarketFeeScreen';

export default function BrandControllerScreen({
  selectedBrand,
  setIsBrandController,
  setComplanFeePairScreen,
  complanFeePairScreen,
}) {
  return (
    <>
      {complanFeePairScreen[0] === 'ExchangeFeePairScreen' ? (
        <ExchangeFeePairScreen
          complanFeePairScreen={complanFeePairScreen}
          setComplanFeePairScreen={setComplanFeePairScreen}
        />
      ) : complanFeePairScreen[0] === 'MoneyMarketFeeScreen' ||
        complanFeePairScreen[0] === 'Bonds' ? (
        <MoneyMarketFeeScreen
          complanFeePairScreen={complanFeePairScreen}
          setComplanFeePairScreen={setComplanFeePairScreen}
        />
      ) : (
        <div className="brandcontroller_container">
          <div className="brandcontroller_header_container">
            <div className="brandcontroller_icon_container">
              <img
                src={selectedBrand?.halfIcon}
                style={{
                  width: 33.11,
                  height: 33.04,
                }}
                alt=""
              />
              <div className="brandcontroller_brand_title">
                {selectedBrand.title}
              </div>
            </div>

            <img
              style={{ width: 15, height: 15 }}
              src={
                require('../../assets/mobileassets/close-icon-grey.png').default
              }
              alt=""
              onClick={() => {
                setIsBrandController(false);
              }}
            />
          </div>
          <BrandController
            setComplanFeePairScreen={setComplanFeePairScreen}
            selectedBrand={selectedBrand}
          />
        </div>
      )}
    </>
  );
}
