import React, { useState } from 'react';
import Licenses from './Licenses';
import ProfileView from './ProfileView';

const BrandDetailsSideBar = ({ selectedBrand }) => {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  // console.log('selectedBrand', selectedBrand);

  const color = selectedBrand?.colorCode
    ? selectedBrand?.colorCode[0] === '#'
      ? selectedBrand?.colorCode
      : `#${selectedBrand?.colorCode}`
    : '#08152D';

  let activeView;

  switch (activeTab) {
    case 'About':
      activeView = <ProfileView selectedBrand={selectedBrand} color={color} />;
      break;

    case 'Licenses':
      activeView = <Licenses selectedBrand={selectedBrand} color={color} />;
      break;

    default:
      activeView = <ProfileView selectedBrand={selectedBrand} color={color} />;
  }

  return (
    <div className="brand-details-sidebar">
      <div className="header-container">
        <img src={selectedBrand.profilePicURL} alt="" className="header-logo" />
        <div className="header-title" style={{ color }}>
          {selectedBrand.bankerTag}
        </div>
        <div className="follow-button" style={{ backgroundColor: color }}>
          Follow
        </div>
      </div>
      <div className="tab-container">
        {TABS.map(item => (
          <div
            key={item}
            className={`tab-item ${activeTab === item ? 'active' : ''}`}
            onClick={() => setActiveTab(item)}
            style={{ borderBottomColor: color }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="fragment-view">{activeView}</div>
    </div>
  );
};

export default BrandDetailsSideBar;

const TABS = ['About', 'Opportunity', 'Licenses'];
