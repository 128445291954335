import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APP_CODE, GX_API_ENDPOINT } from '../../../configs';
import LocalStorageHelper from '../../../utils/LocalStorageHelper';
import LoadingAnimation from '../../LoadingAnimation';

const UserList = () => {
  const [searchInput, setSearchInput] = useState('');
  const [usersList, setUsersList] = useState('');
  const [filteredList, setFilteredList] = useState('');
  const [isLoading, setIsLoading] = useState('');

  useEffect(() => {
    (async () => {
      Axios.get(`${GX_API_ENDPOINT}/gxb/apps/users/get`, {
        params: { app_code: APP_CODE },
      })
        .then(resp => {
          const { data } = resp;
          console.log('Users List', data.users);
          setUsersList(data.users || []);
        })
        .catch(error => {
          console.log('Error on getting AppList', error);
        });
    })();
  }, []);

  useEffect(() => {
    const searchQuery = searchInput.trim().toLowerCase();

    if (usersList) {
      const list = usersList.filter(
        item =>
          item?.email?.toLowerCase()?.includes(searchQuery) ||
          item?.name?.toLowerCase()?.includes(searchQuery) ||
          item?.username?.toLowerCase()?.includes(searchQuery),
      );

      setFilteredList(list);
    }
  }, [searchInput, usersList]);

  const onItemSelected = async item => {
    setIsLoading(true);

    Axios.post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
      email: item?.email || '',
      app_code: APP_CODE,
    })
      .then(({ data }) => {
        if (data.profile_id) {
          LocalStorageHelper.setAdminView(
            item?.email || '',
            data?.profile_id || '',
          );
          window.location.reload();
        }
      })
      .catch(error => {
        console.log('Error on admin menu', error);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="flex-fill d-flex flex-column justify-content-center align-content-center">
        <LoadingAnimation dark />
      </div>
    );
  }

  return (
    <div className="admin-users-list">
      <div className="title">All Users</div>
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Type In The Username"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
        />
        <div className="search-category">Username</div>
      </div>
      <div className="list-container">
        {filteredList ? (
          filteredList.map(item => (
            <div onClick={() => onItemSelected(item)} className="user-item">
              <img src={item.profile_img} alt="" className="user-img" />
              <div className="d-flex flex-column">
                <div className="user-name">{item.name}</div>
                <div className="user-email">{item.email}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex-fill d-flex flex-column justify-content-center align-content-center">
            <LoadingAnimation dark />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
