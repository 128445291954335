import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { APPS_LIST } from '../configs';
import SiteLayout from '../layouts/SiteLayout';
import ReactTooltip from 'react-tooltip';

const AppDetailsPage = ({ appData }) => {
  const history = useHistory();

  return (
    <SiteLayout noHeader>
      <div className="app-details-page-wrapper">
        <div className="app-switcher-container">
          <div className="scroll-container">
            {APPS_LIST.map(item => (
              <Link
                to={item.appPath}
                key={item.appName}
                className={`app-switch-item ${
                  appData?.appName === item.appName ? 'active' : ''
                }`}
                data-tip={item?.appName}
              >
                <img src={item.icon} alt="" className="app-icon" />
              </Link>
            ))}
            <ReactTooltip
              place="right"
              type="dark"
              effect="solid"
              border
              borderColor="#343C5B"
              backgroundColor="#fff"
              textColor="#343C5B"
              className="tooltip"
            />
          </div>
        </div>
        <div className="app-details-wrapper mx-0">
          <div className="app-details-container">
            <img src={appData?.logo} alt="" className="app-logo" />
            <div className="app-header">{appData?.headerText}</div>
            <div className="app-desc">{appData?.desc}</div>
            <div className="action-container">
              <div
                to={appData?.loginPath}
                className="action-button"
                onClick={() => history.push(appData?.loginPath)}
              >
                Get Started
              </div>
            </div>
          </div>
          <div className="px-0 d-flex flex-column overflow-hidden">
            <div className="cover-container">
              <img src={appData?.cover} alt="" className="cover-img" />
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default AppDetailsPage;
