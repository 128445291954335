import React, { useEffect, useContext } from 'react';
import { CRMContext } from '../../../contexts/CRMContext';

const ComingSoon = () => {
  const { setCurrentSets } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets({ list: [] });
  }, []);

  return (
    <div className="h-100 d-flex">
      <h2 className="m-auto text-center text-white">Coming Soon...</h2>
    </div>
  );
};

export default ComingSoon;
