import React, { useContext } from 'react';
import ChatContext from '../../contexts/ChatContext';
import ActionAdd from './ActionAdd';
import AdminMenu from './AdminMenu';
import BotLineage from './BotLineage';
import BotsActions from './BotsActions';
import BotTransactions from './BotTransactions';
import FiatFunding from './FiatFunding';
import Learn from './Learn';
import LicensesBot from './LicensesBot';

const BotFragment = () => {
  const { botActiveCategory, botActiveSubCategory, setIsBot } = useContext(
    ChatContext,
  );

  const getComponentOver = () => {
    // console.log('botActiveCategory', botActiveCategory);
    // console.log('botActiveSubCategory', botActiveSubCategory);

    switch (botActiveCategory?.title) {
      case 'My Licenses':
        return <LicensesBot />;

      case 'My Network':
        switch (botActiveSubCategory?.title) {
          case 'Lineage':
            return <BotLineage />;
          default:
            return <div className="empty" onClick={() => setIsBot(false)} />;
        }

      case 'Transactions':
        switch (botActiveSubCategory?.title) {
          case 'Add':
          case 'Send':
            return <BotTransactions type={botActiveSubCategory?.title || ''} />;
          default:
            return <div className="empty" onClick={() => setIsBot(false)} />;
        }

      case 'Actions':
        switch (botActiveSubCategory.title) {
          case 'Add':
            return <ActionAdd />;

          case 'Fiat Funding':
            return <FiatFunding />;

          default:
            return <div className="empty" onClick={() => setIsBot(false)} />;
        }

      case 'Learn':
        return <Learn />;

      case 'Admin':
        return <AdminMenu />;

      default:
        return <div className="empty" onClick={() => setIsBot(false)} />;
    }
  };

  return (
    <div className="position-relative d-flex flex-fill flex-column">
      <BotsActions setIsBot={setIsBot} />
      <div className="px-4 flex-fill d-flex flex-column pt-3">
        {getComponentOver()}
      </div>
    </div>
  );
};

export default BotFragment;
