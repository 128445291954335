import React, { useContext, useState, useEffect } from 'react';
import { CRMContext } from '../../contexts/CRMContext';
import searchIcon from '../../assets/images/search-table-icon.svg';

const TableSearchBar = () => {
  const { setTableSearchOpen, filterDataSet } = useContext(CRMContext);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    filterDataSet(searchQuery);
  }, [searchQuery]);

  return (
    <div className="search-wrapper">
      <input
        id="search-box"
        type="text"
        className="search-box"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        onFocus={() => setTableSearchOpen(true)}
        onBlur={() => setTableSearchOpen(false)}
      />
      <label htmlFor="search-box">
        <img className="search-icon" src={searchIcon} alt="" height={20} />
      </label>
    </div>
  );
};

export default TableSearchBar;
