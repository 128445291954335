import React, { useContext, useEffect } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import DirectDigitalRevenueHeader from '../../TableHeaders/DirectDigitalRevenueHeader';
import DirectDigitalRevenueItem from '../../TableItems/DirectDigitalRevenueItem';

const DirectDigitalTransactionalVolume = () => {
  const {
    directDigitalVolume,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(directDigitalVolume);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <DirectDigitalRevenueHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <DirectDigitalRevenueItem key={index} data={item} />
      ))}
    </>
  );
};

export default DirectDigitalTransactionalVolume;
