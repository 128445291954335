import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LandingSearch from '../components/LandingSearch';
import Typer from '../components/Typer';
import { GX_API_ENDPOINT } from '../configs';
import SiteLayout from '../layouts/SiteLayout';
import NavBar from  "../components/NavItem"
import Banner from "../components/Banner"
const SiteLadingPage = () => {
  const [latestQuickCommit, setLatestQuickCommit] = useState();

  useEffect(() => {
    axios
      .get(`${GX_API_ENDPOINT}/gxb/apps/mobile/app/links/logs/get`, {
        params: { app_code: 'broker_app' },
      })
      .then(({ data }) => {
        // console.log('App Logs', data);

        const app = data.logs[0] || null;

        setLatestQuickCommit(app);
      })
      .catch((error) => {});
  }, []);

  const onAppInstallerClick = (isAndroid) => {
    if (latestQuickCommit) {
      const link = isAndroid
        ? latestQuickCommit?.android_app_link || ''
        : latestQuickCommit?.ios_app_link || '';
      const win = window.open(link, '_blank');
      if (win != null) {
        win.focus();
      }
    } else {
      axios
        .get(`${GX_API_ENDPOINT}/gxb/apps/mobile/app/links/logs/get`, {
          params: { app_code: 'broker_app' },
        })
        .then(({ data }) => {
          // console.log('App Logs', data);

          const app = data.logs[0] || null;

          const link = isAndroid
            ? app?.android_app_link || ''
            : app?.ios_app_link || '';
          const win = window.open(link, '_blank');
          if (win != null) {
            win.focus();
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <>
    <Banner/>
    <NavBar/>
    {/* <SiteLayout> */}
      <div className="landing-wrapper">
        <div className="landing-container">
          <div className="details-container">
            <div className="header-text">
              Affiliate Gets You Paid
              <br />
              <Typer dataText={TEXTS} />
            </div>
            <LandingSearch />
          </div>
          <div className="feature-img-container" />
        </div>
        {/* <div className="landing-footer-wrapper">
          <Link to="/apps/affiliate/registration" className="footer-action">
            Get Started
          </Link>
          <Link to="/apps/affiliate/login" className="footer-action right">
            Login
          </Link>
          <div className="flex-fill" />
          <div
            className="footer-action"
            onClick={() => onAppInstallerClick(false)}
          >
            <img
              src={
                require('../assets/images/donwload-from-app-store.svg').default
              }
              alt=""
              className="footer-store-icon"
            />
          </div>
          <div
            className="footer-action"
            onClick={() => onAppInstallerClick(true)}
          >
            <img
              src={
                require('../assets/images/download-from-play-store.svg').default
              }
              alt=""
              className="footer-store-icon"
            />
          </div>
        </div> */}
      </div>
    {/* </SiteLayout> */}
    </>
  );
};

export default SiteLadingPage;

const TEXTS = [
  { text: 'For Sales You Influence', color: '#5ABEA0' },
  { text: 'For Products Your Endorse', color: '#F7931A' },
  { text: 'For Brands You Promote', color: '#65A2D9' },
  { text: 'For Introductions You Make', color: '#F63448' },
];
