import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import menuOpenedIcon from '../../assets/images/menu-opened-icon.svg';
import menuClosedIcon from '../../assets/images/menu-closed-icon.svg';
import { sideBarMenu } from '../../configs';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import ReactTooltip from 'react-tooltip';
import ChatContext from '../../contexts/ChatContext';

const NavItems = () => {
  const history = useHistory();

  const {
    activeSideNav,
    setActiveSideNav,
    setActiveMenuGroup,
    activeSideMenuGroup,
    isNavCollapsed,
    setIsNavCollapsed,
  } = useContext(BrokerAppContext);
  const { toggleChatClose, isChatClosed } = useContext(ChatContext);

  const currentPath = history?.location?.pathname;

  const logOutHandler = () => {
    LocalStorageHelper.setAppDataOnLogOut();
    window.location.replace('/');
  };

  const isMenuActive = (item) => {
    if (currentPath === item.path) {
      return true;
    }

    const isFound = item.menus?.find((x) => currentPath === x.path);

    return !!isFound;
  };

  return (
    <>
      <div className="nav-wrapper">
        {sideBarMenu.map((item) => (
          <>
            {
              <Link
                key={item.menuTitle}
                className={`menu-group ${
                  activeSideMenuGroup === item.menuTitle ? 'opened' : ''
                } ${
                  activeSideMenuGroup !== '' &&
                  activeSideMenuGroup !== item.menuTitle
                    ? 'd-none'
                    : ''
                } ${isMenuActive(item) ? 'active' : ''}`}
                data-tip={item?.menuTitle}
                to={item.path || '#'}
              >
                <div
                  className="menu-group-wrapper"
                  onClick={
                    item.isChatAction
                      ? toggleChatClose
                      : () => {
                          if (item.menus && item.menus.length > 0) {
                            setIsNavCollapsed(false);
                            setActiveMenuGroup(
                              activeSideMenuGroup === item.menuTitle
                                ? ''
                                : item.menuTitle,
                            );
                          }
                        }
                  }
                >
                  {item.icon && (
                    <img
                      className={`menu-group-icon ${
                        isNavCollapsed ? '' : 'd-none'
                      }`}
                      src={item.icon}
                      alt=""
                    />
                  )}
                  <h6
                    className={`menu-group-title ${
                      isNavCollapsed ? 'd-none' : ''
                    }`}
                  >
                    {item.menuTitle}
                  </h6>
                  {(item.menus && item.menus.length > 0) ||
                  item.isChatAction ? (
                    <img
                      className={`menu-status-icons ${
                        isNavCollapsed ? 'd-none' : ''
                      }`}
                      style={
                        item.isChatAction
                          ? {
                              transform: `rotate(${
                                isChatClosed ? '270deg' : '90deg'
                              })`,
                            }
                          : {}
                      }
                      src={
                        item.isChatAction
                          ? menuClosedIcon
                          : activeSideMenuGroup === item.menuTitle
                          ? menuOpenedIcon
                          : menuClosedIcon
                      }
                      alt=""
                    />
                  ) : null}
                </div>
                <ul
                  className={`broker-nav ${
                    activeSideMenuGroup === item.menuTitle ? 'opened' : ''
                  }`}
                >
                  {item.menus.map((menu) =>
                    menu.disabled ? (
                      <div key={menu.title}>
                        <li
                          className={`menu-item disabled ${
                            menu.title === activeSideNav ? 'active' : ''
                          }`}
                        >
                          <span>
                            <img src={menu.icon} alt="" />
                          </span>
                          {menu.title}
                        </li>
                      </div>
                    ) : (
                      <Link key={menu.title} to={menu.path || '#'}>
                        <li
                          className={`menu-item ${
                            menu.path === currentPath ? 'active' : ''
                          }`}
                          onClick={() => setActiveSideNav(menu.title)}
                        >
                          {menu.icon && (
                            <span>
                              <img src={menu.icon} alt="" />
                            </span>
                          )}
                          {menu.title}
                        </li>
                      </Link>
                    ),
                  )}
                </ul>
              </Link>
            }
            {isNavCollapsed && (
              <ReactTooltip
                place="right"
                type="dark"
                effect="solid"
                border
                borderColor="#343C5B"
                backgroundColor="#fff"
                textColor="#343C5B"
                className="tooltip"
              />
            )}
          </>
        ))}
      </div>
      <div className="account-actions menu-group">
        <div className="broker-nav">
          <div className="menu-item" onClick={logOutHandler}>
            <span>
              <i className={`fas fa-lock ${isNavCollapsed ? 'closed' : ''}`} />
            </span>
            <h6
              className={`menu-group-title ${isNavCollapsed ? 'd-none' : ''}`}
            >
              Lock
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavItems;
