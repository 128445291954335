import React, { useEffect, useState } from 'react';
import { usdValueFormatterWithoutSign } from '../../utils';
import MoneyMarketActionModal from './MoneyMarketActionModal';
import MoneyMarketFeeUpdateScreen from './MoneyMarketFeeUpdateScreen';

export default function MoneyMarketCoinList({
  feeList,
  search,
  refetch,
  bonds,
  selectedBrand,
  setHideNav,
}) {
  const Data = feeList.filter((itm) =>
    itm?.coin?.toLowerCase().includes(search.toLowerCase()),
  );
  const [coin, setCoin] = useState('');
  const [isMoneyMarketActionModel, setIsMoneyMarketActionModel] =
    useState(false);
  const [isMoneyMarketFeeUpdateScreen, setIsMoneyMarketFeeUpdateScreen] =
    useState(false);

  useEffect(() => {
    if (isMoneyMarketFeeUpdateScreen) {
      setHideNav(true);
    } else {
      setHideNav(false);
      setCoin('');
    }
  }, [isMoneyMarketFeeUpdateScreen]);

  return (
    <>
      {isMoneyMarketFeeUpdateScreen ? (
        <MoneyMarketFeeUpdateScreen
          selectedBrand={selectedBrand}
          coin={coin}
          refetch={refetch}
          bonds={bonds}
          setIsMoneyMarketFeeUpdateScreen={setIsMoneyMarketFeeUpdateScreen}
        />
      ) : (
        <div>
          {Data.map((item, key) => {
            return (
              <>
                <div
                  key={key}
                  onClick={() => {
                    setCoin(item.coin);
                    setIsMoneyMarketActionModel(true);
                  }}
                  className="exchangePairList_item_container"
                >
                  <div className="pair_item_text">{item.coin}</div>
                  <div className="pair_item_text">
                    {usdValueFormatterWithoutSign.format(
                      item?.[bonds ? 'iced' : 'liquid'],
                    )}
                    %
                  </div>
                </div>
              </>
            );
          })}

          <MoneyMarketActionModal
            coin={coin}
            setCoin={setCoin}
            refetch={refetch}
            bonds={bonds}
            setIsMoneyMarketFeeUpdateScreen={setIsMoneyMarketFeeUpdateScreen}
            selectedBrand={selectedBrand}
            isMoneyMarketActionModel={isMoneyMarketActionModel}
            setIsMoneyMarketActionModel={setIsMoneyMarketActionModel}
          />
        </div>
      )}
    </>
  );
}
