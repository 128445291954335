import React, { useState } from 'react';
import { Button } from 'antd';

const CommissionForm = ({
  changeBrokerFee,
  changeBrokerSellingFee,
  brokerSellingFeeLoading,
  brokerFeeLoading
}) => {
  const [brokerFeeInput, setBrokerFeeInput] = useState('');
  const [brokerSellingFeeInput, setBrokerSellingFeeInput] = useState('');

  const re = new RegExp(/^\d*\.?\d*$/);

  const onFeeChange = e => {
    console.log('Vall', e.target.value);
    if (e.target.value === '' || re.test(e.target.value)) {
      setBrokerFeeInput(e.target.value);
    }
  };
  const onSellingFeeChange = e => {
    if (e.target.value === '' || re.test(e.target.value)) {
      setBrokerSellingFeeInput(e.target.value);
    }
  };

  return (
    <div className="row commission-form mx-0">
      <tbody>
        <tr className="input-wrapper">
          <td>
            <label className="current-fee">
              Your current Broker Fee : <span>{0} %</span> Change Your Broker
              Fee:
            </label>
          </td>
          <td>
            <input
              type="text"
              className="commission-input"
              value={brokerFeeInput}
              placeholder="Input your broker fee"
              onChange={onFeeChange}
            />
          </td>
          <td>
            <Button
              type="primary"
              loading={brokerFeeLoading}
              onClick={() => {
                changeBrokerFee(brokerFeeInput);
                setBrokerFeeInput('');
              }}
            >
              Change
            </Button>
          </td>
        </tr>
        <tr className="input-wrapper">
          <td>
            <label className="current-fee">
              Your current Broker Fee for Selling : <span>{0} %</span> Change
              Your Broker Fee:
            </label>
          </td>
          <td>
            <input
              type="text"
              className="commission-input"
              value={brokerSellingFeeInput}
              placeholder="Input your selling broker fee"
              onChange={onSellingFeeChange}
            />
          </td>
          <td>
            <Button
              type="primary"
              loading={brokerSellingFeeLoading}
              onClick={() => {
                changeBrokerSellingFee(brokerSellingFeeInput);
                setBrokerSellingFeeInput('');
              }}
            >
              Change
            </Button>
          </td>
        </tr>
      </tbody>
    </div>
  );
};

export default CommissionForm;
