import React from 'react'

export default function LoadingWithdrawSection() {
    return (
        <div className='withdraw_section_content'
            style={{
                height: "85%",
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',

            }}>
            <div className="full-loading-wrapper"
                style={{
                    position: 'relative'

                }}>
                <img
                    className='full-loading-logo'
                    style={{
                        marginBottom: "15%"
                    }}
                    src={require('../../assets/mobileassets/affiliate_bank_icon.svg').default}
                />
            </div>
            <div className='loading_withdraw'>
                Withdrawing Commissions To InstaCrypto. This Can Take Several Minutes
            </div>

        </div>
    )
}

