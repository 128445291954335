import React, { useContext } from 'react';
import CRMTable from './CRMTable';
import CRMEmptyComponent from './CRMEmptyComponent';
import { CRMContext } from '../../contexts/CRMContext';
import TablePagination from './TablePagination';

const CRMTabFragment = () => {
  const { activeCrmTab, unfilteredDataSet } = useContext(CRMContext);

  return (
    <div className="table-container">
      {activeCrmTab ? <CRMTable /> : <CRMEmptyComponent />}
      {activeCrmTab && unfilteredDataSet && unfilteredDataSet.length > 0 && (
        <TablePagination />
      )}
    </div>
  );
};

export default CRMTabFragment;
