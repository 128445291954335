import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import ChatContext from '../../../contexts/ChatContext';
import ChatLearnSelectCategory from './ChatLearnSelectCategory';
import ChatLearnShowContent from './ChatLearnShowContent';

const Learn = () => {
  const { botActiveSubCategory } = useContext(ChatContext);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    setSelectedCategory('');
    setCategories([]);
    if (botActiveSubCategory) {
      Axios.get(
        `https://fxagency.apimachine.io/category/publication/${botActiveSubCategory?._id}`,
      ).then(({ data }) => {
        // console.log('Data', data);
        if (data.status) {
          setCategories(data.data);
        }
      });
    }
  }, [botActiveSubCategory]);

  return (
    <>
      {selectedCategory ? (
        <ChatLearnShowContent category={selectedCategory} />
      ) : (
        <ChatLearnSelectCategory
          categories={categories}
          setCategory={setSelectedCategory}
        />
      )}
    </>
  );
};

export default Learn;
