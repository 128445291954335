import React, { useContext, useEffect } from 'react';
import { CRMContext } from '../../../../contexts/CRMContext';
import DirectOTCRevenueHeader from '../../TableHeaders/DirectOTCRevenueHeader';
import DirectOTCRevenueItem from '../../TableItems/DirectOTCRevenueItem';

const DirectOTCTransactionalRevenue = () => {
  const {
    directOtcRevenue,
    setCurrentSets,
    currentDataSet,
    currentPage
  } = useContext(CRMContext);

  useEffect(() => {
    setCurrentSets(directOtcRevenue);
  }, []);

  const postPerPage = 10;

  const offset = (currentPage - 1) * postPerPage;

  return (
    <>
      <DirectOTCRevenueHeader />
      {currentDataSet.slice(offset, offset + postPerPage).map((item, index) => (
        <DirectOTCRevenueItem key={index} data={item} />
      ))}
    </>
  );
};

export default DirectOTCTransactionalRevenue;
