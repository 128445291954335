import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import saveIcon from '../../assets/images/save-icons.svg';
import dashSaveIcon from '../../assets/images/dashboard-save-icon.svg';
import { CRMContext } from '../../contexts/CRMContext';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const SaveDashboard = () => {
  const { crmTabs } = useContext(CRMContext);

  const [dashNameInput, setDashNameInput] = useState('');

  const saveDashboardHandler = () => {
    if (crmTabs.length > 0) {
      if (dashNameInput) {
        LocalStorageHelper.saveDashboard(crmTabs, dashNameInput);
        toast.success('New Dashboard saved');
        setDashNameInput('');
      } else {
        toast.error('Please input a name for dashboard');
      }
    } else {
      toast.error('No tabs available');
    }
  };

  return (
    <div className="save-dashboard-wrapper">
      <img src={saveIcon} alt="" className="save-icon" />
      <div className="save-action-wrapper">
        <div className="save-header">
          <h6 className="title">Create Dashboard View</h6>
        </div>
        <div className="save-form-wrapper">
          <input
            type="text"
            className="save-name-input"
            placeholder="Name The Current View"
            value={dashNameInput}
            onChange={e => setDashNameInput(e.target.value)}
          />
          <img
            src={dashSaveIcon}
            alt=""
            className="save-icon"
            onClick={saveDashboardHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default SaveDashboard;
