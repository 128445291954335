import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AFFILIATE_BANK_APP_CODE } from '../configs';
import LoginPage from '../pages/AffliateBankApp/LoginPage';
import PreRegisterPage from '../pages/AffliateBankApp/PreRegisterPage';
import RegistrationRouter from './RegistrationRouter';

const AffiliateBankRouter = () => {
  const APP_DATA = {
    headerLogo: require('../assets/images/affiliate-bank-icon.svg').default,
    appCode: AFFILIATE_BANK_APP_CODE,
  };

  return (
    <BrowserRouter basename="apps/affiliatebank">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/pre-registration" component={PreRegisterPage} />
        <RegistrationRouter basename="apps/broker" appData={APP_DATA} />
      </Switch>
    </BrowserRouter>
  );
};

export default AffiliateBankRouter;
