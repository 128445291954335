import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BrokerAppContext } from '../contexts/BrokerAppContext';

const SiteNavBar = ({ isOpen, onClose }) => {
  const { isMobile } = useContext(BrokerAppContext);

  return (
    <div className={`site-navbar-wrapper ${isOpen ? ' ' : '  d-none'}`}>
      <div
        className={`nav-container  ${
          isOpen ? 'animated slideInLeft faster' : 'animated slideInLeft'
        }`}
      >
        {isMobile && (
          <div className="close-btn" onClick={onClose}>
            <img
              src={require('../assets/images/times-colored-icon.svg').default}
              alt=""
              className="close-icon"
            />
          </div>
        )}
        <img
          src={
            require('../assets/images/affliate-app-dark-full-logo.svg').default
          }
          alt=""
          className="header-image"
        />
        <div className="links-container">
          {LINKS.map((item) => (
            <Link key={item.title} to={item.to} className="link-item">
              {item.title}
            </Link>
          ))}
        </div>
      </div>
      <div className="overlay" onClick={onClose} />
    </div>
  );
};

export default SiteNavBar;

const LINKS = [
  { title: 'How It Works', to: '#' },
  { title: 'Brands', to: '/brands' },
  { title: 'Mobile Apps', to: '#' },
  { title: 'Leaderboard', to: '#' },
];
